import { Disclosure, RadioGroup } from '@headlessui/react';
import {
  ChevronRightIcon,
  MinusIcon,
  CheckIcon,
  XMarkIcon,
  NoSymbolIcon,
} from '@heroicons/react/24/outline';
import { useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RuleTypes, User, UserRequirementType } from '@youshift/shared/types';

import Toggle from '../../../../components/Toggle';
import { MinMaxCounter } from '../../../../components/MinMaxCounter';
import { Action, RoleConfig, State, UserConfig } from './types';
import { classNames } from '../../../../utils/helpers';
import InfoIcon from '../../../../components/InfoIcon';

interface DefineReqsProps {
  state: State;
  dispatch: React.Dispatch<Action>;
  numberOfSlotsSelected: number | undefined;
  selectedUser?: number;
  ruleType?: RuleTypes | null;
}

function ThreeWayToggleRole({
  users,
  dispatch,
  roleId,
}: {
  users: UserConfig[];
  dispatch: React.Dispatch<Action>;
  roleId: number;
}) {
  const [selected, setSelected] = useState(
    users.every(user => !user.included)
      ? 'unincluded'
      : users.every(
            user =>
              user.included &&
              user.min_slots === 0 &&
              user.max_slots === 0 &&
              user.max_duration === 0 &&
              user.min_duration === 0,
          )
        ? 'excluded'
        : users.every(
              user =>
                user.included &&
                !(
                  user.min_slots === 0 &&
                  user.max_slots === 0 &&
                  user.max_duration === 0 &&
                  user.min_duration === 0
                ),
            )
          ? 'included'
          : null,
  );

  return (
    <RadioGroup
      value={selected}
      onChange={setSelected}
      className="flex items-center gap-x-3"
    >
      <RadioGroup.Option
        value="unincluded"
        aria-label="unincluded"
        className={({ checked, active }) =>
          classNames(
            'text-gray-500',
            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-current focus:outline-none',
            checked ? 'ring-2 opacity-100' : 'opacity-40',
            active && checked ? 'ring ring-offset-1' : '',
          )
        }
        onClick={() => {
          setSelected('unincluded');
          dispatch({
            type: 'UNINCLUDE_ROLE',
            id_role: roleId,
          });
        }}
      >
        <span
          aria-hidden="true"
          className="w-5 h-5 rounded-full border border-black/10 bg-current relative"
        >
          <MinusIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
        </span>
      </RadioGroup.Option>

      <RadioGroup.Option
        value="included"
        aria-label="included"
        className={({ checked, active }) =>
          classNames(
            'text-green-500',
            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-current focus:outline-none',
            checked ? 'ring-2 opacity-100' : 'opacity-40',
            active && checked ? 'ring ring-offset-1' : '',
          )
        }
        onClick={() => {
          setSelected('included');
          dispatch({
            type: 'INCLUDE_ROLE',
            id_role: roleId,
          });
        }}
      >
        <span
          aria-hidden="true"
          className="w-5 h-5 rounded-full border border-black/10 bg-current relative"
        >
          <CheckIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
        </span>
      </RadioGroup.Option>

      <RadioGroup.Option
        value="excluded"
        aria-label="excluded"
        className={({ checked, active }) =>
          classNames(
            'text-red-500',
            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-current focus:outline-none',
            checked ? 'ring-2 opacity-100' : 'opacity-40',
            active && checked ? 'ring ring-offset-1' : '',
          )
        }
        onClick={() => {
          setSelected('excluded');
          dispatch({
            type: 'EXCLUDE_ROLE',
            id_role: roleId,
          });
        }}
      >
        <span
          aria-hidden="true"
          className="w-5 h-5 rounded-full border border-black/10 bg-current relative"
        >
          <NoSymbolIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
        </span>
      </RadioGroup.Option>
    </RadioGroup>
  );
}

function ThreeWayToggleUser({
  user,
  dispatch,
}: {
  user: UserConfig;
  dispatch: React.Dispatch<Action>;
}) {
  const [selected, setSelected] = useState(
    !user.included
      ? 'unincluded'
      : user.min_slots === 0 &&
          user.max_slots === 0 &&
          user.max_duration === 0 &&
          user.min_duration === 0
        ? 'excluded'
        : 'included',
  );

  return (
    <RadioGroup
      value={selected}
      onChange={setSelected}
      className="flex items-center gap-x-3"
    >
      <RadioGroup.Option
        value="unincluded"
        aria-label="unincluded"
        className={({ checked, active }) =>
          classNames(
            'text-gray-500',
            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-current focus:outline-none',
            checked ? 'ring-2 opacity-100' : 'opacity-40',
            active && checked ? 'ring ring-offset-1' : '',
          )
        }
        onClick={() => {
          setSelected('unincluded');
          dispatch({
            type: 'UNINCLUDE_USER',
            id_user: user.id_user,
          });
        }}
      >
        <span
          aria-hidden="true"
          className="w-5 h-5 rounded-full border border-black/10 bg-current relative"
        >
          <MinusIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
        </span>
      </RadioGroup.Option>

      <RadioGroup.Option
        value="included"
        aria-label="included"
        className={({ checked, active }) =>
          classNames(
            'text-green-500',
            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-current focus:outline-none',
            checked ? 'ring-2 opacity-100' : 'opacity-40',
            active && checked ? 'ring ring-offset-1' : '',
          )
        }
        onClick={() => {
          setSelected('included');
          dispatch({
            type: 'INCLUDE_USER',
            id_user: user.id_user,
          });
        }}
      >
        <span
          aria-hidden="true"
          className="w-5 h-5 rounded-full border border-black/10 bg-current relative"
        >
          <CheckIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
        </span>
      </RadioGroup.Option>

      <RadioGroup.Option
        value="excluded"
        aria-label="excluded"
        className={({ checked, active }) =>
          classNames(
            'text-red-500',
            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-current focus:outline-none',
            checked ? 'ring-2 opacity-100' : 'opacity-40',
            active && checked ? 'ring ring-offset-1' : '',
          )
        }
        onClick={() => {
          setSelected('excluded');
          dispatch({
            type: 'EXCLUDE_USER',
            id_user: user.id_user,
          });
        }}
      >
        <span
          aria-hidden="true"
          className="w-5 h-5 rounded-full border border-black/10 bg-current relative"
        >
          <NoSymbolIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
        </span>
      </RadioGroup.Option>
    </RadioGroup>
  );
}

export default function DefineReqs({
  state,
  dispatch,
  numberOfSlotsSelected,
  selectedUser,
  ruleType,
}: DefineReqsProps) {
  const { t } = useTranslation();
  const roleReqs = useMemo(
    () =>
      Object.entries(state.roles).reduce(
        (acc, [roleId, { users }]) => {
          // check if req it's the same for all users and if so return this
          const commonMinSlots = users.every(
            user => user.min_slots === users[0].min_slots,
          )
            ? users[0].min_slots
            : // check the case where req is currently NaN for all users (numeric input is blank; possibly because the role numeric input is blank)
              users.every(user => Number.isNaN(user.min_slots))
              ? NaN
              : // if the req is different for at least one user; this is the case where the role numeric input is disabled
                undefined;

          const commonMaxSlots = users.every(
            user => user.max_slots === users[0].max_slots,
          )
            ? users[0].max_slots
            : users.every(user => Number.isNaN(user.max_slots))
              ? NaN
              : undefined;

          const commonMinDuration = users.every(
            user => user.min_duration === users[0].min_duration,
          )
            ? users[0].min_duration
            : users.every(user => Number.isNaN(user.min_duration))
              ? NaN
              : undefined;

          const commonMaxDuration = users.every(
            user => user.max_duration === users[0].max_duration,
          )
            ? users[0].max_duration
            : users.every(user => Number.isNaN(user.max_duration))
              ? NaN
              : undefined;

          acc[roleId] = {
            min_slots: commonMinSlots,
            max_slots: commonMaxSlots,
            min_duration: commonMinDuration,
            max_duration: commonMaxDuration,
          };
          return acc;
        },
        {} as Record<
          string,
          {
            min_slots: number | undefined | typeof NaN;
            max_slots: number | undefined | typeof NaN;
            min_duration: number | undefined | typeof NaN;
            max_duration: number | undefined | typeof NaN;
          }
        >,
      ),
    [state],
  );

  return (
    <div>
      <fieldset className="mb-6">
        <legend className="font-semibold text-gray-900 mb-1">
          {t('manager.rulesConfig.howToConfigureRule')}
        </legend>

        <div className="flex flex-col gap-3">
          <div className="flex gap-3">
            <div className="flex items-center">
              <input
                id="slots"
                name="config-type"
                type="radio"
                checked={state.req_type === 'SLOTS'}
                onChange={() =>
                  dispatch({
                    type: 'TOGGLE_REQ_TYPE',
                    value: UserRequirementType.SLOTS,
                  })
                }
                className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-teal-600 checked:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
              />
              <label
                htmlFor="slots"
                className="ml-3 block text-sm/6 font-medium text-gray-900"
              >
                {t('manager.rulesConfig.slotsConfig')}
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="hours"
                name="config-type"
                type="radio"
                checked={state.req_type === 'DURATION'}
                onChange={() =>
                  dispatch({
                    type: 'TOGGLE_REQ_TYPE',
                    value: UserRequirementType.DURATION,
                  })
                }
                className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-teal-600 checked:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
              />
              <label
                htmlFor="hours"
                className="ml-3 block text-sm/6 font-medium text-gray-900"
              >
                {t('manager.rulesConfig.hoursConfig')}
              </label>
            </div>
          </div>

          {/* Add legend for ThreeWayToggle */}
          {!selectedUser && (
            <div className="flex flex-wrap gap-2 text-sm self-end">
              <div className="inline-flex items-center gap-x-2 rounded-full px-2 py-1 text-gray-900 ring-1 ring-inset ring-gray-200">
                <span className="w-5 h-5 rounded-full bg-gray-500 relative border border-black/10">
                  <MinusIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
                </span>
                {t('manager.rulesConfig.unincluded')}
                <InfoIcon
                  title={t('manager.rulesConfig.unincluded')}
                  content={t('manager.rulesConfig.unincludedInfo')}
                  className="w-4 h-4"
                />
              </div>
              <div className="inline-flex items-center gap-x-2 rounded-full px-2 py-1 text-gray-900 ring-1 ring-inset ring-gray-200">
                <span className="w-5 h-5 rounded-full bg-green-500 relative border border-black/10">
                  <CheckIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
                </span>
                {t('manager.rulesConfig.included')}
              </div>
              <div className="inline-flex items-center gap-x-2 rounded-full px-2 py-1 text-gray-900 ring-1 ring-inset ring-gray-200">
                <span className="w-5 h-5 rounded-full bg-red-500 relative border border-black/10">
                  <NoSymbolIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
                </span>
                {t('manager.rulesConfig.excluded')}
              </div>
            </div>
          )}
        </div>
      </fieldset>
      {Object.entries(state.roles).map(
        ([roleId, { users, role_name }], idx) => {
          // Filter users if selectedUser is provided
          const displayUsers = selectedUser
            ? users.filter(user => user.id_user === selectedUser)
            : users;

          // Skip rendering this role group if no matching users
          if (displayUsers.length === 0) return null;

          // If there's only one user selected, render just the user without role wrapper
          if (selectedUser && displayUsers.length === 1) {
            const user = displayUsers[0];
            return (
              <div key={roleId} className="grid grid-cols-2">
                <div className="flex flex-row items-center gap-2 border-l-2 border-l-blue-600 pl-3">
                  <p>{user.name}</p>
                </div>
                <div className="flex gap-4 mt-3">
                  {state.req_type === UserRequirementType.SLOTS && (
                    <MinMaxCounter
                      min={user.min_slots}
                      max={user.max_slots}
                      setMin={value =>
                        dispatch({
                          type: 'UPDATE_USER_REQS',
                          id_user: user.id_user,
                          payload: { min_slots: value },
                        })
                      }
                      disabled={!user.included}
                      setMax={value =>
                        dispatch({
                          type: 'UPDATE_USER_REQS',
                          id_user: user.id_user,
                          payload: { max_slots: value },
                        })
                      }
                      minText={
                        idx === 0 ? t('manager.rulesConfig.minText') : undefined
                      }
                      maxText={
                        idx === 0 ? t('manager.rulesConfig.maxText') : undefined
                      }
                    />
                  )}
                  {state.req_type === UserRequirementType.DURATION && (
                    <MinMaxCounter
                      min={user.min_duration}
                      max={user.max_duration}
                      disabled={!user.included}
                      setMin={value =>
                        dispatch({
                          type: 'UPDATE_USER_REQS',
                          id_user: user.id_user,
                          payload: { min_duration: value },
                        })
                      }
                      setMax={value =>
                        dispatch({
                          type: 'UPDATE_USER_REQS',
                          id_user: user.id_user,
                          payload: { max_duration: value },
                        })
                      }
                      minText={
                        idx === 0
                          ? t('manager.rulesConfig.minHText')
                          : undefined
                      }
                      maxText={
                        idx === 0
                          ? t('manager.rulesConfig.maxHText')
                          : undefined
                      }
                    />
                  )}
                </div>
              </div>
            );
          }

          return (
            <Disclosure
              key={roleId}
              defaultOpen={
                displayUsers.length < 2 ||
                (!displayUsers.every(user => user.included) &&
                  !displayUsers.every(user => !user.included))
              }
            >
              {({ open }) => (
                <>
                  <div className="grid grid-cols-2">
                    <div className="flex flex-row items-center gap-4 border-l-2 border-l-blue-600 pl-3">
                      {!selectedUser && (
                        <Disclosure.Button
                          as="button"
                          className="flex flex-row items-center"
                        >
                          <ChevronRightIcon
                            className={`${open ? 'rotate-90 transform' : ''} w-5 h-5`}
                          />
                        </Disclosure.Button>
                      )}
                      {!selectedUser && (
                        <ThreeWayToggleRole
                          users={users}
                          dispatch={dispatch}
                          roleId={Number(roleId)}
                          key={`${roleReqs[roleId].min_duration}-${roleReqs[roleId].max_duration}-${roleReqs[roleId].min_slots}-${roleReqs[roleId].max_slots}`}
                        />
                      )}
                      {role_name}
                      {!selectedUser && ` (${users.length})`}
                    </div>
                    <div className="flex gap-4 mt-3">
                      {state.req_type === UserRequirementType.SLOTS && (
                        <MinMaxCounter
                          min={roleReqs[roleId].min_slots}
                          max={roleReqs[roleId].max_slots}
                          setMin={value =>
                            dispatch({
                              type: 'UPDATE_ROLE_REQS',
                              id_role: Number(roleId),
                              payload: { min_slots: value },
                            })
                          }
                          setMax={value =>
                            dispatch({
                              type: 'UPDATE_ROLE_REQS',
                              id_role: Number(roleId),
                              payload: { max_slots: value },
                            })
                          }
                          minText={
                            idx === 0
                              ? t('manager.rulesConfig.minText')
                              : undefined
                          }
                          maxText={
                            idx === 0
                              ? t('manager.rulesConfig.maxText')
                              : undefined
                          }
                          disabled={
                            roleReqs[roleId].min_slots === undefined ||
                            roleReqs[roleId].max_slots === undefined ||
                            users.some(user => !user.included)
                          }
                        />
                      )}
                      {state.req_type === UserRequirementType.DURATION && (
                        <MinMaxCounter
                          min={roleReqs[roleId].min_duration}
                          max={roleReqs[roleId].max_duration}
                          setMin={value =>
                            dispatch({
                              type: 'UPDATE_ROLE_REQS',
                              id_role: Number(roleId),
                              payload: { min_duration: value },
                            })
                          }
                          setMax={value =>
                            dispatch({
                              type: 'UPDATE_ROLE_REQS',
                              id_role: Number(roleId),
                              payload: { max_duration: value },
                            })
                          }
                          minText={
                            idx === 0
                              ? t('manager.rulesConfig.minHText')
                              : undefined
                          }
                          maxText={
                            idx === 0
                              ? t('manager.rulesConfig.maxHText')
                              : undefined
                          }
                          disabled={
                            roleReqs[roleId].min_duration === undefined ||
                            roleReqs[roleId].max_duration === undefined ||
                            users.some(user => !user.included)
                          }
                        />
                      )}
                    </div>
                  </div>
                  <Disclosure.Panel
                    as="div"
                    className="grid grid-cols-2 ml-16 mt-3 border-l-2 border-l-blue-600 pl-3 items-center gap-y-4"
                  >
                    {[...displayUsers]
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(user => (
                        <>
                          <div
                            key={user.id_user}
                            className="flex flex-row items-center gap-2"
                          >
                            {!selectedUser && (
                              <ThreeWayToggleUser
                                user={user}
                                dispatch={dispatch}
                                key={`${user.included}-${user.min_duration}-${user.max_duration}-${user.min_slots}-${user.max_slots}`}
                              />
                            )}
                            <p>{user.name}</p>
                          </div>
                          <div className="flex gap-4">
                            {state.req_type === UserRequirementType.SLOTS && (
                              <MinMaxCounter
                                min={user.min_slots}
                                max={user.max_slots}
                                setMin={value =>
                                  dispatch({
                                    type: 'UPDATE_USER_REQS',
                                    id_user: user.id_user,
                                    payload: { min_slots: value },
                                  })
                                }
                                disabled={!user.included}
                                setMax={value =>
                                  dispatch({
                                    type: 'UPDATE_USER_REQS',
                                    id_user: user.id_user,
                                    payload: { max_slots: value },
                                  })
                                }
                              />
                            )}
                            {state.req_type ===
                              UserRequirementType.DURATION && (
                              <MinMaxCounter
                                min={user.min_duration}
                                max={user.max_duration}
                                disabled={!user.included}
                                setMin={value =>
                                  dispatch({
                                    type: 'UPDATE_USER_REQS',
                                    id_user: user.id_user,
                                    payload: { min_duration: value },
                                  })
                                }
                                setMax={value =>
                                  dispatch({
                                    type: 'UPDATE_USER_REQS',
                                    id_user: user.id_user,
                                    payload: { max_duration: value },
                                  })
                                }
                              />
                            )}
                          </div>
                        </>
                      ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          );
        },
      )}
    </div>
  );
}
