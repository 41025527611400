import { VirtualSlot } from '@youshift/shared/types';

/**
 * Checks if three virtual slots are adjacent to each other in time.
 *
 * @param prevSlot Previous virtual slot
 * @param currSlot Current virtual slot being checked
 * @param nextSlot Next virtual slot
 * @returns true if slots are adjacent, false otherwise
 */
export const checkVirtualSlotsAreAdjacent = (
  prevSlot: VirtualSlot,
  currSlot: VirtualSlot,
  nextSlot: VirtualSlot,
): boolean => {
  // Check if prevSlot ends when currSlot starts
  const prevAdjacent = prevSlot.end === currSlot.start;

  // Check if currSlot ends when nextSlot starts
  const nextAdjacent = currSlot.end === nextSlot.start;

  return prevAdjacent && nextAdjacent;
};

export enum VirtualSlotTripletErrorType {
  VIRTUAL_SLOT_TRIPLET_FEASIBLE_MAX_NEED = 'VIRTUAL_SLOT_TRIPLET_FEASIBLE_MAX_NEED',
  VIRTUAL_SLOT_TRIPLET_REACHABLE_MIN_NEED = 'VIRTUAL_SLOT_TRIPLET_REACHABLE_MIN_NEED',
}

// Whether the current slot min need is reachable given the neighbors max needs.
export interface VirtualSlotTripletReachableMinNeedErrorContext {
  prevSlot: VirtualSlot;
  currSlot: VirtualSlot;
  nextSlot: VirtualSlot;
  maxRestrictedByNeighbors: number;
  maxRestrictedByNeighborsPrev: number;
  maxRestrictedByNeighborsNext: number;
  currentSlotMinNeed: number;
}

// Whether the current slot would have to be exceeded to satisfy the neighbors min needs.
export interface VirtualSlotTripletInfeasibleMaxNeedErrorContext {
  prevSlot: VirtualSlot;
  currSlot: VirtualSlot;
  nextSlot: VirtualSlot;
  minRequiredByNeighbors: number;
  minRequiredByNeighborsPrev: number;
  minRequiredByNeighborsNext: number;
  currentSlotMaxNeed: number;
}

export type VirtualSlotTripletError =
  | {
      type: VirtualSlotTripletErrorType.VIRTUAL_SLOT_TRIPLET_FEASIBLE_MAX_NEED;
      context: VirtualSlotTripletInfeasibleMaxNeedErrorContext;
    }
  | {
      type: VirtualSlotTripletErrorType.VIRTUAL_SLOT_TRIPLET_REACHABLE_MIN_NEED;
      context: VirtualSlotTripletReachableMinNeedErrorContext;
    };

/**
 * Checks for maximum incompatibility for the current VirtualSlot within a triplet.
 *
 * @param prevSlot Previous virtual slot
 * @param currSlot Current virtual slot being checked
 * @param nextSlot Next virtual slot
 * @returns [errorType, context] tuple
 */
export const checkVirtualSlotTripletReachableMinNeed = (
  prevSlot: VirtualSlot,
  currSlot: VirtualSlot,
  nextSlot: VirtualSlot,
): VirtualSlotTripletError | null => {
  // Virtual slots must be adjacent to each other.
  // In some cases, the virtual slots are not adjacent to each other, when there is a gap in the section slots.
  if (!checkVirtualSlotsAreAdjacent(prevSlot, currSlot, nextSlot)) {
    return null;
  }

  const currentSlots = new Set(currSlot.section_slots);
  const prevOnlySlots = new Set(
    prevSlot.section_slots.filter(x => !currentSlots.has(x)),
  );
  const prevSlotAndCurrentSlots = new Set(
    prevSlot.section_slots.filter(x => currentSlots.has(x)),
  );
  const nextSlotAndCurrentSlots = new Set(
    nextSlot.section_slots.filter(x => currentSlots.has(x)),
  );
  const nextOnlySlots = new Set(
    nextSlot.section_slots.filter(x => !currentSlots.has(x)),
  );
  const currentOnlySlots = new Set(
    currSlot.section_slots.filter(
      x =>
        !prevSlot.section_slots.includes(x) &&
        !nextSlot.section_slots.includes(x),
    ),
  );

  // If there are any unique SectionSlots, then the current slot can independently add workers
  if (currentOnlySlots.size > 0) {
    return null;
  }

  const maxRestrictedByNeighborsPrev = prevSlotAndCurrentSlots.size
    ? prevSlot.max_need
    : 0;
  const maxRestrictedByNeighborsNext = nextSlotAndCurrentSlots.size
    ? nextSlot.max_need
    : 0;
  // Calculate available maximum capacity based on shared SectionSlots
  const maxRestrictedByNeighbors =
    maxRestrictedByNeighborsPrev + maxRestrictedByNeighborsNext;

  if (maxRestrictedByNeighbors < currSlot.min_need) {
    return {
      type: VirtualSlotTripletErrorType.VIRTUAL_SLOT_TRIPLET_REACHABLE_MIN_NEED,
      context: {
        prevSlot,
        currSlot,
        nextSlot,
        maxRestrictedByNeighbors,
        maxRestrictedByNeighborsPrev,
        maxRestrictedByNeighborsNext,
        currentSlotMinNeed: currSlot.min_need,
      },
    };
  }

  return null;
};

/**
 *
 * @param prevSlot Previous virtual slot
 * @param currSlot Current virtual slot being checked
 * @param nextSlot Next virtual slot
 * @returns [errorType, context] tuple
 */
export const checkVirtualSlotTripletFeasibleMaxNeed = (
  prevSlot: VirtualSlot,
  currSlot: VirtualSlot,
  nextSlot: VirtualSlot,
): VirtualSlotTripletError | null => {
  // Virtual slots must be adjacent to each other.
  // In some cases, the virtual slots are not adjacent to each other, when there is a gap in the section slots.
  if (!checkVirtualSlotsAreAdjacent(prevSlot, currSlot, nextSlot)) {
    return null;
  }

  const currentSlots = new Set(currSlot.section_slots);
  const prevOnlySlots = new Set(
    prevSlot.section_slots.filter(x => !currentSlots.has(x)),
  );
  const prevSlotAndCurrentSlots = new Set(
    prevSlot.section_slots.filter(x => currentSlots.has(x)),
  );
  const nextSlotAndCurrentSlots = new Set(
    nextSlot.section_slots.filter(x => currentSlots.has(x)),
  );
  const nextOnlySlots = new Set(
    nextSlot.section_slots.filter(x => !currentSlots.has(x)),
  );
  const currentOnlySlots = new Set(
    currSlot.section_slots.filter(
      x =>
        !prevSlot.section_slots.includes(x) &&
        !nextSlot.section_slots.includes(x),
    ),
  );

  // If there are any unique SectionSlots, then the current slot can independently add workers
  if (currentOnlySlots.size > 0) {
    return null;
  }

  const minRequiredByNeighborsPrev =
    prevSlotAndCurrentSlots.size && !prevOnlySlots.size ? prevSlot.min_need : 0;
  const minRequiredByNeighborsNext =
    nextSlotAndCurrentSlots.size && !nextOnlySlots.size ? nextSlot.min_need : 0;
  // Calculate the forced minimum capacity based on the adjacent slots
  const minRequiredByNeighbors =
    minRequiredByNeighborsPrev + minRequiredByNeighborsNext;

  if (currSlot.max_need < minRequiredByNeighbors) {
    return {
      type: VirtualSlotTripletErrorType.VIRTUAL_SLOT_TRIPLET_FEASIBLE_MAX_NEED,
      context: {
        prevSlot,
        currSlot,
        nextSlot,
        minRequiredByNeighbors,
        minRequiredByNeighborsPrev,
        minRequiredByNeighborsNext,
        currentSlotMaxNeed: currSlot.max_need,
      },
    };
  }

  return null;
};

/**
 * Checks all virtual slots in a section for maximum incompatibility.
 *
 * @param virtualSlots Array of virtual slots to check
 * @returns Record of virtual slot id to error type and context
 */
export const checkAllVirtualSlotsMaxIncompatibility = (
  virtualSlots: VirtualSlot[],
): Record<number, VirtualSlotTripletError> => {
  console.log('Running checkAllVirtualSlotsMaxIncompatibility');
  const errors: Record<number, VirtualSlotTripletError> = {};

  // Sort virtual slots by start time
  const sortedVirtualSlots = [...virtualSlots].sort((a, b) => {
    // Parse the ISO datetime strings to ensure correct chronological sorting
    const dateA = new Date(a.start);
    const dateB = new Date(b.start);
    return dateA.getTime() - dateB.getTime();
  });

  // Need at least 3 slots to check triplets
  if (sortedVirtualSlots.length > 2) {
    // Iterate through slots using sliding window of 3
    for (let i = 1; i < sortedVirtualSlots.length - 1; i++) {
      const prevSlot = sortedVirtualSlots[i - 1];
      const currSlot = sortedVirtualSlots[i];
      const nextSlot = sortedVirtualSlots[i + 1];
      // Check max incompatibility
      const maxError = checkVirtualSlotTripletFeasibleMaxNeed(
        prevSlot,
        currSlot,
        nextSlot,
      );
      if (maxError) {
        errors[currSlot.id_virtual_slot] = maxError;
      }

      // Check min incompatibility
      const minError = checkVirtualSlotTripletReachableMinNeed(
        prevSlot,
        currSlot,
        nextSlot,
      );
      if (minError) {
        errors[currSlot.id_virtual_slot] = minError;
      }
    }
  }

  return errors;
};
