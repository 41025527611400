/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import { Switch } from '@headlessui/react';
import { CursorArrowRippleIcon } from '@heroicons/react/24/outline';
import type {
  Section,
  SectionSlot,
  UserPreference,
} from '@youshift/shared/types';
import {
  classNames,
  dateToString,
  formatUserName,
  getCustomDateRange,
  getShadeMap,
  LabelIcon,
  localeNormalizer,
} from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import UsersFilterDropdown from '../../../FilterDropdownWithSubgroups';
import EventSquare from '../../../components/Calendars/EventSquare';
import {
  AssignmentDetail,
  ShiftAssignmentBox,
} from '../../../components/Calendars/ShiftAssignment';
import ShiftLabelLegend from '../../../components/Calendars/ShiftLabelLegend';
import ShiftOutputLegend from '../../../components/Calendars/ShiftOutputLegend';
import FilterDropdown from '../../../components/FilterDropdown';
import AssignShift from '../../../components/ManualAssignment/AssignShiftModal';
import i18n from '../../../utils/i18n';
import { useItrContext } from '../../../layouts/IterationRootLayout/IterationRootLayout';
import { useManagerContext } from '../../../layouts/ManagerLayout';

interface AssignmentsPerUserPerDay {
  [id_user: number]: {
    [date: string]: AssignmentDetail[];
  };
}

interface PreferencesPerUserPerDay {
  [id_user: number]: {
    [date: string]: UserPreference[];
  };
}

export default function TableAssignment() {
  const [showPreferences, setShowPreferences] = useState(false);
  const {
    itrUsers,
    sectionsWithSlots,
    shiftLabels,
    iteration,
    users,
    roles,
    preferenceSlots,
    epa,
  } = useItrContext();
  const { eventTypes } = useManagerContext();

  const { eventsMap, preferencesMap, assignmentsMap } = epa;

  const allSectionSlots: SectionSlot[] = sectionsWithSlots.flatMap(
    ({ section_slots }) => Object.values(section_slots),
  );

  const [selectedPeople, setSelectedPeople] = useState(
    new Set(Object.keys(itrUsers).map(Number)),
  );
  const [selectedSections, setSelectedSections] = useState(
    new Set(sectionsWithSlots.map(({ section }) => String(section.id_section))),
  );

  // State variables for the AssignShiftModal
  const [selectedParticipant, setSelectedParticipant] = useState<number | null>(
    null,
  );
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const [selectedSlot, setSelectedSlot] = useState<number | null>(null);
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const locale = localeNormalizer(i18n.language);

  const daysOfItr = getCustomDateRange(
    iteration.start_day,
    iteration.end_day,
    locale,
    'table',
  );
  const sectionSlotsByIdSectionSlot: Record<number, SectionSlot> =
    allSectionSlots.reduce(
      (acc, slot) => {
        if (!acc[slot.id_section_slot]) {
          acc[slot.id_section_slot] = slot;
        }
        acc[slot.id_section_slot] = slot;
        return acc;
      },
      {} as Record<number, SectionSlot>,
    );

  const sectionsByIdSection: Record<number, Section> = sectionsWithSlots.reduce(
    (acc, { section }) => {
      acc[section.id_section] = section;
      return acc;
    },
    {} as Record<number, Section>,
  );

  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);
  const iconMap = useMemo(
    () =>
      shiftLabels.reduce<Record<number, LabelIcon>>((acc, label) => {
        acc[label.id_slot_label] = label.icon;
        return acc;
      }, {}),
    [shiftLabels],
  );

  const assignmentsPerUserPerDay = useMemo(() => {
    const result: AssignmentsPerUserPerDay = {};

    Object.entries(assignmentsMap.byUser).forEach(
      ([idUserStr, assignments]) => {
        const id_user = Number(idUserStr);
        if (!result[id_user]) {
          result[id_user] = {};
        }

        assignments.forEach(assignment => {
          const slot = sectionSlotsByIdSectionSlot[assignment.id_section_slot];
          if (slot) {
            const dateKey = dateToString(slot.start, 'dd/mm/yyyy');

            if (!result[id_user][dateKey]) {
              result[id_user][dateKey] = [];
            }

            const icon = iconMap[slot.id_slot_label] || '';
            const shade = shadeMap[slot.id_slot_label] || 0;
            const section = sectionsByIdSection[slot.id_section];
            const { color, name } = section;

            if (selectedSections.has(String(section.id_section))) {
              result[id_user][dateKey].push({
                id_section_slot: slot.id_section_slot,
                start: slot.start,
                end: slot.end,
                pointAward: assignment.point_award,
                icon,
                shade,
                color,
                name,
                type: assignment.type,
              });
            }
          }
        });
      },
    );

    return result;
  }, [
    selectedSections,
    assignmentsMap.byUser,
    sectionSlotsByIdSectionSlot,
    iconMap,
    shadeMap,
    sectionsByIdSection,
  ]);

  const preferencesPerUserPerDay = useMemo(() => {
    const result: PreferencesPerUserPerDay = {};

    Object.entries(preferencesMap.byUser).forEach(
      ([idUserStr, preferences]) => {
        const id_user = Number(idUserStr);
        if (!result[id_user]) {
          result[id_user] = {};
        }

        preferences.forEach(preference => {
          const associatedSectionSlots =
            preferenceSlots[preference.id_pref_slot]?.section_slots || [];

          // Get the earliest start time for all associated slots
          const earliestStart = associatedSectionSlots.reduce(
            (earliest, id_section_slot) => {
              const slot = sectionSlotsByIdSectionSlot[id_section_slot];
              if (
                slot &&
                (!earliest || new Date(slot.start) < new Date(earliest))
              ) {
                return slot.start;
              }
              return earliest;
            },
            '',
          );

          if (earliestStart) {
            const dateKey = dateToString(earliestStart, 'dd/mm/yyyy');

            if (!result[id_user][dateKey]) {
              result[id_user][dateKey] = [];
            }

            // Only add one preference per preference slot
            if (
              !result[id_user][dateKey].some(
                p => p.id_pref_slot === preference.id_pref_slot,
              )
            ) {
              result[id_user][dateKey].push(preference);
            }
          }
        });
      },
    );

    return result;
  }, [preferencesMap, preferenceSlots, sectionSlotsByIdSectionSlot]);

  const filteredUsers = Object.fromEntries(
    Object.entries(users).filter(([idUser, user]) =>
      selectedPeople.has(Number(idUser)),
    ),
  );

  return (
    <>
      {open ? (
        <AssignShift
          open={open}
          setOpen={setOpen}
          initialParticipant={selectedParticipant}
          initialDay={selectedDay}
          initialSlot={selectedSlot}
          users={filteredUsers}
          days={daysOfItr}
          sectionSlots={sectionSlotsByIdSectionSlot}
          sections={sectionsByIdSection}
        />
      ) : null}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex flex-row justify-between mt-8 mb-6 align-middle">
          <Switch.Group as="div" className="flex items-center gap-1">
            <Switch
              checked={showPreferences}
              onChange={() => setShowPreferences(!showPreferences)}
              className={classNames(
                showPreferences ? 'bg-blue-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  showPreferences ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
            <Switch.Label as="span" className="text-sm flex ml-3 flex-row">
              <span className="font-medium text-gray-900">
                {t('manager.iterationVerification.showPreferences')}
              </span>
            </Switch.Label>
          </Switch.Group>
          <div className="flex flex-row items-center gap-1">
            <CursorArrowRippleIcon className="text-teal-600 w-4" />
            <p className="text-gray-600 text-sm">
              {t(
                'manager.iterationVerification.shiftReassignmentGlobalExplanation',
              )}
            </p>
          </div>
        </div>
        <div className="flex justify-between flex-row">
          <ShiftLabelLegend labels={shiftLabels} />
          <div className="flex gap-2">
            <div className="pr-2 lg:pr-4 border-r border-r-gray-400">
              <UsersFilterDropdown
                users={users}
                roles={roles}
                selectedUsers={selectedPeople}
                onSelectionChange={setSelectedPeople}
              />
            </div>
            <FilterDropdown
              label={t('generic.sections')}
              items={sectionsWithSlots.map(({ section }) => ({
                id: String(section.id_section),
                name: section.name,
              }))}
              selectedItems={selectedSections}
              onSelectionChange={setSelectedSections}
            />
          </div>
        </div>
        <div className="flex flex-row items-end justify-between">
          <ShiftOutputLegend />
        </div>
        <div className="mt-4 overflow-x-auto h-[65vh]">
          <table className="min-w-full">
            <tbody>
              {/* DATES (first row) */}
              <tr className="sticky top-0 z-10">
                <th className="sticky left-0 z-10 bg-blue-100 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">
                  {t('generic.date')}
                </th>
                {daysOfItr.map(day => {
                  const dayStr = dateToString(day, 'weekday', locale);
                  return (
                    <td
                      key={dayStr}
                      className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium cursor-pointer text-gray-900 border-b border-gray-300 bg-white"
                      onClick={() => {
                        setSelectedDay(day);
                        setSelectedSlot(null);
                        setSelectedParticipant(null);
                        setOpen(true);
                      }}
                    >
                      {dayStr}
                    </td>
                  );
                })}
              </tr>
              {/* USERS rows */}
              {Object.entries(filteredUsers)
                .sort(([_idUserA, userA], [_idUserB, userB]) =>
                  formatUserName(userA, false).localeCompare(
                    formatUserName(userB, false),
                  ),
                )
                .map(([idUserStr, user]) => {
                  const idUser = Number(idUserStr);
                  const assignmentsOfUser = assignmentsPerUserPerDay[idUser];
                  const preferencesOfUser = preferencesPerUserPerDay[idUser];
                  const userName = `${user.firstname} ${user.lastname}`;
                  return (
                    <tr>
                      <th
                        className="sticky left-0 z-10 cursor-pointer bg-opacity-90 py-4 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 border-b border-r bg-white"
                        key={idUser}
                        onClick={() => {
                          setSelectedParticipant(idUser);
                          setSelectedSlot(null);
                          setSelectedDay(null);
                          setOpen(true);
                        }}
                      >
                        {userName}
                      </th>
                      {daysOfItr.map(day => {
                        // const isNotFiltered = filteredShifts.includes(
                        //   slot.shift_outputs[person.id_user]?.id_shift,
                        // );
                        // const output =
                        //   isNotFiltered && slot.shift_outputs[person.id_user];
                        // const pref = slot.slot_prefs[person.id_user];
                        const ddmmyyyy = dateToString(day, 'dd/mm/yyyy');
                        const assignmentsOfUserOnDay =
                          assignmentsOfUser?.[ddmmyyyy];
                        const preferencesOfUserOnDay =
                          preferencesOfUser?.[ddmmyyyy];

                        return (
                          <td
                            key={`${day}-${idUser}`}
                            className="whitespace-nowrap cursor-pointer mx-auto text-center py-2 text-gray-900 border-b border-gray-200"
                            onClick={() => {
                              if (!assignmentsOfUserOnDay?.length) {
                                setSelectedParticipant(idUser);
                                setSelectedDay(day);
                                setSelectedSlot(null);
                                setOpen(true);
                              }
                            }}
                          >
                            <div className="flex flex-col gap-3">
                              {assignmentsOfUserOnDay?.map(assignment => (
                                <button
                                  onClick={() => {
                                    setSelectedParticipant(idUser);
                                    setSelectedDay(day);
                                    setSelectedSlot(assignment.id_section_slot);
                                    setOpen(true);
                                  }}
                                  aria-label={`Assignment for ${userName} on ${dateToString(day, 'dd/mm/yyyy')}`}
                                >
                                  <ShiftAssignmentBox
                                    key={assignment.id_section_slot}
                                    assignment={assignment}
                                  />
                                </button>
                              ))}
                              {eventsMap.byDate[ddmmyyyy]
                                ?.filter(
                                  event => event.id_user === Number(idUser),
                                )
                                .map(event => (
                                  <EventSquare
                                    id_special_event_type={
                                      event.id_special_event_type
                                    }
                                    eventTypes={eventTypes}
                                  />
                                ))}
                              {showPreferences &&
                                preferencesOfUserOnDay?.map(pref => (
                                  <EventSquare
                                    preference={pref.preference}
                                    points={pref.points}
                                    justPoints
                                    eventTypes={eventTypes}
                                  />
                                ))}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
