import { ShieldExclamationIcon } from '@heroicons/react/24/outline';
import {
  AlgErrorDict,
  AlgErrorType,
  AlgSuccessDict,
  AlgComponentType,
  ProblemRelaxationMod,
  GurobiIISMask,
  VirtualSlotNeedsMinMask,
  VirtualSlotNeedsMaxMask,
  SingleGroupIncompatibilityMinMask,
  SingleGroupIncompatibilityMaxMask,
  CrossGroupIncompatibilityMask,
  UserConstraintMask,
  UserRuleConstraintMask,
} from '@youshift/shared/types';
import { useTranslation } from 'react-i18next';
import { dateToString, localeNormalizer } from '@youshift/shared/utils';

import GurobiIISDisplay from './GurobiIISDisplay';
import { useItrContext } from '../../layouts/IterationRootLayout/IterationRootLayout';

export default function InfeasibilityAnalysisModal({
  lastExecutionStatus,
}: {
  lastExecutionStatus: AlgSuccessDict | AlgErrorDict;
}) {
  const { t, i18n } = useTranslation();
  const locale = localeNormalizer(i18n.language);

  const {
    allUserReqs,
    assignmentErrors,
    configErrors,
    epa,
    excludedSlotsPerUser,
    incompatibilities,
    iteration,
    itrUsers,
    preferenceSlots,
    roles,
    sectionsWithSlots,
    shiftAssignments,
    shiftLabels,
    specialEvents,
    status,
    userPreferences,
    users,
  } = useItrContext();

  return (
    <>
      <ShieldExclamationIcon className="w-12 h-12 text-orange-600 mx-auto my-4" />
      <h2 className="font-semibold text-lg text-center">
        {t('manager.shiftAssignment.failureExecutionModalTitle')}
      </h2>
      {'type' in lastExecutionStatus && (
        <>
          <p className="my-2">
            {lastExecutionStatus.type === AlgErrorType.INFEASIBLE ||
            lastExecutionStatus.type === AlgErrorType.INFEASIBLE_RELAXED
              ? t(
                  'manager.shiftAssignment.failureExecutionModalDescriptionInfeasible',
                )
              : t(
                  'manager.shiftAssignment.failureExecutionModalDescriptionGeneric',
                )}
          </p>
          {lastExecutionStatus.type === AlgErrorType.INFEASIBLE_RELAXED &&
          (!lastExecutionStatus.problem_mod_mask ||
            Object.keys(lastExecutionStatus.problem_mod_mask).length === 0) ? (
            <div className="mt-4">
              <p>{t('manager.shiftAssignment.genericInfeasibilityError')}</p>
            </div>
          ) : null}
          {lastExecutionStatus.type === AlgErrorType.INFEASIBLE &&
            lastExecutionStatus.gurobi_IIS && (
              <GurobiIISDisplay lastExecutionStatus={lastExecutionStatus} />
            )}
        </>
      )}
    </>
  );
}

// <div className="mt-4 space-y-4">
//   <h3 className="font-medium">
//     {t('Constraint Modifications for Feasibility')}:
//   </h3>
//   {Object.entries(lastExecutionStatus.problem_mod_mask).map(
//     ([componentType, data]) => {
//       const type = componentType as AlgComponentType;

//       return (
//         <div key={componentType} className="border rounded p-3">
//           <h4 className="font-medium mb-2">
//             {getComponentTitle(type)}
//           </h4>
//           {type === AlgComponentType.VIRTUAL_SLOT_NEEDS_MIN && (
//             <div className="space-y-2">
//               {Object.entries(
//                 (data as ProblemRelaxationMod[AlgComponentType.VIRTUAL_SLOT_NEEDS_MIN]) ||
//                   {},
//               ).map(([sectionId, virtualSlots]) => (
//                 <div key={sectionId}>
//                   <div className="font-medium">
//                     Seccion{' '}
//                     {
//                       sectionsWithSlots.find(
//                         section =>
//                           section.section.id_section ===
//                           Number(sectionId),
//                       )?.section.name
//                     }
//                     :
//                   </div>
//                   {Object.entries(virtualSlots).map(
//                     ([virtualSlotId, mod_value]) => (
//                       // El algoritmo ha tenido que reducir el minimo de personas
//                       // que se necesitan en el VirtualSlot de la Seccion <sectionId> en <mod_value> para encontrar una solución
//                       <div key={virtualSlotId} className="pl-4">
//                         Virtual Slot {virtualSlotId}: {mod_value}
//                       </div>
//                     ),
//                   )}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type === AlgComponentType.VIRTUAL_SLOT_NEEDS_MAX && (
//             <div className="space-y-2">
//               {Object.entries(
//                 (data as ProblemRelaxationMod[AlgComponentType.VIRTUAL_SLOT_NEEDS_MAX]) ||
//                   {},
//               ).map(([sectionId, slots]) => (
//                 <div key={sectionId}>
//                   <div className="font-medium">
//                     Section {sectionId}:
//                   </div>
//                   {Object.entries(slots).map(
//                     ([virtualSlotId, value]) => (
//                       // El algoritmo ha tenido que reducir el maximo de personas
//                       // que se necesitan en el VirtualSlot de la Seccion <sectionId> en <value> para encontrar una solución
//                       <div key={virtualSlotId} className="pl-4">
//                         Virtual Slot {virtualSlotId}: {value}
//                       </div>
//                     ),
//                   )}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type ===
//             AlgComponentType.SINGLE_GROUP_INCOMPATIBILITY_MIN && (
//             <div className="space-y-2">
//               {Object.entries(
//                 (data as ProblemRelaxationMod[AlgComponentType.SINGLE_GROUP_INCOMPATIBILITY_MIN]) ||
//                   {},
//               ).map(([ruleId, slots]) => (
//                 <div key={ruleId}>
//                   <div className="font-medium">Rule {ruleId}:</div>
//                   {Object.entries(slots).map(([slotId, value]) => (
//                     // El algoritmo ha tenido que disminuir el min_simult la regla de incompatibilidad de la regla <ruleId> en el slot <slotId>
//                     // por <value> para encontrar una solución
//                     <div key={slotId} className="pl-4">
//                       Slot {slotId}: {value}
//                     </div>
//                   ))}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type ===
//             AlgComponentType.SINGLE_GROUP_INCOMPATIBILITY_MAX && (
//             <div className="space-y-2">
//               {Object.entries(
//                 (data as ProblemRelaxationMod[AlgComponentType.SINGLE_GROUP_INCOMPATIBILITY_MAX]) ||
//                   {},
//               ).map(([ruleId, slots]) => (
//                 // El algoritmo ha tenido que aumentar el max_simult la regla de incompatibilidad de la regla <ruleId> en el slot <slotId>
//                 // por <value> para encontrar una solución
//                 <div key={ruleId}>
//                   <div className="font-medium">Rule {ruleId}:</div>
//                   {Object.entries(slots).map(([slotId, value]) => (
//                     <div key={slotId} className="pl-4">
//                       Slot {slotId}: {value}
//                     </div>
//                   ))}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type ===
//             AlgComponentType.CROSS_GROUP_INCOMPATIBILITY && (
//             <div className="space-y-2">
//               {Object.entries(
//                 (data as ProblemRelaxationMod[AlgComponentType.CROSS_GROUP_INCOMPATIBILITY]) ||
//                   {},
//               ).map(([ruleId, slots]) => (
//                 // El algoritmo ha tenido que permitir saltarse la regla de incompatibilidad entre grupos de la regla <ruleId>
//                 // para que puedan coincidir <value> usuarios en el slot <slotId> para encontrar una solución
//                 <div key={ruleId}>
//                   <div className="font-medium">Rule {ruleId}:</div>
//                   {Object.entries(slots).map(([slotId, value]) => (
//                     <div key={slotId} className="pl-4">
//                       Slot {slotId}: {value}
//                     </div>
//                   ))}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type === AlgComponentType.C_1 && (
//             <div className="space-y-2">
//               {Object.entries(data).map(([userId, value]) => (
//                 // No debería de ocurrir, pero parseamos por si acaso (Value no se usa)
//                 <div key={userId} className="pl-4">
//                   User {userId}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type === AlgComponentType.C_CUSTOM_REST_PERIOD && (
//             <div className="space-y-2">
//               {Object.entries(data).map(([userId, value]) => (
//                 // El algoritmo ha tenido que permitir saltarse el descanso del <userId> (value no se usa)
//                 <div key={userId} className="pl-4">
//                   User {userId}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type === AlgComponentType.C_PERSONAL_BLOCKING && (
//             <div className="space-y-2">
//               {Object.entries(data).map(([userId, value]) => (
//                 // El algoritmo ha tenido que permitir saltarse el bloqueo personal del <userId> (value no se usa)
//                 <div key={userId} className="pl-4">
//                   User {userId}: {value}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type === AlgComponentType.C_JUSTIFIED_BLOCKING && (
//             <div className="space-y-2">
//               {Object.entries(data).map(([userId, value]) => (
//                 // El algoritmo ha tenido que permitir saltarse el bloqueo justificado del <userId> (value no se usa)
//                 <div key={userId} className="pl-4">
//                   User {userId}: {value}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type === AlgComponentType.C_SPECIAL_EVENTS && (
//             <div className="space-y-2">
//               {Object.entries(data).map(([userId, value]) => (
//                 // El algoritmo ha tenido que permitir saltarse el evento especial del <userId> (value no se usa)
//                 <div key={userId} className="pl-4">
//                   User {userId}: {value}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type ===
//             AlgComponentType.C_PREVIOUSLY_ASSIGNED_SHIFTS && (
//             <div className="space-y-2">
//               {Object.entries(data).map(([userId, value]) => (
//                 // El algoritmo ha tenido que permitir saltarse los turnos ya asignados al <userId> (value no se usa)
//                 <div key={userId} className="pl-4">
//                   User {userId}: {value}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type === AlgComponentType.C_PENALTY_WORKER_MAX && (
//             <div className="space-y-2">
//               {Object.entries(data).map(([userId, value]) => (
//                 // El algoritmo ha tenido que permitir saltarse el máximo de trabajadores penalizados del <userId> (value no se usa)
//                 <div key={userId} className="pl-4">
//                   User {userId}: {value}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type ===
//             AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MAX_SLOTS && (
//             <div className="space-y-2">
//               {Object.entries(
//                 (data as ProblemRelaxationMod[AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MAX_SLOTS]) ||
//                   {},
//               ).map(([userId, rules]) => (
//                 <div key={userId}>
//                   <div className="font-medium">User {userId}:</div>
//                   {Object.entries(rules).map(([ruleId, value]) => (
//                     // El algoritmo ha tenido que permitir aumentar el máximo de turnos en la
//                     // regla <ruleId> del <userId> por <value> turnos para encontrar una solución
//                     <div key={ruleId} className="pl-4">
//                       Rule {ruleId}: {value}
//                     </div>
//                   ))}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type ===
//             AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MIN_SLOTS && (
//             <div className="space-y-2">
//               {Object.entries(
//                 (data as ProblemRelaxationMod[AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MIN_SLOTS]) ||
//                   {},
//               ).map(([userId, rules]) => (
//                 <div key={userId}>
//                   <div className="font-medium">User {userId}:</div>
//                   {Object.entries(rules).map(([ruleId, value]) => (
//                     // El algoritmo ha tenido que permitir disminuir el mínimo de turnos en la
//                     // regla <ruleId> del <userId> por <value> turnos para encontrar una solución
//                     <div key={ruleId} className="pl-4">
//                       Rule {ruleId}: {value}
//                     </div>
//                   ))}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type ===
//             AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MAX_DURATION && (
//             <div className="space-y-2">
//               {Object.entries(
//                 (data as ProblemRelaxationMod[AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MAX_DURATION]) ||
//                   {},
//               ).map(([userId, rules]) => (
//                 <div key={userId}>
//                   <div className="font-medium">User {userId}:</div>
//                   {Object.entries(rules).map(([ruleId, value]) => (
//                     // El algoritmo ha tenido que permitir aumentar la duración máxima de la
//                     // regla <ruleId> del <userId> por <value> horas para encontrar una solución
//                     <div key={ruleId} className="pl-4">
//                       Rule {ruleId}: {value}
//                     </div>
//                   ))}
//                 </div>
//               ))}
//             </div>
//           )}
//           {type ===
//             AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MIN_DURATION && (
//             <div className="space-y-2">
//               {Object.entries(
//                 (data as ProblemRelaxationMod[AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MIN_DURATION]) ||
//                   {},
//               ).map(([userId, rules]) => (
//                 <div key={userId}>
//                   <div className="font-medium">User {userId}:</div>
//                   {Object.entries(rules).map(([ruleId, value]) => (
//                     // El algoritmo ha tenido que permitir disminuir la duración mínima de la
//                     // regla <ruleId> del <userId> por <value> horas para encontrar una solución
//                     <div key={ruleId} className="pl-4">
//                       Rule {ruleId}: {value}
//                     </div>
//                   ))}
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       );
//     },
//   )}
// </div>
