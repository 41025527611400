import {
  CursorArrowRippleIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import {
  QueryClient,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  EditUserReqRuleParams,
  useEditUserReqRule,
  UserReqRuleResponse,
} from '@youshift/shared/hooks/mutations';
import { userReqRuleQuery } from '@youshift/shared/hooks/queries';
import {
  Rule,
  RuleTypes,
  Section,
  SlotLabel,
  UserReqRule,
  UserRequirementInclusionType,
  UserRequirementNumUsersType,
  UserRequirementType,
} from '@youshift/shared/types';
import { getShadeMap } from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { YSButton } from '../../../../components/Buttons';
import ShiftLabelLegend from '../../../../components/Calendars/ShiftLabelLegend';
import EditRuleModal from '../../../../components/ItrConfig/EditRuleModal';
import NavigateSectionsOrRules from '../../../../components/ItrConfig/NavigateSectionsOrRules';
import RuleTypeBadge from '../../../../components/ItrConfig/RuleTypeBadge';
import { VirgueriaVersion } from '../../../../components/ManualAssignment/types';
import { Virgueria } from '../../../../components/ManualAssignment/Virgueria';
import Wrapper from '../../../../components/Wrapper';
import { useItrContext } from '../../../../layouts/IterationRootLayout/IterationRootLayout';
import { requireApproved, requireManager } from '../../../../utils/checks';
import DefineExclusionReqs from './DefineExclusionReqs';
import DefineReqs from './DefineReqs';
import DefineSingleUserReqs from './DefineSingleUserReqs';
import { useSlotSelection } from './hooks/useSlotSelection';
import InclusionTypeBadge from './InclusionTypeBadge';
import { Action, State } from './types';
import { useUserReqReducer } from './hooks/useUserReqReducer';
import SmartSelector from '../../../../components/ItrConfig/SmartSelector';

export const userReqRuleLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<UserReqRule | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined || params.idRule === undefined) {
      return null;
    }
    const { idItr, idRule } = params;
    const userReqRule = await preLoadQuery(
      queryClient,
      userReqRuleQuery(idItr, idRule),
    );
    return userReqRule;
  };

interface ComputeDifferencesOutput {
  addedUserRequirements: Array<{
    id_user: number;
    min_slots?: number;
    max_slots?: number;
    min_duration?: number;
    max_duration?: number;
  }>;
  editedUserRequirements: Array<{
    id_user: number;
    min_slots?: number;
    max_slots?: number;
    min_duration?: number;
    max_duration?: number;
  }>;
  deletedUsers: number[];
}

function computeDifferences(
  initialState: State,
  currentState: State,
): ComputeDifferencesOutput {
  const addedUserRequirements = [];
  const editedUserRequirements = [];
  const deletedUsers = [];

  for (const roleId in currentState.roles) {
    if (Object.prototype.hasOwnProperty.call(currentState.roles, roleId)) {
      const currentRole = currentState.roles[roleId];
      const initialRole = initialState.roles[roleId] || { users: [] };

      // Create a map of initial users for quick lookup
      const initialUsersMap = Object.fromEntries(
        initialRole.users.map(user => [user.id_user, user]),
      );

      // Create a map of current users for reverse lookup
      // const currentUsersMap = Object.fromEntries(
      //   currentRole.users.map(user => [user.id_user, user]),
      // );

      // Iterate over all users in currentRole
      for (const user of currentRole.users) {
        const initialUser = initialUsersMap[user.id_user];

        if (!initialUser.included && user.included) {
          // User was not included before but is now included
          addedUserRequirements.push({
            id_user: user.id_user,
            ...(currentState.req_type === UserRequirementType.SLOTS && {
              min_slots: user.min_slots,
              max_slots: user.max_slots,
            }),
            ...(currentState.req_type === UserRequirementType.DURATION && {
              min_duration: user.min_duration,
              max_duration: user.max_duration,
            }),
          });
        } else if (initialUser.included && !user.included) {
          // User was included before but is now not included
          deletedUsers.push(user.id_user);
        } else if (user.included) {
          // User is included in both states
          // First check if the requirements for the CURRENT req type have changed
          const requirementsChanged =
            currentState.req_type === UserRequirementType.SLOTS
              ? initialUser.min_slots !== user.min_slots ||
                initialUser.max_slots !== user.max_slots
              : initialUser.min_duration !== user.min_duration ||
                initialUser.max_duration !== user.max_duration;

          if (
            requirementsChanged ||
            initialState.req_type !== currentState.req_type
          ) {
            editedUserRequirements.push({
              id_user: user.id_user,
              ...(currentState.req_type === UserRequirementType.SLOTS && {
                min_slots: user.min_slots,
                max_slots: user.max_slots,
              }),
              ...(currentState.req_type === UserRequirementType.DURATION && {
                min_duration: user.min_duration,
                max_duration: user.max_duration,
              }),
            });
          }
          // If both are not included, no action needed
        }
      }
    }
  }

  return {
    addedUserRequirements,
    editedUserRequirements,
    deletedUsers,
  };
}

interface CommonProps {
  rule: Rule;
  activeTab: 'slots' | 'reqs';
  setActiveTab: (tab: 'slots' | 'reqs') => void;
  saveable: boolean;
  slotsArentEditable: boolean;
  selectedSlots: Set<number>;
  setSelectedSlots: (slots: Set<number>) => void;
  toggleSlot: (slotId: number) => void;
  onDayClick: (date: Date) => void;
  onSectionClick: (section: Section) => void;
  filteredShiftLabels: SlotLabel[];
  state: State;
  dispatch: React.Dispatch<Action>; // You might want to properly type this based on your reducer actions
  isEditRuleModalOpen: boolean;
  setIsEditRuleModalOpen: (isOpen: boolean) => void;
  saveChanges: () => void;
  editUserReqRule: UseMutationResult<
    UserReqRuleResponse,
    Error,
    EditUserReqRuleParams
  >;
}

interface SingleUserRuleConfigProps extends CommonProps {
  selectedUser: number;
}

function SingleUserRuleConfig({
  rule,
  selectedUser,
  activeTab,
  setActiveTab,
  saveable,
  slotsArentEditable,
  selectedSlots,
  toggleSlot,
  onDayClick,
  onSectionClick,
  filteredShiftLabels,
  setSelectedSlots,
  state,
  dispatch,
  isEditRuleModalOpen,
  setIsEditRuleModalOpen,
  saveChanges,
  editUserReqRule,
}: SingleUserRuleConfigProps) {
  const { t } = useTranslation();
  const { idRule } = useParams();
  const {
    shiftLabels,
    iteration,
    sectionsWithSlots,
    allUserReqs,
    users,
    ruleAttributes,
    sectionSlotsDict,
  } = useItrContext();
  const { inclusionType } = ruleAttributes[Number(idRule)];
  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);

  return (
    <Wrapper>
      <EditRuleModal
        isOpen={isEditRuleModalOpen}
        onClose={() => setIsEditRuleModalOpen(false)}
        details={rule}
      />
      <div className="flex flex-row justify-between items-center border-b border-gray-400 pb-2 mb-2">
        <div className="flex items-center gap-2">
          <RuleTypeBadge
            type={rule.type}
            string={t(`manager.rulesConfig.${rule.type}`)}
          />
          <InclusionTypeBadge label={inclusionType} />
          <h1 className="text-sm font-bold bg-blue-600/25 px-2 py-1 rounded-md">
            {users[selectedUser]?.firstname} {users[selectedUser]?.lastname}
          </h1>
          <h1 className="text-2xl font-bold">{rule?.name}</h1>
          <button onClick={() => setIsEditRuleModalOpen(true)}>
            <PencilSquareIcon className="w-6 h-6 text-gray-600" />
          </button>
        </div>
      </div>
      <div className={`flex justify-between items-center my-2`}>
        <div className="flex flex-row items-center gap-8">
          <p className="font-semibold text-gray-900 mt-2">
            {t('manager.rulesConfig.singleUserRuleReqs')}
          </p>
          {inclusionType === UserRequirementInclusionType.INCLUSION ? (
            <DefineSingleUserReqs
              state={state}
              dispatch={dispatch}
              selectedUser={selectedUser}
            />
          ) : (
            <p className="text-gray-600 mt-2">
              {t('manager.rulesConfig.userExclusionRuleSubtitle', {
                user: `${users[selectedUser]?.firstname} ${users[selectedUser]?.lastname}`,
              })}
            </p>
          )}
        </div>
        <YSButton
          classNames="self-end"
          onClick={saveChanges}
          disabled={!saveable}
          loading={editUserReqRule.isPending}
        >
          {saveable ? t('generic.saveChanges') : t('generic.savedChanges')}
        </YSButton>
      </div>
      <p className="font-semibold text-gray-900 mb-2">
        {inclusionType === UserRequirementInclusionType.INCLUSION
          ? t('manager.rulesConfig.includedSlots')
          : t('manager.rulesConfig.excludedSlots')}
      </p>
      <div className="flex flex-row gap-1 justify-center items-center">
        <CursorArrowRippleIcon className="h-5 w-5 text-teal-600" />
        <p>
          {inclusionType === UserRequirementInclusionType.INCLUSION
            ? t('manager.rulesConfig.editEnabled')
            : t('manager.rulesConfig.editEnabledExclusion')}
        </p>
      </div>
      <SmartSelector
        sections={Object.fromEntries(
          sectionsWithSlots.map(section => [
            section.section.id_section,
            section.section,
          ]),
        )}
        sectionSlotsConfig={{
          filteredShiftLabels,
          sectionSlots: sectionSlotsDict,
          setSelectedSectionSlots: setSelectedSlots,
          selectedSectionSlots: selectedSlots,
        }}
      />
      <Virgueria
        start={iteration.start_day}
        end={iteration.end_day}
        sectionsWithSlots={sectionsWithSlots.filter(({ section }) =>
          rule.type === RuleTypes.SECTION_USER_REQS
            ? section.id_section === rule.id_section
            : true,
        )}
        selectedSlots={selectedSlots}
        shadeMap={shadeMap}
        onSlotClick={toggleSlot}
        version={VirgueriaVersion.RulesEditing}
        labels={shiftLabels}
        onDayClick={onDayClick}
        onSectionClick={onSectionClick}
      />
    </Wrapper>
  );
}

function MultipleUsersRuleConfig({
  rule,
  activeTab,
  setActiveTab,
  saveable,
  slotsArentEditable,
  selectedSlots,
  toggleSlot,
  onDayClick,
  onSectionClick,
  filteredShiftLabels,
  state,
  dispatch,
  isEditRuleModalOpen,
  setIsEditRuleModalOpen,
  saveChanges,
  setSelectedSlots,
  editUserReqRule,
}: CommonProps) {
  const { t } = useTranslation();
  const { idRule } = useParams();
  const {
    shiftLabels,
    iteration,
    sectionsWithSlots,
    allUserReqs,
    users,
    roles,
    itrUsers,
    sectionSlotsDict,
    ruleAttributes,
  } = useItrContext();
  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);
  const rules = Object.values(allUserReqs).map(userReq => userReq.rule);
  const { inclusionType } = ruleAttributes[Number(idRule)];

  return (
    <Wrapper>
      <EditRuleModal
        isOpen={isEditRuleModalOpen}
        onClose={() => setIsEditRuleModalOpen(false)}
        details={rule}
      />
      <div className="flex flex-row justify-between items-center border-b border-gray-400 pb-2 mb-2">
        <div className="flex items-center gap-2">
          <RuleTypeBadge
            type={rule.type}
            string={t(`manager.rulesConfig.${rule.type}`)}
          />
          <InclusionTypeBadge label={inclusionType} />
          <h1 className="text-2xl font-bold">{rule?.name}</h1>
          <button onClick={() => setIsEditRuleModalOpen(true)}>
            <PencilSquareIcon className="w-6 h-6 text-gray-600" />
          </button>
        </div>
        {rules && (
          <NavigateSectionsOrRules
            currentId={rule.id_rule}
            items={rules.map(rule => ({ id: rule.id_rule, name: rule.name }))}
          />
        )}
      </div>
      {inclusionType === UserRequirementInclusionType.EXCLUSION && (
        <p className="text-gray-600">
          {t('manager.rulesConfig.groupExclusionRuleSubtitle')}
        </p>
      )}
      <div className="border-b border-gray-200 mb-3">
        <nav className="-mb-px flex space-x-8">
          <button
            onClick={() => setActiveTab('reqs')}
            className={`${
              activeTab === 'reqs'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            {t('manager.sectionsConfig.reqs')}
          </button>
          <button
            onClick={() => setActiveTab('slots')}
            className={`${
              activeTab === 'slots'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
          >
            {t('manager.rulesConfig.includedSlots')}
          </button>
        </nav>
      </div>

      {activeTab === 'slots' && (
        <>
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row gap-1 justify-center items-center">
              {!slotsArentEditable && (
                <CursorArrowRippleIcon className="h-5 w-5 text-teal-600" />
              )}
              <p className="edit-disabled-text">
                {slotsArentEditable
                  ? `${t('manager.rulesConfig.editDisabled')} ${rule.name}`
                  : t('manager.rulesConfig.editEnabled')}
              </p>
            </div>
            {!slotsArentEditable && (
              <YSButton
                classNames="self-end"
                onClick={saveChanges}
                disabled={!saveable}
                loading={editUserReqRule.isPending}
              >
                {saveable
                  ? t('generic.saveChanges')
                  : t('generic.savedChanges')}
              </YSButton>
            )}
          </div>
          <div>
            {rule.type === RuleTypes.CUSTOM_USER_REQS && (
              <SmartSelector
                sections={Object.fromEntries(
                  sectionsWithSlots.map(section => [
                    section.section.id_section,
                    section.section,
                  ]),
                )}
                sectionSlotsConfig={{
                  filteredShiftLabels,
                  sectionSlots: sectionSlotsDict,
                  setSelectedSectionSlots: setSelectedSlots,
                  selectedSectionSlots: selectedSlots,
                }}
              />
            )}
          </div>
          <Virgueria
            start={iteration.start_day}
            end={iteration.end_day}
            sectionsWithSlots={sectionsWithSlots.filter(({ section }) =>
              rule.type === RuleTypes.SECTION_USER_REQS
                ? section.id_section === rule.id_section
                : true,
            )}
            inclusionType={inclusionType}
            selectedSlots={selectedSlots}
            shadeMap={shadeMap}
            onSlotClick={toggleSlot}
            version={VirgueriaVersion.RulesEditing}
            labels={shiftLabels}
            onDayClick={onDayClick}
            onSectionClick={onSectionClick}
          />
        </>
      )}
      {activeTab === 'reqs' && (
        <div className="flex flex-col">
          <div className="flex flex-row justify-end items-center mb-2">
            <YSButton
              classNames="self-end"
              onClick={saveChanges}
              disabled={!saveable}
              loading={editUserReqRule.isPending}
            >
              {saveable ? t('generic.saveChanges') : t('generic.savedChanges')}
            </YSButton>
          </div>
          {inclusionType === UserRequirementInclusionType.EXCLUSION ? (
            <DefineExclusionReqs state={state} dispatch={dispatch} />
          ) : (
            <DefineReqs
              state={state}
              dispatch={dispatch}
              numberOfSlotsSelected={selectedSlots.size}
            />
          )}
        </div>
      )}
    </Wrapper>
  );
}

export default function UserReqRuleConfig() {
  const { idItr, idRule } = useParams();

  const userReqRule = useLoaderData() as UserReqRule;
  const {
    data: { rule, user_reqs: userReqs, section_slots: sectionSlots },
  } = useQuery({
    ...userReqRuleQuery(idItr!, idRule!),
    initialData: userReqRule,
  });
  const {
    shiftLabels,
    iteration,
    sectionsWithSlots,
    allUserReqs,
    users,
    roles,
    itrUsers,
    ruleAttributes,
  } = useItrContext();

  const { inclusionType, numUsersType } = ruleAttributes[Number(idRule)];
  const selectedUser = useMemo(() => {
    if (numUsersType === UserRequirementNumUsersType.SINGLE) {
      return Number(Object.keys(userReqs)[0]);
    }
  }, [numUsersType, userReqs]);

  const [activeTab, setActiveTab] = useState<'slots' | 'reqs'>('reqs');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [slotsEdited, setSlotsEdited] = useState(false);
  const [reqsEdited, setReqsEdited] = useState(false);
  const [isEditRuleModalOpen, setIsEditRuleModalOpen] = useState(false);

  const initialReqType =
    Object.values(userReqs || {})[0]?.req_type || UserRequirementType.SLOTS;

  const saveable = slotsEdited || reqsEdited;

  const slotsArentEditable =
    rule?.type === RuleTypes.SECTION_USER_REQS ||
    rule?.type === RuleTypes.SLOT_LABEL_USER_REQS;

  const {
    selectedSlots,
    toggleSlot,
    onDayClick,
    onSectionClick,
    setSelectedSlotsWithCallback,
    filteredShiftLabels,
  } = useSlotSelection({
    initialSlots: sectionSlots ? new Set(sectionSlots) : new Set<number>(),
    sectionsWithSlots,
    onSlotsChange: () => setSlotsEdited(true),
    disabled: slotsArentEditable,
    shiftLabels,
  });

  const { initialState, state, dispatch } = useUserReqReducer({
    itrUsers: Object.keys(itrUsers).map(Number),
    roles,
    users,
    userReqs, // existing user requirements
    initialReqType,
    onAnyEdit: () => setReqsEdited(true), // <--- we want to track edits
  });

  const editUserReqRule = useEditUserReqRule(queryClient, {
    onSuccess: () => {
      setReqsEdited(false);
      setSlotsEdited(false);
      queryClient.invalidateQueries({
        queryKey: ['userReqRule', idRule],
      });
      queryClient.invalidateQueries({
        queryKey: ['extendedUserReqRules', idItr],
      });
      toast.success(t('manager.rulesConfig.userReqRuleEdited'));
    },
  });

  if (!rule) {
    return null;
  }

  const saveChanges = () => {
    const { addedUserRequirements, editedUserRequirements, deletedUsers } =
      reqsEdited
        ? computeDifferences(initialState, state)
        : ({} as ComputeDifferencesOutput);
    editUserReqRule.mutate({
      id_itr: iteration.id_itr,
      id_rule: rule.id_rule,
      user_reqs: reqsEdited
        ? {
            added_user_requirements: addedUserRequirements,
            edited_user_requirements: editedUserRequirements,
            deleted_users: deletedUsers,
            req_type: state.req_type,
          }
        : undefined,
      id_section_slots: slotsEdited ? Array.from(selectedSlots) : undefined,
    });
  };

  const commonProps = {
    rule,
    activeTab,
    setActiveTab,
    saveable,
    slotsArentEditable,
    selectedSlots,
    toggleSlot,
    onDayClick,
    onSectionClick,
    filteredShiftLabels,
    state,
    dispatch,
    isEditRuleModalOpen,
    setIsEditRuleModalOpen,
    saveChanges,
    editUserReqRule,
    setSelectedSlots: setSelectedSlotsWithCallback,
  };

  return numUsersType === UserRequirementNumUsersType.SINGLE ? (
    <SingleUserRuleConfig {...commonProps} selectedUser={selectedUser!} />
  ) : (
    <MultipleUsersRuleConfig {...commonProps} />
  );
}
