import {
  RuleTypes,
  SectionWithSlots,
  SlotLabel,
  UserRequirementNumUsersType,
  UserRequirementInclusionType,
} from '@youshift/shared/types';
import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

import { useItrContext } from '../../../../layouts/IterationRootLayout/IterationRootLayout';

export type DefineSectionRuleDetails = {
  sectionsWithSlots: SectionWithSlots[];
  selectedSection: number | null;
  setSelectedSection: (section: number | null) => void;
};

export default function DefineSectionRuleDetails({
  sectionsWithSlots,
  selectedSection,
  setSelectedSection,
}: DefineSectionRuleDetails) {
  const { t } = useTranslation();
  const { users } = useItrContext();

  return (
    <div className="space-y-8 px-8">
      {/* ────────────── Step 1: Rule Scope ────────────── */}
      <div className="lg:flex lg:space-x-8">
        {/* Rule Type selection */}
        {/* For Section rules, show the section dropdown on the right */}
        <div className="mt-4 lg:mt-0 lg:w-1/2">
          <label className="block text-sm font-medium text-gray-700">
            {t('manager.rulesConfig.selectSection')}
          </label>
          <select
            value={selectedSection || ''}
            onChange={e =>
              setSelectedSection(e.target.value ? Number(e.target.value) : null)
            }
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          >
            <option value="">
              {t('manager.rulesConfig.selectSectionPlaceholder')}
            </option>
            {sectionsWithSlots.map(({ section }) => (
              <option key={section.id_section} value={section.id_section}>
                {section.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
