import {
  ShieldExclamationIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import {
  AlgErrorDict,
  AlgErrorType,
  AlgSuccessDict,
  AlgComponentType,
  VirtualSlotNeedsMinMask,
  VirtualSlotNeedsMaxMask,
  SingleGroupIncompatibilityMinMask,
  SingleGroupIncompatibilityMaxMask,
  CrossGroupIncompatibilityMask,
  UserRuleConstraintMask,
  PreviouslyAssignedShiftsMask,
  SpecialEventsMask,
} from '@youshift/shared/types';
import { useTranslation } from 'react-i18next';
import { dateToString, localeNormalizer } from '@youshift/shared/utils';
import { useState } from 'react';

import { useItrContext } from '../../layouts/IterationRootLayout/IterationRootLayout';
import { getComponentTitle } from './getComponentTitle';
import InfoIcon from '../InfoIcon';
import ConstraintValue from './ConstraintValue';

// Create a reusable component for constraint display
function ConstraintDisplay({
  componentType,
  title,
  children,
}: {
  componentType: string;
  title: string;
  children: React.ReactNode;
}) {
  return (
    <div key={componentType} className="border rounded p-3 bg-white">
      <h4 className="font-medium mb-2">{title}</h4>
      <div className="pl-4 space-y-2">{children}</div>
    </div>
  );
}

export default function GurobiIISDisplay({
  lastExecutionStatus,
}: {
  lastExecutionStatus: AlgSuccessDict | AlgErrorDict;
}) {
  const { t, i18n } = useTranslation();
  const locale = localeNormalizer(i18n.language);
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    allUserReqs,
    assignmentErrors,
    configErrors,
    epa,
    excludedSlotsPerUser,
    incompatibilities,
    preferenceSlots,
    roles,
    sectionsWithSlots,
    shiftAssignments,
    shiftLabels,
    specialEvents,
    status,
    userPreferences,
    users,
  } = useItrContext();
  try {
    return (
      'type' in lastExecutionStatus &&
      lastExecutionStatus.type === AlgErrorType.INFEASIBLE &&
      lastExecutionStatus.gurobi_IIS && (
        <div className="border rounded-lg shadow-sm p-4 bg-yellow-50">
          <div className="relative">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-semibold text-red-600">
                {t('manager.smartass.infeasibilityAnalysis.gurobiIIS.title')}
              </h3>
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="text-gray-500 hover:text-gray-700"
              >
                {isExpanded ? (
                  <ChevronUpIcon className="h-5 w-5" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5" />
                )}
              </button>
            </div>
            <p className="text-sm text-gray-600 mb-4">
              {t('manager.smartass.infeasibilityAnalysis.gurobiIIS.subtitle')}
            </p>
          </div>
          {isExpanded && (
            <>
              <hr className="border-gray-300 my-3" />
              <div>
                <div className="mt-3">
                  <h3 className="font-medium mb-3">
                    {t(
                      'manager.smartass.infeasibilityAnalysis.gurobiIIS.infeasible_constraint_subsystem',
                    )}
                    <InfoIcon
                      title={t(
                        'manager.smartass.infeasibilityAnalysis.gurobiIIS.infeasible_constraint_subsystem',
                      )}
                      content={t(
                        'manager.smartass.infeasibilityAnalysis.gurobiIIS.infeasible_constraint_subsystem_description',
                      )}
                      className="w-4 h-4 z-30"
                    />
                  </h3>
                  <div className="space-y-3">
                    {Object.entries(lastExecutionStatus.gurobi_IIS).map(
                      ([componentType, component_mask]) => {
                        const type = componentType as AlgComponentType;
                        switch (type) {
                          case AlgComponentType.VIRTUAL_SLOT_NEEDS_MIN:
                          case AlgComponentType.VIRTUAL_SLOT_NEEDS_MAX: {
                            const virtualSlotMask = component_mask as
                              | VirtualSlotNeedsMinMask
                              | VirtualSlotNeedsMaxMask;

                            return (
                              <ConstraintDisplay
                                key={componentType}
                                componentType={componentType}
                                title={getComponentTitle(type, t)}
                              >
                                {Object.entries(virtualSlotMask).map(
                                  ([sectionId, virtualSlots]) => {
                                    const section = sectionsWithSlots.find(
                                      s =>
                                        s.section.id_section ===
                                        Number(sectionId),
                                    );
                                    return (
                                      section && (
                                        <div key={sectionId} className="mb-3">
                                          <div className="font-medium">
                                            {t('generic.section')}:{' '}
                                            {section?.section.name}
                                          </div>
                                          <div className="pl-4 mt-1 space-y-1">
                                            {virtualSlots.map(virtualSlotId => {
                                              const virtualSlot =
                                                section?.virtual_slots[
                                                  Number(virtualSlotId)
                                                ];
                                              return (
                                                <div key={virtualSlotId}>
                                                  {t(
                                                    'manager.configErrors.virtualSlot',
                                                  )}{' '}
                                                  {virtualSlot
                                                    ? `${dateToString(virtualSlot.start, 'hh:mm', locale)} - ${dateToString(virtualSlot.end, 'hh:mm', locale)}`
                                                    : t(
                                                        'manager.configErrors.virtualSlotNotFound',
                                                      )}
                                                  {virtualSlot && (
                                                    <ConstraintValue
                                                      label={
                                                        type ===
                                                        AlgComponentType.VIRTUAL_SLOT_NEEDS_MIN
                                                          ? t('generic.min')
                                                          : t('generic.max')
                                                      }
                                                      value={
                                                        type ===
                                                        AlgComponentType.VIRTUAL_SLOT_NEEDS_MIN
                                                          ? `> ${virtualSlot.min_need}`
                                                          : `< ${virtualSlot.max_need}`
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )
                                    );
                                  },
                                )}
                              </ConstraintDisplay>
                            );
                          }
                          case AlgComponentType.SINGLE_GROUP_INCOMPATIBILITY_MIN:
                          case AlgComponentType.SINGLE_GROUP_INCOMPATIBILITY_MAX: {
                            const incompatibilityMask = component_mask as
                              | SingleGroupIncompatibilityMinMask
                              | SingleGroupIncompatibilityMaxMask;

                            return (
                              <ConstraintDisplay
                                key={componentType}
                                componentType={componentType}
                                title={getComponentTitle(type, t)}
                              >
                                {Object.entries(incompatibilityMask).map(
                                  ([ruleId, slots]) => {
                                    const rule =
                                      incompatibilities.single_group_incomp[
                                        Number(ruleId)
                                      ];
                                    return (
                                      rule && (
                                        <div key={ruleId} className="mb-3">
                                          <div className="font-medium">
                                            {t('generic.rule')}: {ruleId}
                                          </div>
                                          <div className="pl-4 mt-1 space-y-1">
                                            {slots.map(slotId => {
                                              const sectionWithSlot =
                                                sectionsWithSlots.find(
                                                  s =>
                                                    s.section_slots[
                                                      Number(slotId)
                                                    ],
                                                );
                                              const slot =
                                                sectionWithSlot?.section_slots[
                                                  Number(slotId)
                                                ];
                                              return (
                                                slot && (
                                                  <div key={slotId}>
                                                    {t('generic.slot')}:{' '}
                                                    {slot &&
                                                      `${dateToString(
                                                        slot.start,
                                                        'long',
                                                        locale,
                                                      )} ${dateToString(slot.start, 'hh:mm', locale)} - ${dateToString(slot.end, 'hh:mm', locale)}`}
                                                    {type ===
                                                      AlgComponentType.SINGLE_GROUP_INCOMPATIBILITY_MIN && (
                                                      <ConstraintValue
                                                        label={t('generic.min')}
                                                        value={`> ${rule.min_simult}`}
                                                      />
                                                    )}
                                                    {type ===
                                                      AlgComponentType.SINGLE_GROUP_INCOMPATIBILITY_MAX && (
                                                      <ConstraintValue
                                                        label={t('generic.max')}
                                                        value={`< ${rule.max_simult}`}
                                                      />
                                                    )}
                                                  </div>
                                                )
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )
                                    );
                                  },
                                )}
                              </ConstraintDisplay>
                            );
                          }
                          case AlgComponentType.CROSS_GROUP_INCOMPATIBILITY: {
                            const crossGroupMask =
                              component_mask as CrossGroupIncompatibilityMask;
                            return (
                              <ConstraintDisplay
                                key={componentType}
                                componentType={componentType}
                                title={getComponentTitle(type, t)}
                              >
                                {Object.entries(crossGroupMask).map(
                                  ([ruleId, slots]) => {
                                    const rule =
                                      incompatibilities.cross_group_incomp[
                                        Number(ruleId)
                                      ];
                                    return (
                                      rule && (
                                        <div key={ruleId} className="mb-3">
                                          <div className="font-medium">
                                            {t('generic.rule')}: {ruleId}
                                          </div>
                                          <div className="pl-4 mt-1 space-y-1">
                                            {slots.map(slotId => {
                                              const sectionWithSlot =
                                                sectionsWithSlots.find(
                                                  s =>
                                                    s.section_slots[
                                                      Number(slotId)
                                                    ],
                                                );
                                              const slot =
                                                sectionWithSlot?.section_slots[
                                                  Number(slotId)
                                                ];
                                              return (
                                                slot && (
                                                  <div key={slotId}>
                                                    {t('generic.slot')}:{' '}
                                                    {slot &&
                                                      `${dateToString(
                                                        slot.start,
                                                        'long',
                                                        locale,
                                                      )} ${dateToString(
                                                        slot.start,
                                                        'hh:mm',
                                                        locale,
                                                      )} - ${dateToString(
                                                        slot.end,
                                                        'hh:mm',
                                                        locale,
                                                      )}`}
                                                  </div>
                                                )
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )
                                    );
                                  },
                                )}
                              </ConstraintDisplay>
                            );
                          }
                          case AlgComponentType.C_PREVIOUSLY_ASSIGNED_SHIFTS: {
                            const previouslyAssignedShiftsMask =
                              component_mask as PreviouslyAssignedShiftsMask;
                            return (
                              <ConstraintDisplay
                                key={componentType}
                                componentType={componentType}
                                title={getComponentTitle(type, t)}
                              >
                                {Object.entries(
                                  previouslyAssignedShiftsMask,
                                ).map(([userId, sectionSlotIds]) => {
                                  const user = users[Number(userId)];
                                  return (
                                    <div key={userId} className="mb-3">
                                      <div className="font-medium">
                                        {t('generic.user')}:{' '}
                                        {user
                                          ? `${user.firstname} ${user.lastname}`
                                          : userId}
                                      </div>
                                      <div className="pl-4 mt-1 space-y-1">
                                        {sectionSlotIds.map(sectionSlotId => {
                                          const sectionSlot =
                                            sectionsWithSlots.find(
                                              s =>
                                                s.section_slots[
                                                  Number(sectionSlotId)
                                                ],
                                            );

                                          // Extract section and slot objects
                                          const section = sectionSlot?.section;
                                          const slot =
                                            sectionSlot?.section_slots[
                                              Number(sectionSlotId)
                                            ];

                                          return (
                                            <div key={sectionSlotId}>
                                              {t('generic.slot')}:{' '}
                                              {section && slot && (
                                                <ConstraintValue
                                                  label={section.name}
                                                  value={`${dateToString(
                                                    slot.start,
                                                    'long',
                                                    locale,
                                                  )} ${dateToString(
                                                    slot.start,
                                                    'hh:mm',
                                                    locale,
                                                  )} - ${dateToString(
                                                    slot.end,
                                                    'hh:mm',
                                                    locale,
                                                  )}`}
                                                />
                                              )}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  );
                                })}
                              </ConstraintDisplay>
                            );
                          }
                          case AlgComponentType.C_SPECIAL_EVENTS: {
                            const specialEventsMask =
                              component_mask as SpecialEventsMask;

                            return (
                              <ConstraintDisplay
                                key={componentType}
                                componentType={componentType}
                                title={getComponentTitle(type, t)}
                              >
                                {Object.entries(specialEventsMask).map(
                                  ([userId, sectionSlotIds]) => {
                                    const user = users[Number(userId)];
                                    return (
                                      <div key={userId} className="mb-3">
                                        <div className="font-medium">
                                          {t('generic.user')}:{' '}
                                          {user
                                            ? `${user.firstname} ${user.lastname}`
                                            : userId}
                                        </div>
                                        <div className="pl-4 mt-1 space-y-1">
                                          {sectionSlotIds.map(sectionSlotId => {
                                            const section =
                                              sectionsWithSlots.find(
                                                s =>
                                                  s.section_slots[
                                                    Number(sectionSlotId)
                                                  ],
                                              );
                                            const sectionSlot =
                                              section?.section_slots[
                                                Number(sectionSlotId)
                                              ];
                                            return (
                                              sectionSlot && (
                                                <div key={sectionSlotId}>
                                                  {t('generic.slot')}:{' '}
                                                  {`${dateToString(
                                                    sectionSlot.start,
                                                    'long',
                                                    locale,
                                                  )} ${dateToString(
                                                    sectionSlot.start,
                                                    'hh:mm',
                                                    locale,
                                                  )}`}
                                                </div>
                                              )
                                            );
                                          })}
                                        </div>
                                      </div>
                                    );
                                  },
                                )}
                              </ConstraintDisplay>
                            );
                          }
                          case AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MAX_SLOTS: {
                            const userRuleConstraintMask =
                              component_mask as UserRuleConstraintMask;

                            return (
                              <ConstraintDisplay
                                key={componentType}
                                componentType={componentType}
                                title={getComponentTitle(type, t)}
                              >
                                {Object.entries(userRuleConstraintMask).map(
                                  ([userId, rules]) => {
                                    const user = users[Number(userId)];
                                    return (
                                      <div key={userId} className="mb-3">
                                        <div className="font-medium">
                                          {t('generic.user')}:{' '}
                                          {user
                                            ? `${user.firstname} ${user.lastname}`
                                            : userId}
                                        </div>
                                        <div className="pl-4 mt-1 space-y-1">
                                          {rules.map(ruleId => {
                                            const rule =
                                              allUserReqs[Number(ruleId)];
                                            const userReq =
                                              rule?.user_reqs[Number(userId)];
                                            return (
                                              rule &&
                                              userReq && (
                                                <div key={ruleId}>
                                                  {t('generic.rule')}:{' '}
                                                  {rule.rule.name}
                                                  <ConstraintValue
                                                    label={t('generic.max')}
                                                    value={userReq.max_slots!}
                                                  />
                                                </div>
                                              )
                                            );
                                          })}
                                        </div>
                                      </div>
                                    );
                                  },
                                )}
                              </ConstraintDisplay>
                            );
                          }
                          case AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MIN_SLOTS: {
                            const userRuleConstraintMask =
                              component_mask as UserRuleConstraintMask;

                            return (
                              <ConstraintDisplay
                                key={componentType}
                                componentType={componentType}
                                title={getComponentTitle(type, t)}
                              >
                                {Object.entries(userRuleConstraintMask).map(
                                  ([userId, rules]) => {
                                    const user = users[Number(userId)];

                                    return (
                                      <div key={userId} className="mb-3">
                                        <div className="font-medium">
                                          {t('generic.user')}:{' '}
                                          {user
                                            ? `${user.firstname} ${user.lastname}`
                                            : userId}
                                        </div>
                                        <div className="pl-4 mt-1 space-y-1">
                                          {rules.map(ruleId => {
                                            const rule =
                                              allUserReqs[Number(ruleId)];
                                            const userReq =
                                              rule.user_reqs[Number(userId)];
                                            return (
                                              rule &&
                                              userReq && (
                                                <div key={ruleId}>
                                                  {t('generic.rule')}:{' '}
                                                  {rule.rule.name}
                                                  <ConstraintValue
                                                    label={t('generic.min')}
                                                    value={userReq.min_slots!}
                                                  />
                                                </div>
                                              )
                                            );
                                          })}
                                        </div>
                                      </div>
                                    );
                                  },
                                )}
                              </ConstraintDisplay>
                            );
                          }
                          case AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MAX_DURATION: {
                            const userRuleConstraintMask =
                              component_mask as UserRuleConstraintMask;

                            return (
                              <ConstraintDisplay
                                key={componentType}
                                componentType={componentType}
                                title={getComponentTitle(type, t)}
                              >
                                {Object.entries(userRuleConstraintMask).map(
                                  ([userId, rules]) => {
                                    const user = users[Number(userId)];

                                    return (
                                      <div key={userId} className="mb-3">
                                        <div className="font-medium">
                                          {t('generic.user')}:{' '}
                                          {user
                                            ? `${user.firstname} ${user.lastname}`
                                            : userId}
                                        </div>
                                        <div className="pl-4 mt-1 space-y-1">
                                          {rules.map(ruleId => {
                                            const rule =
                                              allUserReqs[Number(ruleId)];
                                            const userReq =
                                              rule.user_reqs[Number(userId)];
                                            return (
                                              rule &&
                                              userReq && (
                                                <div key={ruleId}>
                                                  {t('generic.rule')}:{' '}
                                                  {rule.rule.name}
                                                  <ConstraintValue
                                                    label={t('generic.max')}
                                                    value={
                                                      userReq.max_duration!
                                                    }
                                                  />
                                                </div>
                                              )
                                            );
                                          })}
                                        </div>
                                      </div>
                                    );
                                  },
                                )}
                              </ConstraintDisplay>
                            );
                          }
                          case AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MIN_DURATION: {
                            const userRuleConstraintMask =
                              component_mask as UserRuleConstraintMask;

                            return (
                              <ConstraintDisplay
                                key={componentType}
                                componentType={componentType}
                                title={getComponentTitle(type, t)}
                              >
                                {Object.entries(userRuleConstraintMask).map(
                                  ([userId, rules]) => {
                                    const user = users[Number(userId)];

                                    return (
                                      <div key={userId} className="mb-3">
                                        <div className="font-medium">
                                          {t('generic.user')}:{' '}
                                          {user
                                            ? `${user.firstname} ${user.lastname}`
                                            : userId}
                                        </div>
                                        <div className="pl-4 mt-1 space-y-1">
                                          {rules.map(ruleId => {
                                            const rule =
                                              allUserReqs[Number(ruleId)];
                                            const userReq =
                                              rule.user_reqs[Number(userId)];
                                            return (
                                              rule &&
                                              userReq && (
                                                <div key={ruleId}>
                                                  {t('generic.rule')}:{' '}
                                                  {rule.rule.name}
                                                  <ConstraintValue
                                                    label={t('generic.min')}
                                                    value={
                                                      userReq.min_duration!
                                                    }
                                                  />
                                                </div>
                                              )
                                            );
                                          })}
                                        </div>
                                      </div>
                                    );
                                  },
                                )}
                              </ConstraintDisplay>
                            );
                          }
                          default:
                            return (
                              <div key={componentType}>
                                {t('algorithmErrors.genericError')}
                              </div>
                            );
                        }
                      },
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )
    );
  } catch (error) {
    return <div>UNAVAILABLE</div>;
  }
}
