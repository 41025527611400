import { useTranslation } from 'react-i18next';
import { UserRequirementType } from '@youshift/shared/types';

import { useManagerContext } from '../../../../layouts/ManagerLayout';
import { useItrContext } from '../../../../layouts/IterationRootLayout/IterationRootLayout';

export function GroupRulesPerUser() {
  const { t } = useTranslation();
  const { allUserReqs } = useItrContext();
  const { users, roles } = useManagerContext();

  return (
    <div className="relative my-3">
      <button
        className="mb-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        onClick={() => window.history.back()}
      >
        ← {t('generic.back')}
      </button>
      <h2 className="mb-3 font-bold text-lg">
        {t('manager.rulesConfig.howManyShiftsCanEveryoneDo')}
      </h2>
      <p className="mb-3 text-gray-500">
        {t('manager.rulesConfig.howManyShiftsCanEveryoneDoDescription')}
      </p>
      <div className="overflow-x-auto overflow-y-auto max-h-[65vh] border rounded-lg">
        <table className="w-full table-auto bg-white">
          <thead className="sticky top-0 z-20 bg-white">
            <tr className="bg-gray-100">
              <th className="sticky left-0 z-20 px-2 py-1 border bg-gray-100 min-w-[120px] whitespace-normal">
                {t('generic.name')}
              </th>
              <th className="sticky left-[120px] z-20 px-2 py-1 border bg-gray-100 min-w-[100px] whitespace-normal">
                {t('generic.role')}
              </th>
              {Object.values(allUserReqs).map(({ rule, user_reqs }) => (
                <th
                  key={rule.id_rule}
                  className="px-2 py-1 border min-w-[120px] max-w-[160px] whitespace-normal"
                >
                  {rule.name}
                  {Object.keys(user_reqs).length > 0 && (
                    <span className="font-normal text-base">
                      {` (${
                        Object.values(user_reqs)[0]?.req_type ===
                        UserRequirementType.DURATION
                          ? 'h'
                          : t('manager.rulesConfig.shifts')
                      })`}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(users)
              .filter(([_, user]) => user.id_user_role) // Only show users with roles
              .sort((a, b) => {
                // Sort by role name
                const roleA = roles[a[1].id_user_role!]?.name || '';
                const roleB = roles[b[1].id_user_role!]?.name || '';
                return roleA.localeCompare(roleB);
              })
              .map(([userId, user]) => {
                const userRole = roles[user.id_user_role!];

                return (
                  <tr key={userId}>
                    <td className="sticky left-0 z-10 px-2 py-1 border bg-white">
                      {`${user.firstname} ${user.lastname}`}
                    </td>
                    <td className="sticky left-[120px] z-10 px-2 py-1 border bg-white">
                      {userRole?.name}
                    </td>
                    {Object.values(allUserReqs).map(ruleReqs => {
                      const userRuleReqs = ruleReqs.user_reqs[Number(userId)];
                      if (!userRuleReqs?.req_type) {
                        return (
                          <td
                            key={`${ruleReqs.rule.id_rule}-${user.id}`}
                            className="px-2 py-1 border text-center"
                          >
                            -
                          </td>
                        );
                      }
                      return (
                        <td
                          key={`${ruleReqs.rule.id_rule}-${user.id}`}
                          className="px-2 py-1 border text-center"
                        >
                          {userRuleReqs
                            ? userRuleReqs.req_type ===
                              UserRequirementType.DURATION
                              ? userRuleReqs.min_duration === 0 &&
                                userRuleReqs.max_duration === 0
                                ? t('manager.rulesConfig.none')
                                : `${userRuleReqs.min_duration} h - ${userRuleReqs.max_duration} h`
                              : userRuleReqs.min_slots === 0 &&
                                  userRuleReqs.max_slots === 0
                                ? t('manager.rulesConfig.none')
                                : `${userRuleReqs.min_slots} - ${userRuleReqs.max_slots}`
                            : t('manager.rulesConfig.noLimits')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
