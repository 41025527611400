/* eslint-disable react/require-default-props */
import type { VirtualSlot as VirtualSlotType } from '@youshift/shared/types';

import { Epa } from '../../layouts/IterationRootLayout/types';

type SectionToVirtualSlotDict = {
  [idSectionSlot: number]: number[];
};

/**
 * Returns a dictionary where the key is an id_virtual_slot and the value
 * is the total number of assignments for that virtual slot.
 */
export function getAssignmentsByVirtualSlot(
  epa: Epa | undefined,
  virtualSlotsForWeek: VirtualSlotType[],
): Record<number, number> | undefined {
  if (!epa) return undefined;

  return Object.fromEntries(
    virtualSlotsForWeek.map(virtualSlot => {
      const totalAssignments = virtualSlot.section_slots.reduce(
        (total, idSectionSlot) => {
          const assignmentsForSlot =
            epa.assignmentsMap.bySectionSlot[idSectionSlot] || [];
          return total + assignmentsForSlot.length;
        },
        0,
      );

      return [virtualSlot.id_virtual_slot, totalAssignments];
    }),
  );
}

/**
 * Builds a dictionary mapping from a section slot to all the virtual slot IDs
 * that it belongs to.
 */
export function getSectionToVirtualSlotDict(
  virtualSlotsForWeek: VirtualSlotType[],
): SectionToVirtualSlotDict {
  const sectionToVirtualSlot: SectionToVirtualSlotDict = {};

  virtualSlotsForWeek.forEach(virtualSlot => {
    virtualSlot.section_slots.forEach(idSectionSlot => {
      if (!sectionToVirtualSlot[idSectionSlot]) {
        sectionToVirtualSlot[idSectionSlot] = [];
      }
      sectionToVirtualSlot[idSectionSlot].push(
        Number(virtualSlot.id_virtual_slot),
      );
    });
  });

  return sectionToVirtualSlot;
}

/**
 * Determines whether any virtual slot belonging to a given section slot
 * is already "full".
 */
export function getAreSlotsFull(
  assignmentsByVirtualSlot: Record<number, number> | undefined,
  sectionToVirtualSlotDict: SectionToVirtualSlotDict,
  virtualSlotsForWeek: VirtualSlotType[],
): Record<number, boolean> {
  const areSlotsFull: Record<number, boolean> = {};

  if (!assignmentsByVirtualSlot) return areSlotsFull;

  Object.entries(sectionToVirtualSlotDict).forEach(
    ([idSectionSlot, virtualSlotIds]) => {
      const anyFull = virtualSlotIds.some(idVirtualSlot => {
        const virtualSlot = virtualSlotsForWeek.find(
          vs => vs.id_virtual_slot === idVirtualSlot,
        );
        if (!virtualSlot) return false;
        const currentlyAssigned = assignmentsByVirtualSlot[idVirtualSlot] || 0;
        return currentlyAssigned === virtualSlot.max_need;
      });

      areSlotsFull[Number(idSectionSlot)] = anyFull;
    },
  );

  return areSlotsFull;
}
