"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONSTRAINT_COMPONENTS_NUMERICAL_TYPE = exports.ConstraintComponentsNumericalType = exports.AlgComponentType = void 0;
var AlgComponentType;
(function (AlgComponentType) {
    // Check that a user has enough free slots to fill all its requirements for a rule.
    // Global constraints
    AlgComponentType["VIRTUAL_SLOT_NEEDS_MIN"] = "virtSlNeedsMin";
    AlgComponentType["VIRTUAL_SLOT_NEEDS_MAX"] = "virtualSlotNeedsMax";
    AlgComponentType["SINGLE_GROUP_INCOMPATIBILITY_MIN"] = "singleGroupIncompatibilityMin";
    AlgComponentType["SINGLE_GROUP_INCOMPATIBILITY_MAX"] = "singleGroupIncompatibilityMax";
    AlgComponentType["CROSS_GROUP_INCOMPATIBILITY"] = "crossGroupIncompatibility";
    // User constraints
    AlgComponentType["C_1"] = "c1";
    AlgComponentType["C_CUSTOM_REST_PERIOD"] = "customRestPeriod";
    AlgComponentType["C_PERSONAL_BLOCKING"] = "personalBlocking";
    AlgComponentType["C_JUSTIFIED_BLOCKING"] = "justifiedBlocking";
    AlgComponentType["C_SPECIAL_EVENTS"] = "specialEvents";
    AlgComponentType["C_PREVIOUSLY_ASSIGNED_SHIFTS"] = "previouslyAssignedShifts";
    AlgComponentType["C_USER_SHIFT_REQUIREMENT_RULE_MAX_SLOTS"] = "userShiftRequirementRuleMaxSlots";
    AlgComponentType["C_USER_SHIFT_REQUIREMENT_RULE_MIN_SLOTS"] = "userShiftRequirementRuleMinSlots";
    AlgComponentType["C_USER_SHIFT_REQUIREMENT_RULE_MAX_DURATION"] = "userShiftRequirementRuleMaxDuration";
    AlgComponentType["C_USER_SHIFT_REQUIREMENT_RULE_MIN_DURATION"] = "userShiftRequirementRuleMinDuration";
    AlgComponentType["C_PENALTY_WORKER_MAX"] = "penaltyWorkerMax";
})(AlgComponentType || (exports.AlgComponentType = AlgComponentType = {}));
var ConstraintComponentsNumericalType;
(function (ConstraintComponentsNumericalType) {
    ConstraintComponentsNumericalType["SCALAR"] = "scalar";
    ConstraintComponentsNumericalType["BOOLEAN"] = "boolean";
})(ConstraintComponentsNumericalType || (exports.ConstraintComponentsNumericalType = ConstraintComponentsNumericalType = {}));
exports.CONSTRAINT_COMPONENTS_NUMERICAL_TYPE = (_a = {},
    // Scalar constraints
    _a[AlgComponentType.VIRTUAL_SLOT_NEEDS_MIN] = ConstraintComponentsNumericalType.SCALAR,
    _a[AlgComponentType.VIRTUAL_SLOT_NEEDS_MAX] = ConstraintComponentsNumericalType.SCALAR,
    _a[AlgComponentType.SINGLE_GROUP_INCOMPATIBILITY_MIN] = ConstraintComponentsNumericalType.SCALAR,
    _a[AlgComponentType.SINGLE_GROUP_INCOMPATIBILITY_MAX] = ConstraintComponentsNumericalType.SCALAR,
    _a[AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MAX_SLOTS] = ConstraintComponentsNumericalType.SCALAR,
    _a[AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MIN_SLOTS] = ConstraintComponentsNumericalType.SCALAR,
    _a[AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MAX_DURATION] = ConstraintComponentsNumericalType.SCALAR,
    _a[AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MIN_DURATION] = ConstraintComponentsNumericalType.SCALAR,
    _a[AlgComponentType.C_PENALTY_WORKER_MAX] = ConstraintComponentsNumericalType.SCALAR,
    // Boolean constraints
    _a[AlgComponentType.CROSS_GROUP_INCOMPATIBILITY] = ConstraintComponentsNumericalType.BOOLEAN,
    _a[AlgComponentType.C_1] = ConstraintComponentsNumericalType.BOOLEAN,
    _a[AlgComponentType.C_CUSTOM_REST_PERIOD] = ConstraintComponentsNumericalType.BOOLEAN,
    _a[AlgComponentType.C_PERSONAL_BLOCKING] = ConstraintComponentsNumericalType.BOOLEAN,
    _a[AlgComponentType.C_JUSTIFIED_BLOCKING] = ConstraintComponentsNumericalType.BOOLEAN,
    _a[AlgComponentType.C_SPECIAL_EVENTS] = ConstraintComponentsNumericalType.BOOLEAN,
    _a[AlgComponentType.C_PREVIOUSLY_ASSIGNED_SHIFTS] = ConstraintComponentsNumericalType.BOOLEAN,
    _a);
