import {
  CrossGroupIncompatibilityRule,
  SectionSlot,
  ShiftAssignment,
  SingleGroupIncompatibilityRule,
  SpecialEvent,
  UserPreference,
  UserPreferenceType,
  UserReqRule,
  UserRequirement,
  IncompatibilityRules,
  VirtualSlot,
  UserRequirementType,
} from '@youshift/shared/types';
import { getDifferenceInMinutes } from '@youshift/shared/utils';

import { datetimeRangeOverlap } from '../utils';
import {
  AssignmentCheckErrors,
  AssignmentCheckErrorType,
  AssignmentOnJustifiedBlockErrorContext,
  AssignmentOnPersonalBlockErrorContext,
  AssignmentOnSpecialEventErrorContext,
  AssignmentRestPeriodViolationErrorContext,
  AssignmentRests24HoursErrorContext,
  AssignmentOnOverlappingAssignmentErrorContext,
  CrossGroupIncompErrorContext,
  SingleGroupIncompDeficitErrorContext,
  SingleGroupIncompSurplusErrorContext,
  UserAssignmentErrors,
  VirtualSlotNeedsDeficitErrorContext,
  VirtualSlotNeedsSurplusErrorContext,
  UserReqSlotsSurplusErrorContext,
  UserReqSlotsDeficitErrorContext,
  UserReqDurationDeficitErrorContext,
  UserReqDurationSurplusErrorContext,
} from './types';
import {
  Epa,
  AssignmentsMap,
} from '../../../layouts/IterationRootLayout/types';

function findAssignmentsWithinEndRange(
  user_assignment: ShiftAssignment,
  single_user_assignments: ShiftAssignment[],
  section_slots: Record<number, SectionSlot>,
  minutes_difference: number,
): number[] {
  const section_slot = section_slots[user_assignment.id_section_slot];
  const current_slot_end = new Date(section_slot.end).getTime();
  const rest_period_end = current_slot_end + minutes_difference * 60 * 1000;

  const assignments_within_range = Object.values(single_user_assignments)
    .filter(assignment => {
      if (
        user_assignment.id_shift_assignment === assignment.id_shift_assignment
      ) {
        return false; // Exclude current assignment
      }

      const other_slot = section_slots[assignment.id_section_slot];
      const other_slot_start = new Date(other_slot.start).getTime();
      const other_slot_end = new Date(other_slot.end).getTime();

      // Check if the other slot overlaps with the rest period window
      return (
        // Other slot starts during rest period
        (other_slot_start > current_slot_end &&
          other_slot_start < rest_period_end) ||
        // Other slot ends during rest period
        (other_slot_end > current_slot_end &&
          other_slot_end < rest_period_end) ||
        // Other slot completely contains rest period
        (other_slot_start < current_slot_end &&
          other_slot_end > rest_period_end)
      );
    })
    .map(assignment => assignment.id_section_slot);

  return assignments_within_range;
}

export const checkVirtualSlotNeeds = (
  virtualSlot: VirtualSlot,
  assignmentsMap: AssignmentsMap,
):
  | {
      type: AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_DEFICIT;
      context: VirtualSlotNeedsDeficitErrorContext;
    }
  | {
      type: AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_SURPLUS;
      context: VirtualSlotNeedsSurplusErrorContext;
    }
  | false => {
  const assignments_on_virtual_slot = virtualSlot.section_slots.flatMap(
    id_section_slot => assignmentsMap.bySectionSlot[id_section_slot] || [],
  );

  if (assignments_on_virtual_slot.length < virtualSlot.min_need) {
    const deficit_error_context: VirtualSlotNeedsDeficitErrorContext = {
      assigned_shift_assignments: assignments_on_virtual_slot,
      min_need: virtualSlot.min_need,
    };
    return {
      type: AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_DEFICIT,
      context: deficit_error_context,
    };
  }
  if (virtualSlot.max_need < assignments_on_virtual_slot.length) {
    const surplus_error_context: VirtualSlotNeedsSurplusErrorContext = {
      assigned_shift_assignments: assignments_on_virtual_slot,
      max_need: virtualSlot.max_need,
    };
    return {
      type: AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_SURPLUS,
      context: surplus_error_context,
    };
  }
  return false;
};

/**
 * Checks if a user's assignments satisfy their minimum and maximum requirements for a set of section slots.
 *
 * @param user_requirement - The user's requirements containing min_slots and max_slots
 * @param rule_section_slots - Array of section slot IDs to check assignments against
 * @param single_user_assignments - Array of all assignments for this specific user
 * @returns Either false if requirements are satisfied, or an error object containing:
 *   - type: USER_REQ_DEFICIT if below min_slots, USER_REQ_SURPLUS if above max_slots
 *   - context: Contains the actual number of assignments and the min/max requirement that was violated
 */
export const checkUserReqs = (
  user_requirement: UserRequirement,
  rule_section_slots: number[],
  single_user_assignments: ShiftAssignment[],
  sectionSlotsDict: Record<number, SectionSlot>,
):
  | {
      type:
        | AssignmentCheckErrorType.USER_REQ_SLOTS_DEFICIT
        | AssignmentCheckErrorType.USER_REQ_SLOTS_SURPLUS
        | AssignmentCheckErrorType.USER_REQ_DURATION_DEFICIT
        | AssignmentCheckErrorType.USER_REQ_DURATION_SURPLUS;
      context:
        | UserReqSlotsDeficitErrorContext
        | UserReqSlotsSurplusErrorContext
        | UserReqDurationDeficitErrorContext
        | UserReqDurationSurplusErrorContext;
    }
  | false => {
  const assignments_on_rule_slots = single_user_assignments.filter(assignment =>
    rule_section_slots.includes(assignment.id_section_slot),
  );

  if (user_requirement.req_type === UserRequirementType.SLOTS) {
    const assigned_slots: number = assignments_on_rule_slots.length;

    const min_req = user_requirement.min_slots || 0;
    if (min_req > assigned_slots) {
      const deficit_error_context: UserReqSlotsDeficitErrorContext = {
        assigned_slots,
        min_req,
      };
      return {
        type: AssignmentCheckErrorType.USER_REQ_SLOTS_DEFICIT,
        context: deficit_error_context,
      };
    }
    const max_req = user_requirement.max_slots || 0;
    if (max_req < assigned_slots) {
      const surplus_error_context: UserReqSlotsSurplusErrorContext = {
        assigned_slots,
        max_req,
      };
      return {
        type: AssignmentCheckErrorType.USER_REQ_SLOTS_SURPLUS,
        context: surplus_error_context,
      };
    }
    return false;
  }
  if (user_requirement.req_type === UserRequirementType.DURATION) {
    const assigned_duration_minutes = assignments_on_rule_slots.reduce(
      (total, assignment) => {
        const slot = sectionSlotsDict[assignment.id_section_slot];
        const durationInMinutes = getDifferenceInMinutes(slot.start, slot.end);
        return total + durationInMinutes;
      },
      0,
    ); // in minutes

    const min_req_minutes = (user_requirement.min_duration || 0) * 60; // in minutes
    if (min_req_minutes > assigned_duration_minutes) {
      const deficit_error_context: UserReqDurationDeficitErrorContext = {
        assigned_duration_minutes,
        min_req_minutes,
      };
      return {
        type: AssignmentCheckErrorType.USER_REQ_DURATION_DEFICIT,
        context: deficit_error_context,
      };
    }

    const max_req_minutes = (user_requirement.max_duration || 0) * 60; // in minutes
    if (max_req_minutes < assigned_duration_minutes) {
      const surplus_error_context: UserReqDurationSurplusErrorContext = {
        assigned_duration_minutes,
        max_req_minutes,
      };
      return {
        type: AssignmentCheckErrorType.USER_REQ_DURATION_SURPLUS,
        context: surplus_error_context,
      };
    }
    return false;
  }

  throw new Error('Invalid user requirement type');
};

/**
 * Checks if a user has been assigned to a shift on a slot where they have a blocking preference.
 * A blocking preference can be either "unavailable" (justified block) or "points" (personal block).
 *
 * Trigger:
 * - User is assigned to a section_slot
 *
 * @param assignment - The shift assignment to check
 * @param section_slot - The section slot the assignment is on
 * @param user_preference_on_slot - The user's preference for this slot
 * @returns AssignmentCheckError - Object containing:
 *   - type: SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK if user has an "unavailable" preference,
 *          SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK if user has a "points" preference
 *   - context: AssignmentOnJustifiedBlockErrorContext or AssignmentOnPersonalBlockErrorContext
 *   - false if no blocking preference exists
 */
export const checkAssignmentOnBlockingUserPreference = (
  assignment: ShiftAssignment,
  section_slot: SectionSlot,
  user_preference_on_slot: UserPreference,
):
  | {
      type:
        | AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK
        | AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK;
      context:
        | AssignmentOnJustifiedBlockErrorContext
        | AssignmentOnPersonalBlockErrorContext;
    }
  | false => {
  // No conflict if user has no preference on this slot.
  if (!user_preference_on_slot) {
    return false;
  }
  if (assignment.id_section_slot !== section_slot.id_section_slot) {
    throw new Error(
      'section_slot mismatch in checkAssignmentOnBlockingUserPreference for assignment',
    );
  }
  if (assignment.id_user !== user_preference_on_slot.id_user) {
    throw new Error(
      'user mismatch in checkAssignmentOnBlockingUserPreference for assignment',
    );
  }
  if (user_preference_on_slot.id_pref_slot !== section_slot.id_pref_slot) {
    throw new Error(
      'preference_slot mismatch in checkAssignmentOnBlockingUserPreference for assignment',
    );
  }
  if (
    user_preference_on_slot.preference === UserPreferenceType.JUSTIFIED_BLOCKING
  ) {
    const error_context: AssignmentOnJustifiedBlockErrorContext = {
      id_preference_slot: user_preference_on_slot.id_pref_slot,
    };
    return {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK,
      context: error_context,
    };
  }
  if (
    user_preference_on_slot.preference === UserPreferenceType.PERSONAL_BLOCKING
  ) {
    const error_context: AssignmentOnPersonalBlockErrorContext = {
      id_preference_slot: user_preference_on_slot.id_pref_slot,
    };
    return {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK,
      context: error_context,
    };
  }
  return false;
};

/**
 * Checks if a shift assignment conflicts with any special events (e.g. vacation) for the assigned user
 *
 * @param assignment - The shift assignment to check
 * @param section_slot - The section slot the assignment is for
 * @param single_user_events - List of special events for the assigned user
 * @returns Either false (no conflict) or an object containing:
 *   - type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_EVENT
 *   - context: AssignmentOnSpecialEventErrorContext with details about overlapping events
 */
export const checkAssignmentOnSpecialEvent = (
  assignment: ShiftAssignment,
  section_slot: SectionSlot,
  single_user_events: SpecialEvent[],
):
  | {
      type: AssignmentCheckErrorType;
      context: AssignmentOnSpecialEventErrorContext;
    }
  | false => {
  if (assignment.id_section_slot !== section_slot.id_section_slot) {
    throw new Error(
      'section_slot mismatch in checkAssignmentOnEvent for assignment',
    );
  }
  if (
    !single_user_events.every(event => event.id_user === assignment.id_user)
  ) {
    throw new Error('user mismatch in checkAssignmentOnEvent for events');
  }

  // No conflict if user has no special events.
  if (!single_user_events) {
    return false;
  }
  const overlapping_events = single_user_events.filter(event =>
    datetimeRangeOverlap(
      new Date(event.start),
      new Date(new Date(event.end).getTime()), // Add 24 hours to the end date. This is because special events are stored as dates.
      new Date(section_slot.start),
      new Date(section_slot.end),
    ),
  );
  if (overlapping_events.length > 0) {
    const error_context: AssignmentOnSpecialEventErrorContext = {
      events: overlapping_events,
    };
    return {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_EVENT,
      context: error_context,
    };
  }
  return false;
};

export const checkAssignmentRestPeriod = (
  user_assignment: ShiftAssignment,
  single_user_assignments: ShiftAssignment[],
  section_slots: Record<number, SectionSlot>,
):
  | {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION;
      context: AssignmentRestPeriodViolationErrorContext;
    }
  | false => {
  const section_slot = section_slots[user_assignment.id_section_slot];
  const assignments_within_range = findAssignmentsWithinEndRange(
    user_assignment,
    single_user_assignments,
    section_slots,
    section_slot.rest_period,
  );

  if (assignments_within_range.length > 0) {
    return {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION,
      context: {
        conflicting_assignments: assignments_within_range,
      },
    };
  }
  return false;
};

export const checkAssignmentOnOverlappingAssignment = (
  user_assignment: ShiftAssignment,
  single_user_assignments: ShiftAssignment[],
  section_slots: Record<number, SectionSlot>,
):
  | {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_OVERLAPPING_ASSIGNMENT;
      context: AssignmentOnOverlappingAssignmentErrorContext;
    }
  | false => {
  const section_slot = section_slots[user_assignment.id_section_slot];

  const overlapping_assignments = Object.values(single_user_assignments)
    .filter(assignment => {
      if (
        user_assignment.id_shift_assignment === assignment.id_shift_assignment
      ) {
        return false; // Exclude current assignment
      }
      const other_slot = section_slots[assignment.id_section_slot];
      return (
        section_slot.start < other_slot.start &&
        datetimeRangeOverlap(
          new Date(section_slot.start),
          new Date(section_slot.end),
          new Date(other_slot.start),
          new Date(other_slot.end),
        )
      );
    })
    .map(assignment => assignment.id_section_slot);

  if (overlapping_assignments.length > 0) {
    return {
      type: AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_OVERLAPPING_ASSIGNMENT,
      context: {
        conflicting_assignments: overlapping_assignments,
      },
    };
  }
  return false;
};
/**
 * Checks if assignments on a single slot and its overlapping slots violate the single group incompatibility rule.
 * A single group incompatibility rule specifies minimum and maximum number of users
 * from a specific user group that can be assigned simultaneously to a slot and its overlapping slots.
 *
 * @param assignments_on_single_slot - Array of ShiftAssignments for a specific section slot
 * @param assignments_on_overlapping_slots - Array of ShiftAssignments for slots that overlap with the target slot
 * @param single_group_incomp_rule - The single group incompatibility rule to check against
 * @returns Error object with context if rule is violated (too many/few users from group assigned), false otherwise
 */
export const checkSingleGroupIncomp = (
  assignments_on_single_slot: ShiftAssignment[],
  assignments_on_overlapping_slots: ShiftAssignment[],
  single_group_incomp_rule: SingleGroupIncompatibilityRule,
):
  | {
      type:
        | AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_SURPLUS
        | AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_DEFICIT;
      context:
        | SingleGroupIncompSurplusErrorContext
        | SingleGroupIncompDeficitErrorContext;
    }
  | false => {
  const group_users_assigned_on_slot: number[] = [];
  for (const assignment of assignments_on_single_slot) {
    if (single_group_incomp_rule.user_group.includes(assignment.id_user)) {
      group_users_assigned_on_slot.push(assignment.id_user);
    }
  }
  for (const assignment of assignments_on_overlapping_slots) {
    if (single_group_incomp_rule.user_group.includes(assignment.id_user)) {
      group_users_assigned_on_slot.push(assignment.id_user);
    }
  }
  if (
    group_users_assigned_on_slot.length > single_group_incomp_rule.max_simult
  ) {
    const surplus_error_context: SingleGroupIncompSurplusErrorContext = {
      assigned_users: group_users_assigned_on_slot,
      max_simult: single_group_incomp_rule.max_simult,
    };
    return {
      type: AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_SURPLUS,
      context: surplus_error_context,
    };
  }

  if (
    group_users_assigned_on_slot.length < single_group_incomp_rule.min_simult
  ) {
    const deficit_error_context: SingleGroupIncompDeficitErrorContext = {
      assigned_users: group_users_assigned_on_slot,
      min_simult: single_group_incomp_rule.min_simult,
    };
    return {
      type: AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_DEFICIT,
      context: deficit_error_context,
    };
  }
  return false;
};

/**
 * Checks if assignments on a single slot violate the cross group incompatibility rule.
 * A cross group incompatibility rule specifies that users from two different groups
 * cannot be assigned simultaneously to the same slot.
 *
 * @param assignments_on_single_slot - Array of ShiftAssignments for a specific section slot
 * @param cross_group_incomp_rule - The cross group incompatibility rule to check against
 * @returns Object containing error type and context if rule is violated, false otherwise
 */
export const checkCrossGroupIncomp = (
  assignments_on_single_slot: ShiftAssignment[],
  assignments_on_overlapping_slots: ShiftAssignment[],
  cross_group_incomp_rule: CrossGroupIncompatibilityRule,
):
  | {
      type: AssignmentCheckErrorType.CROSS_GROUP_INCOMPATIBILITY;
      context: CrossGroupIncompErrorContext;
    }
  | false => {
  const group_1_users_assigned_on_slot: number[] = [];
  for (const assignment of assignments_on_single_slot) {
    if (cross_group_incomp_rule.user_group.includes(assignment.id_user)) {
      group_1_users_assigned_on_slot.push(assignment.id_user);
    }
  }
  for (const assignment of assignments_on_overlapping_slots) {
    if (cross_group_incomp_rule.user_group.includes(assignment.id_user)) {
      group_1_users_assigned_on_slot.push(assignment.id_user);
    }
  }

  const group_2_users_assigned_on_slot: number[] = [];
  for (const assignment of assignments_on_single_slot) {
    if (
      cross_group_incomp_rule.user_group_secondary.includes(assignment.id_user)
    ) {
      group_2_users_assigned_on_slot.push(assignment.id_user);
    }
  }
  for (const assignment of assignments_on_overlapping_slots) {
    if (
      cross_group_incomp_rule.user_group_secondary.includes(assignment.id_user)
    ) {
      group_2_users_assigned_on_slot.push(assignment.id_user);
    }
  }

  if (
    group_1_users_assigned_on_slot.length > 0 &&
    group_2_users_assigned_on_slot.length > 0
  ) {
    const error_context: CrossGroupIncompErrorContext = {
      assigned_users_group_1: group_1_users_assigned_on_slot,
      assigned_users_group_2: group_2_users_assigned_on_slot,
    };
    return {
      type: AssignmentCheckErrorType.CROSS_GROUP_INCOMPATIBILITY,
      context: error_context,
    };
  }
  return false;
};

/* eslint-disable no-param-reassign */
/**
 * Helper function that emulates Python's defaultdict behavior for the user_assignments error dictionary.
 * Ensures that nested objects exist at the given userId and sectionSlotId paths before attempting to access them.
 * This avoids having to check for undefined at each level when adding errors.
 *
 * @param user_assignments - The user assignments error dictionary to populate
 * @param userId - The ID of the user to ensure exists in the dictionary
 * @param sectionSlotId - The ID of the section slot to ensure exists for the user
 */
function populateUserAssignmentErrorsDict(
  user_assignments: UserAssignmentErrors,
  userId: number,
  sectionSlotId: number,
): void {
  if (!(userId in user_assignments)) {
    user_assignments[userId] = {};
  }
  if (!(sectionSlotId in user_assignments[userId])) {
    user_assignments[userId][sectionSlotId] = {};
  }
}

export function postExecChecks(
  allUserReqs: Record<number, UserReqRule>,
  epa: Epa,
  incompatibilities: IncompatibilityRules,
  sectionSlotsDict: Record<number, SectionSlot>,
  overlappingSlots: Record<number, number[]>,
  allVirtualSlots: VirtualSlot[],
): AssignmentCheckErrors {
  const { eventsMap, preferencesMap, assignmentsMap } = epa;
  const errors: AssignmentCheckErrors = {
    user_req_rules: {},
    single_group_incompatibilities: {},
    cross_group_incompatibilities: {},
    user_assignments: {},
    virtual_slots: {},
  };

  // Virtual Slot checks.
  allVirtualSlots.forEach(virtualSlot => {
    const virtual_slot_needs_error = checkVirtualSlotNeeds(
      virtualSlot,
      assignmentsMap,
    );

    if (virtual_slot_needs_error) {
      if (!errors.virtual_slots[virtualSlot.id_virtual_slot]) {
        errors.virtual_slots[virtualSlot.id_virtual_slot] = {};
      }

      if (
        virtual_slot_needs_error.type ===
        AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_DEFICIT
      ) {
        errors.virtual_slots[virtualSlot.id_virtual_slot][
          AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_DEFICIT
        ] = virtual_slot_needs_error.context;
      } else {
        errors.virtual_slots[virtualSlot.id_virtual_slot][
          AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_SURPLUS
        ] = virtual_slot_needs_error.context;
      }
    }
  });

  // User Requirement Rules checks.
  Object.entries(allUserReqs).forEach(([id_req_rule_str, req_rule]) => {
    const id_req_rule: number = Number(id_req_rule_str);
    if (!errors.user_req_rules[id_req_rule]) {
      errors.user_req_rules[id_req_rule] = {};
    }

    Object.entries(req_rule.user_reqs).forEach(([id_user_str, user_req]) => {
      const id_user = Number(id_user_str);
      let single_user_assignments: ShiftAssignment[] = [];
      if (id_user in assignmentsMap.byUser) {
        single_user_assignments = assignmentsMap.byUser[id_user];
      }
      const user_req_error = checkUserReqs(
        user_req,
        req_rule.section_slots,
        single_user_assignments,
        sectionSlotsDict,
      );
      if (user_req_error) {
        const errorTypeObj =
          errors.user_req_rules[id_req_rule][user_req_error.type] ||
          (errors.user_req_rules[id_req_rule][user_req_error.type] = {});
        errorTypeObj[id_user] = user_req_error.context;
      }
    });
  });

  // Single Group Incompatibilities checks.
  Object.entries(incompatibilities?.single_group_incomp).forEach(
    ([id_incompatibility_str, single_group_incompatibility]) => {
      const id_incompatibility = Number(id_incompatibility_str);

      errors.single_group_incompatibilities[id_incompatibility] = {};

      // Incompatibilities are applied on a per-slot basis (i.e. slots are independent of each other).
      single_group_incompatibility.section_slots.forEach(id_slot_str => {
        const id_slot = Number(id_slot_str);
        // Assignment of users to the slot itself.
        let assignments_on_single_slot: ShiftAssignment[] = [];
        if (id_slot in assignmentsMap.bySectionSlot) {
          assignments_on_single_slot = assignmentsMap.bySectionSlot[id_slot];
        }
        // Assignment of users to overlapping slots.
        let assignments_on_overlapping_slots: ShiftAssignment[] = [];
        if (id_slot in overlappingSlots) {
          // Only get overlapping slots with higher IDs to avoid double counting
          const relevantOverlappingSlots = overlappingSlots[id_slot].filter(
            overlappingId => overlappingId > id_slot,
          );
          if (relevantOverlappingSlots.length > 0) {
            assignments_on_overlapping_slots = relevantOverlappingSlots.flatMap(
              id_slot => assignmentsMap.bySectionSlot[id_slot] || [],
            );
          }
        }
        const single_group_incomp_error = checkSingleGroupIncomp(
          /* assignments_on_single_slot= */ assignments_on_single_slot,
          /* assignments_on_overlapping_slots= */ assignments_on_overlapping_slots,
          /* single_group_incomp_rule= */ single_group_incompatibility,
        );
        if (single_group_incomp_error) {
          const errorTypeObj =
            errors.single_group_incompatibilities[id_incompatibility][
              single_group_incomp_error.type
            ] ||
            (errors.single_group_incompatibilities[id_incompatibility][
              single_group_incomp_error.type
            ] = {});

          errorTypeObj[id_slot] = single_group_incomp_error.context;
        }
      });
    },
  );

  // Cross Group Incompatibilities checks.
  Object.entries(incompatibilities?.cross_group_incomp).forEach(
    ([id_incompatibility_str, cross_group_incompatibility]) => {
      const id_incompatibility = Number(id_incompatibility_str);
      errors.cross_group_incompatibilities[id_incompatibility] = {};
      // Incompatibilities are applied on a per-slot basis (i.e. slots are independent of each other).
      cross_group_incompatibility.section_slots.forEach(id_slot_str => {
        const id_slot = Number(id_slot_str);
        let assignments_on_single_slot: ShiftAssignment[] = [];
        if (id_slot in assignmentsMap.bySectionSlot) {
          assignments_on_single_slot = assignmentsMap.bySectionSlot[id_slot];
        }

        // Assignment of users to overlapping slots.
        let assignments_on_overlapping_slots: ShiftAssignment[] = [];
        if (id_slot in overlappingSlots) {
          // Only get overlapping slots with higher IDs to avoid double counting
          const relevantOverlappingSlots = overlappingSlots[id_slot].filter(
            overlappingId => overlappingId > id_slot,
          );
          if (relevantOverlappingSlots.length > 0) {
            assignments_on_overlapping_slots = relevantOverlappingSlots.flatMap(
              id_slot => assignmentsMap.bySectionSlot[id_slot] || [],
            );
          }
        }
        const cross_group_incomp_error = checkCrossGroupIncomp(
          /* assignments_on_single_slot= */ assignments_on_single_slot,
          /* assignments_on_overlapping_slots= */ assignments_on_overlapping_slots,
          /* cross_group_incomp_rule= */ cross_group_incompatibility,
        );
        if (cross_group_incomp_error) {
          errors.cross_group_incompatibilities[id_incompatibility][id_slot] =
            cross_group_incomp_error.context;
        }
      });
    },
  );

  // User assignment checks (only run for users that have been assigned to any section_slot)
  for (const [id_user_str, single_user_shift_assignments] of Object.entries(
    assignmentsMap.byUser,
  )) {
    const id_user = Number(id_user_str);

    for (const shift_assignment of single_user_shift_assignments) {
      const section_slot: SectionSlot =
        sectionSlotsDict[shift_assignment.id_section_slot];

      let special_events_for_user: SpecialEvent[] = [];
      if (id_user in eventsMap.byUser) {
        special_events_for_user = eventsMap.byUser[id_user];
      }
      // Check if the shift_assignment overlaps with any special events for the user.
      const special_event_error = checkAssignmentOnSpecialEvent(
        shift_assignment,
        section_slot,
        special_events_for_user,
      );
      if (special_event_error) {
        // Create user assignment error object if it doesn't exist.
        populateUserAssignmentErrorsDict(
          errors.user_assignments,
          id_user,
          section_slot.id_section_slot,
        );

        errors.user_assignments[id_user][section_slot.id_section_slot][
          AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_EVENT
        ] = special_event_error.context;
      }
      // Check if the shift_assignment overlaps with any blocking user preferences for the user.
      const preference_error = checkAssignmentOnBlockingUserPreference(
        shift_assignment,
        section_slot,
        preferencesMap.bySectionSlotbyUser[section_slot.id_section_slot]?.[
          id_user
        ],
      );
      if (preference_error) {
        // Create user assignment error object if it doesn't exist.
        populateUserAssignmentErrorsDict(
          errors.user_assignments,
          id_user,
          section_slot.id_section_slot,
        );
        errors.user_assignments[id_user][section_slot.id_section_slot][
          preference_error.type
        ] = preference_error.context;
      }
      // Check if the shift assignment has its rest period honored.
      const rest_period_error = checkAssignmentRestPeriod(
        shift_assignment,
        single_user_shift_assignments,
        sectionSlotsDict,
      );
      if (rest_period_error) {
        // Create user assignment error object if it doesn't exist.
        // Create user assignment error object if it doesn't exist.
        populateUserAssignmentErrorsDict(
          errors.user_assignments,
          id_user,
          section_slot.id_section_slot,
        );
        errors.user_assignments[id_user][section_slot.id_section_slot][
          AssignmentCheckErrorType.SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION
        ] = rest_period_error.context;
      }

      const overlapping_assignment_error =
        checkAssignmentOnOverlappingAssignment(
          shift_assignment,
          single_user_shift_assignments,
          sectionSlotsDict,
        );
      if (overlapping_assignment_error) {
        // Create user assignment error object if it doesn't exist.
        populateUserAssignmentErrorsDict(
          errors.user_assignments,
          id_user,
          section_slot.id_section_slot,
        );
        errors.user_assignments[id_user][section_slot.id_section_slot][
          AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_OVERLAPPING_ASSIGNMENT
        ] = overlapping_assignment_error.context;
      }
    }
  }
  return errors;
}
