/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable max-len */
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import {
  ArrowPathIcon,
  ArrowUpTrayIcon,
  CalendarDaysIcon,
  ExclamationCircleIcon,
  MapPinIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAcceptResponseMutation,
  useCancelRequestMutation,
  useCancelResponseMutation,
  useRejectResponseMutation,
  useRevertResponseMutation,
} from '@youshift/shared/hooks/mutations';
import {
  ExchangeRequest,
  ExchangeResponse,
  ExchangeResponseStatus,
  ExchangeResponseType,
  ShiftAssignment,
} from '@youshift/shared/types';
import {
  classNames,
  dateToString,
  generateErrorStringFromError,
  localeNormalizer,
  parseSectionSlotDateTime,
} from '@youshift/shared/utils';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

import AcceptIcon from '../../../assets/AcceptIcon';
import ExchangeIcon from '../../../assets/ExchangeIcon';
import ConfirmReject from './ConfirmReject';
import { useUserContext } from '../../../layouts/UserLayout';
import { useUserShiftExchangeContext } from './ShiftExchangeLayout';
import i18n from '../../../utils/i18n';
import {
  checkUserCanGiveUpShift,
  checkUserCanTakeNewShift,
  requestorCheckShiftExchange,
  respondentCheckShiftExchange,
} from '../../../utils/shift_exchange/shift_exchange';
import ConfirmSendResponseAnswer from './ConfirmSendResponseAnswer';
import { ShiftExchangeViolation } from '../../../utils/shift_exchange/types';
import Modal from '../../../components/Modal';
import ShiftViolationsList from '../../../components/ShiftExchange/ShiftViolationsList';

function responseStatusToText(status: ExchangeResponseStatus, t: TFunction) {
  switch (status) {
    case ExchangeResponseStatus.PENDING_MANAGER:
      return (
        <p className="text-xs text-orange-400">
          {t('generic.shiftExchange.pendingManager')}
        </p>
      );
    case ExchangeResponseStatus.PENDING_DOCTOR:
      return (
        <p className="text-xs text-orange-400">
          {t('generic.shiftExchange.pendingDoctor')}
        </p>
      );
    case ExchangeResponseStatus.REJECTED_MANAGER:
      return (
        <p className="text-xs text-red-400">
          {t('generic.shiftExchange.rejectedManager')}
        </p>
      );
    case ExchangeResponseStatus.REJECTED_DOCTOR:
      return (
        <p className="text-xs text-red-400">
          {t('generic.shiftExchange.rejectedDoctor')}
        </p>
      );
    case ExchangeResponseStatus.CANCELED_SYSTEM:
      return (
        <p className="text-xs text-red-400">
          {t('generic.shiftExchange.canceledSystem')}
        </p>
      );
    default:
      return null;
  }
}

function UserShiftExchangeMyRequests() {
  const userLayoutContext = useUserContext();
  const { userLayout, user } = userLayoutContext;
  const {
    exchangeData,
    teamAssignments,
    allSections,
    allSectionSlots,
    allShiftAssignments,
    allUserReqRules,
    incompatibleSectionSlotsMap,
  } = useUserShiftExchangeContext();

  // const [my_requests, feed, shiftOutputs, allShifts] = useOutletContext();
  // const { completed, requests, responses } = my_requests;

  const queryClient = useQueryClient();

  const [confirmRejectOpen, setConfirmRejectOpen] = useState(false);
  // Modal to confirm sending a response to a given answer
  const [confirmSendResponseAnswerOpen, setConfirmSendResponseAnswerOpen] =
    useState(false);
  const [idItr, setIdItr] = useState<number | null>(null);
  const [request, setRequest] = useState<ExchangeRequest | null>(null);
  const [response, setResponse] = useState<ExchangeResponse | null>(null);
  const [violations, setViolations] = useState<ShiftExchangeViolation[]>([]);
  const [isViolationsModalOpen, setIsViolationsModalOpen] = useState(false);
  const { t } = useTranslation();

  const locale = localeNormalizer(i18n.language);

  const statusOrder = {
    [ExchangeResponseStatus.PENDING_DOCTOR]: 1,
    [ExchangeResponseStatus.PENDING_MANAGER]: 2,
    [ExchangeResponseStatus.REJECTED_DOCTOR]: 3,
    [ExchangeResponseStatus.REJECTED_MANAGER]: 4,
    [ExchangeResponseStatus.CANCELED_SYSTEM]: 5,
    [ExchangeResponseStatus.CANCELED]: 6,
    [ExchangeResponseStatus.APPROVED]: 7,
  };

  const cancelRequestMutation = useCancelRequestMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userExchangeDashboard'],
      });
      toast.success(t('user.shiftExchange.successCancelRequest'));
    },
  });

  const rejectResponseMutation = useRejectResponseMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userExchangeDashboard'],
      });
      toast.error(t('user.shiftExchange.successRejectResponse'));

      setConfirmRejectOpen(false);
    },
  });

  const acceptResponseMutation = useAcceptResponseMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userExchangeDashboard'] });
      toast.success(t('user.shiftExchange.successAcceptResponse'));
      setConfirmSendResponseAnswerOpen(false);
    },
  });

  const cancelResponseMutation = useCancelResponseMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userExchangeDashboard'] });
      toast.success(t('user.shiftExchange.successCancelResponse'));
    },
  });

  const revertResponseMutation = useRevertResponseMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userExchangeDashboard'],
      });
      toast.success(t('user.shiftExchange.successRevertAcceptance'));
    },
  });

  return (
    <div className="my-6 mx-2 min-w-[45%] flex flex-col gap-4">
      {confirmRejectOpen ? (
        <ConfirmReject
          open={confirmRejectOpen}
          setOpen={setConfirmRejectOpen}
          id_request={request?.id_request}
          id_response={response?.id_respondent}
          rejectResponseMutation={rejectResponseMutation}
        />
      ) : null}

      {confirmSendResponseAnswerOpen ? (
        <ConfirmSendResponseAnswer
          open={confirmSendResponseAnswerOpen}
          setOpen={setConfirmSendResponseAnswerOpen}
          idItr={idItr!}
          request={request!}
          response={response!}
          acceptResponseMutation={acceptResponseMutation}
          violations={violations}
          allUserReqRules={allUserReqRules}
          allSections={allSections}
          allSectionSlots={allSectionSlots}
        />
      ) : null}

      {isViolationsModalOpen ? (
        <Modal
          isOpen={isViolationsModalOpen}
          onClose={() => setIsViolationsModalOpen(false)}
        >
          <div className="mt-2 border border-yellow-600 bg-yellow-50 rounded-md p-2">
            <ShiftViolationsList
              violations={violations}
              allSections={allSections}
              allSectionSlots={allSectionSlots}
              allUserReqRules={allUserReqRules}
            />
          </div>
        </Modal>
      ) : null}
      {/* MY REQUESTS */}
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between rounded-lg border border-teal-600 hover:bg-gray-50 px-4 py-1 text-left text-sm font-medium text-teal-600 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-75">
              <span>{t('user.shiftExchange.myPendingRequests')}</span>
              <ChevronUpIcon
                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-teal-600`}
              />
            </Disclosure.Button>
            <Disclosure.Panel
              className="sm:px-4 px-1 pb-2 sm:text-sm text-xs text-gray-500"
              id="myExchanges-requests"
            >
              <div className="flex flex-row justify-between sm:text-xl text-md text-black mb">
                <h3>{t('user.shiftExchange.shiftsIOffer')}</h3>
                <h3>{t('user.shiftExchange.responses')}</h3>
              </div>
              {Object.values(exchangeData).every(
                itr => Object.keys(itr.my_requests.ongoing).length === 0,
              ) ? (
                <p className="mt-6 text-center text-gray-600">
                  {t('user.shiftExchange.emptyRequests')}
                </p>
              ) : null}
              {Object.entries(exchangeData).map(([id_itr, itr]) =>
                Object.entries(itr.my_requests.ongoing).map(
                  ([id_request, { my_request, candidate_responses }]) => {
                    const request_shift_assignment =
                      itr.my_shift_assignments[my_request.id_shift_assignment];
                    const request_section_slot =
                      userLayout.itrs[Number(id_itr)].section_slots[
                        request_shift_assignment.id_section_slot
                      ];
                    const request_section =
                      userLayout.itrs[Number(id_itr)].sections[
                        request_section_slot.id_section
                      ];
                    return (
                      <div className="flex flex-row mt-5 items-center pb-4">
                        <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 w-[45%] sm:mr-4">
                          <div className="flex flex-row items-start gap-1">
                            <CalendarDaysIcon className="h-4 mt-0.5" />
                            <p>
                              {parseSectionSlotDateTime(
                                request_section_slot?.start,
                                request_section_slot?.end,
                                locale,
                                false,
                              )}
                            </p>
                          </div>
                          <div className="flex flex-row items-start gap-1">
                            <MapPinIcon className="h-4" />
                            <p> {request_section?.name}</p>
                          </div>
                          {my_request.reason && (
                            <p className="mt-2">
                              <span className="font-semibold">
                                {t('generic.justification')}
                              </span>{' '}
                              {my_request.reason}
                            </p>
                          )}
                          <div className="flex flex-row gap-1 mt-2 justify-between items-center">
                            <div className="flex flex-row gap-1">
                              {my_request.allow_one_for_zero ? (
                                <div className="border border-yellow-600 bg-yellow-200 text-gray-600 rounded-md p-1 h-min">
                                  <ArrowPathIcon className="h-3" />
                                </div>
                              ) : null}
                              {my_request.allow_one_for_one ? (
                                <div className="border border-purple-600 bg-purple-200 text-gray-600 rounded-md p-1 h-min">
                                  <ArrowUpTrayIcon className="rotate-90 h-3" />
                                </div>
                              ) : null}
                            </div>
                            <button
                              type="button"
                              className="inline-flex w-full my-auto justify-center items-center rounded-md bg-white px-3 py-1 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-400 hover:bg-blue-400/50 sm:w-auto"
                              onClick={() =>
                                cancelRequestMutation.mutate({ id_request })
                              }
                            >
                              {t('generic.cancel')}
                            </button>
                          </div>
                        </div>
                        <div className="relative flex flex-col gap-3">
                          {/* map over responses for each request */}
                          {Object.values(candidate_responses)
                            .sort(
                              (a: ExchangeResponse, b: ExchangeResponse) =>
                                statusOrder[a.status] - statusOrder[b.status],
                            )
                            .map((response: ExchangeResponse) => {
                              const respondent_user =
                                itr.other_users[response.id_respondent];

                              const exchangeViolations =
                                requestorCheckShiftExchange(
                                  user,
                                  my_request,
                                  response,
                                  userLayoutContext,
                                  allSectionSlots,
                                  teamAssignments,
                                  allShiftAssignments,
                                  incompatibleSectionSlotsMap,
                                );

                              return (
                                <div className="flex flex-row items-center">
                                  <div className="flex flex-col gap-1">
                                    {exchangeViolations.length > 0 ? (
                                      <ExclamationCircleIcon
                                        className="h-6 w-6 bg-white text-yellow-600 absolute rounded-full -top-3 -right-2 fill-white"
                                        onClick={() => {
                                          setIsViolationsModalOpen(true);
                                          setViolations(exchangeViolations);
                                        }}
                                      />
                                    ) : null}
                                    {response.type ===
                                    ExchangeResponseType.ONE_FOR_ONE ? (
                                      <ExchangeIcon status={response.status} />
                                    ) : (
                                      <AcceptIcon
                                        status={response.status}
                                        arrowDirection="right"
                                      />
                                    )}
                                    {responseStatusToText(response.status, t)}
                                  </div>
                                  <div className="border relative border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:ml-4 ml-2 my-auto">
                                    {response.type ===
                                    ExchangeResponseType.ONE_FOR_ONE ? (
                                      <div className="absolute -top-2 -left-2 border border-yellow-600 bg-yellow-200 text-gray-600 rounded-md p-1">
                                        <ArrowPathIcon className="h-3" />
                                      </div>
                                    ) : (
                                      <div className="absolute -top-2 -left-2 border border-purple-600 bg-purple-200 text-gray-600 rounded-md p-1">
                                        <ArrowUpTrayIcon className="rotate-90 h-3" />
                                      </div>
                                    )}
                                    <div className="flex flex-row gap-2 items-center my-2">
                                      <div className="flex flex-col justify-between items-start">
                                        {response.type ===
                                        ExchangeResponseType.ONE_FOR_ONE ? (
                                          <>
                                            {(() => {
                                              const response_shift_assignment =
                                                exchangeData[Number(id_itr)]
                                                  .other_users_shift_assignments[
                                                  response.id_shift_assignment!
                                                ];
                                              const response_section_slot =
                                                userLayout.itrs[Number(id_itr)]
                                                  .section_slots[
                                                  response_shift_assignment
                                                    ?.id_section_slot
                                                ];
                                              const response_section =
                                                userLayout.itrs[Number(id_itr)]
                                                  .sections[
                                                  response_section_slot
                                                    ?.id_section
                                                ];

                                              return (
                                                <div className="flex flex-col justify-between">
                                                  <div className="flex flex-row items-center gap-1">
                                                    <CalendarDaysIcon className="h-4 mt-0.5" />
                                                    <p>
                                                      {parseSectionSlotDateTime(
                                                        response_section_slot?.start,
                                                        response_section_slot?.end,
                                                        locale,
                                                        false,
                                                      )}
                                                    </p>
                                                  </div>
                                                  <div className="flex flex-row items-center gap-1">
                                                    <MapPinIcon className="h-4" />
                                                    <p>
                                                      {response_section?.name}
                                                    </p>
                                                  </div>
                                                </div>
                                              );
                                            })()}
                                          </>
                                        ) : null}
                                        <div className="flex flex-row items-center">
                                          <UserIcon className="h-4" />
                                          <p>
                                            {`${respondent_user?.firstname} ${respondent_user?.lastname}`}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    {response.status ===
                                    ExchangeResponseStatus.PENDING_DOCTOR ? (
                                      <div className="grid grid-cols-2 gap-1">
                                        <button
                                          type="button"
                                          className="border border-green-600 bg-green-200 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-1 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm hover:bg-green-100 sm:w-auto"
                                          onClick={() => {
                                            setRequest(my_request);
                                            setResponse(response);
                                            setIdItr(Number(id_itr));
                                            setViolations(exchangeViolations);
                                            setConfirmSendResponseAnswerOpen(
                                              true,
                                            );
                                          }}
                                        >
                                          {response.type ===
                                          ExchangeResponseType.ONE_FOR_ONE
                                            ? t('user.shiftExchange.trade')
                                            : t('user.shiftExchange.give')}
                                        </button>
                                        <button
                                          type="button"
                                          className="border border-red-600 bg-red-200 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-1 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm hover:bg-red-100 sm:w-auto"
                                          onClick={() => {
                                            setRequest(my_request);
                                            setResponse(response);
                                            setConfirmRejectOpen(true);
                                          }}
                                        >
                                          {t('generic.reject')}
                                        </button>
                                      </div>
                                    ) : response.status ===
                                      ExchangeResponseStatus.PENDING_MANAGER ? (
                                      <button
                                        type="button"
                                        className="inline-flex flex-grow-0 my-auto justify-center items-center rounded-md bg-white px-3 py-1 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-400 hover:bg-blue-400/50 sm:w-auto"
                                        onClick={() =>
                                          revertResponseMutation.mutate({
                                            id_request,
                                            id_response: response.id_response,
                                          })
                                        }
                                      >
                                        {t('user.shiftExchange.revertAccept')}
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  },
                ),
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {/* MY RESPONSES */}
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between rounded-lg border border-teal-600 hover:bg-gray-50 px-4 py-1 text-left text-sm font-medium text-teal-600 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-75">
              <span>{t('user.shiftExchange.myPendingResponses')}</span>
              <ChevronUpIcon
                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-teal-600`}
              />
            </Disclosure.Button>
            <Disclosure.Panel
              className="sm:px-4 px-1 pt-2 pb-2 sm:text-sm text-xs text-gray-500"
              id="myExchanges-responses"
            >
              <div className="flex flex-row justify-between sm:text-xl text-xs text-black">
                <h3>{t('user.shiftExchange.shiftsIOffer')}</h3>
                <h3>{t('user.shiftExchange.shiftsIRequest')}</h3>
              </div>
              {Object.values(exchangeData).every(
                itr => Object.keys(itr.my_responses.ongoing).length === 0,
              ) ? (
                <p className="mt-6 text-center text-gray-600">
                  {t('user.shiftExchange.emptyResponses')}
                </p>
              ) : null}
              {Object.entries(exchangeData).map(([id_itr, itr]) =>
                Object.entries(itr.my_responses.ongoing).map(
                  ([id_response, { request, my_response }]) => {
                    const request_shift_assignment =
                      itr.other_users_shift_assignments[
                        request.id_shift_assignment
                      ];
                    const request_section_slot =
                      userLayout.itrs[Number(id_itr)].section_slots[
                        request_shift_assignment?.id_section_slot
                      ];
                    const request_section =
                      userLayout.itrs[Number(id_itr)].sections[
                        request_section_slot?.id_section
                      ];

                    const requestor_user =
                      itr.other_users[request.id_requestor];

                    const exchangeViolations = respondentCheckShiftExchange(
                      user,
                      request,
                      my_response,
                      userLayoutContext,
                      allSectionSlots,
                      teamAssignments,
                      request_shift_assignment,
                      incompatibleSectionSlotsMap,
                    );

                    return (
                      <div className="flex flex-row items-center mt-5">
                        <div
                          className={classNames(
                            my_response.type ===
                              ExchangeResponseType.ONE_FOR_ONE
                              ? 'border-slate-200'
                              : 'border-slate-400 border-dashed',
                            'relative border shadow-md rounded-lg py-3 sm:px-4 px-1 w-[45%]  flex items-center',
                          )}
                        >
                          {exchangeViolations.length > 0 ? (
                            <ExclamationCircleIcon
                              className="h-6 w-6 bg-white text-yellow-600 absolute rounded-full -top-3 -right-2 fill-white"
                              onClick={() => {
                                setIsViolationsModalOpen(true);
                                setViolations(exchangeViolations);
                              }}
                            />
                          ) : null}
                          <div className="flex flex-col justify-between items-start">
                            {my_response.type ===
                            ExchangeResponseType.ONE_FOR_ONE
                              ? (() => {
                                  const response_section_slot =
                                    userLayout.itrs[Number(id_itr)]
                                      .section_slots[
                                      itr.my_shift_assignments[
                                        my_response.id_shift_assignment!
                                      ]?.id_section_slot
                                    ];
                                  const response_section =
                                    userLayout.itrs[Number(id_itr)].sections[
                                      response_section_slot?.id_section
                                    ];

                                  return (
                                    <>
                                      <div className="flex flex-row items-start gap-1">
                                        <CalendarDaysIcon className="h-4 mt-0.5" />
                                        <p>
                                          {parseSectionSlotDateTime(
                                            response_section_slot?.start,
                                            response_section_slot?.end,
                                            locale,
                                            false,
                                          )}
                                        </p>
                                      </div>
                                      <div className="flex flex-row items-start gap-1">
                                        <MapPinIcon className="h-4" />
                                        <p>{response_section?.name}</p>
                                      </div>
                                    </>
                                  );
                                })()
                              : null}

                            {my_response.status ===
                              ExchangeResponseStatus.PENDING_MANAGER ||
                            my_response.status ===
                              ExchangeResponseStatus.PENDING_DOCTOR ? (
                              <button
                                type="button"
                                className="inline-flex w-full my-auto mt-2 justify-center items-center rounded-md bg-white px-3 py-1 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-400 hover:bg-blue-400/50 sm:w-auto"
                                onClick={() =>
                                  cancelResponseMutation.mutate({
                                    id_request: request.id_request,
                                    id_response: my_response.id_response,
                                  })
                                }
                              >
                                {t('generic.cancel')}
                              </button>
                            ) : null}
                          </div>
                        </div>
                        {my_response.type ===
                        ExchangeResponseType.ONE_FOR_ONE ? (
                          <>
                            <div className="hidden sm:block">
                              <ExchangeIcon status={my_response.status} />
                            </div>
                            <div className="block sm:hidden">
                              <ExchangeIcon status={my_response.status} />
                            </div>
                          </>
                        ) : (
                          <AcceptIcon
                            status={my_response.status}
                            arrowDirection="left"
                          />
                        )}
                        <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 w-[45%] sm:mr-4 flex items-center">
                          <div className="flex flex-col justify-between items-start">
                            <div className="flex flex-row gap-2 items-center my-2">
                              <div className="flex flex-col justify-between items-start">
                                <div className="flex flex-row items-center gap-1">
                                  <CalendarDaysIcon className="h-4 mt-0.5" />
                                  <p>
                                    {parseSectionSlotDateTime(
                                      request_section_slot?.start,
                                      request_section_slot?.end,
                                      locale,
                                      false,
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <MapPinIcon className="h-4" />
                                  <p>{request_section?.name}</p>
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <UserIcon className="h-4" />
                                  <p>
                                    {`${requestor_user?.firstname} ${requestor_user?.lastname}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {responseStatusToText(my_response.status, t)}
                          </div>
                        </div>
                      </div>
                    );
                  },
                ),
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {/* MY COMPLETED REQUESTS AND RESPONSES */}
      <Disclosure>
        {({ open }) => (
          <div>
            <Disclosure.Button className="flex w-full justify-between rounded-lg border border-teal-600 hover:bg-gray-50 px-4 py-1 text-left text-sm font-medium text-teal-600 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-75">
              <span>{t('generic.shiftExchange.completed')}</span>
              <ChevronUpIcon
                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-teal-600`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="sm:px-4 px-1 pt-2 pb-2 text-xs sm:text-sm text-gray-500">
              <div className="flex flex-row justify-between sm:text-xl text-md text-black">
                <h3>{t('user.shiftExchange.myShifts')}</h3>
                <h3>{t('user.shiftExchange.shiftsIGave')}</h3>
              </div>
              {Object.values(exchangeData).every(
                itr => Object.values(itr.my_requests.completed).length === 0,
              ) &&
              Object.values(exchangeData).every(
                itr => Object.values(itr.my_responses.completed).length === 0,
              ) ? (
                <p className="mt-6 text-center text-gray-600">
                  {t('user.shiftExchange.emptyCompleted')}
                </p>
              ) : null}
              {/* User's completed requests */}
              {Object.entries(exchangeData).map(([id_itr, itr]) =>
                Object.entries(itr.my_requests.completed).map(
                  ([id_request, { my_request, approved_response }]) => {
                    const request_shift_assignment =
                      itr.my_shift_assignments[my_request.id_shift_assignment];
                    const request_section_slot =
                      userLayout.itrs[Number(id_itr)].section_slots[
                        request_shift_assignment?.id_section_slot
                      ];
                    const request_section =
                      userLayout.itrs[Number(id_itr)].sections[
                        request_section_slot?.id_section
                      ];
                    const respondent_user =
                      itr.other_users[approved_response.id_respondent];

                    return approved_response.type ===
                      ExchangeResponseType.ONE_FOR_ONE ? (
                      (() => {
                        const response_shift_assignment =
                          itr.other_users_shift_assignments[
                            approved_response.id_shift_assignment!
                          ];
                        const response_section_slot =
                          userLayout.itrs[Number(id_itr)].section_slots[
                            response_shift_assignment?.id_section_slot
                          ];
                        const response_section =
                          userLayout.itrs[Number(id_itr)].sections[
                            response_section_slot?.id_section
                          ];

                        return (
                          <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                            <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                              <div className="flex flex-col justify-between items-start">
                                <div className="flex flex-row items-start gap-1">
                                  <CalendarDaysIcon className="h-4 mt-0.5" />
                                  <p>
                                    {parseSectionSlotDateTime(
                                      response_section_slot?.start,
                                      response_section_slot?.end,
                                      locale,
                                      false,
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-row items-start gap-1">
                                  <MapPinIcon className="h-4" />
                                  <p>{request_section?.name}</p>
                                </div>
                              </div>
                            </div>
                            <ExchangeIcon status={approved_response.status} />
                            <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                              <div className="flex flex-col justify-between items-start">
                                <div className="flex flex-row items-start gap-1">
                                  <CalendarDaysIcon className="h-4 mt-0.5" />
                                  <p>
                                    {parseSectionSlotDateTime(
                                      request_section_slot?.start,
                                      request_section_slot?.end,
                                      locale,
                                      false,
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-row items-start gap-1">
                                  <MapPinIcon className="h-4" />
                                  <p>{response_section?.name}</p>
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <UserIcon className="h-4" />
                                  <p>
                                    {`${respondent_user?.firstname} ${respondent_user?.lastname}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {responseStatusToText(approved_response.status, t)}
                          </div>
                        );
                      })()
                    ) : (
                      <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                        <div className="border border-slate-400 border-dashed shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4 h-full flex items-center" />
                        <AcceptIcon
                          status={approved_response.status}
                          arrowDirection="right"
                        />
                        <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                          <div className="flex flex-col justify-between items-start">
                            <div className="flex flex-row items-start gap-1">
                              <CalendarDaysIcon className="h-4 mt-0.5" />
                              <p>
                                {parseSectionSlotDateTime(
                                  request_section_slot?.start,
                                  request_section_slot?.end,
                                  locale,
                                  false,
                                )}
                              </p>
                            </div>
                            <div className="flex flex-row items-start gap-1">
                              <MapPinIcon className="h-4" />
                              <p>{request_section?.name}</p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                              <UserIcon className="h-4" />
                              <p>
                                {`${respondent_user?.firstname} ${respondent_user?.lastname}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  },
                ),
              )}
              {/* User's completed responses */}
              {Object.entries(exchangeData).map(([id_itr, itr]) =>
                Object.entries(itr.my_responses.completed).map(
                  ([id_response, { request, my_response }]) => {
                    const request_shift_assignment =
                      itr.other_users_shift_assignments[
                        request.id_shift_assignment
                      ];
                    const request_section_slot =
                      userLayout.itrs[Number(id_itr)].section_slots[
                        request_shift_assignment.id_section_slot
                      ];
                    const request_section =
                      userLayout.itrs[Number(id_itr)].sections[
                        request_section_slot?.id_section
                      ];
                    const respondent_user =
                      itr.other_users[request.id_requestor];

                    return my_response.type ===
                      ExchangeResponseType.ONE_FOR_ONE ? (
                      (() => {
                        const response_shift_assignment =
                          itr.my_shift_assignments[
                            my_response.id_shift_assignment!
                          ];
                        const response_section_slot =
                          userLayout.itrs[Number(id_itr)].section_slots[
                            response_shift_assignment.id_section_slot
                          ];
                        const response_section =
                          userLayout.itrs[Number(id_itr)].sections[
                            response_section_slot?.id_section
                          ];

                        return (
                          <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                            <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                              <div className="flex flex-col justify-between items-start">
                                <div className="flex flex-row items-start gap-1">
                                  <CalendarDaysIcon className="h-4 mt-0.5" />
                                  <p>
                                    {parseSectionSlotDateTime(
                                      request_section_slot?.start,
                                      request_section_slot?.end,
                                      locale,
                                      false,
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-row items-start gap-1">
                                  <MapPinIcon className="h-4" />
                                  <p>{request_section?.name}</p>
                                </div>
                              </div>
                            </div>
                            <ExchangeIcon status={my_response.status} />
                            <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                              <div className="flex flex-col justify-between items-start">
                                <div className="flex flex-row items-start gap-1">
                                  <CalendarDaysIcon className="h-4 mt-0.5" />
                                  <p>
                                    {parseSectionSlotDateTime(
                                      response_section_slot?.start,
                                      response_section_slot?.end,
                                      locale,
                                      false,
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-row items-start gap-1">
                                  <MapPinIcon className="h-4" />
                                  <p>{response_section?.name}</p>
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <UserIcon className="h-4" />
                                  <p>
                                    {`${respondent_user?.firstname} ${respondent_user?.lastname}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {responseStatusToText(my_response.status, t)}
                          </div>
                        );
                      })()
                    ) : (
                      // approved, or rejected/canceled one-for-zero
                      <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                        <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                          <div className="flex flex-col justify-between items-start">
                            <div className="flex flex-row items-start gap-1">
                              <CalendarDaysIcon className="h-4 mt-0.5" />
                              <p>
                                {parseSectionSlotDateTime(
                                  request_section_slot?.start,
                                  request_section_slot?.end,
                                  locale,
                                  false,
                                )}
                              </p>
                            </div>
                            <div className="flex flex-row items-start gap-1">
                              <MapPinIcon className="h-4" />
                              <p>{request_section?.name}</p>
                            </div>
                          </div>
                        </div>
                        <AcceptIcon
                          status={my_response.status}
                          arrowDirection="left"
                        />
                        <div className="border border-slate-400 border-dashed shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4 h-full flex items-center">
                          <div className="flex flex-row items-center gap-1">
                            <UserIcon className="h-4" />
                            <p>
                              {`${respondent_user?.firstname} ${respondent_user?.lastname}`}
                            </p>
                          </div>
                        </div>
                        {responseStatusToText(my_response.status, t)}
                      </div>
                    );
                  },
                ),
              )}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
}

export default UserShiftExchangeMyRequests;
