import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
  useStripe,
} from '@stripe/react-stripe-js';
import { request } from '@youshift/shared/api';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';

import { requireManager } from '../../../utils/checks';

interface CheckoutData {
  client_secret: string;
}

export const checkoutLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<CheckoutData> => {
    await requireManager(queryClient);

    const response = await request<CheckoutData>({
      url: `manager/create-checkout-session`,
      method: 'post',
      data: { quantity: params.quantity || 20 },
    });

    return { client_secret: response.client_secret };
  };

export default function Checkout() {
  const { client_secret } = useLoaderData() as CheckoutData;
  const stripe = useStripe();

  return (
    <div className="w-full h-full min-h-[500px]">
      <EmbeddedCheckoutProvider
        stripe={stripe}
        options={{ clientSecret: client_secret }}
      >
        <EmbeddedCheckout className="h-full" />
      </EmbeddedCheckoutProvider>
    </div>
  );
}
