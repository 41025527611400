import { Disclosure } from '@headlessui/react';
import {
  CalendarIcon,
  ClockIcon,
  FunnelIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import {
  getFirstDayOfWeek,
  getWeekdayInitials,
  localeNormalizer,
  returnColor,
  dateToString,
  ColorName,
} from '@youshift/shared/utils';
import {
  SlotLabel,
  VirtualSlot,
  SectionSlot,
  Section,
} from '@youshift/shared/types';
import { useMemo, useState } from 'react';

import { YSButton } from '../Buttons';
import LabelIconComponent from '../LabelIconComponent';
import i18n from '../../utils/i18n';

interface SectionSlotsProps {
  sectionSlots: Record<number, SectionSlot>;
  filteredShiftLabels: SlotLabel[];
  setSelectedSectionSlots: (slots: Set<number>) => void;
  selectedSectionSlots: Set<number>;
}

interface VirtualSlotsProps {
  virtualSlots: Record<number, VirtualSlot>;
  setSelectedVirtualSlots: (slots: Set<number>) => void;
  selectedVirtualSlots: Set<number>;
}

type SmartSelectorProps = {
  sectionSlotsConfig?: SectionSlotsProps;
  virtualSlotsConfig?: VirtualSlotsProps;
  sections?: Record<number, Section>;
};

export default function SmartSelector({
  sectionSlotsConfig,
  virtualSlotsConfig,
  sections,
}: SmartSelectorProps) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<'sectionSlots' | 'virtualSlots'>(
    sectionSlotsConfig ? 'sectionSlots' : 'virtualSlots',
  );

  const locale = localeNormalizer(i18n.language);

  const daysOfWeek = getWeekdayInitials(t, locale);

  // const [smartSelectorSelectedLabels, setSmartSelectorSelectedLabels] =
  //   useState<Set<number>>(new Set());
  const [smartSelectorSelectedTimeFrames, setSmartSelectorSelectedTimeFrames] =
    useState<Set<string>>(new Set());
  const [smartSelectorSelectedDaysOfWeek, setSmartSelectorSelectedDaysOfWeek] =
    useState<Set<number>>(new Set(Object.keys(daysOfWeek).map(Number)));

  const virtualSlotsUniqueTimeFrames = useMemo(() => {
    const timeFrames: Set<string> = new Set();
    Object.values(virtualSlotsConfig?.virtualSlots || {}).forEach(virtualSlot =>
      timeFrames.add(
        `${dateToString(virtualSlot.start, 'hh:mm', locale)} - ${dateToString(virtualSlot.end, 'hh:mm', locale)}`,
      ),
    );
    return Array.from(timeFrames).sort((a, b) => {
      const [aStart, aEnd] = a.split(' - ');
      const [bStart, bEnd] = b.split(' - ');
      // First sort by start time
      const startComparison = aStart.localeCompare(bStart);
      // If start times are the same, sort by end time
      return startComparison !== 0 ? startComparison : aEnd.localeCompare(bEnd);
    });
  }, [virtualSlotsConfig, locale]);

  const handleSmartVirtualSlotsSelector = () => {
    if (virtualSlotsConfig) {
      const previousData = virtualSlotsConfig.selectedVirtualSlots;
      const virtualSlotsToAdd = new Set(
        Object.keys(virtualSlotsConfig.virtualSlots)
          .filter(virtualSlotId => {
            const virtualSlot =
              virtualSlotsConfig.virtualSlots[Number(virtualSlotId)];
            const virtualSlotStart = new Date(virtualSlot.start);
            const virtualSlotDayOfWeek = virtualSlotStart.getUTCDay();
            const isVirtualSlotInSelectedDaysOfWeek =
              smartSelectorSelectedDaysOfWeek.has(virtualSlotDayOfWeek);
            const isVirtualSlotInSelectedTimeFrames =
              smartSelectorSelectedTimeFrames.has(
                `${dateToString(virtualSlot.start, 'hh:mm', locale)} - ${dateToString(virtualSlot.end, 'hh:mm', locale)}`,
              );
            return (
              isVirtualSlotInSelectedDaysOfWeek &&
              isVirtualSlotInSelectedTimeFrames
            );
          })
          .map(Number),
      );
      virtualSlotsConfig.setSelectedVirtualSlots(
        new Set([...previousData, ...virtualSlotsToAdd]),
      );
    }
    setSmartSelectorSelectedTimeFrames(new Set());
  };

  // Add new state for section slots timeframes
  const [sectionSlotsSelectedTimeFrames, setSectionSlotsSelectedTimeFrames] =
    useState<Set<string>>(new Set());
  const [sectionSlotsSelectedDaysOfWeek, setSectionSlotsSelectedDaysOfWeek] =
    useState<Set<number>>(new Set(Object.keys(daysOfWeek).map(Number)));

  // Add state for selected sections
  const [selectedSections, setSelectedSections] = useState<Set<number>>(
    new Set(sections ? Object.keys(sections).map(Number) : []),
  );

  // Add memo for section slots timeframes
  const sectionSlotsUniqueTimeFrames = useMemo(() => {
    const timeFrames: Set<string> = new Set();
    Object.values(sectionSlotsConfig?.sectionSlots || {}).forEach(
      sectionSlot => {
        // Check if the section is selected
        const isSectionSelected = selectedSections.has(sectionSlot.id_section);

        // Check if the day of week is selected
        const slotStart = new Date(sectionSlot.start);
        const slotDayOfWeek = slotStart.getUTCDay();
        const isDayOfWeekSelected =
          sectionSlotsSelectedDaysOfWeek.has(slotDayOfWeek);

        // Only add timeframe if both section and day of week are selected
        if (isSectionSelected && isDayOfWeekSelected) {
          timeFrames.add(
            `${dateToString(sectionSlot.start, 'hh:mm', locale)} - ${dateToString(sectionSlot.end, 'hh:mm', locale)}`,
          );
        }
      },
    );
    return Array.from(timeFrames).sort((a, b) => {
      const [aStart, aEnd] = a.split(' - ');
      const [bStart, bEnd] = b.split(' - ');
      // First sort by start time
      const startComparison = aStart.localeCompare(bStart);
      // If start times are the same, sort by end time
      return startComparison !== 0 ? startComparison : aEnd.localeCompare(bEnd);
    });
  }, [
    sectionSlotsConfig,
    locale,
    selectedSections,
    sectionSlotsSelectedDaysOfWeek,
  ]);

  // Update section slots handler to include section filtering
  const handleSmartSectionSlotsSelector = () => {
    if (sectionSlotsConfig) {
      const previousData = sectionSlotsConfig.selectedSectionSlots;
      const sectionSlotsToAdd = new Set(
        Object.keys(sectionSlotsConfig.sectionSlots)
          .filter(sectionSlotId => {
            const sectionSlot =
              sectionSlotsConfig.sectionSlots[Number(sectionSlotId)];
            const slotStart = new Date(sectionSlot.start);
            const slotDayOfWeek = slotStart.getUTCDay();
            const isSlotInSelectedDaysOfWeek =
              sectionSlotsSelectedDaysOfWeek.has(slotDayOfWeek);
            const isSlotInSelectedTimeFrames =
              sectionSlotsSelectedTimeFrames.has(
                `${dateToString(sectionSlot.start, 'hh:mm', locale)} - ${dateToString(sectionSlot.end, 'hh:mm', locale)}`,
              );
            const isSlotInSelectedSection = selectedSections.has(
              sectionSlot.id_section,
            );

            return (
              isSlotInSelectedDaysOfWeek &&
              isSlotInSelectedTimeFrames &&
              isSlotInSelectedSection
            );
          })
          .map(Number),
      );
      sectionSlotsConfig.setSelectedSectionSlots(
        new Set([...previousData, ...sectionSlotsToAdd]),
      );
    }
    setSectionSlotsSelectedTimeFrames(new Set());
  };

  // Determine available tabs
  const availableTabs = [
    ...(sectionSlotsConfig ? ['sectionSlots'] : []),
    ...(virtualSlotsConfig ? ['virtualSlots'] : []),
  ];

  return (
    <Disclosure
      as="section"
      aria-labelledby="filter-heading"
      className="flex flex-col justify-center border rounded-md p-1 shadow-sm border-gray-200 my-2"
    >
      <div className="relative py-2">
        <div className="mx-auto flex justify-between divide-gray-200 px-4 text-sm">
          <div className="mr-3 pr-3 border-r border-gray-200">
            <Disclosure.Button className="group flex items-center font-medium text-gray-700">
              <FunnelIcon
                aria-hidden="true"
                className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
              />
              {t('manager.sectionsConfig.smartSelector')}
            </Disclosure.Button>
          </div>
          <div className="flex flex-row gap-2">
            {virtualSlotsConfig &&
              (virtualSlotsConfig.selectedVirtualSlots.size === 0 ? (
                <button
                  type="button"
                  className="flex flex-row gap-2 text-gray-500"
                  onClick={() =>
                    virtualSlotsConfig.setSelectedVirtualSlots(
                      new Set(
                        Object.keys(virtualSlotsConfig.virtualSlots).map(
                          Number,
                        ),
                      ),
                    )
                  }
                >
                  <ClockIcon className="w-5 h-5 text-orange-600" />
                  {t('manager.sectionsConfig.selectAllVirtualSlots')}
                </button>
              ) : (
                <button
                  type="button"
                  className="flex flex-row gap-2 text-gray-500"
                  onClick={() =>
                    virtualSlotsConfig.setSelectedVirtualSlots(new Set())
                  }
                >
                  <ClockIcon className="w-5 h-5 text-orange-600" />
                  {t('manager.sectionsConfig.deselectAllVirtualSlots')}
                </button>
              ))}
            {sectionSlotsConfig &&
              (sectionSlotsConfig.selectedSectionSlots.size === 0 ? (
                <button
                  type="button"
                  className="flex flex-row gap-2 text-gray-500"
                  onClick={() =>
                    sectionSlotsConfig.setSelectedSectionSlots(
                      new Set(
                        Object.keys(sectionSlotsConfig.sectionSlots).map(
                          Number,
                        ),
                      ),
                    )
                  }
                >
                  <CalendarIcon className="w-5 h-5 text-purple-600" />
                  {t('manager.sectionsConfig.selectAllSectionSlots')}
                </button>
              ) : (
                <button
                  type="button"
                  className="flex flex-row gap-2 text-gray-500"
                  onClick={() =>
                    sectionSlotsConfig.setSelectedSectionSlots(new Set())
                  }
                >
                  <CalendarIcon className="w-5 h-5 text-purple-600" />
                  {t('manager.sectionsConfig.deselectAllSectionSlots')}
                </button>
              ))}
          </div>
        </div>
      </div>

      <Disclosure.Panel className="border-t border-gray-200 p-2">
        {Object.keys(availableTabs).length === 2 && (
          <div className="flex flex-row gap-2">
            <div className="grid grid-cols-2 mb-4 gap-2">
              {sectionSlotsConfig && (
                <button
                  onClick={() => setActiveTab('sectionSlots')}
                  className={`tab-button ${activeTab === 'sectionSlots' ? 'active' : ''} px-2 py-1 border-b-2 font-semibold text-sm ${
                    activeTab === 'sectionSlots'
                      ? 'border-blue-500'
                      : 'border-gray-200'
                  }`}
                >
                  {t('generic.sectionSlots')}
                </button>
              )}
              {virtualSlotsConfig && (
                <button
                  onClick={() => setActiveTab('virtualSlots')}
                  className={`tab-button ${activeTab === 'virtualSlots' ? 'active' : ''} px-2 py-1 border-b-2 font-semibold text-sm ${
                    activeTab === 'virtualSlots'
                      ? 'border-blue-500'
                      : 'border-gray-200'
                  }`}
                >
                  {t('generic.virtualSlots')}
                </button>
              )}
            </div>
          </div>
        )}

        <div className="flex flex-row gap-1">
          {activeTab === 'sectionSlots' && (
            <div>
              {sections && Object.keys(sections).length > 1 && (
                <>
                  <div className="flex flex-row gap-3 items-center">
                    <label className="block text-gray-700 mb-1">
                      {t('manager.sectionsConfig.selectSections')}
                    </label>
                    <button
                      type="button"
                      className="block text-blue-700 mb-1 text-xs hover:underline"
                      onClick={() =>
                        setSelectedSections(
                          new Set(Object.keys(sections).map(Number)),
                        )
                      }
                    >
                      {t('generic.selectAll')}
                    </button>
                    <button
                      type="button"
                      className="block text-red-700 mb-1 text-xs hover:underline"
                      onClick={() => setSelectedSections(new Set())}
                    >
                      {t('generic.clear')}
                    </button>
                  </div>
                  <div className="flex flex-wrap gap-2 mb-3">
                    {Object.entries(sections).map(([sectionId, section]) => (
                      <span
                        key={sectionId}
                        className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                      >
                        <input
                          type="checkbox"
                          checked={selectedSections.has(Number(sectionId))}
                          onChange={() => {
                            setSelectedSections(prev => {
                              const newSet = new Set(prev);
                              if (prev.has(Number(sectionId))) {
                                newSet.delete(Number(sectionId));
                              } else {
                                newSet.add(Number(sectionId));
                              }
                              return newSet;
                            });
                          }}
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        {section.name}
                      </span>
                    ))}
                  </div>
                </>
              )}

              <div className="flex flex-row gap-3 items-center">
                <label className="block text-gray-700 mb-1">
                  {t('manager.sectionsConfig.selectDaysOfWeek')}
                </label>
                <button
                  type="button"
                  className="block text-blue-700 mb-1 text-xs hover:underline"
                  onClick={() =>
                    setSectionSlotsSelectedDaysOfWeek(
                      new Set(Object.keys(daysOfWeek).map(Number)),
                    )
                  }
                >
                  {t('generic.selectAll')}
                </button>
                <button
                  type="button"
                  className="block text-red-700 mb-1 text-xs hover:underline"
                  onClick={() => setSectionSlotsSelectedDaysOfWeek(new Set())}
                >
                  {t('generic.clear')}
                </button>
              </div>
              <div className="flex space-x-2">
                {daysOfWeek.map((day, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                  >
                    <input
                      type="checkbox"
                      checked={sectionSlotsSelectedDaysOfWeek.has(
                        getFirstDayOfWeek(locale) === 1
                          ? (index + 1) % 7
                          : index,
                      )}
                      onChange={() =>
                        setSectionSlotsSelectedDaysOfWeek(prev => {
                          const newSet = new Set(prev);
                          const normalizedIndex =
                            getFirstDayOfWeek(locale) === 1
                              ? (index + 1) % 7
                              : index;
                          if (prev.has(normalizedIndex)) {
                            newSet.delete(normalizedIndex);
                          } else {
                            newSet.add(normalizedIndex);
                          }
                          return newSet;
                        })
                      }
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    {day}
                  </span>
                ))}
              </div>
              <div className="flex flex-row gap-3 items-center">
                <label className="block text-gray-700 mb-1 mt-2">
                  {t('manager.sectionsConfig.selectTimeFrames')}
                </label>
                <button
                  type="button"
                  className="block text-blue-700 mb-1 mt-2 text-xs hover:underline"
                  onClick={() =>
                    setSectionSlotsSelectedTimeFrames(
                      new Set(sectionSlotsUniqueTimeFrames),
                    )
                  }
                >
                  {t('generic.selectAll')}
                </button>
                <button
                  type="button"
                  className="block text-red-700 mb-1 mt-2 text-xs hover:underline"
                  onClick={() => setSectionSlotsSelectedTimeFrames(new Set())}
                >
                  {t('generic.clear')}
                </button>
              </div>
              <div className="flex space-x-2">
                {sectionSlotsUniqueTimeFrames.map(timeFrame => (
                  <span
                    key={timeFrame}
                    className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                  >
                    <input
                      type="checkbox"
                      checked={sectionSlotsSelectedTimeFrames.has(timeFrame)}
                      onChange={() => {
                        setSectionSlotsSelectedTimeFrames(prev => {
                          const newSet = new Set(prev);
                          if (prev.has(timeFrame)) {
                            newSet.delete(timeFrame);
                          } else {
                            newSet.add(timeFrame);
                          }
                          return newSet;
                        });
                      }}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    {timeFrame}
                  </span>
                ))}
              </div>
              <div className="flex flex-row items-center gap-3">
                <YSButton
                  onClick={handleSmartSectionSlotsSelector}
                  classNames="mt-3"
                >
                  {t('manager.sectionsConfig.selectSectionSlots')}
                </YSButton>
                <button
                  type="button"
                  className="flex flex-row gap-2 text-gray-500 mt-3"
                  onClick={() =>
                    sectionSlotsConfig?.setSelectedSectionSlots(new Set())
                  }
                >
                  {t('manager.sectionsConfig.deselectAllSectionSlots')}
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-row gap-1">
          {activeTab === 'virtualSlots' && (
            <div>
              <div className="flex flex-row gap-3 items-center">
                <label className="block text-gray-700 mb-1">
                  {t('manager.sectionsConfig.selectDaysOfWeek')}
                </label>
                <button
                  type="button"
                  className="block text-blue-700 mb-1 text-xs hover:underline"
                  onClick={() =>
                    setSmartSelectorSelectedDaysOfWeek(
                      new Set(Object.keys(daysOfWeek).map(Number)),
                    )
                  }
                >
                  {t('generic.selectAll')}
                </button>
                <button
                  type="button"
                  className="block text-red-700 mb-1 text-xs hover:underline"
                  onClick={() => setSmartSelectorSelectedDaysOfWeek(new Set())}
                >
                  {t('generic.clear')}
                </button>
              </div>
              <div className="flex space-x-2">
                {daysOfWeek.map((day, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                  >
                    <input
                      type="checkbox"
                      checked={smartSelectorSelectedDaysOfWeek.has(
                        getFirstDayOfWeek(locale) === 1
                          ? (index + 1) % 7
                          : index,
                      )}
                      onChange={() =>
                        setSmartSelectorSelectedDaysOfWeek(prev => {
                          const newSet = new Set(prev);
                          const normalizedIndex =
                            getFirstDayOfWeek(locale) === 1
                              ? (index + 1) % 7
                              : index;
                          if (prev.has(normalizedIndex)) {
                            newSet.delete(normalizedIndex);
                          } else {
                            newSet.add(normalizedIndex);
                          }
                          return newSet;
                        })
                      }
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    {day}
                  </span>
                ))}
              </div>
              <div className="flex flex-row gap-3 items-center">
                <label className="block text-gray-700 mb-1 mt-2">
                  {t('manager.sectionsConfig.selectTimeFrames')}
                </label>
                <button
                  type="button"
                  className="block text-blue-700 mb-1 mt-2 text-xs hover:underline"
                  onClick={() =>
                    setSmartSelectorSelectedTimeFrames(
                      new Set(virtualSlotsUniqueTimeFrames),
                    )
                  }
                >
                  {t('generic.selectAll')}
                </button>
                <button
                  type="button"
                  className="block text-red-700 mb-1 mt-2 text-xs hover:underline"
                  onClick={() => setSmartSelectorSelectedTimeFrames(new Set())}
                >
                  {t('generic.clear')}
                </button>
              </div>
              <div className="flex space-x-2">
                {virtualSlotsUniqueTimeFrames.map(timeFrame => (
                  <span
                    key={timeFrame}
                    className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                  >
                    <input
                      type="checkbox"
                      checked={smartSelectorSelectedTimeFrames.has(timeFrame)}
                      onChange={() => {
                        setSmartSelectorSelectedTimeFrames(prev => {
                          const newSet = new Set(prev);
                          if (prev.has(timeFrame)) {
                            newSet.delete(timeFrame);
                          } else {
                            newSet.add(timeFrame);
                          }
                          return newSet;
                        });
                      }}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    {timeFrame}
                  </span>
                ))}
              </div>
              <div className="flex flex-row items-center gap-3">
                <YSButton
                  classNames="mt-3"
                  onClick={handleSmartVirtualSlotsSelector}
                >
                  {t('manager.sectionsConfig.selectVirtualSlots')}
                </YSButton>
                <button
                  type="button"
                  className="flex flex-row gap-2 text-gray-500 mt-3"
                  onClick={() =>
                    virtualSlotsConfig?.setSelectedVirtualSlots(new Set())
                  }
                >
                  {t('manager.sectionsConfig.deselectAllVirtualSlots')}
                </button>
              </div>
            </div>
          )}
        </div>
      </Disclosure.Panel>
    </Disclosure>
  );
}
