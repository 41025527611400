import { dateToString, returnColor } from '@youshift/shared/utils';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EventStatus } from '@youshift/shared/types';

import Wrapper from '../../../components/Wrapper';
import { useManagerContext } from '../../../layouts/ManagerLayout';

interface UserSpecialEventPeriod {
  id_user_special_event_period: number;
  start: Date;
  end: Date;
  initial_allocated_limit: number;
}

export function EventHistory() {
  const { eventTypes, users, events } = useManagerContext();
  const { t } = useTranslation();

  const allSpecialEvents = Object.values(events).flatMap(
    userEvents => userEvents.special_events || [],
  );
  const allUserSpecialEventPeriods = Object.fromEntries(
    Object.entries(events).map(([id_user, userEvents]) => [
      id_user,
      Object.fromEntries(Object.entries(userEvents.user_special_event_periods)),
    ]),
  );
  // Pre-calculate usage for all users and event types
  const eventTypeUsage = useMemo(() => {
    const usage: Record<string, Record<number, number>> = {};

    Object.values(allSpecialEvents).forEach(event => {
      if (event.status === EventStatus.APPROVED) {
        const userId = event.id_user;
        const eventTypeId = event.id_special_event_type;

        if (!usage[userId]) {
          usage[userId] = {};
        }
        usage[userId][eventTypeId] = (usage[userId][eventTypeId] || 0) + 1;
      }
    });

    return usage;
  }, [allSpecialEvents]);

  return (
    <Wrapper mt="mt-8">
      <div className="flex flex-row items-center justify-between">
        <button
          className="mb-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          onClick={() => window.history.back()}
        >
          ← {t('generic.back')}
        </button>
      </div>

      <div className="mt-8">
        <div className="mb-4">
          <h3 className="text-xl font-semibold leading-6 text-gray-900">
            {t('eventCenter.eventStats')}
          </h3>
          <p className="mt-2 text-gray-500">
            {t('eventCenter.eventStatsDescription')}
            <span className="text-gray-500 text-xs">
              {` (${dateToString(
                Object.values(Object.values(allUserSpecialEventPeriods)[0])[0]
                  .start,
                'longWithYear',
              )} - ${dateToString(
                Object.values(Object.values(allUserSpecialEventPeriods)[0])[0]
                  .end,
                'longWithYear',
              )})`}
            </span>
          </p>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-r-gray-300">
                  {t('generic.user')}
                </th>
                {Object.values(eventTypes).map(eventType => (
                  <th
                    key={eventType.id_special_event_type}
                    className="px-3 py-1.5 bg-gray-50 text-left text-xs font-medium border-r border-r-gray-300 text-gray-500 tracking-wider"
                  >
                    <div className="flex flex-row items-center gap-2">
                      <span
                        className="w-5 h-5 rounded-full"
                        style={{
                          backgroundColor: returnColor(eventType.color),
                        }}
                      />
                      <span className="text-md text-gray-500">
                        {eventType.acronym}
                      </span>
                      <span className="text-md font-medium text-gray-900">
                        {eventType.name}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Object.values(users).map(user => (
                <tr key={user.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {user.firstname} {user.lastname}
                  </td>
                  {Object.values(eventTypes).map(eventType => {
                    const used =
                      eventTypeUsage[user.id]?.[
                        eventType.id_special_event_type
                      ] || 0;
                    const max =
                      allUserSpecialEventPeriods[user.id]?.[
                        eventType.id_special_event_type
                      ]?.initial_allocated_limit || 0;
                    const isOverLimit = used > max;

                    return (
                      <td
                        key={eventType.id_special_event_type}
                        className={`px-6 py-4 whitespace-nowrap text-sm text-center ${
                          isOverLimit
                            ? 'text-red-600 font-medium'
                            : 'text-green-600'
                        }`}
                      >
                        <span className="font-medium">{used}</span>
                        <span className="text-gray-500 mx-1">/</span>
                        <span className="text-gray-500">{max}</span>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  );
}
