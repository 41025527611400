import { MutationCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import './App.css';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { request } from '@youshift/shared/api';
import { generateErrorStringFromError } from '@youshift/shared/utils';
import { t } from 'i18next';
import toast, { Toaster } from 'react-hot-toast';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { esLocale } from '@youshift/shared/i18n';

import AdminLayout, { adminLoader } from './layouts/AdminLayout';
import AuthLayout from './layouts/AuthLayout';
import IterationAssignmentLayout from './layouts/IterationAssignmentLayout';
import IterationConfigLayout from './layouts/IterationConfigLayout';
import IterationRootLayout, { iterationRootLoader } from './layouts/IterationRootLayout/IterationRootLayout';
import IterationLayout from './layouts/IterationLayout';
import IterationStatus from './layouts/IterationStatus';
import ManagerLayout, { managerLoader } from './layouts/ManagerLayout';
import PreAuthLayout from './layouts/PreAuthLayout';
import RootLayout from './layouts/RootLayout';
import TeamLayout from './layouts/TeamLayout';
import UserLayout, { userLayoutLoader } from './layouts/UserLayout';
import AdminDashboard, {
  adminDashboardLoader,
} from './pages/Admin/AdminDashboard';
import AdminAlgExec, { adminAlgExecLoader } from './pages/Admin/AdminAlgExec';
import AdminGroup, { adminGroupLoader } from './pages/Admin/AdminGroup';
import AdminStats, { adminStatsLoader } from './pages/Admin/AdminStats';
import AllOrgs, { orgsLoader } from './pages/Admin/AllOrgs';
import AllUsers, { usersLoader } from './pages/Admin/AllUsers';
import Tools from './pages/Admin/Tools';
import Login from './pages/Auth/Login';
import ResetPassword from './pages/Auth/ResetPassword';
import SignUpDivider from './pages/Auth/SignUpDivider';
import SignUpManager from './pages/Auth/SignUpManager/SignUpManager';
import SignUpMockUser from './pages/Auth/SignUpMockUser';
import SignUpNonManager from './pages/Auth/SignUpNonManager';
import SignUpUser from './pages/Auth/SignUpUser';
import VerifyEmail, { verifyEmailLoader } from './pages/Auth/VerifyEmail';
import Help from './pages/Help';
import Contact from './pages/Landing/Contact';
import Landing from './pages/Landing/Landing';
import Cookies from './pages/Landing/Legal/Cookies';
import Legal from './pages/Landing/Legal/Legal';
import Privacy from './pages/Landing/Legal/Privacy';
import Loading from './pages/Loading';
import Maintenance from './pages/Maintenance';
import Chains, { activeChainsLoader } from './pages/Manager/Chains';
import ChainsHistory, { chainsLoader } from './pages/Manager/ChainsHistory';
import ManualAssignment from './pages/Manager/IterationAssignment/ManualAssignment';
import Day from './pages/Manager/IterationVerification/Day';
import Person from './pages/Manager/IterationVerification/Person';
import SummaryStatistics from './pages/Manager/IterationVerification/SummaryStatistics';
import ManagerDashboard from './pages/Manager/ManagerDashboard';
import NewChain from './pages/Manager/NewChain';
import ManagerShiftExchange, {
  managerShiftExchangeLoader,
} from './pages/Manager/ShiftExchange';
import Personnel from './pages/Manager/Team/Personnel';
import Roles from './pages/Manager/Team/Roles';
import UserStatsManager, {
  userStatsManagerLoader,
} from './pages/Stats/GroupStats/UserStatsManager';
import NotFound from './pages/NotFound';
import Profile, { profileLoader } from './pages/Profile';
import GlobalResults, { globalResultsLoader } from './pages/User/GlobalResults';
import Iterations, { userIterationsLoader } from './pages/User/Iterations';
import Preferences, { preferencesLoader } from './pages/User/Preferences';
import Results from './pages/User/Results';
import UserShiftExchangeFeed from './pages/User/ShiftExchange/Feed';
import UserShiftExchangeMyRequests from './pages/User/ShiftExchange/MyRequests';
import UserShiftExchange, { userShiftExchangeLayoutLoader } from './pages/User/ShiftExchange/ShiftExchangeLayout';
import UserChainsHistory, {
  userIterationsHistoryLoader,
} from './pages/User/UserChainsHistory';
import UserStats, { userStatsLoader } from './pages/Stats/UserStats/UserStats';
import UserVideos from './pages/UserVideos';
import Waitlist from './pages/Waitlist';
import { AppOptionsProvider, useAppOptions } from './utils/AppOptionsContext';
// import { TutorialProvider } from './utils/TutorialContext';
import IterationInitializationLayout from './layouts/IterationInitializationLayout';
import ManagerEventCenter from './pages/Manager/EventCenter/EventCenter';
import TableAssignment from './pages/Manager/IterationAssignment/TableAssignment';
import { IncompatibilitiesConfig, incompatibilitiesLoader } from './pages/Manager/IterationConfig/Incompatibilities/IncompatibilitiesConfig';
import { PreferencesConfig } from './pages/Manager/IterationConfig/PreferencesConfig';
import RolesConfig, { itrUsersLoader } from './pages/Manager/IterationConfig/Roles/RolesConfig';
import { CreateNewSection } from './pages/Manager/IterationConfig/Sections/CreateNewSection';
import SectionConfig, { sectionLoader } from './pages/Manager/IterationConfig/Sections/SectionConfig';
import SectionsConfig, {
  sectionsLoader,
} from './pages/Manager/IterationConfig/Sections/SectionsConfig';
import NewReqRule from './pages/Manager/IterationConfig/UserReqRules/NewReqRule';
import UserReqRuleConfig, { userReqRuleLoader } from './pages/Manager/IterationConfig/UserReqRules/UserReqRuleConfig';
import UserReqRulesConfig from './pages/Manager/IterationConfig/UserReqRules/UserReqRulesConfig';
import UserEventCenter from './pages/User/EventCenter/EventCenter';
import { canSkipAuth } from './utils/checks';
import { AuthProvider } from './utils/globalContext';
import History from './pages/Landing/History';
import ChooseIndustry from './pages/Auth/ChooseIndustry';
import UserDashboard from './pages/User/UserDashboard';
import GroupStats, { groupStatsLoader } from './pages/Stats/GroupStats/GroupStats';
import { GroupRulesPerUser } from './pages/Manager/IterationConfig/UserReqRules/GroupRulesPerUser';
import Checkout, { checkoutLoader } from './pages/Manager/Stripe/Checkout';
import Settings from './pages/Settings';
import { CounterManager } from './components/Stats/CounterManager';
import PerUserAssignment from './pages/Manager/IterationAssignment/PerUserAssignment';
import TeamAssignments, { teamAssignmentsLoader } from './pages/User/TeamAssignments';
import AssignmentsSummary from './pages/Manager/IterationAssignment/AssignmentsSummary';
import { EventTypes } from './pages/Manager/EventCenter/EventTypes';
import { EventHistory } from './pages/Manager/EventCenter/EventHistory';

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: error => {
      const { errorKey, context } = generateErrorStringFromError(error);
      const errorString = t(errorKey, context);
      return toast.error(errorString);
    },
  }),
});

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);

function SharedRoutes() {
  return (
    <>
      <Route index element={<Navigate to="roles" />} />
      <Route
        path="roles"
        element={<RolesConfig />}
        loader={itrUsersLoader(queryClient)}
      />
      <Route
        path="sections"
        element={<SectionsConfig />}
        loader={sectionsLoader(queryClient)}
      />
      <Route
        path="sections/new"
        element={<CreateNewSection />}
      >
        <Route
          path=":idSection"
          element={<SectionConfig creating />}
          loader={sectionLoader(queryClient)}
        />
      </Route>
      <Route
        path="rules"
        element={<UserReqRulesConfig />}
      />
      <Route path="rules/users" element={<GroupRulesPerUser />} />
      <Route
        path="rules/new"
        element={<NewReqRule />}
      />
      <Route
        path="incompatibilities"
        element={<IncompatibilitiesConfig />}
        loader={incompatibilitiesLoader(queryClient)}
      />
      <Route
        path="preferences"
        element={<PreferencesConfig />}
      />
    </>
  );
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route element={<PreAuthLayout />}>
        <Route index element={<Landing />} />
        <Route path="signup" element={<SignUpDivider />} />
        <Route path="signup/manager" element={<ChooseIndustry />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="legal" element={<Legal />} />
        <Route path="cookies" element={<Cookies />} />
        <Route path="contact" element={<Contact />} />
        <Route path="history" element={<History />} />
      </Route>
      <Route element={<AuthLayout />} errorElement={<NotFound />}>
        <Route path="login" element={<Login />} loader={canSkipAuth} />
        <Route path="signup/manager/:industry" element={<SignUpManager />} />
        <Route path="signup/user" element={<SignUpNonManager />} />
        <Route
          path="signup/user/:industry/:token"
          element={<SignUpUser />}
          loader={async ({ params }) => request({
            url: 'user/fetch_user_inv_token',
            method: 'post',
            data: { invitation_token: params.token },
          })}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Pídele a tu gestor que te vuelva a invitar o contacta con nosotros en info@you-shift.com"
            />
          )}
        />
        <Route
          path="signup/user/:token"
          element={<SignUpUser />}
          loader={async ({ params }) => request({
            url: 'user/fetch_user_inv_token',
            method: 'post',
            data: { invitation_token: params.token },
          })}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Pídele a tu gestor que te vuelva a invitar o contacta con nosotros en info@you-shift.com"
            />
          )}
        />
        <Route
          path="signup/user/:industry/mock/:token"
          element={<SignUpMockUser />}
          loader={async ({ params }) => request({
            url: 'user/fetch_user_inv_token',
            method: 'post',
            data: { invitation_token: params.token },
          })}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Pídele a tu gestor que te vuelva a invitar o contacta con nosotros en info@you-shift.com"
            />
          )}
        />
        <Route
          path="signup/user/mock/:token"
          element={<SignUpMockUser />}
          loader={async ({ params }) => request({
            url: 'user/fetch_user_inv_token',
            method: 'post',
            data: { invitation_token: params.token },
          })}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Pídele a tu gestor que te vuelva a invitar o contacta con nosotros en info@you-shift.com"
            />
          )}
        />
        <Route path="/reset_password/:industry/:token" element={<ResetPassword />} />
        <Route path="/reset_password/:token" element={<ResetPassword />} />
        <Route
          path="/verify_email/:industry/:token"
          element={<VerifyEmail />}
          loader={verifyEmailLoader}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Si el error persiste, contacta con nosotros en info@you-shift.com"
            />
          )}
        />
        <Route
          path="/verify_email/:token"
          element={<VerifyEmail />}
          loader={verifyEmailLoader}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Si el error persiste, contacta con nosotros en info@you-shift.com"
            />
          )}
        />
      </Route>
      <Route
        path="manager"
        element={<ManagerLayout />}
        loader={managerLoader(queryClient)}
        errorElement={<NotFound />}
        id="manager"
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="checkout" element={<Checkout />} loader={checkoutLoader(queryClient)} />
        <Route path="help" element={<Help manager />} />
        <Route path="waitlist" element={<Waitlist />} />
        <Route
          path="dashboard"
          element={<ManagerDashboard />}
          errorElement={<NotFound />}
        />
        <Route
          path="team"
          element={<TeamLayout />}
        >
          <Route index element={<Navigate to="personnel" />} />
          <Route path="roles" element={<Roles />} />
          <Route path="personnel" element={<Personnel />} />
        </Route>
        <Route
          path="stats"
          element={<GroupStats />}
          loader={groupStatsLoader(queryClient)}
        />
        <Route
          path="stats/counters"
          element={<CounterManager />}
        />
        <Route
          path="event"
          element={<ManagerEventCenter />}
        />
        <Route
          path="event/types"
          element={<EventTypes />}
        />
        <Route
          path="event/stats"
          element={<EventHistory />}
        />
        <Route
          path="team/personnel/:idUser"
          element={<UserStatsManager />}
          loader={userStatsManagerLoader(queryClient)}
        />
        <Route
          path="chains"
          element={<Chains />}
          loader={activeChainsLoader(queryClient)}
        />
        <Route
          path="chains/history"
          element={<ChainsHistory />}
          loader={chainsLoader(queryClient)}
        />
        <Route path="chains/new" element={<NewChain />} />
        <Route
          path="exchange"
          element={<ManagerShiftExchange />}
          loader={managerShiftExchangeLoader(queryClient)}
        />
        <Route element={<IterationRootLayout />} loader={iterationRootLoader(queryClient)}>
          <Route path="iteration/:idItr/initialization" element={<IterationInitializationLayout />}>
            {SharedRoutes()}
            <Route
              path="sections/:idSection"
              element={<SectionConfig />}
              loader={sectionLoader(queryClient)}
            />
            <Route
              path="rules/:idRule"
              element={<UserReqRuleConfig />}
              loader={userReqRuleLoader(queryClient)}
            />
          </Route>
          <Route
            path="iteration/:idItr"
            element={<IterationLayout />}
            errorElement={<NotFound />}
          >
            <Route
              index
              element={<IterationStatus />}
              id="itrLayout"
            />
            <Route path="configuration" element={<IterationConfigLayout />}>
              {SharedRoutes()}
              <Route
                path="sections/:idSection"
                element={<SectionConfig />}
                loader={sectionLoader(queryClient)}
              />
              <Route
                path="rules/:idRule"
                element={<UserReqRuleConfig />}
                loader={userReqRuleLoader(queryClient)}
              />
            </Route>
            <Route path="assignment" id="assignment" element={<IterationAssignmentLayout />}>
              <Route index element={<Navigate to="manual" />} />
              <Route
                path="manual"
                element={<ManualAssignment />}
              />
              <Route path="table" element={<TableAssignment />} />
              <Route path="day" element={<Day />} />
              <Route path="user" element={<PerUserAssignment />} />
              <Route path="summary" element={<SummaryStatistics />} />
              <Route path="stats" element={<AssignmentsSummary />} />
            </Route>
          </Route>
        </Route>

        <Route
          path="profile"
          element={<Profile />}
          loader={profileLoader(queryClient)}
        />
        <Route path="settings" element={<Settings />} />
      </Route>
      <Route
        path="user"
        element={<UserLayout />}
        loader={userLayoutLoader(queryClient)}
        errorElement={<NotFound />}
      >
        {/* <Route index element={<Navigate to="dashboard" />} /> */}
        <Route path="help" element={<Help />} />
        <Route index element={<Navigate to="iterations" />} />
        <Route path="waitlist" element={<Waitlist />} />
        <Route
          path="dashboard"
          element={<UserDashboard />}
          errorElement={<NotFound />}
        />
        <Route
          path="team-assignments"
          element={<TeamAssignments />}
          loader={teamAssignmentsLoader(queryClient)}
        />
        <Route
          path="iterations"
          element={<Iterations />}
          loader={userIterationsLoader(queryClient)}
        />
        <Route
          path="iterations/history"
          element={<UserChainsHistory />}
          loader={userIterationsHistoryLoader(queryClient)}
        />
        <Route
          path=":idItr/preferences"
          element={<Preferences />}
          loader={preferencesLoader(queryClient)}
        />
        <Route
          path=":idItr/results"
          element={<Results />}
        />
        <Route
          path=":idItr/results/all"
          element={<GlobalResults />}
          loader={globalResultsLoader(queryClient)}
        />
        <Route
          path="stats"
          element={<UserStats />}
          loader={userStatsLoader(queryClient)}
        />
        <Route
          path="event"
          element={<UserEventCenter />}
        />
        <Route
          path="exchange"
          element={<UserShiftExchange />}
          loader={userShiftExchangeLayoutLoader(queryClient)}
        >
          <Route index element={<Navigate to="feed" />} />
          <Route path="feed" element={<UserShiftExchangeFeed />} />
          <Route path="requests" element={<UserShiftExchangeMyRequests />} />
        </Route>
        <Route
          path="profile"
          element={<Profile />}
          loader={profileLoader(queryClient)}
        />
        <Route path="videos" element={<UserVideos />} />
      </Route>
      <Route
        path="admin"
        element={<AdminLayout />}
        loader={adminLoader(queryClient)}
        errorElement={<NotFound />}
        id="admin"
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route
          path="dashboard"
          element={<AdminDashboard />}
          loader={adminDashboardLoader(queryClient)}
          errorElement={<NotFound />}
        />
        <Route
          path="profile"
          element={<Profile />}
          loader={profileLoader(queryClient)}
        />
        <Route
          path="group/:id"
          element={<AdminGroup />}
          loader={adminGroupLoader(queryClient)}
          errorElement={<NotFound />}
        />
        <Route
          path="orgs"
          element={<AllOrgs />}
          loader={orgsLoader(queryClient)}
        />
        <Route
          path="users"
          element={<AllUsers />}
          loader={usersLoader(queryClient)}
        />
        <Route path="tools" element={<Tools />} />
        <Route
          path="stats"
          element={<AdminStats />}
          loader={adminStatsLoader(queryClient)}
        />
        <Route
          path="algorithm"
          element={<AdminAlgExec />}
          loader={adminAlgExecLoader(queryClient)}
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>,
  ),
);

const maintenanceRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route element={<PreAuthLayout />}>
        <Route index element={<Landing />} />
        <Route path="signup" element={<SignUpDivider />} />
        <Route path="signup/manager" element={<ChooseIndustry />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="legal" element={<Legal />} />
        <Route path="cookies" element={<Cookies />} />
        <Route path="contact" element={<Contact />} />
      </Route>
      <Route element={<AuthLayout />} errorElement={<NotFound />}>
        <Route path="login" element={<Login />} loader={canSkipAuth} />
        <Route path="signup/manager/:industry" element={<SignUpManager />} />
        <Route path="signup/user" element={<SignUpNonManager />} />
        <Route
          path="signup/user/:token"
          element={<SignUpUser />}
          loader={async ({ params }) => request({
            url: 'user/fetch_user_inv_token',
            method: 'post',
            data: { invitation_token: params.token },
          })}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Pídele a tu gestor que te vuelva a invitar o contacta con nosotros en info@you-shift.com"
            />
          )}
        />
        <Route path="/reset_password/:token" element={<ResetPassword />} />
        <Route
          path="/verify_email/:token"
          element={<VerifyEmail />}
          loader={verifyEmailLoader}
          errorElement={(
            <NotFound
              text="El enlace no es válido"
              description="Lo sentimos, el enlace con el que intentas acceder no es válido. Es posible que haya caducado (si han pasado más de 5 días desde que lo recibiste), o que haya habido un error. Si el error persiste, contacta con nosotros en info@you-shift.com"
            />
          )}
        />
      </Route>
      <Route
        path="manager"
        element={<ManagerLayout />}
        loader={managerLoader}
        errorElement={<Maintenance />}
        id="manager"
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="*" element={<Maintenance />} />
        <Route
          path="profile"
          element={<Profile />}
          loader={profileLoader(queryClient)}
        />
      </Route>
      <Route
        path="user"
        element={<UserLayout />}
        loader={userLayoutLoader(queryClient)}
        errorElement={<Maintenance />}
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="*" element={<Maintenance />} />
        <Route
          path="profile"
          element={<Profile />}
          loader={profileLoader(queryClient)}
        />
      </Route>
      <Route path="*" element={<Maintenance />} />
    </Route>,
  ),
);

function UnWrappedApp() {
  const { appOptions, loading, error } = useAppOptions();

  if (loading) return <Loading />;
  if (error) {
    return (
      <div>
        Error:
        {error}
      </div>
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <AuthProvider>
        <RouterProvider
          router={
            appOptions.app_config.maintenance_mode
              ? maintenanceRouter
              : router
          }
        />
        <Toaster />
      </AuthProvider>
    </Elements>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AppOptionsProvider>
        <UnWrappedApp />
      </AppOptionsProvider>
    </QueryClientProvider>
  );
}

export default App;
