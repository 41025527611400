import { CheckIcon, TrashIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import {
  useApproveEvent,
  useManagerCreateEvent,
  useManagerDeleteEvent,
  useRejectEvent,
} from '@youshift/shared/hooks/mutations';
import {
  EventStatus,
  type SpecialEvent,
  type User,
} from '@youshift/shared/types';
import { dateToString, localeNormalizer } from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  CalendarDaysIcon,
  Cog8ToothIcon,
  PencilSquareIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

import { YSButton } from '../../../components/Buttons';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal';
import Modal from '../../../components/Modal';
import Wrapper from '../../../components/Wrapper';
import UsersFilterDropdown from '../../../FilterDropdownWithSubgroups';
import { useManagerContext } from '../../../layouts/ManagerLayout';
import i18n from '../../../utils/i18n';

function RequestTable({
  requests,
  isPending,
  handleApprove,
  handleReject,
  handleDelete,
  users,
}: {
  requests: SpecialEvent[];
  isPending: boolean;
  handleApprove: (id: number) => void;
  handleReject: (id: number) => void;
  handleDelete: (id: number) => void;
  users: Record<number, User>;
}) {
  const { t } = useTranslation();
  const locale = localeNormalizer(i18n.language);
  const { eventTypes } = useManagerContext();
  return (
    <div className="overflow-x-auto max-w-full">
      <table className="table-fixed w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="w-1/6 px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              {t('generic.name')}
            </th>
            <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              {t('eventCenter.type')}
            </th>
            <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              {t('eventCenter.startDate')}
            </th>
            <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              {t('eventCenter.endDate')}
            </th>
            <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              {t('eventCenter.reason')}
            </th>
            {/* <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
            {t('eventCenter.attachments')}
          </th> */}
            <th className="px-3 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              {isPending ? '' : t('eventCenter.status')}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {requests
            .sort(
              (a, b) =>
                new Date(b.start).getTime() - new Date(a.start).getTime(),
            )
            .map(request => (
              <tr key={request.id_special_event}>
                <td className="px-3 sm:px-6 py-4 break-words text-sm font-medium text-gray-900">
                  {`${users[request.id_user].firstname} ${users[request.id_user].lastname}`}
                </td>
                <td className="px-3 sm:px-6 py-4 break-words text-sm text-gray-500">
                  {eventTypes[request.id_special_event_type].name}
                </td>
                <td className="px-3 sm:px-6 py-4 break-words text-sm text-gray-500">
                  {dateToString(
                    request.start,
                    new Date(request.start).getFullYear() ===
                      new Date().getFullYear()
                      ? 'long'
                      : 'longWithYear',
                    locale,
                  )}
                </td>
                <td className="px-3 sm:px-6 py-4 break-words text-sm text-gray-500">
                  {dateToString(
                    request.end,
                    new Date(request.end).getFullYear() ===
                      new Date().getFullYear()
                      ? 'long'
                      : 'longWithYear',
                    locale,
                  )}
                </td>
                <td className="px-3 sm:px-6 py-4 break-words max-w-min text-sm text-gray-500">
                  {request.comment}
                </td>
                {/* <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {request.attachments > 0 && (
                <span className="inline-flex items-center px-2 sm:px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                  <PaperClipIcon
                    className="mr-1 h-4 w-4"
                    aria-hidden="true"
                  />
                  {request.attachments}
                </span>
              )}
            </td> */}
                <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {isPending ? (
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleApprove(request.id_special_event)}
                        className="bg-green-600 text-white p-1 rounded focus:ring-green-500"
                        aria-label="Approve request"
                      >
                        <CheckIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                      <button
                        onClick={() => handleReject(request.id_special_event)}
                        className="bg-red-600 text-white p-1 rounded focus:ring-red-500"
                        aria-label="Reject request"
                      >
                        <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-2">
                      <span
                        className={`text-xs font-medium rounded-full px-2 py-1 ${
                          request.status === EventStatus.APPROVED
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        }`}
                      >
                        {t(`eventCenter.${request.status}`)}
                      </span>
                      {request.status === EventStatus.APPROVED && (
                        <button
                          onClick={() => handleDelete(request.id_special_event)}
                          className="text-gray-400 hover:text-red-600 p-1 rounded"
                          aria-label="Delete event"
                        >
                          <TrashIcon className="h-4 w-4" aria-hidden="true" />
                        </button>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

function CreateEventModal({
  isOpen,
  onClose,
  users,
  queryClient,
}: {
  isOpen: boolean;
  onClose: () => void;
  users: Record<number, User>;
  queryClient: QueryClient;
}) {
  const { t } = useTranslation();
  const { eventTypes } = useManagerContext();
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [eventType, setEventType] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  const createEvent = useManagerCreateEvent(queryClient, {
    onSuccess: () => {
      toast.success(t('eventCenter.successCreate'));
      onClose();
      setSelectedUser(null);
      setEventType(null);
      setStartDate('');
      setEndDate('');
      setComment('');
      queryClient.invalidateQueries({
        queryKey: ['groupEvents'],
      });
    },
  });

  const handleSave = () => {
    if (!selectedUser || !eventType || !startDate || !endDate) {
      toast.error(t('eventCenter.fillAllFields'));
      return;
    }

    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);

    if (startDateTime > endDateTime) {
      toast.error(t('eventCenter.invalidDateRange'));
      return;
    }

    createEvent.mutate({
      id_user: selectedUser,
      id_special_event_type: eventType,
      start: startDateTime.toISOString(),
      end: endDateTime.toISOString(),
      comment,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      editButtons
      handleSave={handleSave}
    >
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">
          {t('eventCenter.createEvent')}
        </h2>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t('generic.user')}
          </label>
          <select
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            value={selectedUser || ''}
            onChange={e => setSelectedUser(Number(e.target.value))}
          >
            <option value="">{t('generic.selectUser')}</option>
            {Object.values(users).map(user => (
              <option key={user.id} value={user.id}>
                {`${user.firstname} ${user.lastname}`}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t('eventCenter.type')}
          </label>
          <select
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            value={eventType || ''}
            onChange={e => setEventType(Number(e.target.value))}
          >
            <option value="">{t('eventCenter.selectType')}</option>
            {Object.values(eventTypes).map(type => (
              <option
                key={type.id_special_event_type}
                value={type.id_special_event_type}
              >
                {type.name}
              </option>
            ))}
          </select>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t('eventCenter.startDate')}
            </label>
            <input
              type="date"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t('eventCenter.endDate')}
            </label>
            <input
              type="date"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              value={endDate}
              min={startDate}
              onChange={e => setEndDate(e.target.value)}
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t('eventCenter.reason')}
          </label>
          <textarea
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            rows={3}
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
}

export default function ManagerEventCenter() {
  const { events, users, roles, eventTypes } = useManagerContext();
  const { t } = useTranslation();

  // Pending request filters
  const [typePendingFilter, setTypePendingFilter] = useState<number | null>(
    null,
  );
  const [selectedUsersPending, setSelectedUsersPending] = useState<Set<number>>(
    new Set(
      Object.keys(users)
        .filter(user => users[Number(user)].id_user_role)
        .map(Number),
    ),
  );
  const [dateRangePending, setDateRangePending] = useState<{
    start: string;
    end: string;
  }>({
    start: new Date().toISOString().split('T')[0],
    end: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split('T')[0],
  });

  // Processed request filters
  const [statusProcessedFilter, setStatusProcessedFilter] =
    useState<EventStatus | null>(null);
  const [typeProcessedFilter, setTypeProcessedFilter] = useState<number | null>(
    null,
  );
  const [selectedUsersProcessed, setSelectedUsersProcessed] = useState<
    Set<number>
  >(
    new Set(
      Object.keys(users)
        .filter(user => users[Number(user)].id_user_role)
        .map(Number),
    ),
  );
  const [dateRangeProcessed, setDateRangeProcessed] = useState<{
    start: string;
    end: string;
  }>({
    start: new Date().toISOString().split('T')[0],
    end: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split('T')[0],
  });
  const [eventsState, setEventsState] = useState<SpecialEvent[]>(() =>
    events
      ? Object.values(events).flatMap(
          userEvents => userEvents.special_events || [],
        )
      : [],
  );
  const [eventToDelete, setEventToDelete] = useState<number | null>(null);

  const filteredProcessedRequests = useMemo(
    () =>
      eventsState.filter(request => {
        const matchesStatus =
          !statusProcessedFilter || request.status === statusProcessedFilter;
        const matchesType =
          !typeProcessedFilter ||
          request.id_special_event_type === typeProcessedFilter;
        const matchesUser = selectedUsersProcessed.has(request.id_user);
        const matchesDate =
          (!dateRangeProcessed.start ||
            new Date(request.start) >= new Date(dateRangeProcessed.start)) &&
          (!dateRangeProcessed.end ||
            new Date(request.end) <= new Date(dateRangeProcessed.end));

        return matchesStatus && matchesType && matchesUser && matchesDate;
      }),
    [
      eventsState,
      statusProcessedFilter,
      typeProcessedFilter,
      selectedUsersProcessed,
      dateRangeProcessed,
    ],
  );

  const pendingRequests = useMemo(
    () =>
      eventsState
        .filter(request => {
          const matchesStatus =
            !typePendingFilter ||
            request.id_special_event_type === typePendingFilter;
          const matchesUser = selectedUsersPending.has(request.id_user);
          const matchesDate =
            (!dateRangePending.start ||
              new Date(request.start) >= new Date(dateRangePending.start)) &&
            (!dateRangePending.end ||
              new Date(request.end) <= new Date(dateRangePending.end));

          return (
            request.status === EventStatus.PENDING &&
            matchesStatus &&
            matchesUser &&
            matchesDate
          );
        })
        .sort(
          (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime(),
        ),
    [eventsState, typePendingFilter, selectedUsersPending, dateRangePending],
  );

  const processedRequests = useMemo(
    () =>
      filteredProcessedRequests
        .filter(request => request.status !== EventStatus.PENDING)
        .sort(
          (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime(),
        ),
    [filteredProcessedRequests],
  );

  const queryClient = useQueryClient();
  const approveEvent = useApproveEvent(queryClient, {
    onSuccess: (data, variables) => {
      const { id_special_event } = data || variables;
      setEventsState(prevState =>
        prevState.map(event =>
          event.id_special_event === Number(id_special_event)
            ? { ...event, status: EventStatus.APPROVED }
            : event,
        ),
      );
      toast.success(t('eventCenter.successApprove'));
      queryClient.invalidateQueries({ queryKey: ['groupEvents'] });
    },
  });

  const rejectEvent = useRejectEvent(queryClient, {
    onSuccess: (data, variables) => {
      const { id_special_event } = data || variables;
      setEventsState(prevState =>
        prevState.map(event =>
          event.id_special_event === Number(id_special_event)
            ? { ...event, status: EventStatus.REJECTED }
            : event,
        ),
      );
      toast.success(t('eventCenter.successReject'));
      queryClient.invalidateQueries({ queryKey: ['groupEvents'] });
    },
  });

  const deleteEvent = useManagerDeleteEvent(queryClient, {
    onSuccess: () => {
      setEventsState(prevState =>
        prevState.filter(event => event.id_special_event !== eventToDelete),
      );
      toast.success(t('eventCenter.successDelete'));
      setEventToDelete(null);
      queryClient.invalidateQueries({ queryKey: ['groupEvents'] });
    },
  });

  const [eventToExceedLimit, setEventToExceedLimit] = useState<number | null>(
    null,
  );

  const handleReject = (id: number) => {
    rejectEvent.mutate({ id_special_event: id });
  };

  const handleDelete = (id: number) => {
    setEventToDelete(id);
  };

  const confirmDelete = () => {
    if (eventToDelete) {
      deleteEvent.mutate({ id_special_event: eventToDelete });
    }
  };

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const allSpecialEvents = Object.values(events).flatMap(
    userEvents => userEvents.special_events || [],
  );
  const allUserSpecialEventPeriods = Object.fromEntries(
    Object.entries(events).map(([id_user, userEvents]) => [
      id_user,
      Object.fromEntries(Object.entries(userEvents.user_special_event_periods)),
    ]),
  );
  const eventTypeUsage = useMemo(() => {
    const usage: Record<string, Record<number, number>> = {};

    Object.values(allSpecialEvents).forEach(event => {
      if (event.status === EventStatus.APPROVED) {
        const userId = event.id_user;
        const eventTypeId = event.id_special_event_type;

        if (!usage[userId]) {
          usage[userId] = {};
        }
        usage[userId][eventTypeId] = (usage[userId][eventTypeId] || 0) + 1;
      }
    });

    return usage;
  }, [allSpecialEvents]);

  const handleApprove = (id: number) => {
    const event = eventsState.find(e => e.id_special_event === id);
    if (!event) return;

    const userId = event.id_user;
    const eventTypeId = event.id_special_event_type;

    const currentUsage = eventTypeUsage[userId]?.[eventTypeId] || 0;
    const limit =
      allUserSpecialEventPeriods[userId]?.[eventTypeId]
        ?.initial_allocated_limit;

    if (currentUsage >= limit) {
      setEventToExceedLimit(id);
    } else {
      approveEvent.mutate({ id_special_event: id });
    }
  };

  const confirmExceedLimit = () => {
    if (eventToExceedLimit) {
      approveEvent.mutate({ id_special_event: eventToExceedLimit });
      setEventToExceedLimit(null);
    }
  };

  return (
    <Wrapper>
      <div className="mx-auto">
        <div className="flex justify-between items-center mb-2">
          <div className="flex flex-row items-center gap-2">
            <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-4">
              {t('eventCenter.eventCenter')}
            </h3>
            <Link to="types">
              <Cog8ToothIcon
                className="h-6 w-6 text-blue-600"
                aria-hidden="true"
              />
            </Link>
          </div>
          <div className="flex flex-row gap-1">
            <YSButton
              variant="ghost-primary"
              to="stats"
              classNames="flex flex-row items-center gap-2"
            >
              <CalendarDaysIcon className="h-4 w-4" aria-hidden="true" />
              {t('eventCenter.eventStats')}
            </YSButton>
            <YSButton
              variant="primary"
              onClick={() => setIsCreateModalOpen(true)}
              classNames="flex flex-row items-center gap-2"
            >
              <PlusIcon className="h-4 w-4" aria-hidden="true" />
              {t('eventCenter.createEvent')}
            </YSButton>
          </div>
        </div>
        <p className="text-lg text-gray-600 text-start mb-6 sm:mb-8">
          {t('eventCenter.description')}
        </p>
        <div className="bg-white shadow sm:rounded-lg mb-6 sm:mb-8">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium text-gray-900">
                {t('eventCenter.pendingRequests')}
              </h2>
              <div className="flex gap-4 items-center">
                <UsersFilterDropdown
                  users={users}
                  roles={roles}
                  selectedUsers={selectedUsersPending}
                  onSelectionChange={setSelectedUsersPending}
                />
                <select
                  className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={typePendingFilter || ''}
                  onChange={e =>
                    setTypePendingFilter(
                      e.target.value ? Number(e.target.value) : null,
                    )
                  }
                >
                  <option value="">{t('eventCenter.allTypes')}</option>
                  {Object.values(eventTypes).map(type => (
                    <option
                      key={type.id_special_event_type}
                      value={type.id_special_event_type}
                    >
                      {type.name}
                    </option>
                  ))}
                </select>
                <div className="flex gap-2 items-center">
                  <input
                    type="date"
                    value={dateRangePending.start}
                    onChange={e =>
                      setDateRangePending(prev => ({
                        ...prev,
                        start: e.target.value,
                      }))
                    }
                    className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <span>-</span>
                  <input
                    type="date"
                    value={dateRangePending.end}
                    onChange={e =>
                      setDateRangePending(prev => ({
                        ...prev,
                        end: e.target.value,
                      }))
                    }
                    className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>
          {pendingRequests.length ? (
            <RequestTable
              requests={pendingRequests}
              isPending
              handleApprove={handleApprove}
              handleReject={handleReject}
              handleDelete={handleDelete}
              users={users}
            />
          ) : (
            <p className="text-md px-4 sm:px-6 text-gray-900 pb-4">
              {t('eventCenter.noPendingRequests')}
            </p>
          )}
        </div>

        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium text-gray-900">
                {t('eventCenter.processedRequests')}
              </h2>
              <div className="flex gap-4 items-center">
                <UsersFilterDropdown
                  users={users}
                  roles={roles}
                  selectedUsers={selectedUsersProcessed}
                  onSelectionChange={setSelectedUsersProcessed}
                />
                <select
                  className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={statusProcessedFilter || ''}
                  onChange={e =>
                    setStatusProcessedFilter(
                      e.target.value ? (e.target.value as EventStatus) : null,
                    )
                  }
                >
                  <option value="">{t('eventCenter.allStatuses')}</option>
                  <option value={EventStatus.APPROVED}>
                    {t(`eventCenter.${EventStatus.APPROVED}`)}
                  </option>
                  <option value={EventStatus.REJECTED}>
                    {t(`eventCenter.${EventStatus.REJECTED}`)}
                  </option>
                </select>
                <select
                  className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  value={typeProcessedFilter || ''}
                  onChange={e =>
                    setTypeProcessedFilter(
                      e.target.value ? Number(e.target.value) : null,
                    )
                  }
                >
                  <option value="">{t('eventCenter.allTypes')}</option>
                  {Object.values(eventTypes).map(type => (
                    <option
                      key={type.id_special_event_type}
                      value={type.id_special_event_type}
                    >
                      {type.name}
                    </option>
                  ))}
                </select>
                <div className="flex gap-2 items-center">
                  <input
                    type="date"
                    value={dateRangeProcessed.start}
                    onChange={e =>
                      setDateRangeProcessed(prev => ({
                        ...prev,
                        start: e.target.value,
                      }))
                    }
                    className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                  <span>-</span>
                  <input
                    type="date"
                    value={dateRangeProcessed.end}
                    onChange={e =>
                      setDateRangeProcessed(prev => ({
                        ...prev,
                        end: e.target.value,
                      }))
                    }
                    className="rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>
          </div>
          {processedRequests.length ? (
            <RequestTable
              requests={processedRequests}
              isPending={false}
              handleApprove={handleApprove}
              handleReject={handleReject}
              handleDelete={handleDelete}
              users={users}
            />
          ) : (
            <p className="text-md px-4 sm:px-6 text-gray-900 pb-4">
              {t('eventCenter.noProcessedRequests')}
            </p>
          )}
        </div>

        <CreateEventModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          users={users}
          queryClient={queryClient}
        />

        <DeleteConfirmationModal
          isOpen={!!eventToDelete}
          onClose={() => setEventToDelete(null)}
          onConfirm={confirmDelete}
          title={t('eventCenter.deleteEventTitle')}
          description={t('eventCenter.deleteEventDescription')}
        />

        <Modal
          isOpen={!!eventToExceedLimit}
          onClose={() => setEventToExceedLimit(null)}
          editButtons
          handleSave={confirmExceedLimit}
          size="sm"
        >
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-900">
              {t('eventCenter.exceedLimitTitle')}
            </h2>
            <p className="text-sm text-gray-500">
              {t('eventCenter.exceedLimitDescription')}
            </p>
          </div>
        </Modal>
      </div>
    </Wrapper>
  );
}
