/* eslint-disable react/require-default-props */
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import type {
  Section,
  SectionSlot as SectionSlotType,
  SectionWithSlots,
  SlotLabel,
  SupportedLocale,
  User,
  VirtualSlot as VirtualSlotType,
} from '@youshift/shared/types';
import {
  arrangeSlots,
  ColorIndex,
  ColorName,
  dateToString,
  divideSlotsIntoWeeks,
  getCustomDateRange,
  getDifferenceInColumns,
  getDifferenceInMinutes,
  GRID_GRANULARITY,
  localeNormalizer,
  returnColor,
} from '@youshift/shared/utils';
import React, { MouseEventHandler, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Epa } from '../../layouts/IterationRootLayout/types';
import { ArrangedSectionSlot } from '../../pages/Manager/IterationConfig/Sections/types';
import i18n from '../../utils/i18n';
import {
  SectionSlot,
  SectionSlotSelect,
  SectionSlotVirgueria,
} from '../SectionSlot';
import { VirtualSlotWeek } from '../VirtualSlotWeek';
import { ArrangedVirtualSlot, VirgueriaVersion } from './types';
import {
  VirtualSlotVirgueria,
  VirtualSlotVerticalVirgueria,
} from '../VirtualSlot';
import { getAssignmentsByVirtualSlot } from './helpers';

export type VerticalVirgueriaProps = {
  end: string;
  epa?: Epa;
  labels: SlotLabel[];
  sectionsWithSlots: SectionWithSlots[];
  selectedPeople?: Set<number>;
  shadeMap: Record<number, ColorIndex>;
  showRestPeriod?: boolean;
  start: string;
  users?: Record<number, User>;
};

export function VerticalVirgueria({
  end,
  epa,
  labels,
  sectionsWithSlots,
  selectedPeople,
  shadeMap,
  showRestPeriod,
  start,
  users,
}: VerticalVirgueriaProps) {
  const locale = localeNormalizer(i18n.language);

  // Build array of weeks
  const days = getCustomDateRange(start, end, locale, 'table');

  return (
    <div
      className="grid auto-cols-auto auto-rows-min my-2"
      style={{
        // First column for day names, then one column per section
        gridTemplateColumns: `auto repeat(${sectionsWithSlots.length}, 1fr)`,
        gridTemplateRows: `auto repeat(${days.length}, 1fr)`,
        // First row for header, then one row per day.
      }}
    >
      {/* Header Row */}
      {/* Top left header cell (empty) */}
      <div />
      {/* Section headers */}
      {sectionsWithSlots.map(({ section }) => (
        <div
          key={`header-${section.id_section}`}
          className="border text-center font-semibold rounded-md py-2"
          style={{ backgroundColor: returnColor(section.color) }}
        >
          {section.name}
        </div>
      ))}
      {/* Rows for each day */}
      <div
        style={{
          gridRow: `span ${days.length} / span ${days.length}`,
        }}
        className="grid"
      >
        {days.map(day => (
          <div
            key={day.toISOString()}
            className="flex items-center justify-center p-2 bg-gray-400 text-white font-bold rounded-lg border mb-1 border-gray-500"
          >
            {dateToString(day, 'weekday', locale)}
          </div>
        ))}
      </div>
      {sectionsWithSlots.map(
        ({ section, section_slots, virtual_slots }, sectionIdx) => {
          const rows = arrangeSlots<SectionSlotType>(
            Object.values(section_slots),
          );
          const assignmentsByVirtualSlot = getAssignmentsByVirtualSlot(
            epa,
            Object.values(virtual_slots),
          );
          // virtual slots are non overlapping so we can just take the first (and only) row
          const virtualSlotsColumn = arrangeSlots<VirtualSlotType>(
            Object.values(virtual_slots),
          )[0];
          const arrangedVirtualSlots: ArrangedVirtualSlot[] = Object.values(
            virtualSlotsColumn,
          ).map(slot => {
            const diffFromStart = getDifferenceInColumns(days[0], slot.start);
            const duration = getDifferenceInColumns(slot.start, slot.end);
            const { id_virtual_slot, min_need, max_need } = slot;
            return {
              start_position:
                diffFromStart === 0 ? diffFromStart + 1 : diffFromStart,
              end_position: diffFromStart + duration,
              start_time: dateToString(slot.start, 'hh:mm', locale),
              end_time: dateToString(slot.end, 'hh:mm', locale),
              duration_minutes: getDifferenceInMinutes(slot.start, slot.end),
              id_section_slots: slot.section_slots,
              id_virtual_slot,
              min_need,
              max_need,
              spanning: false,
            };
          });
          return (
            <div
              key={`section-${section.id_section}`}
              className={`grid px-1 ${sectionIdx !== sectionsWithSlots.length - 1 ? 'border-r' : ''}`}
              style={{
                gridRow: `span ${days.length} / span ${days.length}`,
                gridTemplateColumns: `auto repeat(${rows.length}, 1fr)`,
              }}
            >
              <div
                key={`${section.id_section}-slots`}
                className="grid"
                style={{
                  gridTemplateRows: `repeat(${24 * GRID_GRANULARITY * days.length}, 1fr)`,
                }}
              >
                {arrangedVirtualSlots.map((arrangedVirtualSlot, idx) => {
                  const nextIsContiguous =
                    arrangedVirtualSlots[idx + 1]?.start_position ===
                    arrangedVirtualSlot.end_position;
                  const currentlyAssigned =
                    assignmentsByVirtualSlot?.[
                      arrangedVirtualSlot.id_virtual_slot
                    ] || 0;
                  return (
                    <VirtualSlotVerticalVirgueria
                      slot={arrangedVirtualSlot}
                      sectionColor={section.color}
                      currentlyAssigned={currentlyAssigned}
                      nextIsContiguous={nextIsContiguous}
                    />
                  );
                })}
              </div>
              {rows.map((row, rowIndex) => (
                <div
                  key={`row-${rowIndex}`}
                  className="grid"
                  style={{
                    gridTemplateRows: `repeat(${24 * GRID_GRANULARITY * days.length}, 1fr)`,
                  }}
                >
                  {row.map(slot => {
                    const diffFromStart = getDifferenceInColumns(
                      days[0],
                      slot.start,
                    );
                    const duration = getDifferenceInColumns(
                      slot.start,
                      slot.end,
                    );
                    const shade = shadeMap[slot.id_slot_label];
                    const labelObj = labels.find(
                      label => slot.id_slot_label === label.id_slot_label,
                    );
                    const icon = labelObj?.icon;

                    const {
                      id_pref_slot,
                      rest_period,
                      id_slot_label,
                      id_section_slot,
                      spanning,
                    } = slot;
                    const arrangedSectionSlot: ArrangedSectionSlot = {
                      start_position:
                        diffFromStart === 0 ? diffFromStart + 1 : diffFromStart,
                      end_position: diffFromStart + duration,
                      start_time: dateToString(slot.start, 'hh:mm', locale),
                      end_time: dateToString(slot.end, 'hh:mm', locale),
                      duration_minutes: getDifferenceInMinutes(
                        slot.start,
                        slot.end,
                      ),
                      id_pref_slot,
                      rest_period,
                      id_slot_label,
                      id_section_slot,
                      spanning,
                    };
                    // TODO
                    const isSlotFull = false;

                    return (
                      <SectionSlotVirgueria
                        end={slot.end}
                        epa={epa}
                        isSlotFull={Boolean(isSlotFull)}
                        key={slot.id_section_slot}
                        labelIcon={icon}
                        sectionColor={section.color}
                        selectedPeople={selectedPeople || new Set<number>()}
                        shade={shade}
                        showRestPeriod={Boolean(showRestPeriod)}
                        slot={arrangedSectionSlot}
                        start={slot.start}
                        users={users}
                        isVertical
                      />
                    );
                  })}
                </div>
              ))}
            </div>
          );
        },
      )}
    </div>
  );
}
