import React from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { UserRequirementType } from '@youshift/shared/types';
import { minutesToHours } from '@youshift/shared/utils';

import { UserRequirementRuleErrors } from '../../../utils/iteration_checks/assignment_checks/types';

type Props = {
  ruleErrors: UserRequirementRuleErrors[number];
  userFullNameMap: Record<number, string>;
  reqType?: UserRequirementType;
};

function UserReqTable({ ruleErrors, userFullNameMap, reqType }: Props) {
  const slotsDeficits = ruleErrors.user_req_slots_deficit || {};
  const slotsSurpluses = ruleErrors.user_req_slots_surplus || {};
  const durationDeficits = ruleErrors.user_req_duration_deficit || {};
  const durationSurpluses = ruleErrors.user_req_duration_surplus || {};

  const slotDeficitUsers = Object.entries(slotsDeficits);
  const slotSurplusUsers = Object.entries(slotsSurpluses);
  const durationDeficitUsers = Object.entries(durationDeficits);
  const durationSurplusUsers = Object.entries(durationSurpluses);

  const { t } = useTranslation();

  // If no deficits or surpluses, render nothing (or you could return null)
  if (
    slotDeficitUsers.length === 0 &&
    slotSurplusUsers.length === 0 &&
    durationDeficitUsers.length === 0 &&
    durationSurplusUsers.length === 0
  ) {
    return null;
  }

  const isHours = reqType === UserRequirementType.DURATION;

  return (
    <div className="">
      {/* Deficit Table */}
      {(slotDeficitUsers.length > 0 || durationDeficitUsers.length > 0) && (
        <div className="my-2">
          <div className="flex flex-row items-center">
            <MinusIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-rose-500" />
            <h3 className="text-base font-semibold p-2">
              {isHours
                ? t('manager.assignmentErrors.user_req_deficit_title_hours')
                : t('manager.assignmentErrors.user_req_deficit_title')}
            </h3>
          </div>
          <div className=" bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <th className="px-3 py-1.5 text-left">
                      {t('generic.name')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.has')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('generic.min')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.missing')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {slotDeficitUsers.map(
                    ([userId, { assigned_slots, min_req }]) => {
                      const missing = min_req - assigned_slots;
                      return (
                        <tr key={userId} className="hover:bg-gray-50">
                          <td className="px-2 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                            {userFullNameMap[Number(userId)]}
                          </td>
                          <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                            {assigned_slots}
                          </td>
                          <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                            {min_req}
                          </td>
                          <td className="px-2 py-1 whitespace-nowrap text-sm text-red-500 text-center font-semibold">
                            {missing}
                          </td>
                        </tr>
                      );
                    },
                  )}
                  {durationDeficitUsers.map(
                    ([
                      userId,
                      { assigned_duration_minutes, min_req_minutes },
                    ]) => {
                      const hours_missing =
                        (min_req_minutes - assigned_duration_minutes) / 60;
                      return (
                        <tr key={userId} className="hover:bg-gray-50">
                          <td className="px-2 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                            {userFullNameMap[Number(userId)]}
                          </td>
                          <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                            {assigned_duration_minutes / 60}
                          </td>
                          <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500 text-center">
                            {min_req_minutes / 60}
                          </td>
                          <td className="px-2 py-1 whitespace-nowrap text-sm text-red-500 text-center font-semibold">
                            {hours_missing}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {/* Surplus Table */}
      {(slotSurplusUsers.length > 0 || durationSurplusUsers.length > 0) && (
        <div className="">
          <div className="flex flex-row items-center">
            <PlusIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-yellow-500" />
            <h3 className="text-base font-semibold p-2">
              {isHours
                ? t('manager.assignmentErrors.user_req_surplus_title_hours')
                : t('manager.assignmentErrors.user_req_surplus_title')}
            </h3>
          </div>{' '}
          <div className="mb-6 bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <th className="px-3 py-1.5 text-left">
                      {t('generic.name')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.has')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('generic.max')}
                    </th>
                    <th className="px-3 py-1.5 text-center">
                      {t('manager.assignmentErrors.excess')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {slotSurplusUsers.map(
                    ([userId, { assigned_slots, max_req }]) => {
                      const excess = assigned_slots - max_req;
                      return (
                        <tr key={userId} className="hover:bg-gray-50">
                          <td className="px-2 py-1whitespace-nowrap text-sm font-medium text-gray-900">
                            {userFullNameMap[Number(userId)]}
                          </td>
                          <td className="px-2 py-1whitespace-nowrap text-sm text-gray-500 text-center">
                            {assigned_slots}
                          </td>
                          <td className="px-2 py-1whitespace-nowrap text-sm text-gray-500 text-center">
                            {max_req}
                          </td>
                          <td className="px-2 py-1whitespace-nowrap text-sm text-red-500 text-center font-semibold">
                            {excess}
                          </td>
                        </tr>
                      );
                    },
                  )}
                  {durationSurplusUsers.map(
                    ([
                      userId,
                      { assigned_duration_minutes, max_req_minutes },
                    ]) => {
                      const hours_excess =
                        (assigned_duration_minutes - max_req_minutes) / 60;
                      return (
                        <tr key={userId} className="hover:bg-gray-50">
                          <td className="px-2 py-1whitespace-nowrap text-sm font-medium text-gray-900">
                            {userFullNameMap[Number(userId)]}
                          </td>
                          <td className="px-2 py-1whitespace-nowrap text-sm text-gray-500 text-center">
                            {assigned_duration_minutes / 60}
                          </td>
                          <td className="px-2 py-1whitespace-nowrap text-sm text-gray-500 text-center">
                            {max_req_minutes / 60}
                          </td>
                          <td className="px-2 py-1whitespace-nowrap text-sm text-red-500 text-center font-semibold">
                            {hours_excess}
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserReqTable;
