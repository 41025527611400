/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PreferenceSlot,
  Section,
  SectionSlot,
  ShiftAssignment,
  ShiftAssignmentType,
  SlotLabel,
  SpecialEvent,
  SpecialEventType,
  SpecialEventWithoutDates,
  User,
  UserPreference,
  UserPreferenceType,
} from '@youshift/shared/types';
import {
  AMPMFormat,
  ColorName,
  LabelIcon,
  adjustSlotToWeekBoundary,
  convert24To12,
  dateToString,
  eventBgColor,
  getFirstDayOfWeek,
  localeNormalizer,
  returnColor,
} from '@youshift/shared/utils';
import {
  CalendarTouchableOpacityProps,
  EventRenderer,
  ICalendarEventBase,
} from 'react-native-big-calendar';
import { useTranslation } from 'react-i18next';

import { backendToLocalDate } from './helpers';
import LabelIconComponent from '../components/LabelIconComponent';
import i18n from './i18n';
import { iconTypes } from './constants';

enum CalendarEventTypes {
  USER_PREFERENCE = 'USER_PREFERENCE',
  SHIFT_ASSIGNMENT = 'SHIFT_ASSIGNMENT',
  EVENT = 'EVENT',
}
export interface CalendarEvent extends ICalendarEventBase {
  type: CalendarEventTypes;
}

export interface CalendarShiftAssignments extends CalendarEvent {
  color: string; // Background color for the event
  icon: LabelIcon;
  section: string; // Section name
  assignmentType: ShiftAssignmentType;
  realEnd: Date;
  id_shift_assignment: number;
  id_section_slot: number;
  id_user: number;
}

export interface GroupedTeamShiftAssignments extends ICalendarEventBase {
  day_idx: number;
  // section -> assignments
  assignments: Record<string, CalendarShiftAssignments[]>;
}

export interface CalendarUserPreference extends CalendarEvent {
  id_pref_slot: number;
  preference: UserPreferenceType | null;
  icon: LabelIcon;
  justification: string | null;
  points: number;
  associatedSectionSlots: number[];
}

export interface CalendarSpecialEvent extends CalendarEvent {
  id_special_event: number;
  color: ColorName;
}

export type CalendarEvents =
  | CalendarUserPreference // This extends CalendarEvent with additional fields
  | CalendarShiftAssignments
  | CalendarSpecialEvent;

export function isCalendarUserPreference(
  event: CalendarEvents,
): event is CalendarUserPreference {
  return event.type === 'USER_PREFERENCE';
}

export function isCalendarShiftAssignments(
  event: CalendarEvents,
): event is CalendarShiftAssignments {
  return event.type === 'SHIFT_ASSIGNMENT';
}

export function isCalendarSpecialEvent(
  event: CalendarEvents,
): event is CalendarSpecialEvent {
  return event.type === 'EVENT';
}

// workaround to find the width of an event that spans across days without using
// react native but using the native classes from the calendar library
type StyleObject = {
  [key: string]: any; // Allows dynamic keys with any value
  width?: number | string; // Specifically typing the width property
};

export type RecursiveStyle = StyleObject | StyleObject[];

export function findWidth(style: RecursiveStyle): number | string | undefined {
  if (Array.isArray(style)) {
    // If it's an array, recursively check each element
    for (const subStyle of style) {
      const width = findWidth(subStyle);
      if (width !== undefined) {
        return width;
      }
    }
  } else if (style && typeof style === 'object') {
    // If it's an object and has a width property, return it
    if ('width' in style) {
      return style.width;
    }
  }
  // Return undefined if no width is found
  return undefined;
}

export function findHeight(style: RecursiveStyle): number | string | undefined {
  if (Array.isArray(style)) {
    // If it's an array, recursively check each element
    for (const subStyle of style) {
      const height = findHeight(subStyle);
      if (height !== undefined) {
        return height;
      }
    }
  } else if (style && typeof style === 'object') {
    // If it's an object and has a width property, return it
    if ('height' in style) {
      return style.height;
    }
  }
  // Return undefined if no width is found
  return undefined;
}

export function findTop(style: RecursiveStyle): number | string | undefined {
  if (Array.isArray(style)) {
    // If it's an array, recursively check each element
    for (const subStyle of style) {
      const top = findTop(subStyle);
      if (top !== undefined) {
        return top;
      }
    }
  } else if (style && typeof style === 'object') {
    // If it's an object and has a width property, return it
    if ('top' in style) {
      return style.top;
    }
  }
  // Return undefined if no width is found
  return undefined;
}

type DaysCoveredResult = {
  fullDays: number;
  lastDayPercentage: number;
};

export function calculateDaysCovered(
  startDate: Date,
  endDate: Date,
): DaysCoveredResult {
  // Normalize start and end to the beginning of their respective days
  const startOfDay = new Date(startDate);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(endDate);
  endOfDay.setHours(0, 0, 0, 0);

  // Calculate the difference in days
  const dayDifference = Math.round(
    (endOfDay.getTime() - startOfDay.getTime()) / (1000 * 60 * 60 * 24),
  );

  // At least one full day (the start day)
  const fullDays = Math.max(0, dayDifference);

  // Calculate the percentage of the last day covered
  const totalHoursInDay = 24;
  const endHours = endDate.getHours() + endDate.getMinutes() / 60;
  const lastDayPercentage = Math.min(1, endHours / totalHoursInDay);

  return {
    fullDays,
    lastDayPercentage: parseFloat(lastDayPercentage.toFixed(2)),
  };
}

// Utility function for generating events by date
export const generateEventsByDate = (
  special_events: Record<string, SpecialEvent> | SpecialEvent[],
) => {
  const events: Record<string, SpecialEventWithoutDates[]> = {};
  Object.values(special_events).forEach(event => {
    const { start, end, ...rest } = event;
    const start_date = new Date(start).setUTCHours(0, 0, 0, 0);
    const end_date = new Date(end).setUTCHours(23, 59, 59, 999);

    for (
      let date = new Date(start_date);
      date <= new Date(end_date);
      date.setDate(date.getDate() + 1)
    ) {
      const dateString = dateToString(date, 'dd/mm/yyyy');
      if (!events[dateString]) {
        events[dateString] = [];
      }
      events[dateString].push({ ...rest });
    }
  });
  return events;
};

// Utility function for generating timed preference slots
export const generateTimedPrefSlots = (
  pref_slots: Record<number, PreferenceSlot>,
  section_slots: Record<number, SectionSlot>,
  slot_labels: Record<number, SlotLabel>,
  userPrefs: Record<number, UserPreference>,
  eventsByDate: Record<string, SpecialEventWithoutDates[]>,
  shift_assignments: Record<number, ShiftAssignment>,
): CalendarUserPreference[] =>
  Object.values(pref_slots)
    .filter(
      prefSlot =>
        prefSlot.section_slots.length > 0 &&
        prefSlot.section_slots.every(
          slotId =>
            !eventsByDate[
              dateToString(section_slots[slotId].start, 'dd/mm/yyyy')
            ] && !shift_assignments[slotId],
        ),
    )
    .map(prefSlot => {
      const { id_pref_slot, section_slots: associatedSectionSlots } = prefSlot;
      const times = associatedSectionSlots.map(slotId => {
        const sectionSlot = section_slots[slotId];
        return {
          start: new Date(sectionSlot.start),
          end: new Date(sectionSlot.end),
        };
      });

      const earliestStart = new Date(
        Math.min(...times.map(t => t.start.getTime())),
      );
      const startDate = new Date(
        ...backendToLocalDate(earliestStart.toISOString()),
      );
      const latestEnd = new Date(Math.max(...times.map(t => t.end.getTime())));
      const endDate = new Date(startDate);
      endDate.setSeconds(1);

      const userPref = userPrefs[id_pref_slot];
      const label = slot_labels[prefSlot.id_slot_label];

      return {
        id_pref_slot,
        associatedSectionSlots,
        title: label.name || '',
        icon: label.icon,
        start: startDate,
        end: endDate,
        preference: userPref?.preference || null,
        points: userPref?.points || 0,
        justification: userPref?.justification || null,
        type: CalendarEventTypes.USER_PREFERENCE,
      } as CalendarUserPreference;
    })
    .sort((a, b) => b.start.getTime() - a.start.getTime());

// Add more utility functions as needed for shift assignments, events, etc.
export const generateShiftAssignments = (
  shift_assignments: Record<number, ShiftAssignment>,
  section_slots: Record<number, SectionSlot>,
  sections: Record<number, Section>,
  slot_labels: Record<number, SlotLabel>,
  sectionsBaseIds?: Record<number, number>,
): CalendarShiftAssignments[] =>
  Object.values(shift_assignments)
    .map(assignment => {
      const sectionSlot = section_slots[assignment.id_section_slot];
      const section = sectionsBaseIds
        ? sections[sectionsBaseIds[assignment.id_section]]
        : sections[assignment.id_section];

      const label = slot_labels[sectionSlot.id_slot_label];
      const locale = localeNormalizer(i18n.language);
      const start = new Date(...backendToLocalDate(sectionSlot.start));
      const realEnd = new Date(...backendToLocalDate(sectionSlot.end));

      // Adjust end time to prevent shifts from appearing split across week boundaries.
      // For English calendars (Sun-Sat), shifts starting Saturday will end at 23:59 Saturday
      // For other calendars (Mon-Sun), shifts starting Sunday will end at 23:59 Sunday
      const end = adjustSlotToWeekBoundary(
        start,
        realEnd,
        getFirstDayOfWeek(locale),
      );

      return {
        title: label.name,
        start,
        end,
        icon: label.icon,
        realEnd,
        id_shift_assignment: assignment.id_shift_assignment,
        id_user: assignment.id_user,
        id_section_slot: assignment.id_section_slot,
        color: returnColor(section.color),
        assignmentType: assignment.type,
        section: `${section.name}`,
        type: CalendarEventTypes.SHIFT_ASSIGNMENT,
      };
    })
    .sort((a, b) => {
      // First sort by section
      if (a.section !== b.section) {
        return a.section.localeCompare(b.section);
      }
      // Then sort by start time
      if (a.start.getTime() !== b.start.getTime()) {
        return a.start.getTime() - b.start.getTime();
      }
      // If start times are equal, sort by end time
      return b.end.getTime() - a.end.getTime();
    });

// These are generated in groups of days because I want to then have control over the sorting of the events within a day
export const generateGroupedTeamShiftAssignments = (
  shift_assignments: Record<number, ShiftAssignment>,
  section_slots: Record<number, SectionSlot>,
  sections: Record<number, Section>,
  slot_labels: Record<number, SlotLabel>,
): GroupedTeamShiftAssignments[] => {
  const groupedAssignments: Record<string, GroupedTeamShiftAssignments> = {};

  Object.values(shift_assignments).forEach((assignment, idx) => {
    const sectionSlot = section_slots[assignment.id_section_slot];
    const section = sections[assignment.id_section];
    const label = slot_labels[sectionSlot.id_slot_label];

    const start = new Date(...backendToLocalDate(sectionSlot.start));
    const assignmentStartStr = dateToString(start, 'dd/mm/yyyy');
    const realEnd = new Date(...backendToLocalDate(sectionSlot.end));
    const locale = localeNormalizer(i18n.language);

    // Adjust end time to prevent shifts from appearing split across week boundaries.
    // For English calendars (Sun-Sat), shifts starting Saturday will end at 23:59 Saturday
    // For other calendars (Mon-Sun), shifts starting Sunday will end at 23:59 Sunday
    const end = adjustSlotToWeekBoundary(
      start,
      realEnd,
      getFirstDayOfWeek(locale),
    );

    const formattedAssignment = {
      title: label.name,
      start: start,
      end: end,
      realEnd,
      icon: label.icon,
      id_shift_assignment: assignment.id_shift_assignment,
      id_user: assignment.id_user,
      id_section_slot: assignment.id_section_slot,
      color: returnColor(section.color),
      assignmentType: assignment.type,
      section: `${section.acronym}`,
      type: CalendarEventTypes.SHIFT_ASSIGNMENT,
    };

    if (!groupedAssignments[assignmentStartStr]) {
      groupedAssignments[assignmentStartStr] = {
        day_idx: idx,
        assignments: { [formattedAssignment.section]: [formattedAssignment] },
        title: label.name,
        start: new Date(...backendToLocalDate(sectionSlot.start)),
        end: new Date(...backendToLocalDate(sectionSlot.end)),
      };
    } else if (
      !groupedAssignments[assignmentStartStr].assignments[
        formattedAssignment.section
      ]
    ) {
      groupedAssignments[assignmentStartStr].assignments[
        formattedAssignment.section
      ] = [formattedAssignment];
    } else {
      groupedAssignments[assignmentStartStr].assignments[
        formattedAssignment.section
      ].push(formattedAssignment);
    }
  });

  return Object.values(groupedAssignments);
};
/**
 * Generates an array of CalendarSpecialEvent from a record of special events.
 *
 * @param special_events - The record of special events to process
 * @returns An array of CalendarSpecialEvent objects
 */
export const generateCalendarEvents = (
  special_events: Record<number, SpecialEvent>,
  event_types: Record<number, SpecialEventType>,
): CalendarSpecialEvent[] =>
  Object.values(special_events).map(event => {
    const { start, end, id_special_event_type, id_special_event, ...rest } =
      event;
    const eventType = event_types[id_special_event_type];
    return {
      ...rest,
      id_special_event,
      title: eventType.acronym,
      color: eventType.color,
      start: new Date(...backendToLocalDate(start)),
      end: new Date(...backendToLocalDate(end)),
      type: CalendarEventTypes.EVENT,
    } as CalendarSpecialEvent;
  });

type ButtonProps = {
  delayPressIn: number;
  key: string;
  onPress: () => void;
  disabled: boolean;
};

interface IconDisplayProps {
  type: ShiftAssignmentType;
}

export function IconDisplay({ type }: IconDisplayProps) {
  const { t } = useTranslation();

  const iconConfig = iconTypes[type];

  if (!iconConfig) return null;

  const { bgColor, Icon } = iconConfig;

  return (
    <p
      className={`absolute right-0 top-0 transform -translate-y-1/2 w-5 h-5 z-10 p-0.5 text-center ${bgColor} rounded-xl text-sm text-white`}
      data-tooltip-id="my-tooltip"
      data-tooltip-content={t(`generic.assignedBy${type}`)}
    >
      <Icon />
    </p>
  );
}

const renderUserPreference = (
  event: CalendarUserPreference,
  buttonProps: ButtonProps,
) => {
  const { preference, icon } = event;
  const dotBgColor = preference
    ? eventBgColor(
        preference,
        false,
        preference === UserPreferenceType.POINTS ? event.points : undefined,
        50, // Default base points
      )
    : 'gray';
  const bgColor = preference
    ? eventBgColor(
        preference,
        true,
        preference === UserPreferenceType.POINTS ? event.points : undefined,
        50,
      )
    : 'white';
  return (
    <button
      {...buttonProps}
      className="relative z-30 bg-white border border-solid rounded-md text-xs p-1 mt-1 flex flex-row items-center gap-1"
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div
        className="h-3 w-3 rounded-full"
        style={{
          backgroundColor: dotBgColor,
        }}
      />
      <LabelIconComponent icon={icon} className="w-4 h-4" />
      <p>{event.title}</p>
    </button>
  );
};

// Helper to render CalendarShiftAssignments
const renderShiftAssignments = (
  event: CalendarShiftAssignments,
  buttonProps: ButtonProps,
  style: RecursiveStyle,
  canSeeOriginOfShift: boolean = true,
) => {
  const locale = localeNormalizer(i18n.language);
  const ampm = AMPMFormat(locale);
  const startHours = event.start.toLocaleString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: ampm,
  });
  const endHours = event.realEnd.toLocaleString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: ampm,
  });
  const covered = calculateDaysCovered(event.start, event.end);
  // const totalWidth = findWidth(style as RecursiveStyle);
  // const widthPerDay = (totalWidth as number) / (covered.fullDays + 1);
  // const width =
  //   covered.fullDays * widthPerDay + widthPerDay * covered.lastDayPercentage;
  return (
    <button
      className="bg-white z-30 relative mt-2 border border-l-[16px] border-solid rounded-md flex items-center justify-start text-xs p-1"
      style={{
        borderLeftColor: event.color,
        borderColor: event.color,
        // width: `${width}px`,
      }}
    >
      {canSeeOriginOfShift && <IconDisplay type={event.assignmentType} />}
      <div className="relative">
        <LabelIconComponent
          icon={event.icon}
          className="w-4 h-4 absolute -left-5"
        />
        {`${startHours} - ${endHours}: ${event.title}`}
      </div>
    </button>
  );
};

const renderTeamShiftAssignments = (
  event: GroupedTeamShiftAssignments,
  buttonProps: ButtonProps,
  style: RecursiveStyle,
  users: Record<number, User>,
) => (
  <div className="flex flex-col my-1 gap-2 rounded-md text-xs">
    {Object.entries(event.assignments).map(([section, assignments]) => {
      // Sort assignments within each section by time
      const sortedAssignments = assignments.sort((a, b) => {
        if (a.start.getTime() !== b.start.getTime()) {
          return a.start.getTime() - b.start.getTime();
        }
        return b.end.getTime() - a.end.getTime();
      });

      return (
        <div
          key={section}
          className="flex flex-row gap-1 border rounded-md"
          style={{ borderColor: assignments[0].color }}
        >
          {/* Section label that spans all assignments */}
          <div
            style={{
              backgroundColor: assignments[0].color,
            }}
            className="rounded-l-[4px] p-0.5 flex items-center"
          >
            <p>{section}</p>
          </div>

          {/* Assignments for this section */}
          <div className="flex flex-col flex-grow self-center">
            {sortedAssignments.map(assignment => {
              const locale = localeNormalizer(i18n.language);
              const ampm = AMPMFormat(locale);
              const startHours = assignment.start.toLocaleString(undefined, {
                hour: 'numeric',
                minute: 'numeric',
                hour12: ampm,
              });
              const endHours = assignment.realEnd.toLocaleString(undefined, {
                hour: 'numeric',
                minute: 'numeric',
                hour12: ampm,
              });
              const user = users[assignment.id_user];

              return (
                <button
                  key={assignment.id_shift_assignment}
                  className="bg-white flex items-center justify-start rounded-md"
                >
                  <div className="flex flex-row items-center">
                    {`${startHours}-${endHours}: ${user.firstname[0]}. ${user.lastname}`}
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      );
    })}
  </div>
);

// Helper to render CalendarSpecialEvent
const renderSpecialEvent = (
  calendarEvent: CalendarSpecialEvent,
  style: RecursiveStyle,
) => {
  const width = findWidth(style);
  return (
    <div
      style={{ width: `${width}px` }}
      className="flex flex-row flex-grow z-0 relative"
    >
      <div
        style={{
          backgroundColor: returnColor(calendarEvent.color),
          borderRadius: '0.5rem',
          border: `1px solid ${returnColor(calendarEvent.color, 800)}`,
        }}
        className="w-full text-xs border-md mt-1 py-1 text-center pl-3 relative z-0"
      >
        {calendarEvent.title}
      </div>
    </div>
  );
};

// Main customEventRenderer function
export const customEventRenderer: (
  event: CalendarEvents,
  touchableOpacityProps: CalendarTouchableOpacityProps,
  canSeeOriginOfShift?: boolean,
) => JSX.Element = (
  event: CalendarEvents,
  touchableOpacityProps: CalendarTouchableOpacityProps,
  canSeeOriginOfShift: boolean = true,
) => {
  const { style, ...buttonProps } = touchableOpacityProps;
  if (event.type === 'USER_PREFERENCE') {
    return renderUserPreference(event as CalendarUserPreference, buttonProps);
  }
  if (event.type === 'SHIFT_ASSIGNMENT') {
    return renderShiftAssignments(
      event as CalendarShiftAssignments,
      buttonProps,
      style as RecursiveStyle,
      canSeeOriginOfShift,
    );
  }
  if (event.type === 'EVENT') {
    return renderSpecialEvent(
      event as CalendarSpecialEvent,
      style as RecursiveStyle,
    );
  }

  return <></>;
};

export const teamShiftAssignmentRenderer = (
  event: GroupedTeamShiftAssignments,
  touchableOpacityProps: CalendarTouchableOpacityProps,
  users: Record<number, User>,
) => {
  const { style, ...buttonProps } = touchableOpacityProps;
  return renderTeamShiftAssignments(
    event,
    buttonProps,
    style as RecursiveStyle,
    users,
  );
};

// WEEKLY EVENTS
const renderWeeklySpecialEvent = (
  calendarEvent: CalendarSpecialEvent,
  style: RecursiveStyle,
) => {
  const height = findHeight(style);
  const top = findTop(style);

  return (
    <div
      style={{
        height,
        top,
        left: '3px',
        right: '3px',
        backgroundColor: returnColor(calendarEvent.color),
        border: `1px solid ${returnColor(calendarEvent.color, 800)}`,
      }}
      className="absolute z-10 rounded-lg flex items-center justify-center"
    >
      <p className="font-semibold text-lg">{calendarEvent.title}</p>
    </div>
  );
};

const renderWeeklyShiftAssignments = (
  event: CalendarShiftAssignments,
  buttonProps: ButtonProps,
  style: RecursiveStyle,
  canSeeOriginOfShift: boolean = true,
) => {
  const locale = localeNormalizer(i18n.language);
  const ampm = AMPMFormat(locale);
  const startHours = event.start.toLocaleString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: ampm,
  });
  const endHours = event.realEnd.toLocaleString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: ampm,
  });
  const height = findHeight(style);
  const top = findTop(style);

  // for assignment that span across multiple days, the library will divide them into multiple ones
  // and in doing so it will convert the start to a moment object. for an event that started in day 1 and finishes in day 2,
  // we want to display something different in day 1 than in day 2 to show continuity.
  const firstPartOfAssignment = event.start instanceof Date;

  return (
    <button
      {...buttonProps}
      className={`absolute bg-white z-30 border border-solid rounded-md flex flex-col gap-2 items-center justify-start text-xs p-1 ${
        firstPartOfAssignment ? 'border-t-[30px]' : 'border-t-0'
      }`}
      style={{
        borderTopColor: firstPartOfAssignment ? event.color : 'transparent',
        borderColor: event.color,
        height: `${height}`,
        top: `${top}`,
        left: '3px',
        right: '3px',
        zIndex: 10,
      }}
    >
      {firstPartOfAssignment && (
        <>
          {canSeeOriginOfShift && <IconDisplay type={event.assignmentType} />}
          <p className="text-base font-semibold mt-2">{event.section}</p>
          <div className="flex flex-col items-center justify-center">
            <LabelIconComponent icon={event.icon} className="w-6 h-6" />
            <p className="text-base">{event.title}</p>
            <p className="text-base font-semibold">{`${startHours}-${endHours}`}</p>
          </div>
        </>
      )}
    </button>
  );
};

export const customWeeklyEventRenderer: (
  event: CalendarEvents,
  touchableOpacityProps: CalendarTouchableOpacityProps,
  canSeeOriginOfShift?: boolean,
) => JSX.Element = (
  event: CalendarEvents,
  touchableOpacityProps: CalendarTouchableOpacityProps,
  canSeeOriginOfShift: boolean = true,
) => {
  const { style, ...buttonProps } = touchableOpacityProps;
  if (event.type === 'SHIFT_ASSIGNMENT') {
    return renderWeeklyShiftAssignments(
      event as CalendarShiftAssignments,
      buttonProps,
      style as RecursiveStyle,
      canSeeOriginOfShift,
    );
  }
  if (event.type === 'EVENT') {
    return renderWeeklySpecialEvent(
      event as CalendarSpecialEvent,
      style as RecursiveStyle,
    );
  }
  return <></>;
};
