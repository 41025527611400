import {
  CalendarDaysIcon,
  Cog8ToothIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  SparklesIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  CheckBadgeIcon,
  StopIcon,
} from '@heroicons/react/24/solid';
import {
  AlgSuccessDict,
  AlgErrorDict,
  ItrStatuses,
  ItrStatusType,
  AlgExecutionStatus,
  AlgErrorType,
} from '@youshift/shared/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { YSButton } from './Buttons';
import { ErrorDisplaySeverity } from '../utils/iteration_checks/types';
import LoadingSpinner from '../assets/LoadingSpinner';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import Modal from './Modal';
import InfeasibilityAnalysisModal from './InfeasibilityAnalysis/InfeasibilityAnalysisModal';
import { useItrContext } from '../layouts/IterationRootLayout/IterationRootLayout';

interface ChecksToolbarProps {
  openConfigErrors: () => void;
  hasConfigErrors: boolean;
  assignmentErrorDisplaySeverity?: ErrorDisplaySeverity;
  openAssignmentErrors?: () => void;
  status: ItrStatusType;
  shouldBeShown: boolean;
  onStopClick?: () => void;
  isAlgBeingInterrupted?: boolean;
  runAlg?: () => void;
  runAlgIsPending?: boolean;
  onDeleteAssignments?: () => void;
  areThereAlgorithmAssignedShifts?: boolean;
  lastExecutionStatus?: null | AlgSuccessDict | AlgErrorDict;
}

function ChecksToolbar({
  status,
  openConfigErrors,
  openAssignmentErrors,
  shouldBeShown,
  assignmentErrorDisplaySeverity,
  hasConfigErrors,
  onStopClick,
  isAlgBeingInterrupted,
  runAlg,
  runAlgIsPending,
  onDeleteAssignments,
  areThereAlgorithmAssignedShifts,
  lastExecutionStatus,
}: ChecksToolbarProps) {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const isRunning = status === ItrStatuses.RUNNING_SMARTASS;
  const shouldShowAssignment =
    status === ItrStatuses.CONFIGURATION ||
    status === ItrStatuses.ASSIGNMENT ||
    status === ItrStatuses.PUBLISHED ||
    status === ItrStatuses.RUNNING_SMARTASS;
  const warnings =
    assignmentErrorDisplaySeverity === ErrorDisplaySeverity.WARNINGS;
  const errors = assignmentErrorDisplaySeverity === ErrorDisplaySeverity.ERRORS;

  const [successfulExecutionModalOpen, setSuccessfulExecutionModalOpen] =
    useState(false);
  const [failureExecutionModalOpen, setFailureExecutionModalOpen] =
    useState(false);

  const { epa } = useItrContext();

  const hasAtLeastOneShiftAssignment = Object.values(
    epa.assignmentsMap.bySectionSlot,
  ).some(assignments => assignments.length > 0);

  if (
    !shouldBeShown ||
    (!hasAtLeastOneShiftAssignment && status === ItrStatuses.CONFIGURATION)
  ) {
    return null;
  }

  const lastExecutionIsSuccess =
    lastExecutionStatus?.status === AlgExecutionStatus.SUCCESS;
  const lastExecutionIsFailure =
    lastExecutionStatus?.status === AlgExecutionStatus.FAILED;

  return (
    <div className="fixed top-1/3 right-4 flex flex-col justify-center items-center gap-4 px-2 py-4 bg-white shadow-lg rounded-full border border-gray-200 z-50">
      {/* <button
        onClick={openConfigErrors}
        className={`px-4 py-2 text-sm relative font-medium ${hasConfigErrors ? 'bg-red-100 hover:bg-red-200 border-red-800' : 'bg-green-100 hover:bg-green-200 border-green-800'} text-gray-700 rounded-full`}
        disabled={isRunning || !hasConfigErrors}
      >
        <Cog8ToothIcon className="w-6 h-6" />
        {hasConfigErrors ? (
          <ExclamationCircleIcon className="text-red-600 w-7 h-7 font-bold absolute -top-3 -left-1" />
        ) : (
          <CheckBadgeIcon className="text-teal-600 w-7 h-7 font-bold absolute -top-3 -left-1" />
        )}
      </button> */}
      {shouldShowAssignment && (
        <>
          <button
            onClick={openAssignmentErrors}
            className={`px-4 py-2 text-sm font-medium relative text-gray-700 rounded-full
              ${
                errors
                  ? 'bg-red-100 hover:bg-red-200 border-red-800'
                  : warnings
                    ? 'bg-amber-100 hover:bg-amber-200 border-amber-800'
                    : 'bg-green-100 hover:bg-green-200 border-green-800'
              }`}
            disabled={isRunning || (!errors && !warnings)}
          >
            <CalendarDaysIcon className="w-6 h-6" />
            {errors ? (
              <ExclamationCircleIcon className="text-red-600 w-7 h-7 font-bold absolute -top-3 -left-1" />
            ) : warnings ? (
              <ExclamationTriangleIcon className="text-amber-600 w-7 h-7 font-bold absolute -top-3 -left-1" />
            ) : (
              <CheckBadgeIcon className="text-teal-600 w-7 h-7 font-bold absolute -top-3 -left-1" />
            )}
          </button>
          {status !== ItrStatuses.CONFIGURATION && (
            <div className="relative flex flex-col justify-center items-center">
              <YSButton
                onClick={runAlg}
                disabled={isRunning || runAlgIsPending}
                classNames="relative"
              >
                <SparklesIcon className="w-6 h-6" />
              </YSButton>
              {(lastExecutionStatus || isRunning) && (
                <span className="relative h-4 w-0.5 my-1 bg-gray-400" />
              )}
              {isRunning ? (
                <div
                  aria-label="Interrupt"
                  className="flex items-center bg-blue-500 hover:bg-blue-600 text-white font-semibold p-2 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <LoadingSpinner />
                </div>
              ) : lastExecutionIsSuccess ? (
                <button
                  aria-label="Delete assignments"
                  className="flex items-center bg-teal-100 hover:bg-teal-200 text-teal-900 font-semibold p-1 rounded-full"
                  onClick={() => setSuccessfulExecutionModalOpen(true)}
                >
                  <CheckBadgeIcon className="h-6 w-6" />
                </button>
              ) : lastExecutionIsFailure ? (
                <button
                  aria-label="Delete assignments"
                  className="flex items-center bg-red-100 hover:bg-red-200 text-red-900 font-semibold p-1 rounded-full"
                  onClick={() => setFailureExecutionModalOpen(true)}
                >
                  <ExclamationCircleIcon className="h-6 w-6" />
                </button>
              ) : null}
              {areThereAlgorithmAssignedShifts && (
                <>
                  <span className="relative h-4 w-0.5 my-1 bg-gray-400" />
                  <button
                    aria-label="Delete assignments"
                    className="flex items-center bg-red-100 hover:bg-red-200 text-red-900 font-semibold p-1 rounded-full"
                    onClick={() => setIsDeleteModalOpen(true)}
                  >
                    <TrashIcon className="h-6 w-6" />
                  </button>
                </>
              )}
            </div>
          )}
        </>
      )}

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() => {
          onDeleteAssignments?.();
          setIsDeleteModalOpen(false);
        }}
        title={t('manager.shiftAssignment.deleteAlgResultsTitle')}
        description={t('manager.shiftAssignment.deleteAlgResultsDescription')}
      />
      <Modal
        isOpen={successfulExecutionModalOpen && lastExecutionIsSuccess}
        onClose={() => setSuccessfulExecutionModalOpen(false)}
      >
        <ShieldCheckIcon className="w-12 h-12 text-blue-600 mx-auto my-4" />
        <h2 className="font-semibold text-lg text-center">
          {t('manager.shiftAssignment.successfulExecutionModalTitle')}
        </h2>
        <p className="my-2">
          {t('manager.shiftAssignment.successfulExecutionModalDescription')}
        </p>
      </Modal>
      {lastExecutionIsFailure && (
        <Modal
          isOpen={failureExecutionModalOpen}
          onClose={() => setFailureExecutionModalOpen(false)}
        >
          <InfeasibilityAnalysisModal
            lastExecutionStatus={lastExecutionStatus}
          />
        </Modal>
      )}
    </div>
  );
}

export default ChecksToolbar;
