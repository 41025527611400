import type {
  Section,
  SectionSlot,
  SpecialEvent,
} from '@youshift/shared/types';
import {
  BellAlertIcon,
  CalendarIcon,
  ClockIcon,
  MoonIcon,
  ShieldCheckIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import {
  UserAssignmentErrors,
  AssignmentCheckErrorType,
} from '../../../utils/iteration_checks/assignment_checks/types';
import SectionSlotChip from '../../Calendars/SectionSlotChip';
import { useManagerContext } from '../../../layouts/ManagerLayout';

type Props = {
  userErrors: UserAssignmentErrors[number];
  sectionSlots: Record<number, SectionSlot>;
  sections: Record<number, Section>;
};

const getErrorIcon = (type: AssignmentCheckErrorType) => {
  switch (type) {
    case AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_EVENT:
      return <CalendarIcon className="h-5 w-5 text-amber-500" />;
    case AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK:
      return <ShieldCheckIcon className="h-5 w-5 text-purple-500" />;
    case AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK:
      return <UserCircleIcon className="h-5 w-5 text-blue-500" />;
    case AssignmentCheckErrorType.SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION:
      return <MoonIcon className="h-5 w-5 text-red-500" />;
    default:
      return <BellAlertIcon className="h-5 w-5 text-gray-500" />;
  }
};

function UserErrors({ userErrors, sectionSlots, sections }: Props) {
  const { t } = useTranslation();
  const { eventTypes } = useManagerContext();
  return (
    <div className="border border-gray-200 rounded-lg mb-2 divide-y">
      {Object.entries(userErrors).map(([slotId, errors]) => {
        const sectionSlot = sectionSlots[Number(slotId)];
        const section = sections[sectionSlot.id_section];
        return (
          <div key={slotId} className="p-2">
            <SectionSlotChip
              small
              sectionName={section?.name}
              start={sectionSlot.start}
              end={sectionSlot.end}
            />
            {Object.entries(errors).map(([errorType, context]) => (
              <div
                key={errorType}
                className="flex flex-row gap-2 items-center mt-1.5 text-sm"
              >
                <div className="min-w-6">
                  {getErrorIcon(errorType as AssignmentCheckErrorType)}
                </div>
                {t(`manager.assignmentErrors.${errorType}`, {
                  event:
                    'events' in context
                      ? eventTypes[context?.events[0].id_special_event_type]
                          .name
                      : undefined,
                })}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}

export default UserErrors;
