import {
  Rule,
  UserReqRule,
  UserRequirementInclusionType,
  UserRequirementType,
  UserRequirementNumUsersType,
} from '@youshift/shared/types';

export interface RuleAttributes {
  inclusionType: UserRequirementInclusionType;
  numUsersType: UserRequirementNumUsersType;
}

const isExclusionRule = (userReqs: UserReqRule['user_reqs']): boolean => {
  return Object.values(userReqs).every(req => {
    if (req.req_type === UserRequirementType.SLOTS) {
      return (req.min_slots ?? 0) === 0 && (req.max_slots ?? 0) === 0;
    }
    return (req.min_duration ?? 0) === 0 && (req.max_duration ?? 0) === 0;
  });
};

const getNumUsersType = (
  userReqs: UserReqRule['user_reqs'],
): UserRequirementNumUsersType => {
  return Object.keys(userReqs).length > 1
    ? UserRequirementNumUsersType.MULTIPLE
    : UserRequirementNumUsersType.SINGLE;
};

export const useRuleAttributes = (allUserReqs: Record<number, UserReqRule>) => {
  const ruleAttributes: Record<number, RuleAttributes> = {};

  for (const [ruleId, userReqRule] of Object.entries(allUserReqs)) {
    ruleAttributes[Number(ruleId)] = {
      inclusionType: isExclusionRule(userReqRule.user_reqs)
        ? UserRequirementInclusionType.EXCLUSION
        : UserRequirementInclusionType.INCLUSION,
      numUsersType: getNumUsersType(userReqRule.user_reqs),
    };
  }

  return ruleAttributes;
};

type UserRulesType = {
  personal: Record<
    number,
    Array<
      Rule & {
        inclusionType: UserRequirementInclusionType;
        min_slots: number | null;
        max_slots: number | null;
        min_duration: number | null;
        max_duration: number | null;
      }
    >
  >;
  group: Record<
    number,
    Array<
      Rule & {
        inclusionType: UserRequirementInclusionType;
        min_slots: number | null;
        max_slots: number | null;
        min_duration: number | null;
        max_duration: number | null;
      }
    >
  >;
};

export const useClassifyAllUserReqs = (
  allUserReqs: Record<number, UserReqRule>,
) => {
  const ruleAttributes = useRuleAttributes(allUserReqs);
  const multipleUsersRules: Record<
    number,
    Rule & { inclusionType: UserRequirementInclusionType }
  > = {};
  const userRules: UserRulesType = { personal: {}, group: {} };

  for (const ruleIdStr in allUserReqs) {
    const ruleId = Number(ruleIdStr);
    const ruleReqRule = allUserReqs[ruleId];
    const rule = ruleReqRule.rule;
    const userReqs = ruleReqRule.user_reqs;
    const { inclusionType, numUsersType } = ruleAttributes[ruleId];

    if (numUsersType === UserRequirementNumUsersType.MULTIPLE) {
      // Group rule logic
      multipleUsersRules[rule.id_rule] = { ...rule, inclusionType };
      for (const [userId, userReq] of Object.entries(userReqs)) {
        if (!userRules.group[Number(userId)]) {
          userRules.group[Number(userId)] = [];
        }
        userRules.group[Number(userId)].push({
          ...rule,
          inclusionType,
          min_slots:
            userReq.req_type === UserRequirementType.SLOTS
              ? userReq.min_slots
              : null,
          max_slots:
            userReq.req_type === UserRequirementType.SLOTS
              ? userReq.max_slots
              : null,
          min_duration:
            userReq.req_type === UserRequirementType.DURATION
              ? userReq.min_duration
              : null,
          max_duration:
            userReq.req_type === UserRequirementType.DURATION
              ? userReq.max_duration
              : null,
        });
      }
    } else {
      // Personal rule logic
      const userId = Number(Object.keys(userReqs)[0]);
      const userReq = userReqs[userId];
      if (!userRules.personal[userId]) {
        userRules.personal[userId] = [];
      }
      userRules.personal[userId].push({
        ...rule,
        inclusionType,
        min_slots:
          userReq.req_type === UserRequirementType.SLOTS
            ? userReq.min_slots
            : null,
        max_slots:
          userReq.req_type === UserRequirementType.SLOTS
            ? userReq.max_slots
            : null,
        min_duration:
          userReq.req_type === UserRequirementType.DURATION
            ? userReq.min_duration
            : null,
        max_duration:
          userReq.req_type === UserRequirementType.DURATION
            ? userReq.max_duration
            : null,
      });
    }
  }

  return { multipleUsersRules, userRules };
};
