import { useTranslation } from 'react-i18next';
import {
  CalendarIcon,
  CheckCircleIcon,
  ClockIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import {
  ExchangeResponseType,
  Section,
  SectionSlot,
  ShiftAssignment,
  UserReqRule,
} from '@youshift/shared/types';
import {
  localeNormalizer,
  minutesToHours,
  parseSectionSlotDateTime,
} from '@youshift/shared/utils';

import {
  ShiftExchangeViolation,
  ShiftExchangeViolationType,
  UserReqMaxSlotsViolation,
  UserReqMinSlotsViolation,
  UserReqMaxDurationViolation,
  UserReqMinDurationViolation,
  SingleGroupIncompatibilityMinSimultViolation,
  SingleGroupIncompatibilityMaxSimultViolation,
  CrossGroupIncompatibilityViolation,
} from '../../../utils/shift_exchange/types';
import { useUserContext } from '../../../layouts/UserLayout';
import i18n from '../../../utils/i18n';
import ShiftViolationsList from '../../../components/ShiftExchange/ShiftViolationsList';

interface ExchangeViolationsSummaryProps {
  violations: ShiftExchangeViolation[];
  allUserReqRules: Record<number, UserReqRule>;
  allSectionSlots: Record<number, SectionSlot>;
  allSections: Record<number, Section>;
}

export default function ExchangeViolationsSummary({
  violations,
  allUserReqRules,
  allSectionSlots,
  allSections,
}: ExchangeViolationsSummaryProps) {
  const { t } = useTranslation();

  const violationsByType = violations.reduce<
    Record<ShiftExchangeViolationType, ShiftExchangeViolation[]>
  >(
    (acc, violation) => {
      if (!acc[violation.type]) {
        acc[violation.type] = [];
      }
      acc[violation.type].push(violation);
      return acc;
    },
    {} as Record<ShiftExchangeViolationType, ShiftExchangeViolation[]>,
  );
  const locale = localeNormalizer(i18n.language);

  return violations.length == 0 ? (
    <div className="mt-2 border border-green-600 bg-green-50 rounded-md p-2">
      <div className="flex items-center gap-1 text-green-600 mb-1">
        <CheckCircleIcon
          className="mt-1 h-5 w-5 flex-none text-green-600"
          aria-hidden="true"
        />
        <h4 className="font-medium">
          {t('generic.shiftExchange.shiftExchangeCompliant')}
        </h4>
      </div>
    </div>
  ) : (
    <div className="mt-2 border border-yellow-600 bg-yellow-50 rounded-md p-2">
      <div className="flex items-center gap-1 text-yellow-600 mb-1">
        <ExclamationTriangleIcon className="h-5 w-5" />
        <h4 className="font-medium">
          {t('generic.shiftExchange.shiftExchangeViolation')}
        </h4>
      </div>
      <ShiftViolationsList
        violations={violations}
        allUserReqRules={allUserReqRules}
        allSectionSlots={allSectionSlots}
        allSections={allSections}
      />
    </div>
  );
}
