import { UserRequirementType } from '@youshift/shared/types';

interface ValidationResult {
  isValid: boolean;
  error?: string;
}

export const hasInvalidReqs = (
  roles: Record<
    string,
    {
      users: Array<{
        included: boolean;
        min_duration?: number;
        max_duration?: number;
        min_slots?: number;
        max_slots?: number;
      }>;
    }
  >,
  reqType: UserRequirementType,
): ValidationResult => {
  // Check if any user is included
  const anyIncluded = Object.values(roles).some(role =>
    role.users.some(user => user.included),
  );

  if (!anyIncluded) {
    return {
      isValid: false,
      error: 'manager.rulesConfig.noUserIncluded',
    };
  }

  // Check min/max constraints
  const invalidUser = Object.values(roles).find(role =>
    role.users.find(user => {
      if (!user.included) return false;

      if (reqType === UserRequirementType.DURATION) {
        return (
          user.min_duration !== undefined &&
          user.max_duration !== undefined &&
          user.min_duration > user.max_duration
        );
      } else {
        return (
          user.min_slots !== undefined &&
          user.max_slots !== undefined &&
          user.min_slots > user.max_slots
        );
      }
    }),
  );

  if (invalidUser) {
    return {
      isValid: false,
      error:
        reqType === UserRequirementType.DURATION
          ? 'manager.rulesConfig.invalidDurationConstraints'
          : 'manager.rulesConfig.invalidSlotsConstraints',
    };
  }

  return { isValid: true };
};
