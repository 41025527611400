import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowPathRoundedSquareIcon,
  Bars3Icon,
  BuildingOffice2Icon,
  CalendarIcon,
  ChartBarIcon,
  GlobeEuropeAfricaIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon,
  UserIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  eventTypesQuery,
  loadUserQuery,
  SingleUserEventsResponse,
  userEventsQuery,
  userLayoutQuery,
  type UserLayoutResponse,
} from '@youshift/shared/hooks/queries';
import { SpecialEvent, SpecialEventType, User } from '@youshift/shared/types';
import { createContext, Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  LoaderFunctionArgs,
  NavLink,
  Outlet,
  useLoaderData,
} from 'react-router-dom';
import {
  isAllowedToUsePlatform,
  userSubscriptionStatus,
} from '@youshift/shared/utils';

import { requireLoggedIn } from '../utils/checks';
import NoData from '../pages/Manager/NoData';

interface NavigationItem {
  id: string;
  name: string;
  href: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

type UserLayoutLoader = {
  user: User;
  userLayout: UserLayoutResponse | null;
  events: SingleUserEventsResponse | null;
  eventTypes: Record<number, SpecialEventType> | null;
  canUsePlatform?: boolean;
};

export type UserLayoutContext = {
  user: User;
  userLayout: UserLayoutResponse;
  events: SingleUserEventsResponse;
  eventTypes: Record<number, SpecialEventType>;
};

export const UserContext = createContext<UserLayoutContext | undefined>(
  undefined,
);

export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within an UserProvider');
  }
  return context;
}

export const userLayoutLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<UserLayoutLoader | null> => {
    const user = await requireLoggedIn(queryClient);
    const canUsePlatform = isAllowedToUsePlatform(user);
    const userLayout = canUsePlatform
      ? await preLoadQuery(queryClient, userLayoutQuery())
      : null;
    const events = canUsePlatform
      ? await preLoadQuery(queryClient, userEventsQuery())
      : null;
    const eventTypes = canUsePlatform
      ? await preLoadQuery(queryClient, eventTypesQuery())
      : null;

    return { user, userLayout, events, eventTypes, canUsePlatform };
  };

export function useUserLayoutLoader(): UserLayoutLoader {
  const initialData = useLoaderData() as UserLayoutLoader;
  const { data: user } = useQuery({
    ...loadUserQuery(),
    initialData: initialData.user,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
  const { data: userLayout } = useQuery({
    ...userLayoutQuery(),
    initialData: initialData.userLayout,
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: initialData.canUsePlatform,
  });
  const { data: events } = useQuery({
    ...userEventsQuery(),
    initialData: initialData.events,
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: initialData.canUsePlatform,
  });

  const { data: eventTypes } = useQuery({
    ...eventTypesQuery(),
    initialData: initialData.eventTypes,
    staleTime: 1000 * 60 * 5,
    enabled: initialData.canUsePlatform,
  });

  return {
    user,
    userLayout,
    events,
    eventTypes,
  };
}

export default function UserLayout(): JSX.Element {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const contextValue = useUserLayoutLoader();
  const { user } = contextValue;
  const subscriptionStatus = userSubscriptionStatus(user.group!);
  const canUsePlatform = isAllowedToUsePlatform(subscriptionStatus.status);
  const { t } = useTranslation();

  if (!user) {
    return <p>Loading...</p>;
  }
  const navigation: NavigationItem[] = canUsePlatform
    ? [
        {
          id: 'dashboard',
          name: t('manager.navigation.home'),
          href: 'dashboard',
          icon: HomeIcon,
        },
        {
          id: 'guardias',
          name: t('user.navigation.myShifts'),
          href: 'iterations',
          icon: CalendarIcon,
        },
        {
          id: 'stats',
          name: t('user.navigation.myStats'),
          href: 'stats',
          icon: ChartBarIcon,
        },
        {
          id: 'shiftExchange',
          name: t('generic.shiftExchange.title'),
          href: 'exchange',
          icon: ArrowPathRoundedSquareIcon,
        },
        {
          id: 'event',
          name: t('eventCenter.eventCenter'),
          href: 'event',
          icon: GlobeEuropeAfricaIcon,
        },
        {
          id: 'help',
          name: t('manager.navigation.help'),
          href: 'help',
          icon: QuestionMarkCircleIcon,
        },
        {
          id: 'manager',
          name: t('user.navigation.managerProfile'),
          href: '/manager/chains',
          icon: UserIcon,
        },
      ]
    : [
        {
          id: 'dashboard',
          name: t('manager.navigation.home'),
          href: 'dashboard',
          icon: HomeIcon,
        },
        // {
        //   id: 'help',
        //   name: t('manager.navigation.help'),
        //   href: 'help',
        //   icon: QuestionMarkCircleIcon,
        // },
        {
          id: 'manager',
          name: t('user.navigation.managerProfile'),
          href: '/manager/chains',
          icon: UserIcon,
        },
      ];

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-teal-700">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <Link to="/" className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-16 w-auto"
                      src="https://youshift-docs.s3.eu-west-3.amazonaws.com/youshift_bubbles.png"
                      alt="YouShift"
                    />
                  </Link>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigation
                      .filter(
                        item => !(!user?.is_manager && item.id === 'manager'),
                      )
                      .map(item => (
                        <NavLink
                          to={item.href}
                          key={item.name}
                          className="[&.active]:bg-teal-800 text-white hover:bg-teal-600 hover:bg-opacity-75 group flex items-center rounded-md px-2 py-2 text-base font-medium"
                        >
                          <item.icon
                            className="mr-4 h-6 w-6 flex-shrink-0 text-teal-300"
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      ))}
                  </nav>
                </div>
                <BuildingOffice2Icon className="text-white h-9" />
                <p className="text-center mb-11 font-semibold text-white text-lg">
                  {user?.org?.name}
                </p>
                <div className="flex flex-shrink-0 border-t border-teal-800 p-4">
                  <NavLink to="profile" className="group block flex-shrink-0">
                    <div className="flex items-center">
                      <div>
                        {/* <img
                            className="inline-block h-10 w-10 rounded-full"
                            src="https://media.licdn.com/dms/image/D4E03AQGa4cBZ-nwl7w/profile-displayphoto-shrink_800_800/0/1672491577663?e=1686787200&v=beta&t=TMw9_RUt5-_RhZxojvBAhbC03kSOjUti59a7GDOjuww"
                            alt=""
                          /> */}
                        <UserCircleIcon className="inline-block h-9 w-9 rounded-full bg-white text-blue-600" />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-white">{`${user?.firstname} ${user?.lastname}`}</p>
                        <p className="text-sm font-medium text-teal-200 group-hover:text-white">
                          {t('manager.navigation.seeProfile')}
                        </p>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-24 lg:flex-col group hover:w-64 transition-all duration-300 ease-in-out z-50 p-4">
        <div className="flex flex-1 flex-col bg-gradient-to-b from-teal-600 to-teal-400 rounded-xl shadow-xl">
          <div className="flex flex-shrink-0 items-center w-20 px-4 my-3">
            <Link to="/" className="flex items-center justify-start w-full">
              <img
                className="h-8 w-auto"
                src="https://youshift-docs.s3.eu-west-3.amazonaws.com/youshift_bubbles.png"
                alt="YouShift"
              />
            </Link>
          </div>
          <div className="flex min-h-0 flex-1 flex-col">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <nav className="mt-5 flex-1 space-y-1 px-2">
                {navigation
                  .filter(item => !(!user.is_manager && item.id === 'manager'))
                  .map(item => (
                    <NavLink
                      to={item.href}
                      key={item.name}
                      id={item.id}
                      className="[&.active]:bg-teal-800 text-white hover:bg-teal-600 hover:bg-opacity-75 group flex items-center rounded-md px-2 py-2 text-base font-medium transition-all duration-300 ease-in-out"
                    >
                      <item.icon
                        className="mr-4 h-6 w-6 flex-shrink-0 text-teal-300"
                        aria-hidden="true"
                      />
                      <span className="hidden group-hover:inline text-pretty">
                        {item.name}
                      </span>
                    </NavLink>
                  ))}
              </nav>
            </div>
            <div className="hidden group-hover:flex flex-col items-center">
              <BuildingOffice2Icon className="text-white h-9" />
              <p className="text-center mb-11 font-semibold text-white text-lg text-nowrap">
                {user?.org?.name}
              </p>
            </div>
            <div className="flex flex-shrink-0 border-t border-white/20 p-2 py-4">
              <NavLink
                to="profile"
                className="group block w-full flex-shrink-0"
              >
                <div className="flex items-center">
                  <div className="relative">
                    <div className="h-10 w-10 rounded-full bg-gradient-to-r from-teal-400 to-teal-300 flex items-center justify-center">
                      <span className="text-white font-medium text-lg">
                        {`${user?.firstname?.[0]}${user?.lastname?.[0]}`}
                      </span>
                    </div>
                    <div className="absolute bottom-0 right-0 h-3 w-3 rounded-full bg-green-400 border-2 border-white"></div>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white mb-0.5 text-nowrap hidden group-hover:block">{`${user?.firstname} ${user?.lastname}`}</p>
                    <p className="text-xs text-teal-200 group-hover:text-white opacity-75 hidden group-hover:block">
                      {t('manager.navigation.seeProfile')}
                    </p>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col lg:pl-24">
        <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500"
            onClick={() => setSidebarOpen(true)}
          >
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1">
          <div className="py-6">
            {/* removed this class from the div below to allow for more content max-w-7xl */}
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              {canUsePlatform &&
              contextValue.events !== null &&
              contextValue.eventTypes !== null &&
              contextValue.userLayout !== null ? (
                <UserContext.Provider value={contextValue as UserLayoutContext}>
                  <Outlet />
                </UserContext.Provider>
              ) : (
                <NoData
                  text={t('user.iterations.noItrs')}
                  description={t('user.iterations.noItrsSubtitle')}
                />
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
