import { useMemo, useEffect, useState } from 'react';
import { ShiftAssignment } from '@youshift/shared/types';
import { getUserRuleParticipation } from '@youshift/shared/utils';

import { useItrContext } from '../../../layouts/IterationRootLayout/IterationRootLayout';
import GroupStatsTable from '../../Stats/GroupStats/GroupStatsTable';
import {
  AllShiftAssignmentsGroupMap,
  buildGroupStats,
  calculateGroupPercentages,
  createSectionSlotToRulesMapping,
  loadFromStorage,
  UserGroupStats,
} from '../../Stats/utils';
import { useManagerContext } from '../../../layouts/ManagerLayout';
import { StatsFilters } from '../../Stats/StatsFilters';

export default function AssignmentsSummary() {
  const {
    users,
    allUserReqs,
    sectionSlotsDict,
    epa,
    roles,
    sectionsWithSlots,
    shiftLabels,
  } = useItrContext();
  const { counters } = useManagerContext();

  const [selectedUsers, setSelectedUsers] = useState(
    () => new Set(Object.keys(users).map(Number)),
  );

  const [selectedCounters, setSelectedCounters] = useState(
    () => new Set(Object.keys(counters).map(Number)),
  );

  const [selectedRules, setSelectedRules] = useState(
    () => new Set(Object.values(allUserReqs).map(rule => rule.rule.id_rule)),
  );

  const [selectedSections, setSelectedSections] = useState(
    () =>
      new Set(
        Object.values(sectionsWithSlots).map(
          section => section.section.id_section,
        ),
      ),
  );

  const [selectedLabels, setSelectedLabels] = useState(
    () => new Set(Object.values(shiftLabels).map(label => label.id_slot_label)),
  );

  const groupShiftAssignmentsMap = useMemo(
    () =>
      Object.entries(epa.assignmentsMap.byUser).reduce(
        (acc, [userId, assignments]) => {
          acc[Number(userId)] = assignments.reduce(
            (assignmentMap, assignment) => {
              assignmentMap[assignment.id_shift_assignment] = assignment;
              return assignmentMap;
            },
            {} as { [id_shift_assignment: number]: ShiftAssignment },
          );
          return acc;
        },
        {} as AllShiftAssignmentsGroupMap,
      ),
    [epa.assignmentsMap.byUser],
  );

  const userRuleParticipation = useMemo(
    () =>
      Object.keys(users).reduce(
        (acc, userId) => {
          acc[Number(userId)] = getUserRuleParticipation(
            allUserReqs,
            Number(userId),
          );
          return acc;
        },
        {} as Record<number, Record<number, boolean>>,
      ),
    [users, allUserReqs],
  );

  const sectionSlotToRules = useMemo(
    () => createSectionSlotToRulesMapping(allUserReqs),
    [allUserReqs],
  );

  const userPercentages = useMemo(() => {
    const userTotals: Record<
      number,
      {
        totalPositive: number;
        totalNegative: number;
        positiveRespected: number;
        negativeNotRespected: number;
      }
    > = {};

    Object.keys(users).forEach(userIdStr => {
      const userId = Number(userIdStr);
      userTotals[userId] = {
        totalPositive: 0,
        totalNegative: 0,
        positiveRespected: 0,
        negativeNotRespected: 0,
      };
    });

    Object.entries(epa.preferencesMap.byUser).forEach(
      ([userIdStr, preferences]) => {
        const userId = Number(userIdStr);
        if (!userTotals[userId]) {
          userTotals[userId] = {
            totalPositive: 0,
            totalNegative: 0,
            positiveRespected: 0,
            negativeNotRespected: 0,
          };
        }
        preferences.forEach(pref => {
          if (pref.points > 0) {
            userTotals[userId].totalPositive += pref.points;
          } else if (pref.points < 0) {
            userTotals[userId].totalNegative += Math.abs(pref.points);
          }
        });
      },
    );

    Object.entries(epa.assignmentsMap.byUser).forEach(
      ([userIdStr, assignments]) => {
        const userId = Number(userIdStr);
        assignments.forEach(assignment => {
          const sectionSlotId = assignment.id_section_slot;
          const slotPrefs =
            epa.preferencesMap.bySectionSlotbyUser[sectionSlotId];
          if (slotPrefs) {
            const pref = slotPrefs[userId];
            if (pref) {
              if (pref.points > 0) {
                userTotals[userId].positiveRespected += pref.points;
              } else if (pref.points < 0) {
                userTotals[userId].negativeNotRespected += Math.abs(
                  pref.points,
                );
              }
            }
          }
        });
      },
    );
    return userTotals;
  }, [epa, users]);

  const groupStats: UserGroupStats[] = useMemo(
    () =>
      buildGroupStats({
        allSectionSlots: sectionSlotsDict,
        allShiftAssignments: groupShiftAssignmentsMap,
        userBaseRuleParticipation: userRuleParticipation,
        labels: shiftLabels,
        rules: Object.fromEntries(
          Object.values(allUserReqs).map(rule => [
            rule.rule.id_rule,
            rule.rule,
          ]),
        ),
        sections: Object.fromEntries(
          Object.values(sectionsWithSlots).map(section => [
            section.section.id_section,
            section.section,
          ]),
        ),
        roles,
        users,
        sectionSlotToRules,
      }),
    [
      sectionSlotsDict,
      groupShiftAssignmentsMap,
      userRuleParticipation,
      sectionSlotToRules,
      roles,
      users,
      shiftLabels,
      allUserReqs,
      sectionsWithSlots,
    ],
  );

  const rulesList = Object.values(allUserReqs).map(rule => rule.rule);
  const sectionsList = Object.values(sectionsWithSlots).map(
    section => section.section,
  );
  const labelsList = Object.values(shiftLabels);

  return (
    <div className="my-3">
      <StatsFilters
        counters={counters}
        selectedCounters={selectedCounters}
        setSelectedCounters={setSelectedCounters}
        rules={rulesList}
        selectedRules={selectedRules}
        setSelectedRules={setSelectedRules}
        sections={sectionsList}
        selectedSections={selectedSections}
        setSelectedSections={setSelectedSections}
        labels={labelsList}
        selectedLabels={selectedLabels}
        setSelectedLabels={setSelectedLabels}
        users={users}
        roles={roles}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />

      <GroupStatsTable
        groupStats={groupStats}
        selectedUsers={selectedUsers}
        userPercentages={userPercentages}
        users={users}
        labelsList={labelsList}
        rulesList={rulesList}
        sectionsList={sectionsList}
        allUserReqRules={allUserReqs}
        selectedCounters={selectedCounters}
        selectedLabels={selectedLabels}
        selectedRules={selectedRules}
        selectedSections={selectedSections}
        userBaseRuleParticipation={userRuleParticipation}
      />
    </div>
  );
}
