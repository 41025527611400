import { Disclosure } from '@headlessui/react';
import {
  CheckBadgeIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import {
  Section,
  SectionSlot,
  ShiftAssignment,
  UserId,
  UserReqRule,
  UserRequirement,
  UserRequirementType,
  VirtualSlot,
} from '@youshift/shared/types';

import UserReqTable from './UserReqTable';
import {
  AssignmentCheckErrors,
  AssignmentCheckErrorType,
  CrossGroupIncompatibilityErrors,
  SingleGroupIncompatibilityErrors,
  UserAssignmentErrors,
  UserRequirementRuleErrors,
  VirtualSlotErrors,
} from '../../../utils/iteration_checks/assignment_checks/types';
import SingleIncompTable from './SingleIncompTable';
import CrossGroupTable from './CrossGroupTable';
import UserErrors from './UserErrors';
import VirtualSlotsTable from './VirtualSlotsTable';
import { UserRequirementsTable } from './UserRequirementsTable';
import {
  AssignmentCheckErrorStatus,
  AssignmentCheckToStatusMap,
  getSingleUserDisplaySeverity,
  getSingleUserErrors,
} from '../../../utils/iteration_checks/types';

type Props = {
  assignmentsForUser: ShiftAssignment[];
  errors: AssignmentCheckErrors;
  userId: UserId;
  name: string;
  sections?: Record<number, Section>;
  sectionSlots?: Record<number, SectionSlot>;
  allUserReqs: Record<number, UserReqRule>;
  userErrors?: UserAssignmentErrors[number];
  userReqsForUser?: Record<number, UserRequirement | null>;
};

function UserErrorsTableDisclosure({
  assignmentsForUser,
  errors,
  userId,
  name,
  sections,
  sectionSlots,
  userReqsForUser,
  allUserReqs,
}: Props) {
  const userAssignmentErrors = errors.user_assignments?.[Number(userId)];
  // Collect all error types from the nested structure
  const allErrors = getSingleUserErrors(errors, userId);
  const errorStatus = getSingleUserDisplaySeverity(allErrors);

  return (
    <Disclosure>
      {({ open }) => (
        <>
          {/* Title Bar */}
          <Disclosure.Button
            className={`flex justify-start gap-1 mb-2 items-center w-full px-4 py-2 text-lg font-medium text-left ${errorStatus === AssignmentCheckErrorStatus.VIOLATION ? 'bg-red-100' : errorStatus === AssignmentCheckErrorStatus.DEFICIT ? 'bg-amber-100' : 'bg-green-100'} text-gray-900 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75`}
          >
            <ChevronUpIcon
              className={`${
                open ? 'transform rotate-180' : ''
              } w-5 h-5 text-gray-500`}
            />

            {errorStatus === AssignmentCheckErrorStatus.VIOLATION ? (
              <div className="flex flex-row gap-1 justify-center items-center">
                <ExclamationCircleIcon className="w-6 h-6 text-red-600" />
              </div>
            ) : errorStatus === AssignmentCheckErrorStatus.DEFICIT ? (
              <div className="flex flex-row gap-1 justify-center items-center">
                <ExclamationTriangleIcon className="w-6 h-6 text-amber-600" />
              </div>
            ) : (
              <div className="flex flex-row gap-1 justify-center items-center">
                <CheckBadgeIcon className="w-6 h-6 text-teal-600" />
              </div>
            )}

            <span>{`${name}`}</span>
          </Disclosure.Button>

          {/* Collapsible Content */}
          <Disclosure.Panel className="text-sm text-gray-700">
            {userReqsForUser && (
              <UserRequirementsTable
                userReqsForUser={userReqsForUser}
                allUserReqs={allUserReqs}
                assignmentsForUser={assignmentsForUser}
                sectionSlots={sectionSlots}
              />
            )}
            {sections && sectionSlots && userAssignmentErrors && (
              <UserErrors
                userErrors={userAssignmentErrors}
                sectionSlots={sectionSlots}
                sections={sections}
              />
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default UserErrorsTableDisclosure;
