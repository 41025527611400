import { Menu, Popover } from '@headlessui/react';
import { ChartPieIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  groupStatsQuery,
  GroupStatsReturn,
} from '@youshift/shared/hooks/queries';
import { Rule } from '@youshift/shared/types';
import { parseIterationDates } from '@youshift/shared/utils';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLoaderData } from 'react-router-dom';

import { YSButton } from '../../../components/Buttons';
import { EmptyState } from '../../../components/EmptyState';
import Wrapper from '../../../components/Wrapper';
import { useManagerContext } from '../../../layouts/ManagerLayout';
import NoData from '../../Manager/NoData';
import { StatsFilters } from '../StatsFilters';
import {
  buildGroupStats,
  calculateGroupPercentages,
  createBaseIdMapping,
  createLatestBaseObjectMapping,
  createSectionSlotToRulesMapping,
  createShiftAssignmentsMapping,
  loadFromStorage,
  mergeIterationsData,
  participatesInWhichBaseRules,
  saveToStorage,
  toggleItrSelection,
  UserGroupStats,
} from '../utils';
import GroupStatsTable from './GroupStatsTable';

export const groupStatsLoader =
  (queryClient: QueryClient) => async (): Promise<GroupStatsReturn> => {
    const groupStats = await preLoadQuery(queryClient, groupStatsQuery());
    return groupStats;
  };

export const useGroupStatsLoader = (): GroupStatsReturn => {
  const initialData = useLoaderData() as GroupStatsReturn;
  const { data: groupStats } = useQuery({
    ...groupStatsQuery(),
    initialData,
    staleTime: 1000 * 60 * 5,
  });

  return groupStats;
};

const removeFiltersFromStorage = () => {
  const filterKeys = [
    'groupStats.selectedCounters',
    'groupStats.selectedRules',
    'groupStats.selectedSections',
    'groupStats.selectedLabels',
    'groupStats.selectedUsers',
  ];
  filterKeys.forEach(key => localStorage.removeItem(key));
};

export default function GroupStats() {
  const { t } = useTranslation();
  const groupStatsData = useGroupStatsLoader();
  const {
    counters,
    users: usersFromManagerContext,
    roles,
  } = useManagerContext();
  const users = Object.fromEntries(
    Object.entries(usersFromManagerContext).filter(
      ([_, user]) => user.is_group_member,
    ),
  );

  const [selectedChain, setSelectedChain] = useState(
    Number(Object.keys(groupStatsData)[0]),
  );

  const { chain_name, itrs } = groupStatsData[selectedChain] || {
    itrs: {},
    chain_name: '',
  };
  const [selectedItrs, setSelectedItrs] = useState(
    new Set(itrs ? Object.keys(itrs).map(Number) : []),
  );

  const {
    section_slots: allSectionSlots,
    user_req_rules: allUserReqRules,
    sections: allSections,
    slot_labels: allSlotLabels,
  } = useMemo(
    () =>
      mergeIterationsData(
        itrs,
        ['section_slots', 'user_req_rules', 'sections', 'slot_labels'],
        selectedItrs,
      ),
    [itrs, selectedItrs],
  );

  const allShiftAssignments = useMemo(
    () => createShiftAssignmentsMapping(itrs, selectedItrs),
    [itrs, selectedItrs],
  );

  const allRules = Object.values(allUserReqRules).reduce<Record<number, Rule>>(
    (acc, { rule }) => {
      acc[rule.id_rule] = rule;
      return acc;
    },
    {},
  );

  const sectionSlotToRules = useMemo(
    () => createSectionSlotToRulesMapping(allUserReqRules),
    [allUserReqRules],
  );

  // Create mappings id_object: base_id_object for sections, rules, and labels
  const sectionsBaseIds = createBaseIdMapping(
    allSections,
    'id_section',
    'base_id_section',
  );
  const rulesBaseIds = createBaseIdMapping(allRules, 'id_rule', 'base_id_rule');
  const labelsBaseIds = createBaseIdMapping(
    allSlotLabels,
    'id_slot_label',
    'base_id_slot_label',
  );

  // Groups all rules that share the same base rule ID
  // e.g., base rule 100 -> [rule1, rule2, rule3]
  const rulesWithSameBase = Object.values(allRules).reduce<
    Record<number, number[]>
  >((acc, rule) => {
    const baseId = rule.base_id_rule || rule.id_rule;
    if (!acc[baseId]) {
      acc[baseId] = [];
    }
    acc[baseId].push(rule.id_rule);
    return acc;
  }, {});

  const userBaseRuleParticipation = useMemo(() => {
    const participation: Record<number, Record<number, boolean>> = {};

    // Initialize participation map for all users
    Object.keys(users).forEach(userId => {
      const numUserId = Number(userId);
      participation[numUserId] = participatesInWhichBaseRules(
        rulesBaseIds,
        rulesWithSameBase,
        allUserReqRules,
        numUserId,
      );
    });

    return participation;
  }, [users, rulesBaseIds, rulesWithSameBase, allUserReqRules]);

  // Create mappings of base object ids (rules, sections, labels) to their most recent version
  const latestRules = createLatestBaseObjectMapping(
    allRules,
    'id_rule',
    'base_id_rule',
  );
  const latestSections = createLatestBaseObjectMapping(
    allSections,
    'id_section',
    'base_id_section',
  );
  const latestLabels = createLatestBaseObjectMapping(
    allSlotLabels,
    'id_slot_label',
    'base_id_slot_label',
  );

  const groupStats: UserGroupStats[] = useMemo(
    () =>
      buildGroupStats({
        allSectionSlots,
        allShiftAssignments,
        userBaseRuleParticipation,
        labelsBaseIds,
        labels: latestLabels,
        rules: latestRules,
        sections: latestSections,
        roles,
        rulesBaseIds,
        sectionsBaseIds,
        sectionSlotToRules,
        users,
      }),
    [
      allSectionSlots,
      allShiftAssignments,
      userBaseRuleParticipation,
      labelsBaseIds,
      latestLabels,
      latestRules,
      latestSections,
      roles,
      rulesBaseIds,
      sectionsBaseIds,
      sectionSlotToRules,
      users,
    ],
  );

  const userPercentages = calculateGroupPercentages(selectedItrs, itrs);

  // Prepare lists for headers
  const sectionsList = useMemo(
    () => Object.values(latestSections),
    [latestSections],
  );
  const rulesList = useMemo(() => Object.values(latestRules), [latestRules]);
  const labelsList = useMemo(() => Object.values(latestLabels), [latestLabels]);

  useEffect(() => {
    const storedChainId = localStorage.getItem('groupStats.selectedChain');
    if (!storedChainId) {
      // If no chain was stored yet, store it.
      localStorage.setItem(
        'groupStats.selectedChain',
        selectedChain.toString(),
      );
      return;
    }

    // If we have a stored chain but it's different from the current one:
    if (Number(storedChainId) !== selectedChain) {
      removeFiltersFromStorage();
      localStorage.setItem(
        'groupStats.selectedChain',
        selectedChain.toString(),
      );

      // Manually reset all the filter states:
      setSelectedCounters(new Set(Object.keys(counters).map(Number)));
      setSelectedRules(new Set(Object.values(allRules).map(r => r.id_rule)));
      setSelectedSections(
        new Set(Object.values(latestSections).map(s => s.id_section)),
      );
      setSelectedLabels(
        new Set(Object.values(latestLabels).map(l => l.id_slot_label)),
      );
      setSelectedUsers(new Set(Object.keys(users).map(Number)));
    }
  }, [selectedChain, counters, allRules, latestSections, latestLabels, users]);

  const [selectedCounters, setSelectedCounters] = useState(() =>
    loadFromStorage(
      'groupStats.selectedCounters',
      new Set(Object.keys(counters).map(Number)),
    ),
  );

  const [selectedRules, setSelectedRules] = useState(() =>
    loadFromStorage(
      'groupStats.selectedRules',
      new Set(Object.values(allRules).map(r => r.id_rule)),
    ),
  );

  const [selectedSections, setSelectedSections] = useState(() =>
    loadFromStorage(
      'groupStats.selectedSections',
      new Set(Object.values(latestSections).map(s => s.id_section)),
    ),
  );

  const [selectedLabels, setSelectedLabels] = useState(() =>
    loadFromStorage(
      'groupStats.selectedLabels',
      new Set(Object.values(latestLabels).map(l => l.id_slot_label)),
    ),
  );

  const [selectedUsers, setSelectedUsers] = useState(() =>
    loadFromStorage(
      'groupStats.selectedUsers',
      new Set(Object.keys(users).map(Number)),
    ),
  );

  // Add useEffect hooks to save changes
  useEffect(() => {
    saveToStorage('groupStats.selectedCounters', selectedCounters);
  }, [selectedCounters]);

  useEffect(() => {
    saveToStorage('groupStats.selectedRules', selectedRules);
  }, [selectedRules]);

  useEffect(() => {
    saveToStorage('groupStats.selectedSections', selectedSections);
  }, [selectedSections]);

  useEffect(() => {
    saveToStorage('groupStats.selectedLabels', selectedLabels);
  }, [selectedLabels]);

  useEffect(() => {
    saveToStorage('groupStats.selectedUsers', selectedUsers);
  }, [selectedUsers]);

  if (Object.keys(groupStats).length === 0) {
    return (
      <NoData
        text={t('manager.stats.emptyStatsTitle')}
        description={t('manager.stats.emptyStatsSubtitle')}
      />
    );
  }

  return (
    <Wrapper mt="mt-8">
      {/* <h1 className="text-2xl font-semibold leading-6 text-gray-900 my-4">
        {t('manager.stats.title')}
      </h1> */}

      <div className="flex justify-between">
        <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-4">
          {t('manager.stats.title')}
        </h3>
        <Link to="/manager/stats/counters">
          <YSButton>
            {Object.keys(counters).length > 0
              ? t('manager.stats.manageCounters')
              : t('manager.stats.createCounters')}
          </YSButton>
        </Link>
      </div>

      {Object.keys(itrs).length > 0 ? (
        <div className="mt-4">
          <div className="flex flex-row justify-end items-center">
            <div className="flex flex-row lg:gap-4 gap-2 mb-4">
              {Object.keys(groupStats).length > 1 && (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      {chain_name}
                      <ChevronDownIcon
                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Menu.Items className="absolute left-0 z-30 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                    <div className="py-1">
                      {Object.entries(groupStatsData).map(
                        ([idChain, chainInfo]) => (
                          <Menu.Item key={idChain}>
                            {() => (
                              <button
                                onClick={() => {
                                  setSelectedItrs(
                                    new Set(
                                      Object.keys(chainInfo.itrs).map(Number),
                                    ),
                                  );
                                  setSelectedChain(Number(idChain));
                                }}
                                className="hover:bg-gray-100 block px-4 py-2 text-sm font-medium text-gray-900 text-start"
                              >
                                {chainInfo.chain_name}
                              </button>
                            )}
                          </Menu.Item>
                        ),
                      )}
                    </div>
                  </Menu.Items>
                </Menu>
              )}
              <Popover as="div" className="relative inline-block text-left">
                <div>
                  <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    <span>{t('generic.iterations')}</span>
                    {Object.keys(itrs).length === selectedItrs.size ? (
                      <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                        {t('generic.all')}
                      </span>
                    ) : null}
                    <ChevronDownIcon
                      className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </Popover.Button>
                </div>

                <Popover.Panel className="absolute right-0 z-30 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                  <form className="space-y-4">
                    {Object.entries(itrs).map(([idItr, itrInfo]) => (
                      <div key={idItr} className="flex items-center">
                        <input
                          id={`filter-${idItr}`}
                          name={`${idItr}[]`}
                          defaultValue={idItr}
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                          checked={selectedItrs.has(Number(idItr))}
                          onChange={() =>
                            toggleItrSelection(
                              Number(idItr),
                              selectedItrs,
                              setSelectedItrs,
                            )
                          }
                        />
                        <label
                          htmlFor={`filter-${idItr}`}
                          className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                        >
                          {parseIterationDates(
                            itrInfo.itr.start_day,
                            itrInfo.itr.end_day,
                            itrInfo.itr.itr_type,
                          )}
                        </label>
                      </div>
                    ))}
                  </form>
                </Popover.Panel>
              </Popover>

              <StatsFilters
                counters={counters}
                selectedCounters={selectedCounters}
                setSelectedCounters={setSelectedCounters}
                rules={rulesList}
                selectedRules={selectedRules}
                setSelectedRules={setSelectedRules}
                sections={sectionsList}
                selectedSections={selectedSections}
                setSelectedSections={setSelectedSections}
                labels={labelsList}
                selectedLabels={selectedLabels}
                setSelectedLabels={setSelectedLabels}
                users={users}
                roles={roles}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
              />
            </div>
          </div>

          <GroupStatsTable
            groupStats={groupStats}
            selectedUsers={selectedUsers}
            users={users}
            userPercentages={userPercentages}
            sectionsList={sectionsList}
            selectedSections={selectedSections}
            allUserReqRules={allUserReqRules}
            rulesList={rulesList}
            selectedRules={selectedRules}
            labelsList={labelsList}
            selectedLabels={selectedLabels}
            selectedCounters={selectedCounters}
            userBaseRuleParticipation={userBaseRuleParticipation}
          />
        </div>
      ) : (
        <div>
          <Menu as="div" className="relative text-left mt-12 flex justify-end">
            <div>
              <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                {chain_name}
                <ChevronDownIcon
                  className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Menu.Items className="absolute left-0 z-30 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
              <div className="py-1">
                {Object.entries(groupStatsData).map(([idChain, chainInfo]) => (
                  <Menu.Item key={idChain}>
                    {() => (
                      <button
                        onClick={() => {
                          setSelectedItrs(
                            new Set(Object.keys(chainInfo.itrs).map(Number)),
                          );
                          setSelectedChain(Number(idChain));
                        }}
                        className="hover:bg-gray-100 block px-4 py-2 text-sm font-medium text-gray-900 text-start"
                      >
                        {chainInfo.chain_name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Menu>
          <EmptyState
            title={t('manager.stats.noData')}
            subtitle={t('manager.stats.noDataSubtitle')}
            Icon={ChartPieIcon}
          />
        </div>
      )}
    </Wrapper>
  );
}
