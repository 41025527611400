"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StripeSubscriptionStatus = void 0;
var StripeSubscriptionStatus;
(function (StripeSubscriptionStatus) {
    StripeSubscriptionStatus["INCOMPLETE"] = "incomplete";
    StripeSubscriptionStatus["INCOMPLETE_EXPIRED"] = "incomplete_expired";
    StripeSubscriptionStatus["ACTIVE"] = "active";
    StripeSubscriptionStatus["TRIALING"] = "trialing";
    StripeSubscriptionStatus["PAST_DUE"] = "past_due";
    StripeSubscriptionStatus["CANCELED"] = "canceled";
    StripeSubscriptionStatus["UNPAID"] = "unpaid";
    StripeSubscriptionStatus["PAUSED"] = "paused";
})(StripeSubscriptionStatus || (exports.StripeSubscriptionStatus = StripeSubscriptionStatus = {}));
