import {
  FaceSmileIcon,
  MinusIcon,
  NoSymbolIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { SpecialEventType, UserPreferenceType } from '@youshift/shared/types';
import { eventBgColor, returnColor } from '@youshift/shared/utils';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

// either we get:
// id_special_event_type only > it's an event
// preference + points: points
// preference only: it's a block
interface EventSquareProps {
  preference?: UserPreferenceType;
  points?: number;
  id_special_event_type?: number;
  eventTypes: Record<number, SpecialEventType>;
  small?: boolean;
  date?: string;
  dispatch?: (action: { type: 'UPDATE_POINTS'; points: number }) => void;
  justPoints?: boolean;
  maxPoints?: number;
  noMargin?: boolean;
}

const blockToIcon = (
  preference:
    | UserPreferenceType.JUSTIFIED_BLOCKING
    | UserPreferenceType.PERSONAL_BLOCKING,
) => {
  if (preference === UserPreferenceType.PERSONAL_BLOCKING) {
    return <FaceSmileIcon className="h-6 w-6" />;
  }
  return <NoSymbolIcon className="h-6 w-6" />;
};

function EventSquare({
  preference,
  eventTypes,
  id_special_event_type,
  small = false,
  points,
  dispatch,
  justPoints = false,
  maxPoints,
  noMargin = false,
}: EventSquareProps) {
  const bgColor = preference
    ? eventBgColor(preference, true, points, maxPoints || 50)
    : id_special_event_type
      ? returnColor(eventTypes[id_special_event_type].color)
      : undefined;
  const borderColor = preference
    ? eventBgColor(preference, false, points, maxPoints || 50)
    : id_special_event_type
      ? returnColor(eventTypes[id_special_event_type].color, 800)
      : undefined;
  const square: React.CSSProperties = {
    width: small ? '35px' : '55px',
    height: small ? '35px' : '55px',
    backgroundColor: bgColor,
    borderRadius: small ? '0.5rem' : '0.5rem',
    border: `1px solid ${borderColor}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '0.85rem',
    margin: noMargin ? '' : '0px auto',
    padding: '0px',
  };

  const changePoints = (increment: boolean) => {
    if (dispatch) {
      let ctPoints = Number(points);
      ctPoints += increment ? 1 : -1;
      dispatch({ type: 'UPDATE_POINTS', points: ctPoints });
    }
  };

  if (preference) {
    if (preference === UserPreferenceType.POINTS) {
      if (justPoints) {
        return <div style={square}>{points}</div>;
      }
      return (
        <div className="flex flex-col items-center">
          <button onClick={() => changePoints(true)}>
            <PlusIcon className="h-3 w-3" />
          </button>
          <NumericFormat
            value={points}
            valueIsNumericString
            onValueChange={values =>
              dispatch &&
              dispatch({
                type: 'UPDATE_POINTS',
                points: Number(values.value),
              })
            }
            style={square}
          />
          <button onClick={() => changePoints(false)}>
            <MinusIcon className="w-3 h-3" />
          </button>
        </div>
      );
    }
    return <div style={square}>{blockToIcon(preference)}</div>;
  }

  if (eventTypes && id_special_event_type) {
    return (
      <div style={square}>{eventTypes[id_special_event_type].acronym}</div>
    );
  }
  return <></>;
}

export default EventSquare;
