import { ColorName } from '@youshift/shared/utils';
import { useTranslation } from 'react-i18next';
import { Switch } from '@headlessui/react';

import ColorPicker from '../../../../../components/ColorPicker';

type SectionDetailsProps = {
  name: string;
  setName: (name: string) => void;
  acronym: string;
  setAcronym: (acronym: string) => void;
  setColor: (color: ColorName) => void;
  color: ColorName;
  assignedBySmartass: boolean;
  setAssignedBySmartass: (assignedBySmartass: boolean) => void;
  userCanSelfassign: boolean;
  setUserCanSelfassign: (userCanSelfassign: boolean) => void;
};

function SectionDetails({
  name,
  setName,
  acronym,
  setAcronym,
  setColor,
  color,
  assignedBySmartass,
  setAssignedBySmartass,
  userCanSelfassign,
  setUserCanSelfassign,
}: SectionDetailsProps) {
  const { t } = useTranslation();
  return (
    <div className="bg-white px-8 rounded-lg w-full mx-auto">
      <div className="mb-4">
        <label
          className="block text-gray-700 font-medium mb-1"
          htmlFor="section-name"
        >
          {t('manager.sectionsConfig.name')}
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="text"
          id="section-name"
          placeholder={t('manager.sectionsConfig.namePlaceholder')}
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 font-medium mb-1"
          htmlFor="acronym"
        >
          {t('manager.sectionsConfig.acronym')}
        </label>
        <input
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          type="text"
          id="acronym"
          maxLength={5}
          placeholder={t('manager.sectionsConfig.acronymPlaceholder')}
          value={acronym}
          onChange={e => setAcronym(e.target.value)}
        />
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 font-medium mb-1" htmlFor="color">
          {t('generic.color')}
        </label>
        <ColorPicker value={color} onChange={setColor} />
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 font-medium mb-1"
          htmlFor="userCanSelfAssign"
        >
          {t('manager.sectionsConfig.userCanSelfAssign')}
        </label>
        <Switch
          checked={userCanSelfassign}
          onChange={() => {
            setUserCanSelfassign(!userCanSelfassign);
          }}
          className={`${
            userCanSelfassign ? 'bg-teal-500' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2`}
        >
          <span
            aria-hidden="true"
            className={`${
              userCanSelfassign ? 'translate-x-5' : 'translate-x-0'
            } inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 font-medium mb-1"
          htmlFor="assignedBySmartass"
        >
          {t('manager.sectionsConfig.sectionAssignedBySmartass')}
        </label>
        <Switch
          checked={assignedBySmartass}
          onChange={() => {
            setAssignedBySmartass(!assignedBySmartass);
          }}
          className={`${
            assignedBySmartass ? 'bg-teal-500' : 'bg-gray-200'
          } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2`}
        >
          <span
            aria-hidden="true"
            className={`${
              assignedBySmartass ? 'translate-x-5' : 'translate-x-0'
            } inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
    </div>
  );
}

export default SectionDetails;
