import { Listbox, Transition } from '@headlessui/react';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import {
  useManagerDeleteUserPref,
  useManagerEditUserPref,
} from '@youshift/shared/hooks/mutations';
import {
  EventStatus,
  Section,
  SectionSlot,
  SlotLabel,
  UserPreference,
  UserPreferenceType,
} from '@youshift/shared/types';
import {
  addMonths,
  classNames,
  dateToString,
  getCustomDateRange,
  getFirstDayOfWeek,
  localeNormalizer,
  subtractMonths,
} from '@youshift/shared/utils';
import { Fragment, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-native-big-calendar';
import { useParams } from 'react-router-dom';

import EventSquare from '../../../components/Calendars/EventSquare';
import PreferencesLegend from '../../../components/Calendars/PreferencesLegend';
import SectionSlotChip from '../../../components/Calendars/SectionSlotChip';
import LabelIconComponent from '../../../components/LabelIconComponent';
import Modal from '../../../components/Modal';
import { useItrContext } from '../../../layouts/IterationRootLayout/IterationRootLayout';
import {
  CalendarEvents,
  CalendarShiftAssignments,
  CalendarUserPreference,
  customEventRenderer,
  generateCalendarEvents,
  generateEventsByDate,
  generateShiftAssignments,
  generateTimedPrefSlots,
  isCalendarShiftAssignments,
  isCalendarUserPreference,
} from '../../../utils/calendar';
import { backendToLocalDate } from '../../../utils/helpers';
import i18n from '../../../utils/i18n';
import { useManagerContext } from '../../../layouts/ManagerLayout';
import PointsBreakdown from '../../../components/PointsBreakdown';
import SectionLegend from '../../../components/Calendars/SectionLegend';
import { YSButton } from '../../../components/Buttons';
import AssignShift from '../../../components/ManualAssignment/AssignShiftModal';

export default function PerUserAssignment() {
  // Context and router hooks
  const {
    allUserReqs,
    epa,
    excludedSlotsPerUser,
    iteration,
    itrUsers,
    preferenceSlots,
    sectionsWithSlots,
    shiftLabels,
    users,
    awardedShiftAssignments,
  } = useItrContext();
  const { eventTypes } = useManagerContext();
  const { idItr } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const locale = localeNormalizer(i18n.language);

  // State
  const usersList = Object.values(users).sort((a, b) =>
    `${a.firstname} ${a.lastname}`.localeCompare(
      `${b.firstname} ${b.lastname}`,
    ),
  );
  const [calendarMonthStart, setCalendarMonthStart] = useState(
    new Date(...backendToLocalDate(iteration.start_day)),
  );
  const [prefModalOpen, setPrefModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] =
    useState<CalendarUserPreference | null>(null);
  const [selectedEventInitialState, setSelectedEventInitialState] =
    useState<CalendarUserPreference | null>(null);
  const [selectedPerson, setSelectedPerson] = useState(usersList[0].id);

  const [assignShiftOpen, setAssignShiftOpen] = useState(false);
  const [assignedShiftToDelete, setAssignedShiftToDelete] =
    useState<CalendarShiftAssignments | null>(null);

  // Mutations
  const editUserPrefMutation = useManagerEditUserPref(queryClient, {
    onSuccess: () => {
      setPrefModalOpen(false);
      setSelectedEvent(null);
      setSelectedEventInitialState(null);
      queryClient.invalidateQueries({
        queryKey: ['assignmentTool', idItr],
      });
      toast.success(t('manager.shiftAssignment.blockPreferenceUpdated'));
    },
  });

  const deleteUserPrefMutation = useManagerDeleteUserPref(queryClient, {
    onSuccess: () => {
      setPrefModalOpen(false);
      setSelectedEvent(null);
      setSelectedEventInitialState(null);
      queryClient.invalidateQueries({
        queryKey: ['assignmentTool', idItr],
      });
      toast.success(t('manager.shiftAssignment.blockPreferenceDeleted'));
    },
  });

  // Dictionary memos
  const labelsDict: Record<number, SlotLabel> = useMemo(
    () =>
      shiftLabels.reduce<Record<number, SlotLabel>>((acc, slot_label) => {
        acc[slot_label.id_slot_label] = slot_label;
        return acc;
      }, {}),
    [shiftLabels],
  );

  const sectionsDict: Record<number, Section> = useMemo(
    () =>
      sectionsWithSlots.reduce<Record<number, Section>>((acc, { section }) => {
        acc[section.id_section] = section;
        return acc;
      }, {}),
    [sectionsWithSlots],
  );

  const sectionSlotsDict: Record<number, SectionSlot> = useMemo(
    () =>
      sectionsWithSlots.reduce<Record<number, SectionSlot>>(
        (acc, { section_slots }) => ({
          ...acc,
          ...section_slots,
        }),
        {},
      ),
    [sectionsWithSlots],
  );

  // User-specific memos
  const itrUser = useMemo(
    () => itrUsers[selectedPerson],
    [itrUsers, selectedPerson],
  );

  const reqsOfUser = useMemo(
    () =>
      Object.values(allUserReqs)
        .filter(userReqRule => !!userReqRule.user_reqs[selectedPerson])
        .map(userReqRule => userReqRule.user_reqs[selectedPerson]),
    [allUserReqs, selectedPerson],
  );

  const userAssignments = useMemo(
    () => epa.assignmentsMap.byUser[selectedPerson] || [],
    [epa.assignmentsMap, selectedPerson],
  );

  const userEvents = useMemo(
    () => epa.eventsMap.byUser[selectedPerson] || [],
    [epa.eventsMap, selectedPerson],
  );

  const userPreferences = useMemo(
    () => epa.preferencesMap.byUser[selectedPerson] || [],
    [epa.preferencesMap, selectedPerson],
  ).reduce(
    (acc, pref) => {
      acc[pref.id_pref_slot] = pref;
      return acc;
    },
    {} as Record<number, UserPreference>,
  );

  // Calendar-related memos
  const calendarShiftAssignments = useMemo(
    () =>
      generateShiftAssignments(
        userAssignments,
        sectionSlotsDict,
        sectionsDict,
        labelsDict,
      ),
    [labelsDict, sectionSlotsDict, sectionsDict, userAssignments],
  );

  const calendarEvents = useMemo(
    () =>
      generateCalendarEvents(
        userEvents.filter(event => event.status === EventStatus.APPROVED),
        eventTypes,
      ),
    [userEvents, eventTypes],
  );

  const eventsByDateForUser = useMemo(
    () => generateEventsByDate(userEvents),
    [userEvents],
  );

  const filteredPrefSlots = useMemo(
    () =>
      excludedSlotsPerUser[selectedPerson]
        ? Object.fromEntries(
            Object.entries(preferenceSlots).filter(
              ([id_pref_slot, prefSlot]) =>
                !excludedSlotsPerUser[selectedPerson].includes(
                  prefSlot.id_pref_slot,
                ),
            ),
          )
        : preferenceSlots,
    [excludedSlotsPerUser, preferenceSlots, selectedPerson],
  );

  const calendarTimedPrefSlots = useMemo(
    () =>
      generateTimedPrefSlots(
        filteredPrefSlots,
        sectionSlotsDict,
        labelsDict,
        userPreferences,
        eventsByDateForUser,
        userAssignments,
      ),
    [
      eventsByDateForUser,
      filteredPrefSlots,
      labelsDict,
      sectionSlotsDict,
      userAssignments,
      userPreferences,
    ],
  );

  const justifiedBlocks = useMemo(
    () =>
      calendarTimedPrefSlots.filter(
        event =>
          isCalendarUserPreference(event) &&
          event.preference === UserPreferenceType.JUSTIFIED_BLOCKING,
      ),
    [calendarTimedPrefSlots],
  );

  // Navigation functions
  const changeSelectedPerson = (direction: 'next' | 'previous') => {
    const currentIndex = usersList.findIndex(
      person => person.id === selectedPerson,
    );
    const totalUsers = usersList.length;
    const offset = direction === 'next' ? 1 : -1;
    const newIndex = (currentIndex + offset + totalUsers) % totalUsers;
    setSelectedPerson(usersList[newIndex].id);
  };

  const goToNextMonth = () => {
    setCalendarMonthStart(prev => addMonths(prev, 1));
  };

  const goToPreviousMonth = () => {
    setCalendarMonthStart(prev => subtractMonths(prev, 1));
  };

  const daysOfItr = getCustomDateRange(
    iteration.start_day,
    iteration.end_day,
    locale,
    'table',
  );

  return (
    <>
      {prefModalOpen && selectedEvent ? (
        <Modal
          isOpen={prefModalOpen}
          onClose={() => {
            setSelectedEvent(null);
            setSelectedEventInitialState(null);
            setPrefModalOpen(false);
          }}
          editButtons
          handleSave={() => {
            editUserPrefMutation.mutate({
              id_itr: idItr,
              id_user: selectedPerson,
              id_pref_slot: selectedEvent.id_pref_slot,
              preference: selectedEvent.preference,
              justification: selectedEvent.justification || '',
              points: selectedEvent.points,
            });
          }}
          handleDelete={
            selectedEvent &&
            selectedEventInitialState &&
            selectedEventInitialState.preference
              ? () =>
                  deleteUserPrefMutation.mutate({
                    id_itr: idItr,
                    id_user: selectedPerson,
                    id_pref_slot: selectedEvent.id_pref_slot,
                  })
              : undefined
          }
          disableSave={
            selectedEvent.preference ===
              UserPreferenceType.JUSTIFIED_BLOCKING &&
            !selectedEvent.justification
          }
        >
          <h3 className="font-semibold">
            {`${t('user.preferences.addPreference')}:`}
          </h3>
          <div className="flex flex-row gap-1 items-center">
            <LabelIconComponent
              icon={selectedEvent.icon}
              className="h-5 w-5 text-teal-600"
            />
            <p>{selectedEvent.title}</p>
            <span>-</span>
            <CalendarIcon className="h-5 w-5 text-blue-600" />
            <p>{dateToString(selectedEvent.start, 'long')}</p>
          </div>
          <div className="flex sm:flex-col min-[1244px]:flex-row flex-row justify-start mt-4 mb-4 items-center">
            <Listbox
              value={selectedEvent.preference}
              onChange={(e: UserPreferenceType) =>
                setSelectedEvent(prev =>
                  prev ? { ...prev, preference: e } : null,
                )
              }
            >
              {({ open }) => (
                <div className="relative mr-2">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                    <span className="flex items-center">
                      <span className="ml-3 block">
                        {selectedEvent.preference
                          ? t(
                              `user.preferences.${selectedEvent.preference}long`,
                            )
                          : t(
                              'manager.shiftAssignment.selectPreferenceForUser',
                            )}
                      </span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {[
                        UserPreferenceType.JUSTIFIED_BLOCKING,
                        UserPreferenceType.PERSONAL_BLOCKING,
                      ].map((preferenceOption, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-blue-600 text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            )
                          }
                          value={preferenceOption}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'ml-3 block',
                                  )}
                                >
                                  {t(
                                    `user.preferences.${preferenceOption}long`,
                                  )}
                                </span>
                              </div>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-blue-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              )}
            </Listbox>
            {selectedEvent.preference ? (
              <EventSquare
                preference={selectedEvent.preference}
                eventTypes={eventTypes}
                // points={selectedEvent.points}
                small
                noMargin
              />
            ) : null}
          </div>
          {selectedEvent.preference ===
          UserPreferenceType.JUSTIFIED_BLOCKING ? (
            <div className="py-4 -mt-3">
              <h3 className="font-medium mb-2">
                {t('manager.editPreference.justifyLabel')}
              </h3>
              <input
                type="text"
                value={selectedEvent.justification || ''}
                onChange={e =>
                  setSelectedEvent(prev =>
                    prev ? { ...prev, justification: e.target.value } : null,
                  )
                }
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
              />
            </div>
          ) : null}
          <p>{t('user.preferences.prefSlotDetails')}</p>
          {selectedEvent.associatedSectionSlots
            .sort((a, b) => {
              const aStart = new Date(sectionSlotsDict[a].start).getTime();
              const bStart = new Date(sectionSlotsDict[b].start).getTime();

              // If start times are different, sort by start time
              if (aStart !== bStart) {
                return aStart - bStart;
              }

              // If start times are the same, sort by duration
              const aEnd = new Date(sectionSlotsDict[a].end).getTime();
              const bEnd = new Date(sectionSlotsDict[b].end).getTime();

              return aEnd - bEnd; // Shorter duration first
            })
            .map(sectionSlotId => {
              const slot = sectionSlotsDict[sectionSlotId];
              return (
                <SectionSlotChip
                  sectionName={sectionsDict[slot.id_section].name}
                  start={slot.start}
                  end={slot.end}
                />
              );
            })}
        </Modal>
      ) : null}
      {assignShiftOpen && (
        <AssignShift
          open={assignShiftOpen}
          setOpen={setAssignShiftOpen}
          initialParticipant={selectedPerson}
          initialDay={
            assignedShiftToDelete?.start
              ? new Date(assignedShiftToDelete.start)
              : null
          }
          initialSlot={assignedShiftToDelete?.id_section_slot ?? null}
          users={users}
          days={daysOfItr}
          sectionSlots={sectionSlotsDict}
          sections={sectionsDict}
        />
      )}
      <div className="flex flex-row items-center justify-center gap-2 mb-2 mt-4">
        <button onClick={() => changeSelectedPerson('previous')}>
          <ArrowLeftIcon className="h-5 w-5 text-blue-600" />
        </button>
        <select
          id="week"
          name="week"
          className="block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
          value={selectedPerson}
          onChange={e => setSelectedPerson(parseInt(e.target.value, 10))}
        >
          {usersList.map(user => (
            <option key={user.id} value={user.id}>
              {`${user.firstname} ${user.lastname}`}
            </option>
          ))}
        </select>
        <button onClick={() => changeSelectedPerson('next')}>
          <ArrowRightIcon className="h-5 w-5 text-blue-600" />
        </button>
      </div>
      <section className="mb-2 mt-2">
        <label className="relative">
          <input type="checkbox" className="peer/showLabel absolute scale-0" />
          <span className="flex peer-checked/showLabel:hidden">
            <ChevronDownIcon className="absolute right-2 w-10 p-2 text-white cursor-pointer" />
          </span>
          <span className="hidden peer-checked/showLabel:flex">
            <ChevronUpIcon className="absolute right-2 w-10 p-2 text-white cursor-pointer" />
          </span>
          <span className="block max-h-10 max-w-100 overflow-hidden rounded-lg py-0 text-white font-bold  shadow-md transition-all duration-300 peer-checked/showLabel:max-h-fit">
            <h3 className="flex h-10 cursor-pointer items-center px-4 bg-blue-600">
              {t('manager.shiftAssignment.userConfiguration', {
                name: users[selectedPerson].firstname,
              })}
            </h3>
            <div className="shadow ring-1 px-4 ring-black ring-opacity-5 flex flex-auto flex-col my-3 h-100">
              <div className="grid grid-cols-5 gap-px border-b border-gray-300 bg-gray-200 text-xs leading-6 font-semibold text-gray-700 lg:flex-none">
                <div className="bg-white p-2">
                  {t('user.preferences.shift')}
                </div>
                <div className="bg-white p-2">
                  {t('user.preferences.minShift')}
                </div>
                <div className="bg-white p-2">
                  {t('user.preferences.maxShift')}
                </div>
                <div className="bg-white p-2">
                  {t('user.preferences.minDuration')}
                </div>
                <div className="bg-white p-2">
                  {t('user.preferences.maxDuration')}
                </div>
              </div>

              {reqsOfUser.map(req => (
                <div className="grid grid-cols-5 bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                  <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                    {allUserReqs[req.id_rule].rule.name}
                  </div>
                  <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                    {req.min_slots}
                  </div>
                  <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                    {req.max_slots}
                  </div>
                  <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                    {req.min_duration}
                  </div>
                  <div className="bg-white text-gray-500 relative py-2 px-3 flex flex-col justify-around">
                    {req.max_duration}
                  </div>
                </div>
              ))}
            </div>
          </span>
        </label>
      </section>
      <PointsBreakdown
        itrUser={itrUser}
        awardedShiftAssignments={awardedShiftAssignments[selectedPerson] || {}}
        userPrefs={userPreferences}
        shiftAssignments={userAssignments}
        prefSlots={filteredPrefSlots}
        maxSavedPointsAllowed={iteration.chain?.max_saved_points_allowed || 0}
        manager
      />
      {/* <div className="flex flex-row justify-between items-center mb-1">
        <div className="flex flex-row gap-1">
          <button
            onClick={goToPreviousMonth}
            className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
            aria-label="previous-week"
          >
            <ChevronLeftIcon className="w-4 h-4" />
          </button>
          <button
            aria-label="next-week"
            onClick={goToNextMonth}
            className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
          >
            <ChevronRightIcon className="w-4 h-4" />
          </button>
          <p className="text-md font-semibold text-blue-600">
            {dateToString(calendarMonthStart, 'month-year')}
          </p>
        </div>
        <PreferencesLegend />
      </div> */}
      <div className="mb-2 flex flex-row justify-between">
        <PreferencesLegend />
        <SectionLegend sections={Object.values(sectionsDict)} />
      </div>
      <div className="flex flex-row justify-between items-center mb-2">
        <div className="flex flex-row gap-1">
          <button
            onClick={goToPreviousMonth}
            className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
            aria-label="previous-week"
          >
            <ChevronLeftIcon className="w-4 h-4" />
          </button>
          <button
            aria-label="next-week"
            onClick={goToNextMonth}
            className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
          >
            <ChevronRightIcon className="w-4 h-4" />
          </button>
          <p className="text-md font-semibold text-blue-600">
            {dateToString(calendarMonthStart, 'month-year')}
          </p>
        </div>
        <YSButton
          type="button"
          variant="outline-primary"
          classNames="flex flex-row items-center gap-0.5"
          onClick={() => {
            setAssignedShiftToDelete(null);
            setAssignShiftOpen(true);
          }}
        >
          <PlusIcon className="w-4 h-4" />
          {t('manager.shiftAssignment.assignShiftTo', {
            name: users[selectedPerson].firstname,
          })}
        </YSButton>
      </div>
      <div className="flex flex-col">
        <Calendar
          locale={locale}
          events={[
            ...calendarShiftAssignments,
            ...calendarEvents,
            ...calendarTimedPrefSlots,
          ]}
          mode="month"
          height={600}
          maxVisibleEventCount={10}
          onPressEvent={(event: CalendarEvents) => {
            if (
              isCalendarUserPreference(event) &&
              event.preference !== UserPreferenceType.POINTS
            ) {
              setPrefModalOpen(true);
              setSelectedEvent(event);
              setSelectedEventInitialState(event);
            } else if (isCalendarShiftAssignments(event)) {
              setAssignedShiftToDelete(event);
              setAssignShiftOpen(true);
            }
          }}
          date={calendarMonthStart}
          weekStartsOn={getFirstDayOfWeek(locale) === 7 ? 0 : 1}
          renderEvent={customEventRenderer}
        />
      </div>

      {justifiedBlocks.length > 0 && (
        <div className="mt-3">
          <h3 className="text-lg font-medium mb-2 flex flex-row items-center gap-2">
            <div className="bg-sky-300 rounded-full h-5 w-5" />
            {t('user.preferences.justifiedBlocks')}
          </h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                    {t('generic.date')}
                  </th>
                  <th className="px-6 py-3 text-left text-sm font-semibold text-gray-900">
                    {t('generic.justification')}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {justifiedBlocks
                  .sort((a, b) => a.start.getTime() - b.start.getTime())
                  .map((block, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {dateToString(block.start, 'weekday', locale)}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {block.justification}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
