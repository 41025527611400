import { Popover } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import {
  Rule,
  Section,
  SlotLabel,
  User,
  UserRole,
} from '@youshift/shared/types';

import UsersFilterDropdown from '../../FilterDropdownWithSubgroups';

type FilterItem = {
  id: number;
  name: string;
  acronym?: string;
};

type FilterProps<T extends FilterItem> = {
  items: T[];
  selected: Set<number>;
  onSelectionChange: (newSelection: Set<number>) => void;
  label: string;
  getName?: (item: T) => string;
};

function Filter<T extends FilterItem>({
  items,
  selected,
  onSelectionChange,
  label,
  getName = item => item.name,
}: FilterProps<T>) {
  const { t } = useTranslation();

  const handleChange = (itemId: number) => {
    const newSelection = new Set(selected);
    if (newSelection.has(itemId)) {
      newSelection.delete(itemId);
    } else {
      newSelection.add(itemId);
    }
    onSelectionChange(newSelection);
  };

  return (
    <Popover as="div" className="relative inline-block text-left">
      <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
        <span>{label}</span>
        {items.length === selected.size && (
          <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
            {t('generic.all')}
          </span>
        )}
        <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
      </Popover.Button>

      <Popover.Panel className="absolute right-0 z-30 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
        <form className="space-y-4">
          {items.map(item => (
            <div key={item.id} className="flex items-center">
              <input
                type="checkbox"
                checked={selected.has(item.id)}
                onChange={() => handleChange(item.id)}
                className="h-4 w-4 rounded border-gray-300 text-blue-600"
              />
              <label className="ml-3 text-sm font-medium text-gray-900">
                {getName(item)}
              </label>
            </div>
          ))}
        </form>
      </Popover.Panel>
    </Popover>
  );
}

type FiltersProps = {
  counters: Record<number, { id_custom_counter: number; name: string }>;
  selectedCounters: Set<number>;
  setSelectedCounters: (selection: Set<number>) => void;

  rules: Rule[];
  selectedRules: Set<number>;
  setSelectedRules: (selection: Set<number>) => void;

  sections: Section[];
  selectedSections: Set<number>;
  setSelectedSections: (selection: Set<number>) => void;

  labels: SlotLabel[];
  selectedLabels: Set<number>;
  setSelectedLabels: (selection: Set<number>) => void;

  users: Record<string, User>;
  roles: Record<number, UserRole>;
  selectedUsers: Set<number>;
  setSelectedUsers: (selection: Set<number>) => void;
};

export function StatsFilters({
  counters,
  selectedCounters,
  setSelectedCounters,
  rules,
  selectedRules,
  setSelectedRules,
  sections,
  selectedSections,
  setSelectedSections,
  labels,
  selectedLabels,
  setSelectedLabels,
  users,
  roles,
  selectedUsers,
  setSelectedUsers,
}: FiltersProps) {
  const { t } = useTranslation();

  const countersArray = Object.values(counters).map(counter => ({
    id: counter.id_custom_counter,
    name: counter.name,
  }));

  const rulesArray = rules.map(rule => ({
    id: rule.id_rule,
    name: rule.name,
  }));

  const sectionsArray = sections.map(section => ({
    id: section.id_section,
    name: section.name,
    acronym: section.acronym,
  }));

  const labelsArray = labels.map(label => ({
    id: label.id_slot_label,
    name: label.name,
  }));

  return (
    <div className="flex flex-row lg:gap-4 gap-2 mb-4">
      <Filter
        items={sectionsArray}
        selected={selectedSections}
        onSelectionChange={setSelectedSections}
        label={t('generic.sections')}
        getName={item => item.acronym || item.name}
      />

      <Filter
        items={rulesArray}
        selected={selectedRules}
        onSelectionChange={setSelectedRules}
        label={t('generic.rules')}
      />

      <Filter
        items={labelsArray}
        selected={selectedLabels}
        onSelectionChange={setSelectedLabels}
        label={t('generic.labels')}
      />

      <Filter
        items={countersArray}
        selected={selectedCounters}
        onSelectionChange={setSelectedCounters}
        label={t('generic.counters')}
      />

      <UsersFilterDropdown
        users={users}
        roles={roles}
        selectedUsers={selectedUsers}
        onSelectionChange={setSelectedUsers}
      />
    </div>
  );
}
