"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// English [en]
// We don't need weekdaysShort, weekdaysMin, monthsShort in en.js locale
exports.default = {
    name: 'en',
    weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
    months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
    ordinal: function (n) {
        var s = ['th', 'st', 'nd', 'rd'];
        var v = n % 100;
        return "[".concat(n).concat((s[(v - 20) % 10] || s[v] || s[0]), "]");
    }
};
