import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from '../../assets/Logo/logo_lq.png';

const social = [
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/you-shift/',
    icon: props => (
      <svg
        fill="currentColor"
        viewBox="0 0 24 24"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
      >
        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
      </svg>
    ),
  },
  {
    name: 'Twitter',
    href: 'https://twitter.com/youshift_',
    icon: props => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
  {
    name: 'YouTube',
    href: '#',
    icon: props => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
];

export default function Footer() {
  const { t } = useTranslation();

  const legal = [
    {
      text: t('landing.footer.legal'),
      href: '/legal',
    },
    {
      text: t('landing.footer.cookies'),
      href: '/cookies',
    },
    {
      text: t('landing.footer.privacy'),
      href: '/privacy',
    },
  ];

  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl md:gap-8 py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center items-center space-x-6 md:order-2">
          {legal.map(item => (
            <Link
              to={item.href}
              className="text-xs text-gray-400 hover:text-gray-500"
            >
              {item.text}
            </Link>
          ))}

          {social.map(item => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:order-1 md:mt-0 flex flex-row items-center">
          <img className="h-8 w-auto mr-6" src={logo} alt="" />
          <p className="text-center text-xs leading-5 text-gray-500">
            &copy; 2024 YouShift Corp. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
