import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { RuleTypes, UserRequirementType } from '@youshift/shared/types';

import Toggle from '../../../../components/Toggle';
import { MinMaxCounter } from '../../../../components/MinMaxCounter';
import { Action, RoleConfig, State } from './types';

interface DefineSingleUserReqsProps {
  state: State;
  dispatch: React.Dispatch<Action>;
  selectedUser?: number;
}

export default function DefineSingleUserReqs({
  state,
  dispatch,
  selectedUser,
}: DefineSingleUserReqsProps) {
  const { t } = useTranslation();

  return (
    <div>
      {Object.entries(state.roles).map(([roleId, { users }], idx) => {
        // Filter users if selectedUser is provided
        const displayUsers = selectedUser
          ? users.filter(user => user.id_user === selectedUser)
          : users;

        // Skip rendering this role group if no matching users
        if (displayUsers.length === 0) return null;

        const user = displayUsers[0];
        return (
          <div className="flex flex-row items-center gap-4">
            <div>
              {state.req_type === UserRequirementType.SLOTS && (
                <MinMaxCounter
                  min={user.min_slots}
                  max={user.max_slots}
                  setMin={value =>
                    dispatch({
                      type: 'UPDATE_USER_REQS',
                      id_user: user.id_user,
                      payload: { min_slots: value },
                    })
                  }
                  disabled={!user.included}
                  setMax={value =>
                    dispatch({
                      type: 'UPDATE_USER_REQS',
                      id_user: user.id_user,
                      payload: { max_slots: value },
                    })
                  }
                  minText={t('manager.rulesConfig.minText')}
                  maxText={t('manager.rulesConfig.maxText')}
                />
              )}
              {state.req_type === UserRequirementType.DURATION && (
                <MinMaxCounter
                  min={user.min_duration}
                  max={user.max_duration}
                  disabled={!user.included}
                  setMin={value =>
                    dispatch({
                      type: 'UPDATE_USER_REQS',
                      id_user: user.id_user,
                      payload: { min_duration: value },
                    })
                  }
                  setMax={value =>
                    dispatch({
                      type: 'UPDATE_USER_REQS',
                      id_user: user.id_user,
                      payload: { max_duration: value },
                    })
                  }
                  minText={t('manager.rulesConfig.minHText')}
                  maxText={t('manager.rulesConfig.maxHText')}
                />
              )}
            </div>
            <fieldset>
              <div className="flex flex-col gap-1 mt-3">
                <div className="flex items-center">
                  <input
                    id="slots"
                    name="config-type"
                    type="radio"
                    checked={state.req_type === 'SLOTS'}
                    onChange={() =>
                      dispatch({
                        type: 'TOGGLE_REQ_TYPE',
                        value: UserRequirementType.SLOTS,
                      })
                    }
                    className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-teal-600 checked:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                  />
                  <label
                    htmlFor="slots"
                    className="ml-3 block text-sm/6 font-medium text-gray-900"
                  >
                    {t('manager.rulesConfig.slotsConfig')}
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="hours"
                    name="config-type"
                    type="radio"
                    checked={state.req_type === 'DURATION'}
                    onChange={() =>
                      dispatch({
                        type: 'TOGGLE_REQ_TYPE',
                        value: UserRequirementType.DURATION,
                      })
                    }
                    className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-teal-600 checked:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                  />
                  <label
                    htmlFor="hours"
                    className="ml-3 block text-sm/6 font-medium text-gray-900"
                  >
                    {t('manager.rulesConfig.hoursConfig')}
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
        );
      })}
    </div>
  );
}
