import { Combobox, Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import {
  MinusCircleIcon,
  PlusCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAssignSlotMutation,
  useUnassignSlotMutation,
} from '@youshift/shared/hooks/mutations';
import type { Section, SectionSlot, User } from '@youshift/shared/types';
import {
  dateToString,
  formatUserName,
  areDatesOnTheSameDay,
} from '@youshift/shared/utils';
import { CSSProperties, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';

import { YSButton } from '../Buttons';
import SectionSlotChip from '../Calendars/SectionSlotChip';

interface AssignShiftProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  initialParticipant: number | null;
  initialDay: Date | null;
  initialSlot: number | null;
  users: Record<number, User>;
  days: Date[];
  sectionSlots: Record<number, SectionSlot>;
  sections: Record<number, Section>;
}

export default function AssignShift({
  open,
  setOpen,
  initialParticipant,
  initialDay,
  initialSlot,
  users,
  days,
  sectionSlots,
  sections,
}: AssignShiftProps) {
  const [rewards, setRewards] = useState<number>(0);

  const [selectedParticipant, setSelectedParticipant] = useState<number | null>(
    initialParticipant,
  );
  const [selectedDay, setSelectedDay] = useState<Date | null>(initialDay);
  const [selectedSlot, setSelectedSlot] = useState<number | null>(initialSlot);

  const { idItr } = useParams();

  const classNames = (...classes: string[]) =>
    classes.filter(Boolean).join(' ');

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const assignSlotMutation = useAssignSlotMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shiftAssignments', idItr] });
      setOpen(false);
    },
  });

  const unassignSlotMutation = useUnassignSlotMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['shiftAssignments', idItr] });
      setOpen(false);
    },
  });

  const square: CSSProperties = {
    width: '40px',
    height: '40px',
    backgroundColor:
      rewards >= 0 ? 'rgba(0, 157, 113, 0.2)' : 'rgba(255, 48, 48, 0.2)',
    borderRadius: '0.5rem',
    border: `1px solid ${rewards >= 0 ? 'rgba(0, 157, 113, 1)' : 'rgba(255, 48, 48, 1)'}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '0.85rem',
    margin: '0px auto',
    padding: '0px',
  };

  const [query, setQuery] = useState('');
  // TODO remove users that are already assigned that day?
  const filteredData =
    query === ''
      ? Object.values(users)
      : Object.values(users)
          .sort((userA, userB) =>
            formatUserName(userA, false).localeCompare(
              formatUserName(userB, false),
            ),
          )
          .filter(el =>
            formatUserName(el, false)
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')),
          );

  const assigning = !(initialDay && initialParticipant && initialSlot);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-center">
                  <div
                    className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${!assigning ? 'bg-red-100' : 'bg-blue-100'} sm:mx-0 sm:h-10 sm:w-10`}
                  >
                    {!assigning ? (
                      <MinusCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    ) : (
                      <PlusCircleIcon
                        className="h-6 w-6 text-blue-600"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {!assigning
                        ? t('manager.shiftAssignment.eliminateShift')
                        : t('manager.shiftAssignment.assignShift')}
                    </Dialog.Title>
                  </div>
                </div>
                {/* Participant */}
                <div className="my-5 flex flex-row items-start gap-6">
                  <h2 className="font-semibold">
                    {t('manager.shiftAssignment.selectParticipant')}
                  </h2>
                  <div className="items-center">
                    <Combobox
                      value={selectedParticipant}
                      onChange={setSelectedParticipant}
                      disabled={initialParticipant !== null}
                    >
                      <div className="relative mt-1">
                        <div
                          className={classNames(
                            selectedParticipant ? 'bg-gray-200' : 'bg-white',
                            'relative cursor-default rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm',
                          )}
                        >
                          {/* Selected User */}
                          {selectedParticipant ? (
                            <Combobox.Button
                              as="p"
                              className="cursor-pointer truncate"
                            >
                              {formatUserName(users[selectedParticipant], true)}
                            </Combobox.Button>
                          ) : (
                            // Input for Search Query
                            <Combobox.Input
                              className="w-full border-none pl-0.5 py-0.5 leading-5 text-gray-900 focus:ring-0 sm:text-sm"
                              displayValue={(user: User | null) =>
                                user ? formatUserName(user, true) : ''
                              }
                              onChange={event => {
                                setQuery(event.target.value);
                              }}
                            />
                          )}
                          {/* Dropdown Icon */}
                          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </Combobox.Button>
                        </div>
                        {/* Dropdown Options */}
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                          afterLeave={() => setQuery('')}
                        >
                          <Combobox.Options className="absolute z-20 mt-1 max-h-60 min-w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                            {filteredData.length === 0 && query !== '' ? (
                              <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                                {t('generic.noResults')}
                              </div>
                            ) : (
                              filteredData.map(el => (
                                <Combobox.Option
                                  key={el.id}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-3 pr-10 ${
                                      active
                                        ? 'bg-blue-100 text-blue-900'
                                        : 'text-gray-900'
                                    }`
                                  }
                                  value={el.id}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block truncate ${
                                          selected
                                            ? 'font-medium'
                                            : 'font-normal'
                                        }`}
                                      >
                                        {formatUserName(el, true)}
                                      </span>
                                      {selected ? (
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Combobox.Option>
                              ))
                            )}
                          </Combobox.Options>
                        </Transition>
                      </div>
                    </Combobox>
                  </div>
                </div>
                {/* Day */}
                <div className="my-5 flex flex-row items-start gap-6">
                  <h2 className="font-semibold">
                    {t('manager.shiftAssignment.selectDay')}
                  </h2>
                  <div className="items-center">
                    <Listbox
                      value={selectedDay}
                      onChange={setSelectedDay}
                      disabled={initialDay !== null}
                    >
                      <div className="relative">
                        <Listbox.Button
                          className={classNames(
                            selectedDay ? 'bg-gray-200' : 'bg-white',
                            'relative cursor-default rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm',
                          )}
                        >
                          <span className="block truncate">
                            {selectedDay
                              ? dateToString(selectedDay, 'weekday')
                              : '-'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {days.map(day => (
                              <Listbox.Option
                                key={dateToString(day, 'dd/mm/yyyy')}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'}`
                                }
                                value={day}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                    >
                                      {dateToString(day, 'weekday')}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>
                {/* Slot */}
                <div className="my-5 flex flex-row items-start gap-6">
                  <h2 className="font-semibold">
                    {t('manager.shiftAssignment.selectSectionSlot')}
                  </h2>
                  <div className="items-center">
                    <Listbox
                      value={selectedSlot}
                      onChange={setSelectedSlot}
                      disabled={initialSlot !== null || !selectedDay}
                    >
                      <div className="relative">
                        <Listbox.Button
                          className={classNames(
                            selectedSlot ? 'bg-gray-200' : 'bg-white',
                            'relative cursor-default rounded-lg py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm',
                          )}
                        >
                          <span className="block truncate">
                            {selectedSlot ? (
                              <SectionSlotChip
                                sectionName={
                                  sections[
                                    sectionSlots[selectedSlot]?.id_section
                                  ]?.name
                                }
                                start={sectionSlots[selectedSlot]?.start}
                                end={sectionSlots[selectedSlot]?.end}
                                small
                              />
                            ) : (
                              '-'
                            )}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="mt-1 max-h-x60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {selectedDay &&
                              Object.values(sectionSlots)
                                .filter(slotOption =>
                                  areDatesOnTheSameDay(
                                    slotOption.start,
                                    selectedDay,
                                  ),
                                )
                                .map(slot => (
                                  <Listbox.Option
                                    key={slot.id_section_slot}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'}`
                                    }
                                    value={slot.id_section_slot}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                        >
                                          <SectionSlotChip
                                            sectionName={
                                              sections[slot.id_section]?.name
                                            }
                                            start={slot.start}
                                            end={slot.end}
                                            small
                                          />
                                        </span>
                                        {selected ? (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>
                {/* Rewards */}
                {selectedDay &&
                selectedParticipant &&
                selectedSlot &&
                assigning ? (
                  <div className="my-5 flex flex-row start gap-6">
                    <h2 className="font-semibold">
                      {t('manager.shiftAssignment.selectRewards')}
                    </h2>
                    <div className="items-center">
                      <div className="relative">
                        <NumericFormat
                          value={rewards}
                          valueIsNumericString
                          onValueChange={values =>
                            setRewards(Number(values.value))
                          }
                          style={square}
                          allowNegative={false}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="mt-5 sm:mt-4 flex gap-4">
                  {assigning ? (
                    <YSButton
                      type="button"
                      onClick={() => {
                        assignSlotMutation.mutate({
                          id_itr: Number(idItr),
                          id_section_slot: selectedSlot,
                          id_user: selectedParticipant,
                          point_award: rewards,
                        });
                      }}
                      disabled={
                        !selectedParticipant || !selectedDay || !selectedSlot
                      }
                    >
                      {t('manager.shiftAssignment.assign')}
                    </YSButton>
                  ) : (
                    <YSButton
                      type="button"
                      variant="delete"
                      onClick={() => {
                        unassignSlotMutation.mutate({
                          id_itr: Number(idItr),
                          id_section_slot: selectedSlot,
                          id_user: selectedParticipant,
                        });
                      }}
                    >
                      {t('generic.eliminate')}
                    </YSButton>
                  )}
                  <YSButton
                    type="button"
                    variant="ghost-primary"
                    onClick={() => setOpen(false)}
                  >
                    {t('generic.cancel')}
                  </YSButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
