interface ConstraintValueProps {
  label: string;
  value: string | number;
}

export default function ConstraintValue({
  label,
  value,
}: ConstraintValueProps) {
  return (
    <span className="inline-flex items-center gap-1 bg-gray-100 px-2 py-0.5 rounded text-sm">
      <span className="font-medium">{label}</span>
      {value}
    </span>
  );
}
