import { getShadeMap } from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ItrStatuses } from '@youshift/shared/types';
import {
  ArrowsRightLeftIcon,
  ArrowsUpDownIcon,
} from '@heroicons/react/24/outline';

import UsersFilterDropdown from '../../../FilterDropdownWithSubgroups';
import ShiftLabelLegend from '../../../components/Calendars/ShiftLabelLegend';
import FilterDropdown from '../../../components/FilterDropdown';
import { Virgueria } from '../../../components/ManualAssignment/Virgueria';
import {
  VirgueriaLayoutMode,
  VirgueriaVersion,
} from '../../../components/ManualAssignment/types';
import Wrapper from '../../../components/Wrapper';
import { useItrContext } from '../../../layouts/IterationRootLayout/IterationRootLayout';
import { VerticalVirgueria } from '../../../components/ManualAssignment/VerticalVirgueria';

export default function ManualAssignment() {
  const { t } = useTranslation();
  const {
    itrUsers,
    sectionsWithSlots,
    shiftLabels,
    iteration,
    users,
    roles,
    status,
    epa,
  } = useItrContext();

  const [selectedPeople, setSelectedPeople] = useState(
    new Set(Object.keys(itrUsers).map(Number)),
  );
  const [selectedSections, setSelectedSections] = useState(
    new Set(sectionsWithSlots.map(({ section }) => String(section.id_section))),
  );
  const [showRestPeriod, setShowRestPeriod] = useState(false);

  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);

  const [virgueriaLayout, setVirgueriaLayout] = useState<VirgueriaLayoutMode>(
    VirgueriaLayoutMode.HORIZONTAL,
  );

  return (
    <Wrapper mt="mt-8">
      <div className="flex flex-row gap-1 flex-wrap justify-between">
        <ShiftLabelLegend labels={shiftLabels} />
        <div className="flex flex-row gap-2 lg:gap-4 items-center">
          <div className="flex items-center pr-2 lg:pr-4 border-r border-r-gray-400">
            <input
              id="show-libranza"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              checked={showRestPeriod}
              onChange={() => setShowRestPeriod(!showRestPeriod)}
            />
            <label
              htmlFor="show-libranza"
              className="ml-3 text-sm font-medium text-gray-900"
            >
              {t('manager.iterationVerification.showRestPeriod')}
            </label>
          </div>

          <div className="pr-2 lg:pr-4 border-r border-r-gray-400">
            <UsersFilterDropdown
              users={users}
              roles={roles}
              selectedUsers={selectedPeople}
              onSelectionChange={setSelectedPeople}
            />
          </div>
          <FilterDropdown
            label={t('generic.sections')}
            items={sectionsWithSlots.map(({ section }) => ({
              id: String(section.id_section),
              name: section.name,
            }))}
            selectedItems={selectedSections}
            onSelectionChange={setSelectedSections}
          />
          <div className="flex gap-2">
            <button
              type="button"
              onClick={() => setVirgueriaLayout(VirgueriaLayoutMode.HORIZONTAL)}
              className={`p-2 rounded-md ${
                virgueriaLayout === VirgueriaLayoutMode.HORIZONTAL
                  ? 'bg-gray-200'
                  : 'bg-gray-100 hover:bg-gray-200'
              }`}
            >
              <ArrowsRightLeftIcon
                className={`w-4 h-4 ${
                  virgueriaLayout === VirgueriaLayoutMode.HORIZONTAL
                    ? 'text-gray-800'
                    : 'text-gray-400'
                }`}
              />
            </button>
            <button
              type="button"
              onClick={() => setVirgueriaLayout(VirgueriaLayoutMode.VERTICAL)}
              className={`p-2 rounded-md ${
                virgueriaLayout === VirgueriaLayoutMode.VERTICAL
                  ? 'bg-gray-200'
                  : 'bg-gray-100 hover:bg-gray-200'
              }`}
            >
              <ArrowsUpDownIcon
                className={`w-4 h-4 ${
                  virgueriaLayout === VirgueriaLayoutMode.VERTICAL
                    ? 'text-gray-800'
                    : 'text-gray-400'
                }`}
              />
            </button>
          </div>
        </div>
      </div>
      {virgueriaLayout === VirgueriaLayoutMode.HORIZONTAL ? (
        <Virgueria
          start={iteration.start_day}
          end={iteration.end_day}
          sectionsWithSlots={sectionsWithSlots.filter(({ section }) =>
            selectedSections.has(String(section.id_section)),
          )}
          shadeMap={shadeMap}
          version={VirgueriaVersion.Virgueria}
          epa={epa}
          users={users}
          labels={shiftLabels}
          showRestPeriod={showRestPeriod}
          selectedPeople={selectedPeople}
        />
      ) : (
        <VerticalVirgueria
          start={iteration.start_day}
          end={iteration.end_day}
          sectionsWithSlots={sectionsWithSlots.filter(({ section }) =>
            selectedSections.has(String(section.id_section)),
          )}
          shadeMap={shadeMap}
          epa={epa}
          users={users}
          labels={shiftLabels}
          showRestPeriod={showRestPeriod}
          selectedPeople={selectedPeople}
        />
      )}
    </Wrapper>
  );
}
