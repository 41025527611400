import {
  Rule,
  Section,
  SlotLabel,
  User,
  UserReqRule,
} from '@youshift/shared/types';
import {
  getFirstDayOfWeek,
  getWeekdayInitials,
  localeNormalizer,
  returnColor,
} from '@youshift/shared/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LabelIconComponent from '../../../components/LabelIconComponent';
import { useManagerContext } from '../../../layouts/ManagerLayout';
import {
  calculateNegativePercentage,
  calculatePositivePercentage,
  UserGroupStats,
} from '../utils';
import i18n from '../../../utils/i18n';

interface GroupStatsTableProps {
  groupStats: UserGroupStats[];
  labelsList: SlotLabel[];
  rulesList: Rule[];
  sectionsList: Section[];
  selectedCounters: Set<number>;
  selectedLabels: Set<number>;
  selectedRules: Set<number>;
  selectedSections: Set<number>;
  selectedUsers: Set<number>;
  users: Record<string, User>;
  allUserReqRules: Record<number, UserReqRule>;
  userPercentages: Record<
    number,
    {
      totalPositive: number;
      positiveRespected: number;
      totalNegative: number;
      negativeNotRespected: number;
    }
  >;
  userBaseRuleParticipation: Record<number, Record<number, boolean>>;
}

export default function GroupStatsTable({
  groupStats,
  selectedUsers,
  users,
  userPercentages,
  sectionsList,
  selectedSections,
  rulesList,
  selectedRules,
  labelsList,
  selectedLabels,
  allUserReqRules,
  selectedCounters,
  userBaseRuleParticipation,
}: GroupStatsTableProps) {
  const { t } = useTranslation();
  const { counters } = useManagerContext();

  const locale = localeNormalizer(i18n.language);
  const days = {
    0: t('calendars.su'),
    1: t('calendars.mo'),
    2: t('calendars.tu'),
    3: t('calendars.we'),
    4: t('calendars.th'),
    5: t('calendars.fr'),
    6: t('calendars.sa'),
  };
  const firstDay = getFirstDayOfWeek(locale);
  const daysOfWeek =
    firstDay === 7
      ? Object.entries(days)
      : Object.entries(days).slice(1).concat(Object.entries(days).slice(0, 1));

  return (
    <div className="relative">
      <div className="overflow-x-auto overflow-y-auto max-h-[80vh] border rounded-lg">
        <table className="w-full table-auto bg-white">
          <thead className="sticky top-0 z-20 bg-white">
            <tr className="bg-gray-100">
              <th
                rowSpan={2}
                className="sticky left-0 top-0 z-20 px-2 py-1 border bg-gray-100 min-w-[120px] whitespace-normal"
              >
                {t('generic.name')}
              </th>
              <th
                rowSpan={2}
                className="sticky left-[120px] top-0 z-20 px-2 py-1 border bg-gray-100 min-w-[100px] whitespace-normal"
              >
                {t('generic.role')}
              </th>
              <th
                rowSpan={2}
                className="sticky left-[220px] top-0 z-20 px-2 py-1 border bg-gray-100 min-w-[80px] whitespace-normal"
              >
                {t('generic.total')}
              </th>
              <th colSpan={2} className="px-2 py-1 border whitespace-normal">
                {t('user.stats.pointsRespected')}
              </th>
              <th colSpan={7} className="px-2 py-1 border whitespace-normal">
                {t('user.stats.statsPerDayOfWeek')}
              </th>
              {selectedSections.size > 0 && (
                <th
                  colSpan={selectedSections.size}
                  className="px-2 py-1 border whitespace-normal"
                >
                  {t('generic.sections')}
                </th>
              )}
              {selectedRules.size > 0 && (
                <th
                  colSpan={selectedRules.size}
                  className="px-2 py-1 border whitespace-normal"
                >
                  {t('generic.rules')}
                </th>
              )}
              {selectedLabels.size > 0 && (
                <th
                  colSpan={selectedLabels.size}
                  className="px-2 py-1 border whitespace-normal"
                >
                  {t('generic.labels')}
                </th>
              )}
              {selectedCounters.size > 0 && (
                <th
                  colSpan={selectedCounters.size}
                  className="px-2 py-1 border whitespace-normal"
                >
                  {t('generic.counters')}
                </th>
              )}
            </tr>
            <tr>
              <th className="px-2 py-1 border text-center font-medium text-green-600">
                +
              </th>
              <th className="px-2 py-1 border text-center font-medium text-red-600">
                -
              </th>
              {daysOfWeek.map(([absoluteDayIdx, dayLabel]) => (
                <th
                  key={absoluteDayIdx}
                  className="px-2 py-1 border text-center font-medium text-gray-600"
                >
                  {dayLabel}
                </th>
              ))}
              {sectionsList
                .filter(s => selectedSections.has(s.id_section))
                .map(section => (
                  <th
                    key={section.id_section}
                    className="px-2 py-1 border relative min-w-[80px] max-w-[120px]"
                  >
                    <div className="flex items-center justify-center gap-2">
                      <div
                        className="w-3 h-3 flex-shrink-0 rounded-full"
                        style={{
                          backgroundColor: returnColor(section.color),
                        }}
                      />
                      <span className="whitespace-normal text-sm break-words">
                        {section.acronym}
                      </span>
                    </div>
                    <div
                      className="absolute bottom-0 left-0 w-full h-1"
                      style={{
                        backgroundColor: returnColor(section.color),
                      }}
                    />
                  </th>
                ))}
              {rulesList
                .filter(
                  r =>
                    selectedRules.has(r.id_rule) &&
                    Object.keys(allUserReqRules[r.id_rule].user_reqs).length >
                      1,
                )
                .map(rule => (
                  <th
                    key={rule.id_rule}
                    className="px-2 py-1 border min-w-[100px] max-w-[120px] whitespace-normal"
                  >
                    {rule.name}
                  </th>
                ))}
              {labelsList
                .filter(l => selectedLabels.has(l.id_slot_label))
                .map(label => (
                  <th
                    key={label.id_slot_label}
                    className="px-2 py-1 border min-w-[120px] max-w-[160px] whitespace-normal"
                  >
                    <div className="flex items-center justify-center gap-2">
                      {label.icon && (
                        <LabelIconComponent
                          icon={label.icon}
                          className="w-5 h-5 p-0.5"
                        />
                      )}
                      <span className="whitespace-normal text-sm break-words">
                        {label.name}
                      </span>
                    </div>
                  </th>
                ))}
              {Object.values(counters)
                .filter(c => selectedCounters.has(c.id_custom_counter))
                .map(counter => (
                  <th
                    key={counter.id_custom_counter}
                    className="px-2 py-1 border font-medium text-gray-900 min-w-[80px] max-w-[100px] whitespace-normal"
                    title={counter.name || undefined}
                  >
                    {counter.name}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {groupStats
              .filter(
                stat =>
                  selectedUsers.has(stat.userId) &&
                  users[stat.userId]?.is_group_member,
              )
              .sort(
                (userStatA, userStatB) =>
                  userStatA.roleName.localeCompare(userStatB.roleName) ||
                  userStatA.userName.localeCompare(userStatB.userName),
              )
              .map(userStat => {
                const positivePercentage = calculatePositivePercentage(
                  userPercentages[userStat.userId].positiveRespected,
                  userPercentages[userStat.userId].totalPositive,
                );
                const negativePercentage = calculateNegativePercentage(
                  userPercentages[userStat.userId].negativeNotRespected,
                  userPercentages[userStat.userId].totalNegative,
                );
                return (
                  <tr key={userStat.userId}>
                    <td className="sticky left-0 z-10 px-2 py-1 border bg-white">
                      <Link
                        to={`/manager/team/personnel/${userStat.userId}`}
                        state={{
                          name: userStat.userName,
                          role: userStat.roleName,
                          previous: 'stats',
                        }}
                      >
                        {userStat.userName}
                      </Link>
                    </td>
                    <td className="sticky left-[120px] z-10 px-2 py-1 border bg-white">
                      {userStat.roleName}
                    </td>
                    <td className="sticky left-[220px] z-10 px-2 py-1 border bg-white text-center">
                      <div className="flex flex-row gap-1 justify-center text-center items-center">
                        <div>
                          {Object.values(userStat.shiftsPerSection).reduce(
                            (acc, curr) => acc + (curr?.count || 0),
                            0,
                          )}
                        </div>
                        <span>|</span>
                        <div className="text-gray-500">
                          {Object.values(userStat.shiftsPerSection).reduce(
                            (acc, curr) => acc + (curr?.hours || 0),
                            0,
                          )}
                          h
                        </div>
                      </div>
                    </td>
                    <td className="px-2 py-1 border text-center text-green-600">
                      {positivePercentage !== null
                        ? `${positivePercentage}%`
                        : '-'}
                    </td>
                    <td className="px-2 py-1 border text-center text-red-600">
                      {negativePercentage !== null
                        ? `${negativePercentage}%`
                        : '-'}
                    </td>
                    {daysOfWeek.map(([absoluteDayIdx, dayLabel]) => (
                      <td
                        key={absoluteDayIdx}
                        className="px-2 py-1 border text-center"
                      >
                        {userStat.shiftsPerDayOfWeek[Number(absoluteDayIdx)]
                          ?.count || 0}
                      </td>
                    ))}
                    {sectionsList
                      .filter(s => selectedSections.has(s.id_section))
                      .map(section => (
                        <td
                          key={section.id_section}
                          className="px-2 py-1 border bg-white text-center"
                        >
                          <div className="flex flex-row gap-1 justify-center text-center items-center">
                            <div>
                              {userStat.shiftsPerSection[section.id_section]
                                ?.count || 0}
                            </div>
                            <span>|</span>
                            <div className="text-gray-500">
                              {userStat.shiftsPerSection[section.id_section]
                                ?.hours || 0}
                              h
                            </div>
                          </div>
                        </td>
                      ))}
                    {rulesList
                      .filter(r => selectedRules.has(r.id_rule))
                      .map(rule => (
                        <td
                          key={rule.id_rule}
                          className="px-2 py-1 border text-center min-w-[100px] max-w-[120px]"
                        >
                          {userBaseRuleParticipation[userStat.userId][
                            rule.id_rule
                          ] ? (
                            <div className="relative flex flex-row gap-1 justify-center text-center items-center">
                              <div>
                                {userStat.shiftsPerRule[rule.id_rule]?.count ||
                                  0}
                              </div>
                              <span>|</span>
                              <div className="text-sm text-gray-500">
                                {userStat.shiftsPerRule[rule.id_rule]?.hours ||
                                  0}
                                h
                              </div>
                            </div>
                          ) : (
                            '-'
                          )}
                        </td>
                      ))}
                    {labelsList
                      .filter(l => selectedLabels.has(l.id_slot_label))
                      .map(label => (
                        <td
                          key={label.id_slot_label}
                          className="px-2 py-1 border text-center group min-w-[120px] max-w-[160px]"
                        >
                          <div className="relative flex flex-row gap-1 justify-center text-center items-center">
                            <div>
                              {userStat.shiftsPerLabel[label.id_slot_label]
                                ?.count || 0}
                            </div>
                            <span>|</span>
                            <div className="text-sm text-gray-500">
                              {userStat.shiftsPerLabel[label.id_slot_label]
                                ?.hours || 0}
                              h
                            </div>
                          </div>
                        </td>
                      ))}
                    {Object.values(counters)
                      .filter(c => selectedCounters.has(c.id_custom_counter))
                      .map(counter => (
                        <td
                          key={counter.id_custom_counter}
                          className="px-2 py-1 border text-center min-w-[80px] max-w-[100px]"
                        >
                          {userStat.customCounters[counter.id_custom_counter] ||
                            '0'}
                        </td>
                      ))}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
