"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useManagerDeleteUserPref = exports.useManagerEditUserPref = exports.useEditUserPreferencesConfig = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
var useEditUserPreferencesConfig = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, preference_configs = params.preference_configs;
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/preferences/edit"),
                method: "patch",
                data: { preference_configs: preference_configs },
            });
        } }, options), queryClient);
};
exports.useEditUserPreferencesConfig = useEditUserPreferencesConfig;
var useManagerEditUserPref = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, data = __rest(params, ["id_itr"]);
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/preferences/edit_user_pref"),
                method: "patch",
                data: data,
            });
        } }, options), queryClient);
};
exports.useManagerEditUserPref = useManagerEditUserPref;
var useManagerDeleteUserPref = function (queryClient, options) {
    return (0, react_query_1.useMutation)(__assign({ mutationFn: function (params) {
            var id_itr = params.id_itr, data = __rest(params, ["id_itr"]);
            return (0, api_1.request)({
                url: "manager/itrs/".concat(id_itr, "/preferences/delete_user_pref"),
                method: "delete",
                data: data,
            });
        } }, options), queryClient);
};
exports.useManagerDeleteUserPref = useManagerDeleteUserPref;
