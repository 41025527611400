/* eslint-disable max-len */
import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import {
  useAssignRoleMutation,
  useBulkAssignRoleMutation,
} from '@youshift/shared/hooks/mutations';
import { User, UserRole } from '@youshift/shared/types';
import {
  classNames,
  generateErrorStringFromError,
} from '@youshift/shared/utils';
import { Fragment, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useManagerContext } from '../../layouts/ManagerLayout';
import Modal from '../Modal';

export const NoRoleId = -1;

export default function BulkAssignRole({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { t } = useTranslation();
  const { users, roles } = useManagerContext();

  const [selectedRole, setSelectedRole] = useState<number | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<Set<number>>(new Set());

  const queryClient = useQueryClient();
  const assignRoleMutation = useBulkAssignRoleMutation(queryClient, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['personnel'] });
      queryClient.invalidateQueries({ queryKey: ['itrUsers'] });
      toast.success(
        t('manager.team.assignRole.successBulk', {
          roleName: roles[variables.id_user_role].name,
        }),
      );
      setSelectedRole(null);
      setSelectedUsers(new Set());
      setOpen(false);
    },
  });

  const handleBulkAssignRole = async () => {
    const data = { id_users: [...selectedUsers], id_user_role: selectedRole };
    assignRoleMutation.mutate(data);
  };

  const toggleUserInclusion = (id_user: number) => {
    const newSelectedUsers = new Set(selectedUsers);
    if (selectedUsers.has(id_user)) {
      newSelectedUsers.delete(id_user);
    } else {
      newSelectedUsers.add(id_user);
    }
    setSelectedUsers(newSelectedUsers);
  };

  const toggleRoleSelection = (roleId: number | null) => {
    const usersInRole = Object.values(users).filter(
      user => user.id_user_role === roleId,
    );
    const userIds = usersInRole.map(user => user.id);

    // Check if all users in this role are already selected
    const allSelected = userIds.every(id => selectedUsers.has(id));

    const newSelectedUsers = new Set(selectedUsers);

    // Toggle selection for all users in this role
    userIds.forEach(id => {
      if (allSelected) {
        newSelectedUsers.delete(id);
      } else {
        newSelectedUsers.add(id);
      }
    });

    setSelectedUsers(newSelectedUsers);
  };

  const selectAll = () => {
    setSelectedUsers(new Set(Object.values(users).map(user => user.id)));
  };

  const deselectAll = () => {
    setSelectedUsers(new Set());
  };

  // Group users by role
  const usersByRole: Record<string, User[]> = {};

  // Initialize with "No Role" group
  usersByRole['null'] = [];

  // Group users by their role
  Object.values(users).forEach(user => {
    const roleId = user.id_user_role;
    const key = roleId === null ? 'null' : roleId.toString();

    if (!usersByRole[key]) {
      usersByRole[key] = [];
    }

    usersByRole[key].push(user);
  });

  return (
    <Modal
      isOpen={open}
      onClose={() => setOpen(false)}
      handleSave={handleBulkAssignRole}
      editButtons
      disableSave={!selectedRole || !selectedUsers.size}
    >
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
          <PencilSquareIcon
            className="h-6 w-6 text-blue-600"
            aria-hidden="true"
          />
        </div>
        <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
          <Dialog.Title
            as="h3"
            className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6"
          >
            {t('manager.team.bulkAssignRole')}
          </Dialog.Title>
          <div className="mt-2">
            <Listbox value={selectedRole} onChange={setSelectedRole}>
              {({ open }) => (
                <>
                  <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                    {t('manager.team.selectRole')}
                  </Listbox.Label>
                  <div className="relative mt-2">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                      <span className="block truncate">
                        {selectedRole ? roles[selectedRole]?.name : '-'}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {Object.values(roles)
                          .filter(role => role.id !== NoRoleId)
                          .map(role => (
                            <Listbox.Option
                              key={role.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? 'bg-blue-600 text-white'
                                    : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-8 pr-4',
                                )
                              }
                              value={role.id}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'block truncate',
                                    )}
                                  >
                                    {role.name}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-blue-600',
                                        'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
          <div className="mt-4">
            <div className="flex justify-between mb-2 text-xs">
              <p className="block text-sm font-medium leading-6 text-gray-900">
                {t('manager.team.selectUsers')}
              </p>
              <div>
                <button
                  className="text-red-600 hover:underline mr-2 text-xs"
                  onClick={deselectAll}
                >
                  {t('generic.deselectAll')}
                </button>
                <button
                  className="text-blue-600 hover:underline text-xs"
                  onClick={selectAll}
                >
                  {t('generic.selectAll')}
                </button>
              </div>
            </div>

            <div className="max-h-80 overflow-y-auto border rounded-md">
              {/* No Role section */}
              <div key="no-role">
                <div className="flex items-center bg-gray-200 px-4 py-1">
                  <input
                    id="role-no-role"
                    type="checkbox"
                    checked={
                      usersByRole['null'].length > 0 &&
                      usersByRole['null'].every(user =>
                        selectedUsers.has(user.id),
                      )
                    }
                    onChange={() => toggleRoleSelection(null)}
                    className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <label
                    htmlFor="role-no-role"
                    className="ml-2 text-sm font-medium text-gray-900"
                  >
                    {t('manager.team.noRole')}
                  </label>
                </div>
                <div className="pl-8">
                  {usersByRole['null']
                    .sort((a, b) =>
                      `${a.firstname} ${a.lastname}`.localeCompare(
                        `${b.firstname} ${b.lastname}`,
                      ),
                    )
                    .map(user => (
                      <div
                        key={user.id}
                        className="flex items-center py-0.5 px-2"
                      >
                        <input
                          id={`user-${user.id}`}
                          type="checkbox"
                          checked={selectedUsers.has(user.id)}
                          onChange={() => toggleUserInclusion(user.id)}
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <label
                          htmlFor={`user-${user.id}`}
                          className="ml-2 text-sm text-gray-900"
                        >
                          {user.firstname} {user.lastname}
                        </label>
                      </div>
                    ))}
                </div>
              </div>

              {/* Roles with users */}
              {Object.entries(usersByRole)
                .filter(([roleId]) => roleId !== 'null')
                .map(([roleId, usersInRole]) => {
                  const role = roles[parseInt(roleId)];
                  if (!role || !usersInRole.length) return null;

                  const allSelected = usersInRole.every(user =>
                    selectedUsers.has(user.id),
                  );

                  return (
                    <div key={roleId}>
                      <div className="flex items-center bg-gray-200 px-4 py-1">
                        <input
                          id={`role-${roleId}`}
                          type="checkbox"
                          checked={allSelected}
                          onChange={() => toggleRoleSelection(parseInt(roleId))}
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <label
                          htmlFor={`role-${roleId}`}
                          className="ml-2 text-sm font-medium text-gray-900"
                        >
                          {role.name}
                        </label>
                      </div>
                      <div className="pl-8">
                        {usersInRole
                          .sort((a, b) =>
                            `${a.firstname} ${a.lastname}`.localeCompare(
                              `${b.firstname} ${b.lastname}`,
                            ),
                          )
                          .map(user => (
                            <div
                              key={user.id}
                              className="flex items-center py-0.5 px-2"
                            >
                              <input
                                id={`user-${user.id}`}
                                type="checkbox"
                                checked={selectedUsers.has(user.id)}
                                onChange={() => toggleUserInclusion(user.id)}
                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                              />
                              <label
                                htmlFor={`user-${user.id}`}
                                className="ml-2 text-sm text-gray-900"
                              >
                                {user.firstname} {user.lastname}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
