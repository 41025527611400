import {
  Bars4Icon,
  LightBulbIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import {
  RuleTypes,
  UserRequirementInclusionType,
  UserRequirementType,
} from '@youshift/shared/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { YSButton } from '../../../../components/Buttons';
import InfoIcon from '../../../../components/InfoIcon';
import Wrapper from '../../../../components/Wrapper';
import { useItrContext } from '../../../../layouts/IterationRootLayout/IterationRootLayout';
import InclusionTypeBadge, {
  RequirementTypeLegend,
} from './InclusionTypeBadge';
import {
  MultipleUsersRuleType,
  RuleWithInclusionType,
  SingleUserRuleType,
} from './types';
import UserRulesTable from './UserRulesTable';

const GroupRulesSection = ({
  rules,
  title,
  inclusionType,
  type,
  max,
}: {
  rules: RuleWithInclusionType[];
  title?: string;
  inclusionType: UserRequirementInclusionType;
  type: RuleTypes;
  max?: number;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRuleClick = (ruleId: number) => {
    // Navigate programmatically and scroll to top
    navigate(`${ruleId}`, { replace: false });
    window.scrollTo(0, 0);
  };

  return (
    <div className="mb-3">
      {title && (
        <div className="flex items-center gap-2 mb-3">
          <h3 className="text-md">{title}</h3>
        </div>
      )}
      <div className="flex flex-row gap-2 items-center flex-wrap">
        {rules.map(rule => (
          <div
            key={rule.id_rule}
            className="bg-white rounded-lg border border-gray-200 p-3"
          >
            <div
              onClick={() => handleRuleClick(rule.id_rule)}
              className="flex items-center justify-between space-x-3 cursor-pointer"
            >
              <div className="flex flex-row items-center gap-2">
                <InclusionTypeBadge label={rule.inclusionType} />
                <span>{rule.name}</span>
              </div>
              <button className="p-1 rounded-full border border-gray-300">
                <Bars4Icon className="w-5 h-5 text-gray-400" />
              </button>
            </div>
          </div>
        ))}
        {rules.length < (max ?? 100) && (
          <Link to={`./new?type=${type}&inclusionType=${inclusionType}`}>
            <div
              className="p-1 rounded-full border border-gray-300 text-gray-400"
              aria-label={t('manager.rulesConfig.createRule')}
            >
              <PlusIcon className="w-5 h-5" />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default function UserReqRulesConfig() {
  const { allUserReqs, sectionsWithSlots } = useItrContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // ---------------------------------------------------------------------------
  // Process allUserReqs into:
  // - multipleUsersRules: rules (with computed inclusionType) that apply to more than one user.
  // - userRules: personal and group rules per user.
  // ---------------------------------------------------------------------------
  const { multipleUsersRules, userRules } = useMemo(() => {
    const multipleUsersRules: MultipleUsersRuleType = {};
    const userRules: {
      personal: SingleUserRuleType;
      group: SingleUserRuleType;
    } = { personal: {}, group: {} };

    for (const ruleIdStr in allUserReqs) {
      const ruleId = Number(ruleIdStr);
      const ruleReqRule = allUserReqs[ruleId];
      const rule = ruleReqRule.rule;
      const userReqs = ruleReqRule.user_reqs;
      const reqKeys = Object.keys(userReqs);
      const reqCount = reqKeys.length;

      // Determine inclusionType: if every user requirement has zeros, mark as EXCLUSION.
      let isExclusion = true;
      for (const key of reqKeys) {
        const req = userReqs[Number(key)];
        if (req.req_type === UserRequirementType.SLOTS) {
          if ((req.min_slots ?? 0) !== 0 || (req.max_slots ?? 0) !== 0) {
            isExclusion = false;
            break;
          }
        } else if (req.req_type === UserRequirementType.DURATION) {
          if ((req.min_duration ?? 0) !== 0 || (req.max_duration ?? 0) !== 0) {
            isExclusion = false;
            break;
          }
        }
      }
      const inclusionType = isExclusion
        ? UserRequirementInclusionType.EXCLUSION
        : UserRequirementInclusionType.INCLUSION;

      if (reqCount > 1) {
        // This is a group rule.
        multipleUsersRules[rule.id_rule] = { ...rule, inclusionType };
        for (const key of reqKeys) {
          const userId = Number(key);
          const userReq = userReqs[userId];
          if (!userRules.group[userId]) {
            userRules.group[userId] = [];
          }
          userRules.group[userId].push({
            ...rule,
            inclusionType,
            min_slots:
              userReq.req_type === UserRequirementType.SLOTS
                ? userReq.min_slots
                : null,
            max_slots:
              userReq.req_type === UserRequirementType.SLOTS
                ? userReq.max_slots
                : null,
            min_duration:
              userReq.req_type === UserRequirementType.DURATION
                ? userReq.min_duration
                : null,
            max_duration:
              userReq.req_type === UserRequirementType.DURATION
                ? userReq.max_duration
                : null,
          });
        }
      } else if (reqCount === 1) {
        const userId = Number(reqKeys[0]);
        const userReq = userReqs[userId];
        if (!userRules.personal[userId]) {
          userRules.personal[userId] = [];
        }
        userRules.personal[userId].push({
          ...rule,
          inclusionType,
          min_slots:
            userReq.req_type === UserRequirementType.SLOTS
              ? userReq.min_slots
              : null,
          max_slots:
            userReq.req_type === UserRequirementType.SLOTS
              ? userReq.max_slots
              : null,
          min_duration:
            userReq.req_type === UserRequirementType.DURATION
              ? userReq.min_duration
              : null,
          max_duration:
            userReq.req_type === UserRequirementType.DURATION
              ? userReq.max_duration
              : null,
        });
      }
    }

    return { multipleUsersRules, userRules };
  }, [allUserReqs]);

  return (
    <Wrapper>
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <RequirementTypeLegend />
          <YSButton to="./new" classNames="flex flex-row items-center gap-1">
            <PlusIcon className="w-5 h-5" />
            {t('manager.rulesConfig.createRule')}
          </YSButton>
        </div>
        {!Object.keys(allUserReqs).length && (
          <div className="p-1 border border-blue-600 rounded-lg w-2/3 mx-auto flex flex-row items-center">
            <LightBulbIcon className="w-10 text-blue-600 h-10" />
            <p className="text-gray-700 text-center">
              {t('manager.rulesConfig.createYourFirstRulePrompt')}
            </p>
          </div>
        )}
        {/* Group Rules Section */}
        <h2 className="text-xl font-semibold mb-4">
          {t('manager.rulesConfig.groupRules')}
          <InfoIcon
            title={t(`manager.rulesConfig.groupRules`)}
            content={t(`manager.rulesConfig.groupRulesDefinition`)}
          />
        </h2>
        <div className="space-y-6 ml-6">
          {/* Section Rules */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-2">
              {t('manager.rulesConfig.sectionRules')}
              <InfoIcon
                title={t(`manager.rulesConfig.sectionRules`)}
                content={t(`manager.rulesConfig.sectionRulesDefinition`)}
              />
            </h2>
            <GroupRulesSection
              rules={Object.values(multipleUsersRules)
                .filter(rule => rule.type === RuleTypes.SECTION_USER_REQS)
                .sort((a, b) => a.name.localeCompare(b.name))}
              inclusionType={UserRequirementInclusionType.INCLUSION}
              type={RuleTypes.SECTION_USER_REQS}
              max={sectionsWithSlots.length}
            />
          </div>

          {/* Custom Rules */}
          <div className="bg-gray-50 rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-2">
              {t('manager.rulesConfig.customRules')}
              <InfoIcon
                title={t(`manager.rulesConfig.customRules`)}
                content={t(`manager.rulesConfig.customRulesDefinition`)}
              />
            </h2>
            <GroupRulesSection
              rules={Object.values(multipleUsersRules)
                .filter(
                  rule =>
                    rule.type === RuleTypes.CUSTOM_USER_REQS &&
                    rule.inclusionType ===
                      UserRequirementInclusionType.INCLUSION,
                )
                .sort((a, b) => a.name.localeCompare(b.name))}
              title={t('manager.rulesConfig.inclusionRules')}
              inclusionType={UserRequirementInclusionType.INCLUSION}
              type={RuleTypes.CUSTOM_USER_REQS}
            />
            <GroupRulesSection
              rules={Object.values(multipleUsersRules)
                .filter(
                  rule =>
                    rule.type === RuleTypes.CUSTOM_USER_REQS &&
                    rule.inclusionType ===
                      UserRequirementInclusionType.EXCLUSION,
                )
                .sort((a, b) => a.name.localeCompare(b.name))}
              title={t('manager.rulesConfig.exclusionRules')}
              inclusionType={UserRequirementInclusionType.EXCLUSION}
              type={RuleTypes.CUSTOM_USER_REQS}
            />
          </div>
        </div>
      </div>
      {/* <Link
        to="./users"
        className="col-span-1 md:col-span-2 lg:col-span-3 flex items-center justify-between bg-blue-50 hover:bg-blue-100 rounded-lg p-4 text-blue-700 transition-colors duration-200"
      >
        <div className="flex items-center gap-3">
          <UsersIcon className="w-6 h-6" />
          <span className="text-lg font-medium">
            {t('manager.rulesConfig.viewPerUser')}
          </span>
        </div>
      </Link> */}

      {/* User Rules Table */}
      <UserRulesTable userRules={userRules} />
    </Wrapper>
  );
}
