import { Switch } from '@headlessui/react';
import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/20/solid';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { request } from '@youshift/shared/api';
import { classNames } from '@youshift/shared/utils';

export default function Toggle({ enabled, setEnabled }) {
  const { idItr: id } = useParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  async function toggle() {
    try {
      const url = enabled
        ? `/manager/itrs/${id}/flow/unlock`
        : `/manager/itrs/${id}/flow/lock`;
      const res = await request({ url, method: 'post' });
      queryClient.invalidateQueries(['iteration', id]);
      queryClient.invalidateQueries({ queryKey: ["itrs"] });
      setEnabled(!enabled);
      return res;
    } catch (error) {
      return error;
    }
  }

  return (
    <Switch.Group
      as="div"
      className="flex items-center gap-1"
      id="toggleItrLock"
    >
      <Switch
        checked={enabled}
        onChange={() => toggle()}
        className={classNames(
          enabled ? 'bg-blue-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2',
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
      {enabled ? (
        <LockClosedIcon className="h-5 w-5 text-teal-500" />
      ) : (
        <LockOpenIcon className="h-5 w-5 text-teal-500" />
      )}
      <Switch.Label as="span" className="text-sm flex flex-row">
        <span className="font-medium text-gray-900">
          {enabled
            ? t('manager.iterationConfig.toggleLocked')
            : t('manager.iterationConfig.toggleUnlocked')}
        </span>
        {' '}
      </Switch.Label>
    </Switch.Group>
  );
}
