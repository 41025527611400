import {
  ClientSourcingOrigin,
  MedicalSpecialty,
  Organization,
} from '@youshift/shared/types';

export interface OrganizationInfo {
  name: string;
  country: string;
  state: string;
  province: string;
  city: string;
  address: string;
  postal_code: string;
}

export interface UserInfo {
  firstname: string;
  lastname: string;
  group_name: string;
  email: string;
  password: string;
  confirmPassword: string;
  has_agreed_to_privacy_policy: boolean;
  phone: string;
  sourceOrigin: ClientSourcingOrigin | undefined;
  specialty: MedicalSpecialty | undefined;
  numBilledUsers: number;
}

export interface SignUpState {
  organization: OrganizationInfo;
  id_org: number | undefined;
  user: UserInfo;
}

export type FormActions =
  | { type: 'UPDATE_USER_FIELD'; field: keyof UserInfo; value: string }
  | { type: 'UPDATE_ID_ORG'; value: number | undefined }
  | {
      type: 'UPDATE_ORGANIZATION_FIELDS';
      payload: Partial<OrganizationInfo>;
    }
  | { type: 'TOGGLE_PRIVACY_POLICY' }
  | { type: 'RESET' };

export interface LocationData {
  country: string;
  state: string;
  province: string;
}

export interface Option {
  label: string;
  id: string;
}

export interface AvailableOptions {
  country: Option[];
  province: Option[];
  organization: Option[];
}

export interface VisibleDropdowns {
  province: boolean;
  organization: boolean;
}

export interface SelectedValues {
  province: Option | null;
  organization: Option | null;
}

export const managerSignUpState: SignUpState = {
  organization: {
    name: '',
    country: '',
    state: '',
    province: '',
    city: '',
    address: '',
    postal_code: '',
  },
  id_org: undefined,
  user: {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
    has_agreed_to_privacy_policy: false,
    group_name: '',
    phone: '',
    sourceOrigin: undefined,
    specialty: undefined,
    numBilledUsers: 0,
  },
};

export function managerSignUpreducer(
  state: SignUpState,
  action: FormActions,
): SignUpState {
  switch (action.type) {
    case 'UPDATE_USER_FIELD':
      return {
        ...state,
        user: {
          ...state.user,
          [action.field]: action.value,
        },
      };
    case 'UPDATE_ID_ORG':
      return {
        ...state,
        id_org: action.value,
      };
    case 'UPDATE_ORGANIZATION_FIELDS':
      return {
        ...state,
        organization: {
          ...state.organization,
          ...action.payload,
        },
      };
    case 'TOGGLE_PRIVACY_POLICY':
      return {
        ...state,
        user: {
          ...state.user,
          has_agreed_to_privacy_policy:
            !state.user.has_agreed_to_privacy_policy,
        },
      };
    case 'RESET':
      return managerSignUpState;
    default:
      return state;
  }
}
export function getPlaces(data: Organization[]): LocationData[] {
  const places: LocationData[] = [];

  function createNewPlace(data: Organization): LocationData {
    return {
      country: data.country,
      state: data.state,
      province: data.province,
    };
  }

  function isEqual(a: LocationData, b: LocationData): boolean {
    return (
      a.country === b.country &&
      a.state === b.state &&
      a.province === b.province
    );
  }

  data.forEach(d => {
    const newPlace = createNewPlace(d);

    if (!places.filter(n => isEqual(n, newPlace)).length) {
      places.push(newPlace);
    }
  });

  return places;
}
