import { useTranslation } from 'react-i18next';
import { SectionSlot, Section, UserReqRule } from '@youshift/shared/types';
import {
  minutesToHours,
  parseSectionSlotDateTime,
  localeNormalizer,
} from '@youshift/shared/utils';

import {
  ShiftExchangeViolation,
  ShiftExchangeViolationType,
  SingleGroupIncompatibilityMaxSimultViolation,
  SingleGroupIncompatibilityMinSimultViolation,
  CrossGroupIncompatibilityViolation,
  UserReqMaxDurationViolation,
  UserReqMaxSlotsViolation,
  UserReqMinDurationViolation,
  UserReqMinSlotsViolation,
} from '../../utils/shift_exchange/types';
import i18n from '../../utils/i18n';

interface ShiftViolationsListProps {
  violations: ShiftExchangeViolation[];
  allSections: Record<number, Section>;
  allSectionSlots: Record<number, SectionSlot>;
  allUserReqRules: Record<number, UserReqRule>;
}

export default function ShiftViolationsList({
  violations,
  allUserReqRules,
  allSectionSlots,
  allSections,
}: ShiftViolationsListProps) {
  const { t } = useTranslation();

  const violationsByType = violations.reduce<
    Record<ShiftExchangeViolationType, ShiftExchangeViolation[]>
  >(
    (acc, violation) => {
      if (!acc[violation.type]) {
        acc[violation.type] = [];
      }
      acc[violation.type].push(violation);
      return acc;
    },
    {} as Record<ShiftExchangeViolationType, ShiftExchangeViolation[]>,
  );
  const locale = localeNormalizer(i18n.language);

  const renderViolation = (
    violation: ShiftExchangeViolation,
    index: number,
  ) => {
    switch (violation.type) {
      case ShiftExchangeViolationType.OVERLAPPING_ASSIGNMENT_VIOLATION:
        return (
          <li key={index} className="text-xs text-gray-500 ml-4 mb-2">
            {t(
              'generic.shiftExchange.violations.overlappingAssignmentViolation.description',
            )}
            {violation.context.overlappingSectionSlots.map(
              (idSectionSlot: number, idx: number) => (
                <ul key={idx} className="list-none list-inside ml-4 mt-1">
                  <li className="text-red-600">
                    {
                      allSections[allSectionSlots[idSectionSlot].id_section]
                        .acronym
                    }
                    {' - '}
                    {parseSectionSlotDateTime(
                      allSectionSlots[idSectionSlot].start,
                      allSectionSlots[idSectionSlot].end,
                      locale,
                      false,
                    )}
                  </li>
                </ul>
              ),
            )}
          </li>
        );

      case ShiftExchangeViolationType.NEW_ASSIGNMENT_ON_REST_PERIOD_VIOLATION: {
        const firstViolatedRestPeriodShiftAssignments = Object.values(
          violation.context.violatedRestPeriodShiftAssignments,
        )[0];
        return (
          <li key={index} className="text-xs text-gray-500 ml-4 mb-2">
            {t(
              'generic.shiftExchange.violations.newAssignmentOnRestPeriodViolation.description',
              {
                restHours: minutesToHours(
                  allSectionSlots[firstViolatedRestPeriodShiftAssignments]
                    .rest_period,
                ),
              },
            )}
            <ul className="list-none list-inside ml-4 mt-1">
              <li className="text-red-600">
                {
                  allSections[
                    allSectionSlots[firstViolatedRestPeriodShiftAssignments]
                      .id_section
                  ].acronym
                }{' '}
                -{' '}
                {parseSectionSlotDateTime(
                  allSectionSlots[firstViolatedRestPeriodShiftAssignments]
                    .start,
                  allSectionSlots[firstViolatedRestPeriodShiftAssignments].end,
                  locale,
                  false,
                )}
              </li>
            </ul>
          </li>
        );
      }

      case ShiftExchangeViolationType.EXISTING_ASSIGNMENT_ON_NEW_REST_PERIOD_VIOLATION: {
        const firstExistingShiftAssignentOnNewRestPeriod = Object.values(
          violation.context.existingShiftAssignmentsOnNewRestPeriod,
        )[0];
        return (
          <li key={index} className="text-xs text-gray-500 ml-4 mb-2">
            {t(
              'generic.shiftExchange.violations.existingAssignmentOnNewRestPeriodViolation.description',
              {
                restHours: minutesToHours(
                  allSectionSlots[firstExistingShiftAssignentOnNewRestPeriod]
                    .rest_period,
                ),
              },
            )}
            <ul className="list-none list-inside ml-4 mt-1">
              <li className="text-red-600">
                {
                  allSections[
                    allSectionSlots[firstExistingShiftAssignentOnNewRestPeriod]
                      .id_section
                  ].acronym
                }{' '}
                -{' '}
                {parseSectionSlotDateTime(
                  allSectionSlots[firstExistingShiftAssignentOnNewRestPeriod]
                    .start,
                  allSectionSlots[firstExistingShiftAssignentOnNewRestPeriod]
                    .end,
                  locale,
                  false,
                )}
              </li>
            </ul>
          </li>
        );
      }

      case ShiftExchangeViolationType.SPECIAL_EVENT_CONFLICT:
        return (
          <li key={index} className="text-xs text-gray-500 ml-4 mb-2">
            {t(
              'generic.shiftExchange.violations.specialEventConflict.description',
            )}
          </li>
        );

      case ShiftExchangeViolationType.USER_SHIFT_REQ_MAX_SLOTS_VIOLATION: {
        const v = violation as UserReqMaxSlotsViolation;
        return (
          <li key={index} className="text-xs text-gray-500 mb-2">
            {t('generic.rule')}
            {' - '}
            <strong>{allUserReqRules[v.context.ruleId].rule.name}</strong>
            {': '}
            <div className="block mt-1">
              {t(
                'generic.shiftExchange.violations.userShiftReqMaxSlotsViolation.description',
                {
                  alreadyAssignedSlots: v.context.alreadyAssignedSlots,
                  maxSlots: v.context.maxSlots,
                },
              )}
            </div>
          </li>
        );
      }

      case ShiftExchangeViolationType.USER_SHIFT_REQ_MIN_SLOTS_VIOLATION: {
        const v = violation as UserReqMinSlotsViolation;
        return (
          <li key={index} className="text-xs text-gray-500 mb-2">
            {t('generic.rule')}
            {' - '}
            <strong>{allUserReqRules[v.context.ruleId].rule.name}</strong>
            {': '}
            <div className="block mt-1">
              {t(
                'generic.shiftExchange.violations.userShiftReqMinSlotsViolation.description',
                {
                  alreadyAssignedSlots: v.context.alreadyAssignedSlots,
                  minSlots: v.context.minSlots,
                },
              )}
            </div>
          </li>
        );
      }

      case ShiftExchangeViolationType.USER_SHIFT_REQ_MAX_DURATION_VIOLATION: {
        const v = violation as UserReqMaxDurationViolation;
        return (
          <li key={index} className="text-xs text-gray-500 mb-2">
            {t('generic.rule')}
            {' - '}
            <strong>{allUserReqRules[v.context.ruleId].rule.name}</strong>
            {': '}
            <div className="block mt-1">
              {t(
                'generic.shiftExchange.violations.userShiftReqMaxDurationViolation.description',
                {
                  totalAssignedDuration: v.context.totalAssignedDuration,
                  maxReqDuration: v.context.maxReqDuration,
                },
              )}
            </div>
          </li>
        );
      }

      case ShiftExchangeViolationType.USER_SHIFT_REQ_MIN_DURATION_VIOLATION: {
        const v = violation as UserReqMinDurationViolation;
        return (
          <li key={index} className="text-xs text-gray-500 mb-2">
            {t('generic.rule')}
            {' - '}
            <strong>{allUserReqRules[v.context.ruleId].rule.name}</strong>
            {': '}
            <div className="block mt-1">
              {t(
                'generic.shiftExchange.violations.userShiftReqMinDurationViolation.description',
                {
                  totalAssignedDuration: v.context.totalAssignedDuration,
                  minReqDuration: v.context.minReqDuration,
                },
              )}
            </div>
          </li>
        );
      }

      case ShiftExchangeViolationType.SINGLE_GROUP_INCOMPATIBILITY_MAX_SIMULT_VIOLATION: {
        const v = violation as SingleGroupIncompatibilityMaxSimultViolation;
        return (
          <li key={index} className="text-xs text-gray-500 ml-4 mb-2">
            {t(
              'generic.shiftExchange.violations.singleGroupIncompatibilityMaxSimultViolation',
            )}
          </li>
        );
      }

      case ShiftExchangeViolationType.SINGLE_GROUP_INCOMPATIBILITY_MIN_SIMULT_VIOLATION: {
        const v = violation as SingleGroupIncompatibilityMinSimultViolation;
        return (
          <li key={index} className="text-xs text-gray-500 ml-4 mb-2">
            {t(
              'generic.shiftExchange.violations.singleGroupIncompatibilityMinSimultViolation',
            )}
          </li>
        );
      }

      case ShiftExchangeViolationType.CROSS_GROUP_INCOMPATIBILITY_VIOLATION: {
        const v = violation as CrossGroupIncompatibilityViolation;
        return (
          <li key={index} className="text-xs text-gray-500 ml-4 mb-2">
            {t(
              'generic.shiftExchange.violations.crossGroupIncompatibilityViolation',
            )}
          </li>
        );
      }

      default:
        return null;
    }
  };

  return (
    <ul className="list-none text-sm text-gray-600">
      {Object.entries(violationsByType).map(([type, typeViolations]) => (
        <li key={type} className="mb-2">
          <div className="font-medium">
            {t(`generic.shiftExchange.violations.${type}.title`)}
          </div>
          <ul className="list-disc list-inside ml-4">
            {typeViolations.map((violation, index) =>
              renderViolation(violation, index),
            )}
          </ul>
        </li>
      ))}
    </ul>
  );
}
