/* eslint-disable react/require-default-props */
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import type {
  Section,
  SectionSlot as SectionSlotType,
  SectionWithSlots,
  SlotLabel,
  SupportedLocale,
  User,
  UserRequirementInclusionType,
  VirtualSlot as VirtualSlotType,
} from '@youshift/shared/types';
import {
  arrangeSlots,
  ColorIndex,
  ColorName,
  dateToString,
  divideSlotsIntoWeeks,
  getCustomDateRange,
  getDifferenceInColumns,
  getDifferenceInMinutes,
  GRID_GRANULARITY,
  localeNormalizer,
  returnColor,
} from '@youshift/shared/utils';
import { MouseEventHandler, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Epa } from '../../layouts/IterationRootLayout/types';
import { ArrangedSectionSlot } from '../../pages/Manager/IterationConfig/Sections/types';
import i18n from '../../utils/i18n';
import {
  SectionSlot,
  SectionSlotSelect,
  SectionSlotVirgueria,
} from '../SectionSlot';
import { VirtualSlotWeek } from '../VirtualSlotWeek';
import { VirgueriaVersion } from './types';
import {
  getAreSlotsFull,
  getAssignmentsByVirtualSlot,
  getSectionToVirtualSlotDict,
} from './helpers';
import {
  checkAllVirtualSlotsMaxIncompatibility,
  VirtualSlotTripletError,
} from '../../pages/Manager/IterationConfig/Sections/utils';
/* ------------------------------------------------------------------
 * TYPES
 * ------------------------------------------------------------------ */

export type VirgueriaProps = {
  children?: ReactNode;
  end: string;
  epa?: Epa;
  inclusionType?: UserRequirementInclusionType;
  labels: SlotLabel[];
  onDayClick?: (d: Date) => void;
  onIconClick?: (slot: ArrangedSectionSlot) => void;
  onSectionClick?: (s: Section) => void;
  onSlotClick?: (slotId: number) => void;
  onVirtualSlotClick?: (slotId: number) => void;
  sectionsWithSlots: SectionWithSlots[];
  selectedPeople?: Set<number>;
  selectedSlots?: Set<number>;
  selectedVirtualSlots?: Set<number>;
  shadeMap: Record<number, ColorIndex>;
  showRestPeriod?: boolean;
  start: string;
  users?: Record<number, User>;
  version: VirgueriaVersion;
};

/* ------------------------------------------------------------------
 * SUBCOMPONENTS
 * ------------------------------------------------------------------ */

/* --------------------- VirgueriaDayHeader ------------------------ */
export interface VirgueriaDayHeaderProps {
  day: Date;
  dayIdx: number;
  locale: SupportedLocale;
  onDayClick?: (d: Date) => void;
  weekLength: number;
}

/**
 * Renders the day header with the day name and mini tick marks (6,12,18).
 */
export function VirgueriaDayHeader({
  day,
  dayIdx,
  locale,
  onDayClick,
  weekLength,
}: VirgueriaDayHeaderProps) {
  const isFirst = dayIdx === 0;
  const isLast = dayIdx === weekLength - 1;

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (onDayClick) onDayClick(day);
  };

  return (
    <button
      type="button"
      className={`text-white relative bg-gray-400 border mb-1 border-gray-500 ${
        isFirst ? 'rounded-l-lg' : isLast ? 'rounded-r-lg' : ''
      }`}
      onClick={handleClick}
    >
      <p className="text-center font-bold">
        {dateToString(day, 'weekday', locale)}
      </p>

      {/* Ticks: 6,12,18 */}
      <div className="w-[1px] h-[3px] bg-gray-400 absolute left-[12%]" />
      <div className="absolute left-1/4 flex flex-col justify-start items-start">
        <div className="w-[1px] h-1 bg-gray-600" />
        <p className="text-xxxs text-gray-600 relative -left-0.5">6</p>
      </div>
      <div className="w-[1px] h-[3px] bg-gray-400 absolute left-[37%]" />
      <div className="absolute left-1/2 flex flex-col justify-start items-start">
        <div className="w-[1px] h-1 bg-gray-600" />
        <p className="text-xxxs text-gray-600 relative -left-1">12</p>
      </div>
      <div className="w-[1px] h-[3px] bg-gray-400 absolute left-[62%]" />
      <div className="absolute left-3/4 flex flex-col justify-start items-start">
        <div className="w-[1px] h-1 bg-gray-600" />
        <p className="text-xxxs text-gray-600 relative -left-1">18</p>
      </div>
      <div className="w-[1px] h-[3px] bg-gray-400 absolute left-[87%]" />
    </button>
  );
}

/* ---------------------- VirtualSlotsGrid ------------------------- */
export interface VirtualSlotsGridProps {
  assignmentsByVirtualSlot?: Record<number, number>;
  isVirgueria: boolean;
  onVirtualSlotClick?: (slotId: number) => void;
  sectionColor: ColorName;
  selectedVirtualSlots?: Set<number>;
  virtualSlotsForWeek: VirtualSlotType[];
  week: Date[];
  virtualSlotErrors: Record<number, VirtualSlotTripletError>;
}

/**
 * Grid for arranged virtual slots (top portion).
 */
export function VirtualSlotsGrid({
  assignmentsByVirtualSlot,
  isVirgueria,
  onVirtualSlotClick,
  sectionColor,
  selectedVirtualSlots,
  virtualSlotsForWeek,
  week,
  virtualSlotErrors,
}: VirtualSlotsGridProps) {
  const rows = arrangeSlots<VirtualSlotType>(virtualSlotsForWeek);

  return (
    <>
      {rows.map((row, rowIndex) => (
        <VirtualSlotWeek
          key={`virtual-row-${rowIndex}`}
          rowIndex={rowIndex}
          row={row}
          week={week}
          assignmentsByVirtualSlot={assignmentsByVirtualSlot}
          isVirgueria={isVirgueria}
          onVirtualSlotClick={onVirtualSlotClick}
          sectionColor={sectionColor}
          selectedVirtualSlots={selectedVirtualSlots}
          virtualSlotErrors={virtualSlotErrors}
        />
      ))}
    </>
  );
}

/* ---------------------- SectionSlotsGrid ------------------------- */
export interface SectionSlotsGridProps {
  areSlotsFull?: Record<number, boolean>;
  epa?: Epa;
  inclusionType?: UserRequirementInclusionType;
  isRules: boolean;
  isVirgueria: boolean;
  labels: SlotLabel[];
  onIconClick?: (slot: ArrangedSectionSlot) => void;
  onSlotClick?: (slot: number) => void;
  sectionColor: ColorName;
  selectedPeople?: Set<number>;
  selectedSlots?: Set<number>;
  shadeMap: Record<number, ColorIndex>;
  showRestPeriod?: boolean;
  slotsForWeek: SectionSlotType[];
  users?: Record<number, User>;
  week: Date[];
}

/**
 * Grid for the actual section slots (bottom portion).
 */
export function SectionSlotsGrid({
  areSlotsFull,
  epa,
  inclusionType,
  isRules,
  isVirgueria,
  labels,
  onIconClick,
  onSlotClick,
  sectionColor,
  selectedPeople,
  selectedSlots,
  shadeMap,
  showRestPeriod,
  slotsForWeek,
  users,
  week,
}: SectionSlotsGridProps) {
  const rows = arrangeSlots<SectionSlotType>(slotsForWeek);
  const locale = localeNormalizer(i18n.language);

  return (
    <>
      {rows.map((row, rowIndex) => (
        <div
          key={`section-row-${rowIndex}`}
          className="grid"
          style={{
            gridTemplateColumns: `repeat(${GRID_GRANULARITY * 168}, 1fr)`,
          }}
        >
          {row.map(slot => {
            const diffFromStart = getDifferenceInColumns(week[0], slot.start);
            const duration = getDifferenceInColumns(slot.start, slot.end);
            const shade = shadeMap[slot.id_slot_label];
            const labelObj = labels.find(
              label => slot.id_slot_label === label.id_slot_label,
            );
            const icon = labelObj?.icon;

            const {
              id_pref_slot,
              rest_period,
              id_slot_label,
              id_section_slot,
              spanning,
            } = slot;

            const arrangedSectionSlot: ArrangedSectionSlot = {
              start_position:
                diffFromStart === 0 ? diffFromStart + 1 : diffFromStart,
              end_position: diffFromStart + duration,
              start_time: dateToString(slot.start, 'hh:mm', locale),
              end_time: dateToString(slot.end, 'hh:mm', locale),
              duration_minutes: getDifferenceInMinutes(slot.start, slot.end),
              id_pref_slot,
              rest_period,
              id_slot_label,
              id_section_slot,
              spanning,
            };

            const isSlotFull = areSlotsFull?.[slot.id_section_slot] ?? false;

            // Decide which subcomponent to render:
            if (isRules) {
              return (
                <SectionSlotSelect
                  key={slot.id_section_slot}
                  labelIcon={icon}
                  onClick={onSlotClick}
                  inclusionType={inclusionType}
                  sectionColor={sectionColor}
                  selectedSlots={selectedSlots || new Set()}
                  shade={shade}
                  slot={arrangedSectionSlot}
                />
              );
            }

            if (isVirgueria) {
              return (
                <SectionSlotVirgueria
                  end={slot.end}
                  epa={epa}
                  isSlotFull={Boolean(isSlotFull)}
                  key={slot.id_section_slot}
                  labelIcon={icon}
                  sectionColor={sectionColor}
                  selectedPeople={selectedPeople || new Set<number>()}
                  shade={shade}
                  showRestPeriod={Boolean(showRestPeriod)}
                  slot={arrangedSectionSlot}
                  start={slot.start}
                  users={users}
                />
              );
            }

            // Default case (SectionSlot).
            return (
              <SectionSlot
                Icon={PencilSquareIcon}
                isSelected={selectedSlots?.has(slot.id_section_slot) || false}
                key={slot.id_section_slot}
                labelIcon={icon}
                onIconClick={onIconClick}
                onSlotClick={onSlotClick}
                sectionColor={sectionColor}
                shade={shade}
                slot={arrangedSectionSlot}
              />
            );
          })}
        </div>
      ))}
    </>
  );
}

/* ------------------------- SectionRow ---------------------------- */
export interface SectionRowProps {
  collapsedSections: Set<number>;
  epa?: Epa;
  indexInSections: number;
  isRules: boolean;
  isSection: boolean;
  isVirgueria: boolean;
  inclusionType?: UserRequirementInclusionType;
  labels: SlotLabel[];
  onIconClick?: (slot: ArrangedSectionSlot) => void;
  onSectionClick?: (s: Section) => void;
  onSlotClick?: (slot: number) => void;
  onVirtualSlotClick?: (slotId: number) => void;
  section: Section;
  selectedPeople?: Set<number>;
  selectedSlots?: Set<number>;
  selectedVirtualSlots?: Set<number>;
  shadeMap: Record<number, ColorIndex>;
  showRestPeriod?: boolean;
  slotsForWeek: SectionSlotType[];
  totalSections: number; // total length of sectionsWithSlots, for rounding corners
  updateCollapsedSections: (idSection: number) => void;
  users?: Record<number, User>;
  virtualSlotsForWeek: VirtualSlotType[];
  week: Date[];
  virtualSlotErrors: Record<number, VirtualSlotTripletError>;
}

export function SectionRow({
  collapsedSections,
  epa,
  indexInSections,
  isRules,
  isSection,
  isVirgueria,
  inclusionType,
  labels,
  onIconClick,
  onSectionClick,
  onSlotClick,
  onVirtualSlotClick,
  section,
  selectedPeople,
  selectedSlots,
  selectedVirtualSlots,
  shadeMap,
  showRestPeriod,
  slotsForWeek,
  totalSections,
  updateCollapsedSections,
  users,
  virtualSlotsForWeek,
  week,
  virtualSlotErrors,
}: SectionRowProps) {
  const { t } = useTranslation();
  // Compute "assignmentsByVirtualSlot" if epa is provided
  const assignmentsByVirtualSlot = getAssignmentsByVirtualSlot(
    epa,
    virtualSlotsForWeek,
  );

  // Compute mapping from section slot to virtual slot IDs
  const sectionToVirtualSlot = isVirgueria
    ? getSectionToVirtualSlotDict(virtualSlotsForWeek)
    : {};

  // Compute "areSlotsFull"
  const areSlotsFull = isVirgueria
    ? getAreSlotsFull(
        assignmentsByVirtualSlot,
        sectionToVirtualSlot,
        virtualSlotsForWeek,
      )
    : {};

  // Corner rounding logic
  const isLastInSectionList = indexInSections === totalSections - 1;
  const isFirstInSectionList = indexInSections === 0;

  const isSectionShown = !collapsedSections.has(section.id_section);

  return (
    <>
      {/* Left cell: Section acronym (for isVirgueria or rule with more than one section) */}
      {(isVirgueria || (isRules && totalSections > 1)) && (
        <button
          type="button"
          className={`flex row-span-2 justify-center items-center border-b-2 ${
            isVirgueria && isLastInSectionList ? 'rounded-bl-md' : ''
          } ${isFirstInSectionList ? 'rounded-tl-md' : ''}`}
          style={{ backgroundColor: returnColor(section.color) }}
          onClick={() => onSectionClick && onSectionClick(section)}
          disabled={isRules && !isSectionShown}
        >
          <p className="font-semibold">{section.acronym}</p>
        </button>
      )}
      {/* Middle top or left top corner (for isVirgueria or not isRules) */}
      {!isRules && isSectionShown && (
        <div
          style={{
            backgroundColor: isVirgueria ? returnColor(section.color) : 'white',
          }}
          className={`relative h-full ${slotsForWeek.length && 'border-b border-b-gray-500'} ${
            isSection ? 'border-r border-r-gray-500' : ''
          } flex justify-center items-center mr-1 pr-1 ${
            isVirgueria && isFirstInSectionList ? 'rounded-tr-md' : ''
          }`}
        >
          <p
            className={`text-xxs ${
              isVirgueria ? 'relative -left-2/3' : ''
            } ${isSection ? '-rotate-90' : ''} ${!slotsForWeek.length && 'invisible'}`}
          >
            {isVirgueria
              ? t('manager.shiftAssignment.assignedPeople')
              : t('manager.sectionsConfig.needs')}
          </p>
        </div>
      )}
      {/* Virtual Slots (top grid) */}
      <div
        className={`col-span-7 grid text-center auto-rows-min relative ${
          isSection ? 'my-4' : 'my-1'
        }`}
      >
        {isRules && (
          <button
            type="button"
            className="absolute -top-2 w-5 h-5 cursor-pointer"
            onClick={() => updateCollapsedSections(section.id_section)}
          >
            {isSectionShown ? (
              <ChevronDownIcon className="w-5 h-5" />
            ) : (
              <ChevronUpIcon className="w-5 h-5" />
            )}
          </button>
        )}
        {!isRules && isSectionShown && (
          <VirtualSlotsGrid
            assignmentsByVirtualSlot={assignmentsByVirtualSlot}
            isVirgueria={isVirgueria}
            onVirtualSlotClick={onVirtualSlotClick}
            sectionColor={section.color}
            selectedVirtualSlots={selectedVirtualSlots}
            virtualSlotsForWeek={virtualSlotsForWeek}
            week={week}
            virtualSlotErrors={virtualSlotErrors}
          />
        )}
      </div>

      {/* Right top or middle cell (not isRules) */}
      {!isRules && isSectionShown && (
        <div
          style={{
            backgroundColor: isVirgueria ? returnColor(section.color) : 'white',
          }}
          className={`h-full ${
            isSection ? 'border-r border-r-gray-500' : ''
          } ${isVirgueria ? 'border-b-2' : ''} flex justify-center items-center mr-1 pr-1 ${
            isVirgueria && isLastInSectionList ? 'rounded-br-md' : ''
          }`}
        >
          <p
            className={`text-xxs -rotate-90 ${!slotsForWeek.length && 'invisible'}`}
          >
            {t('generic.shifts')}
          </p>
        </div>
      )}

      {/* Section Slots (bottom grid) */}
      <div className="relative col-span-7 grid text-center auto-rows-min">
        {isSectionShown && (
          <SectionSlotsGrid
            areSlotsFull={areSlotsFull}
            epa={epa}
            inclusionType={inclusionType}
            isRules={isRules}
            isVirgueria={isVirgueria}
            labels={labels}
            onIconClick={onIconClick}
            onSlotClick={onSlotClick}
            sectionColor={section.color}
            selectedPeople={selectedPeople}
            selectedSlots={selectedSlots}
            shadeMap={shadeMap}
            showRestPeriod={showRestPeriod}
            slotsForWeek={slotsForWeek}
            users={users}
            week={week}
          />
        )}
      </div>
    </>
  );
}

/* ------------------------------------------------------------------
 * MAIN COMPONENT
 * ------------------------------------------------------------------ */
export function Virgueria({
  end,
  epa,
  labels,
  onDayClick,
  inclusionType,
  onIconClick,
  onSectionClick,
  onSlotClick,
  onVirtualSlotClick,
  sectionsWithSlots,
  selectedPeople,
  selectedSlots,
  selectedVirtualSlots,
  shadeMap,
  showRestPeriod,
  start,
  users,
  version,
}: VirgueriaProps) {
  const locale = localeNormalizer(i18n.language);

  // Determine which "mode" we are in
  const isRules =
    version === VirgueriaVersion.RulesCreating ||
    version === VirgueriaVersion.RulesEditing;
  const isVirgueria = version === VirgueriaVersion.Virgueria;
  const isSection = version === VirgueriaVersion.Section;

  // Build array of weeks
  const weeks = getCustomDateRange(start, end, locale, 'virgueria');

  // For each section, divide slots into weeks
  const slotsPerSectionPerWeek = useMemo(
    () =>
      sectionsWithSlots.map(({ section, section_slots, virtual_slots }) => ({
        section,
        slotsPerWeek: divideSlotsIntoWeeks<SectionSlotType>(
          weeks,
          section_slots,
        ),
        virtualSlotsPerWeek: divideSlotsIntoWeeks<VirtualSlotType>(
          weeks,
          virtual_slots,
        ),
      })),
    [weeks, sectionsWithSlots],
  );

  const sectionsIdsWithNoSelectedSlots = sectionsWithSlots
    .filter(({ section_slots }) =>
      Object.keys(section_slots).every(
        sectionSlotId => !selectedSlots?.has(Number(sectionSlotId)),
      ),
    )
    .map(({ section }) => section.id_section);

  const [collapsedSections, setCollapsedSections] = useState<Set<number>>(
    new Set(
      version === VirgueriaVersion.RulesEditing
        ? sectionsIdsWithNoSelectedSlots
        : [],
    ),
  );
  const updateCollapsedSections = (idSection: number) => {
    setCollapsedSections(prev => {
      const newSet = new Set(prev);
      newSet.has(idSection) ? newSet.delete(idSection) : newSet.add(idSection);
      return newSet;
    });
  };

  if (isSection && sectionsWithSlots.length !== 1) {
    throw new Error('Virgueria: isSection must have exactly one section');
  }
  // On SectionConfig, we need to check all virtual slots for incompatibilities
  // between triplets of adjacent virtual slots.
  const virtualSlotErrors = useMemo(() => {
    return isSection
      ? checkAllVirtualSlotsMaxIncompatibility(
          Object.values(sectionsWithSlots[0].virtual_slots),
        )
      : {};
  }, [sectionsWithSlots, isSection]);

  return (
    <div className="w-full">
      {weeks.map((week, weekIdx) => {
        // Decide on the grid template columns based on the mode
        const gridTemplateColumns = isRules
          ? sectionsWithSlots.length === 1
            ? 'repeat(7, 1fr)'
            : 'minmax(auto, 75px) repeat(7, 1fr)'
          : isSection
            ? '35px repeat(7, 1fr)'
            : 'minmax(auto, 75px) 35px repeat(7, 1fr)';

        return (
          <div
            key={`week-row-${weekIdx}`}
            className="grid auto-cols-auto auto-rows-min mt-4 mb-2 pb-2"
            style={{ gridTemplateColumns }}
          >
            {/* PADDING DIVS (left/middle placeholders if needed) */}
            {(isVirgueria ||
              isSection ||
              (isRules && sectionsWithSlots.length > 1)) && <div />}
            {isVirgueria && <div />}

            {/* Day headers for this week */}
            {week.map((day, dayIdx) => (
              <VirgueriaDayHeader
                key={`day-${dayIdx}`}
                day={day}
                dayIdx={dayIdx}
                weekLength={week.length}
                onDayClick={(d: Date) => {
                  if (onDayClick) onDayClick(d);
                  setCollapsedSections(new Set());
                }}
                locale={locale}
              />
            ))}

            {/* For each section, render a row */}
            {slotsPerSectionPerWeek.map(
              ({ section, slotsPerWeek, virtualSlotsPerWeek }, idx) => {
                const virtualSlotsForWeek = virtualSlotsPerWeek[weekIdx] || [];
                const sectionSlotsForWeek = slotsPerWeek[weekIdx] || [];

                return (
                  <SectionRow
                    collapsedSections={collapsedSections}
                    epa={epa}
                    indexInSections={idx}
                    isRules={isRules}
                    isSection={isSection}
                    isVirgueria={isVirgueria}
                    inclusionType={inclusionType}
                    key={`section-row-${section.id_section}-${weekIdx}`}
                    labels={labels}
                    onIconClick={onIconClick}
                    onSectionClick={onSectionClick}
                    onSlotClick={onSlotClick}
                    onVirtualSlotClick={onVirtualSlotClick}
                    section={section}
                    selectedPeople={selectedPeople}
                    selectedSlots={selectedSlots}
                    selectedVirtualSlots={selectedVirtualSlots}
                    shadeMap={shadeMap}
                    showRestPeriod={showRestPeriod}
                    slotsForWeek={sectionSlotsForWeek}
                    totalSections={sectionsWithSlots.length}
                    updateCollapsedSections={updateCollapsedSections}
                    users={users}
                    virtualSlotsForWeek={virtualSlotsForWeek}
                    week={week}
                    virtualSlotErrors={virtualSlotErrors}
                  />
                );
              },
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Virgueria;
