import { SectionSlot, SectionSlotId } from '@youshift/shared/types';

import { datetimeRangeOverlap } from '../datetime/datetime_utils';

interface IncompatibleSectionSlots {
  // Section slots that are overlapping in time with the base SectionSlot (from idSectionSlot)
  // E.g Slot A [8-17] overlaps with Slot B [14-21]
  overlappingSectionSlots: number[];

  // Section slots that are overlapping with the restPeriod of the base SectionSlot (from idSectionSlot)
  // E.g Base Slot A [8-17] has a rest period of 3 hours which means that Slot B [19-23] is overlapping with Slot A's rest period.
  customRestPeriodOverlaps: number[];

  // Section slots that are overlapping with the 24 hour rest period of the base SectionSlot (from idSectionSlot)
  // E.g Base Slot A [8-17] has a rest period of 24 hours which means that Slot B [25-29] is overlapping with Slot A's rest period.
  base24HourRestPeriodOverlaps: number[];

  // Section slots whose rest period overlaps with the period of the base SectionSlot.
  // E.g Slot C [8-11] has a rest of period of 3 hours which means that it overlaps with Base Slot A [13-21] duration.
  overlapedByRestPeriod: number[];
}

/**
 * Map of section slot IDs to their incompatible section slots
 */
export type IncompatibleSectionSlotsMap = Record<
  SectionSlotId,
  IncompatibleSectionSlots
>;

// Interface of relevant section slot info for computing incompatible section slots
export interface SectionSlotInfo {
  idSectionSlot: SectionSlotId;
  start: Date;
  end: Date;
  restPeriod: number;
}

/**
 * Compute dictionary overlapping section slots given a list of SectionSlots
 *
 * @param sectionSlots Dictionary mapping section slot IDs to their info
 * @returns Dictionary mapping section slot IDs to their incompatible slots
 */
export function buildIncompatibleSectionSlotsMap(
  sectionSlots: Record<number, SectionSlotInfo>,
): IncompatibleSectionSlotsMap {
  const incompatibleSlots: IncompatibleSectionSlotsMap = {};

  // Initialize empty incompatible slots for each section slot
  Object.keys(sectionSlots).forEach(id => {
    incompatibleSlots[Number(id)] = {
      overlappingSectionSlots: [],
      customRestPeriodOverlaps: [],
      base24HourRestPeriodOverlaps: [],
      overlapedByRestPeriod: [],
    };
  });

  // Compare each slot with every other slot
  const slotIds = Object.keys(sectionSlots).map(Number);
  for (let currentIdx = 0; currentIdx < slotIds.length; currentIdx++) {
    const currentSlotId = slotIds[currentIdx];
    const currentSlotInfo = sectionSlots[currentSlotId];

    const currentStart = currentSlotInfo.start;
    const currentEnd = currentSlotInfo.end;
    const currentRestPeriod = currentSlotInfo.restPeriod;

    for (
      let compareIdx = currentIdx + 1;
      compareIdx < slotIds.length;
      compareIdx++
    ) {
      const compareIdSectionSlot = slotIds[compareIdx];
      const compareSlotInfo = sectionSlots[compareIdSectionSlot];
      const compareStart = compareSlotInfo.start;
      const compareEnd = compareSlotInfo.end;
      const compareRestPeriod = compareSlotInfo.restPeriod;

      // Check if the two slots overlap
      if (
        datetimeRangeOverlap(currentStart, currentEnd, compareStart, compareEnd)
      ) {
        incompatibleSlots[currentSlotId].overlappingSectionSlots.push(
          compareIdSectionSlot,
        );
        incompatibleSlots[compareIdSectionSlot].overlappingSectionSlots.push(
          currentSlotId,
        );
      } else {
        // Only check for rest period overlaps if there's no direct overlap
        if (
          datetimeRangeOverlap(
            currentEnd,
            new Date(currentEnd.getTime() + currentRestPeriod * 60 * 1000),
            compareStart,
            compareEnd,
          )
        ) {
          incompatibleSlots[currentSlotId].customRestPeriodOverlaps.push(
            compareIdSectionSlot,
          );
          incompatibleSlots[compareIdSectionSlot].overlapedByRestPeriod.push(
            currentSlotId,
          );
        }

        if (
          datetimeRangeOverlap(
            compareEnd,
            new Date(compareEnd.getTime() + compareRestPeriod * 60 * 1000),
            currentStart,
            new Date(currentStart.getTime() + currentRestPeriod * 60 * 1000),
          )
        ) {
          incompatibleSlots[compareIdSectionSlot].overlapedByRestPeriod.push(
            currentSlotId,
          );
          incompatibleSlots[currentSlotId].customRestPeriodOverlaps.push(
            compareIdSectionSlot,
          );
        }
      }
    }
  }

  if (
    Object.keys(incompatibleSlots).length !== Object.keys(sectionSlots).length
  ) {
    throw new Error('Incompatible slots length mismatch');
  }

  return incompatibleSlots;
}

/**
 * Find incompatible section slots for a single section slot
 *
 * @param targetSlot The section slot to find incompatibilities for
 * @param allSectionSlots Dictionary mapping section slot IDs to their info
 * @returns Object containing lists of incompatible slots by type
 */
export function findIncompatibleSectionSlots(
  targetSlot: SectionSlotInfo,
  allSectionSlots: Record<number, SectionSlotInfo>,
): IncompatibleSectionSlots {
  const incompatibleSlots: IncompatibleSectionSlots = {
    overlappingSectionSlots: [],
    customRestPeriodOverlaps: [],
    base24HourRestPeriodOverlaps: [],
    overlapedByRestPeriod: [],
  };

  const targetStart = new Date(targetSlot.start);
  const targetEnd = new Date(targetSlot.end);
  const targetRestPeriod = targetSlot.restPeriod;

  Object.entries(allSectionSlots).forEach(([idStr, compareSlot]) => {
    const compareId = Number(idStr);
    if (compareId === targetSlot.idSectionSlot) return; // Skip self-comparison

    const compareStart = new Date(compareSlot.start);
    const compareEnd = new Date(compareSlot.end);
    const compareRestPeriod = compareSlot.restPeriod;

    // Check direct overlap
    if (
      datetimeRangeOverlap(targetStart, targetEnd, compareStart, compareEnd)
    ) {
      incompatibleSlots.overlappingSectionSlots.push(compareId);
    } else {
      // Check rest period overlaps
      if (
        datetimeRangeOverlap(
          targetEnd,
          new Date(targetEnd.getTime() + targetRestPeriod * 60 * 1000),
          compareStart,
          compareEnd,
        )
      ) {
        incompatibleSlots.customRestPeriodOverlaps.push(compareId);
      }

      if (
        datetimeRangeOverlap(
          compareEnd,
          new Date(compareEnd.getTime() + compareRestPeriod * 60 * 1000),
          targetStart,
          targetEnd,
        )
      ) {
        incompatibleSlots.overlapedByRestPeriod.push(compareId);
      }
    }
  });

  return incompatibleSlots;
}
