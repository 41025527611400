import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseMutationResult, useQueryClient } from '@tanstack/react-query';
import {
  EditNeedsParams,
  useDeleteSlotsMutation,
} from '@youshift/shared/hooks/mutations';
import { SectionSlot, SlotLabel } from '@youshift/shared/types';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import Modal from '../../../../components/Modal';
import { SectionSlotsReducerActionType } from './reducers';

interface BulkEditSlotsProps {
  isBulkEditSlotsOpen: boolean;
  setIsBulkEditSlotsOpen: (open: boolean) => void;
  setIsBulkDeleteConfirmationOpen: (open: boolean) => void;
  selectedSectionSlots: Set<number>;
  setSelectedSectionSlots: (slots: Set<number>) => void;
  sectionSlots: Record<number, SectionSlot>;
  shiftLabels: SlotLabel[];
  newLabelId: number | undefined;
  setNewLabelId: Dispatch<SetStateAction<number | undefined>>;
  handleSectionSlotsDispatch: (action: SectionSlotsReducerActionType) => void;
  editNeeds: UseMutationResult<object, Error, EditNeedsParams>;
}

export default function BulkEditSlots({
  isBulkEditSlotsOpen,
  setIsBulkEditSlotsOpen,
  setIsBulkDeleteConfirmationOpen,
  selectedSectionSlots,
  setSelectedSectionSlots,
  sectionSlots,
  shiftLabels,
  newLabelId,
  setNewLabelId,
  handleSectionSlotsDispatch,
  editNeeds,
}: BulkEditSlotsProps) {
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { idItr, idSection } = useParams();

  const deleteSlotsMutation = useDeleteSlotsMutation(queryClient, {
    onSuccess: () => {
      handleSectionSlotsDispatch({
        type: 'DELETE_SLOTS',
        idSectionSlots: Array.from(selectedSectionSlots),
      });
      setIsBulkEditSlotsOpen(false);
      setSelectedSectionSlots(new Set());
      queryClient.invalidateQueries({
        queryKey: ['section', String(idSection)],
      });
      queryClient.invalidateQueries({
        queryKey: ['extendedUserReqRules', String(idItr)],
      });
      queryClient.invalidateQueries({
        queryKey: ['assignmentTool', String(idItr)],
      });
      queryClient.invalidateQueries({
        queryKey: ['shiftAssignments', String(idItr)],
      });
      toast.success(t('manager.sectionsConfig.eliminateSlotSuccessBulk'));
    },
  });

  const handleBulkEditSlotsLabels = () => {
    if (!newLabelId) {
      return;
    }
    handleSectionSlotsDispatch({
      type: 'UPDATE_LABEL',
      newLabelId,
      idSectionSlots: Array.from(selectedSectionSlots),
    });
    editNeeds.mutate({
      id_itr: Number(idItr),
      slot_labels: Object.values(sectionSlots)
        .filter(({ id_section_slot }) =>
          selectedSectionSlots.has(id_section_slot),
        )
        .map(({ id_section_slot }) => ({
          id_section_slot,
          id_slot_label: newLabelId,
        })),
    });
  };

  const handleBulkDeleteSlots = () => {
    if (!isDeleteMode) {
      setIsDeleteMode(true);
    } else if (deleteConfirmationText === t('generic.delete').toLowerCase()) {
      setIsBulkDeleteConfirmationOpen(false);
      deleteSlotsMutation.mutate({
        id_itr: idItr,
        id_section_slots: Array.from(selectedSectionSlots),
      });
    }
  };

  return (
    <Modal
      isOpen={isBulkEditSlotsOpen}
      onClose={() => setIsBulkEditSlotsOpen(false)}
      size="md"
      editButtons
      handleDelete={handleBulkDeleteSlots}
      handleSave={handleBulkEditSlotsLabels}
      loadingSave={editNeeds.isPending}
      loadingDelete={deleteSlotsMutation.isPending}
    >
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">
          {t('manager.sectionsConfig.bulkEditSlots')}
        </h2>
        <div>
          <label htmlFor="label" className="block text-sm text-gray-700">
            {t('manager.sectionsConfig.editLabelForSlots')}
          </label>
          <select
            id="label"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            onChange={e => setNewLabelId(Number(e.target.value))}
            defaultValue={undefined}
            value={newLabelId}
          >
            <option value={undefined}>-</option>
            {shiftLabels.map(label => (
              <option key={label.id_slot_label} value={label.id_slot_label}>
                {label.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <p className="block text-sm text-gray-700 py-1">
        {t('manager.sectionsConfig.orYouCanDeleteThem')}
      </p>
      {isDeleteMode && (
        <div className="mb-3">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            {`${t('manager.sectionsConfig.confirmBulkDeleteSlots')} ${t('generic.delete').toLowerCase()}`}
          </label>
          <input
            id="name"
            type="text"
            placeholder={t('generic.delete').toLowerCase()}
            value={deleteConfirmationText}
            onChange={e => setDeleteConfirmationText(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>
      )}
    </Modal>
  );
}
