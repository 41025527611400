import { useQueryClient } from '@tanstack/react-query';
import {
  useCreateGroupMutation,
  useCreateGroupWithOrgMutation,
} from '@youshift/shared/hooks/auth';
import { Industry } from '@youshift/shared/types';
import { localeNormalizer, passwordRegex } from '@youshift/shared/utils';
import { useReducer, useState, useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { YSButton } from '../../../components/Buttons';
import i18n from '../../../utils/i18n';
import SignUpSuccessful from '../SignUpSuccessful';
import ExistingOrganizationSignUpForm from './ExistingOrganizationSignUpForm';
import NewOrganizationSignUpForm from './NewOrganizationSignUpForm';
import { managerSignUpreducer, managerSignUpState } from './types';
import UserSignUpForm from './UserSignUpForm';

export default function SignUpManager() {
  const { t } = useTranslation();
  const locale = localeNormalizer(i18n.language);

  const { industry: industryStr } = useParams();
  const industry = (industryStr?.toUpperCase() as Industry) || Industry.OTHER;

  const [formState, dispatch] = useReducer(
    managerSignUpreducer,
    managerSignUpState,
  );
  const [success, setSuccess] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [creatingGroupWithExistingOrg, setCreatingGroupWithExistingOrg] =
    useState(industry === Industry.HEALTHCARE);

  const queryClient = useQueryClient();

  const onSuccess = useCallback(() => {
    dispatch({ type: 'RESET' });
    setSuccess(t('auth.signUpManager.successMessage'));
  }, [t]);

  const createGroupMutation = useCreateGroupMutation(queryClient, {
    onSuccess,
  });
  const createGroupWithOrgMutation = useCreateGroupWithOrgMutation(
    queryClient,
    { onSuccess },
  );

  const buttonDisabled = useMemo(() => {
    if (currentStep === 0) {
      const { name, country, state, province, city } = formState.organization;
      return creatingGroupWithExistingOrg
        ? !formState.id_org
        : !name || !country || !state || !province || !city;
    }
    if (currentStep === 1) {
      const { firstname, lastname, email, password, confirmPassword } =
        formState.user;
      return !firstname || !lastname || !email || !password || !confirmPassword;
    }
    return false;
  }, [
    creatingGroupWithExistingOrg,
    currentStep,
    formState.id_org,
    formState.organization,
    formState.user,
  ]);

  const signUpAction = () => {
    setSuccess(null);
    const { user, id_org, organization } = formState;
    const {
      firstname,
      lastname,
      group_name,
      phone,
      sourceOrigin,
      email,
      password,
      confirmPassword,
      has_agreed_to_privacy_policy,
      specialty,
      numBilledUsers,
    } = user;
    const { name, country, state, province, city, address, postal_code } =
      organization || {};

    if (!passwordRegex.test(password)) {
      toast.error(t('auth.passwordRequirements'));
    } else if (!group_name) {
      toast.error(t('auth.groupNameError'));
    } else if (password !== confirmPassword) {
      toast.error(t('auth.passwordNoMatch'));
    } else if (!has_agreed_to_privacy_policy) {
      toast.error(t('auth.signUpManager.privacyPolicyError'));
    } else if (specialty === undefined && industry === Industry.HEALTHCARE) {
      toast.error(t('auth.signUpManager.specialtyError'));
    } else if (sourceOrigin === undefined) {
      toast.error(t('auth.signUpManager.sourceOriginError'));
    } else if (numBilledUsers < 1) {
      toast.error(t('auth.signUpManager.numBilledUsersError'));
    } else if (creatingGroupWithExistingOrg) {
      createGroupMutation.mutate({
        id_org,
        group: {
          group_name,
          email,
          firstname,
          lastname,
          language: locale,
          specialty: industry === Industry.HEALTHCARE ? specialty : undefined,
          sourcing_origin: sourceOrigin,
          phone,
          has_agreed_to_privacy_policy,
          password,
          num_billed_users: numBilledUsers,
        },
      });
    } else {
      if (!name || !country || !city || !state) {
        throw new Error('Invalid organization data');
      }
      createGroupWithOrgMutation.mutate({
        org: {
          name,
          country,
          state,
          province,
          city,
          address,
          postal_code,
          industry: industry || Industry.OTHER,
        },
        group: {
          group_name,
          email,
          firstname,
          lastname,
          language: locale,
          specialty: industry === Industry.HEALTHCARE ? specialty : undefined,
          sourcing_origin: sourceOrigin,
          phone,
          has_agreed_to_privacy_policy,
          password,
          num_billed_users: numBilledUsers,
        },
      });
    }
  };

  if (!industry || !(industry in Industry)) {
    return null;
  }

  return success ? (
    <SignUpSuccessful
      h1={t('auth.signUpManager.successTitle')}
      h2={t('auth.signUpManager.successSubtitle')}
      h3={success}
    />
  ) : (
    <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div className="mx-auto w-full max-w-sm lg:max-w-none lg:w-[450px]">
        <div>
          <img
            className="h-12 w-auto"
            src="https://youshift-docs.s3.eu-west-3.amazonaws.com/youshift_logotype.svg"
            alt="YouShift"
          />
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
            {t('auth.signUpManager.registrationTitle')}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {t('auth.signUpManager.or')}{' '}
            <Link
              to="/login"
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              {t('auth.signUpManager.loginLink')}
            </Link>
          </p>
        </div>
        {currentStep === 0 ? (
          creatingGroupWithExistingOrg ? (
            <ExistingOrganizationSignUpForm
              dispatch={dispatch}
              setCreatingGroupWithExistingOrg={setCreatingGroupWithExistingOrg}
            />
          ) : (
            <NewOrganizationSignUpForm
              organization={formState.organization}
              dispatch={dispatch}
            />
          )
        ) : (
          <UserSignUpForm formState={formState} dispatch={dispatch} />
        )}
        <div className="flex justify-between">
          {currentStep > 0 && (
            <YSButton
              variant="ghost-primary"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              {t('generic.back')}
            </YSButton>
          )}
          {currentStep === 0 ? (
            <YSButton
              onClick={() => setCurrentStep(currentStep + 1)}
              disabled={buttonDisabled}
            >
              {t('generic.next')}
            </YSButton>
          ) : (
            <YSButton
              onClick={signUpAction}
              loading={
                createGroupMutation.isPending ||
                createGroupWithOrgMutation.isPending
              }
              disabled={buttonDisabled}
            >
              {t('generic.create')}
            </YSButton>
          )}
        </div>
      </div>
    </div>
  );
}
