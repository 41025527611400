import {
  RuleTypes,
  SectionWithSlots,
  SlotLabel,
  UserRequirementNumUsersType,
  UserRequirementInclusionType,
} from '@youshift/shared/types';
import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

import { useItrContext } from '../../../../layouts/IterationRootLayout/IterationRootLayout';

export type DefineCustomRuleDetails = {
  inclusionType: UserRequirementInclusionType | null;
  numberOfUsers: UserRequirementNumUsersType | null;
  ruleDescription: string;
  ruleName: string;
  selectedUser: number | null;
  setInclusionType: (
    inclusionType: UserRequirementInclusionType | null,
  ) => void;
  setNumberOfUsers: Dispatch<UserRequirementNumUsersType>;
  setRuleDescription: (description: string) => void;
  setRuleName: (name: string) => void;
  setSelectedUser: (user: number | null) => void;
};

export default function DefineCustomRuleDetails({
  inclusionType,
  numberOfUsers,
  ruleDescription,
  ruleName,
  selectedUser,
  setInclusionType,
  setNumberOfUsers,
  setRuleDescription,
  setRuleName,
  setSelectedUser,
}: DefineCustomRuleDetails) {
  const { t } = useTranslation();
  const { users } = useItrContext();

  return (
    <div className="space-y-8 px-8">
      {/* Who is this rule for? */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-3 pb-1.5 border-b border-blue-600">
          {t('manager.rulesConfig.whoIsThisRuleFor')}
        </h3>
        <fieldset className="rounded-md bg-white">
          <div className="flex">
            <label className="group flex-1 flex cursor-pointer border border-gray-200 p-4 rounded-l-md">
              <input
                type="radio"
                id="single-user"
                value={UserRequirementNumUsersType.SINGLE}
                checked={numberOfUsers === UserRequirementNumUsersType.SINGLE}
                onChange={e =>
                  setNumberOfUsers(
                    e.target.value as UserRequirementNumUsersType,
                  )
                }
                className="mt-0.5 size-4 rounded-full border-gray-300"
              />
              <span className="ml-3">
                <span className="block text-sm font-medium text-gray-900">
                  {t('manager.rulesConfig.singleUser')}
                </span>
              </span>
            </label>
            <label className="group flex-1 flex cursor-pointer border-t border-r border-b border-gray-200 p-4 rounded-r-md">
              <input
                type="radio"
                id="multiple-users"
                value={UserRequirementNumUsersType.MULTIPLE}
                checked={numberOfUsers === UserRequirementNumUsersType.MULTIPLE}
                onChange={e => {
                  setNumberOfUsers(
                    e.target.value as UserRequirementNumUsersType,
                  );
                  setSelectedUser(null);
                }}
                className="mt-0.5 size-4 rounded-full border-gray-300"
              />
              <span className="ml-3">
                <span className="block text-sm font-medium text-gray-900">
                  {t('manager.rulesConfig.multipleUsers')}
                </span>
              </span>
            </label>
          </div>
        </fieldset>

        {numberOfUsers === UserRequirementNumUsersType.SINGLE && (
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">
              {t('manager.rulesConfig.selectUser')}
            </label>
            <select
              value={selectedUser || ''}
              onChange={e =>
                setSelectedUser(e.target.value ? Number(e.target.value) : null)
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            >
              <option value="">
                {t('manager.rulesConfig.selectUserPlaceholder')}
              </option>
              {Object.entries(users).map(([userId, user]) => (
                <option key={userId} value={userId}>
                  {`${user.firstname} ${user.lastname}`}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      {/* What type of rule is this? */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-3 pb-1.5 border-b border-blue-600">
          {t('manager.rulesConfig.whatTypeOfRule')}
        </h3>
        <fieldset className="rounded-md bg-white">
          <div className="flex">
            <label className="group flex-1 flex cursor-pointer border border-gray-200 p-4 rounded-l-md">
              <input
                type="radio"
                id="inclusion"
                value={UserRequirementInclusionType.INCLUSION}
                checked={
                  inclusionType === UserRequirementInclusionType.INCLUSION
                }
                onChange={e =>
                  setInclusionType(
                    e.target.value as UserRequirementInclusionType,
                  )
                }
                className="mt-0.5 size-4 rounded-full border-gray-300"
              />
              <span className="ml-3">
                <span className="block text-sm font-medium text-gray-900">
                  {t('manager.rulesConfig.inclusion')}
                </span>
                <span className="block text-sm text-gray-500">
                  {t('manager.rulesConfig.inclusionDescription')}
                </span>
              </span>
            </label>
            <label className="group flex-1 flex cursor-pointer border-t border-r border-b border-gray-200 p-4 rounded-r-md">
              <input
                type="radio"
                id="exclusion"
                value={UserRequirementInclusionType.EXCLUSION}
                checked={
                  inclusionType === UserRequirementInclusionType.EXCLUSION
                }
                onChange={e =>
                  setInclusionType(
                    e.target.value as UserRequirementInclusionType,
                  )
                }
                className="mt-0.5 size-4 rounded-full border-gray-300"
              />
              <span className="ml-3">
                <span className="block text-sm font-medium text-gray-900">
                  {t('manager.rulesConfig.exclusion')}
                </span>
                <span className="block text-sm text-gray-500">
                  {t('manager.rulesConfig.exclusionDescription')}
                </span>
              </span>
            </label>
          </div>
        </fieldset>
      </div>

      {/* Rule Name and Description */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-3 pb-1.5 border-b border-blue-600">
          {t('manager.rulesConfig.ruleDetails')}
        </h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t('manager.rulesConfig.summarizeRule')}
            </label>
            <input
              type="text"
              value={ruleName}
              onChange={e => setRuleName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              placeholder={t('manager.rulesConfig.summarizeRulePlaceholder')}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t('manager.rulesConfig.writeRuleDescription')}
            </label>
            <input
              type="text"
              value={ruleDescription}
              onChange={e => setRuleDescription(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              placeholder={t(
                'manager.rulesConfig.writeRuleDescriptionPlaceholder',
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
