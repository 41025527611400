import {
  ShiftAssignment,
  SpecialEvent,
  UserId,
  SectionSlotId,
  RequirementRuleId,
  IncompatibilityId,
  SlotId,
  VirtualSlotId,
} from '@youshift/shared/types';

// Types of errors that can occur during assignment checks.
/**
 * Each error type defined in AssignmentCheckErrorType enum must have a corresponding
 * Error interface that defines the structure of the error context data for that specific type.
 * This ensures consistent error handling and type safety throughout the application.
 */
export enum AssignmentCheckErrorType {
  USER_REQ_SLOTS_DEFICIT = 'user_req_slots_deficit',
  USER_REQ_SLOTS_SURPLUS = 'user_req_slots_surplus',
  USER_REQ_DURATION_DEFICIT = 'user_req_duration_deficit',
  USER_REQ_DURATION_SURPLUS = 'user_req_duration_surplus',
  CROSS_GROUP_INCOMPATIBILITY = 'cross_group_incompatibility',
  SINGLE_GROUP_INCOMPATIBILITY_SURPLUS = 'single_group_incompatibility_surplus',
  SINGLE_GROUP_INCOMPATIBILITY_DEFICIT = 'single_group_incompatibility_deficit',
  SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK = 'assignment_on_justified_block',
  SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK = 'assignment_on_personal_block',
  SHIFT_ASSIGNMENT_ON_EVENT = 'assignment_on_event',
  SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION = 'assignment_rest_period_violation',
  SHIFT_ASSIGNMENT_ON_OVERLAPPING_ASSIGNMENT = 'assignment_on_overlapping_assignment',
  VIRTUAL_SLOT_NEEDS_DEFICIT = 'virtual_slot_needs_deficit',
  VIRTUAL_SLOT_NEEDS_SURPLUS = 'virtual_slot_needs_surplus',
  DEFAULT = 'default',
}

// Falta de turnos asignados
// '{{user}} tiene {{numAssignments}} y necesita mínimo {{minSlots}}'
export interface UserReqSlotsDeficitErrorContext {
  assigned_slots: number;
  min_req: number;
}

// Exceso de turnos asignados
// '{{user}} tiene {{numAssignments}} y puede hacer máximo {{maxSlots}}'
export interface UserReqSlotsSurplusErrorContext {
  assigned_slots: number;
  max_req: number;
}

// Falta de duración asignada
// '{{user}} tiene {{numAssignments}} y necesita mínimo {{minDuration}}'
export interface UserReqDurationDeficitErrorContext {
  // Assigned units can be either number of slots or duration in minutes
  assigned_duration_minutes: number;
  min_req_minutes: number;
}

// Exceso de duración asignada
// '{{user}} tiene {{numAssignments}} y puede hacer máximo {{maxDuration}}'
export interface UserReqDurationSurplusErrorContext {
  // Assigned units can be either number of slots or duration in minutes
  assigned_duration_minutes: number;
  max_req_minutes: number;
}

// no subtitle
// '{{datetime}}: {{assigned_users_group_1 (list)}} y {{assigned_users_group_2 (list)}} no pueden estar juntos.'
export interface CrossGroupIncompErrorContext {
  assigned_users_group_1: number[];
  assigned_users_group_2: number[];
}

// Exceso de usuarios en incompatibilidad
// '{{datetime}}: hay {{numUsers}} personas y puede haber máximo {{maxSimult}} a la vez.'
export interface SingleGroupIncompSurplusErrorContext {
  assigned_users: number[];
  max_simult: number;
}

// Exceso de usuarios en incompatibilidad
// '{{datetime}}: hay {{numUsers}} personas y tiene que haber mínimo {{minSimult}} a la vez.'
export interface SingleGroupIncompDeficitErrorContext {
  assigned_users: number[];
  min_simult: number;
}

// '{{sectionSlotDate}} - {{section}}: conflicto con {{type[0]}}'
export interface AssignmentOnSpecialEventErrorContext {
  events: SpecialEvent[];
}

// '{{sectionSlotDate}}: conflicto con bloqueo justificado.'
export interface AssignmentOnJustifiedBlockErrorContext {
  id_preference_slot: number;
}

// '{{sectionSlotDate}}: conflicto con bloqueo personal.'
export interface AssignmentOnPersonalBlockErrorContext {
  id_preference_slot: number;
}

// '{{sectionSlotDate}}: no se puede respetar la libranza a causa de los turnos {{chips}}.'
export interface AssignmentRestPeriodViolationErrorContext {
  // id_section_slots
  conflicting_assignments: number[];
}

// '{{sectionSlotDate}}: no se respeta un mínimo de 24 horas con el siguiente turno.'
export interface AssignmentRests24HoursErrorContext {
  // id_section_slots
  conflicting_assignments: number[];
}

// A user is assigned to 2 overlapping slots.
export interface AssignmentOnOverlappingAssignmentErrorContext {
  // id_section_slots
  conflicting_assignments: number[];
}

export interface VirtualSlotNeedsDeficitErrorContext {
  // id_shift_assignments
  assigned_shift_assignments: ShiftAssignment[];
  min_need: number;
}

export interface VirtualSlotNeedsSurplusErrorContext {
  // id_shift_assignments
  assigned_shift_assignments: ShiftAssignment[];
  max_need: number;
}

//
// Return type of the user requirement rules errors.
//
export type UserRequirementRuleErrors = {
  [id_req_rule: RequirementRuleId]: Partial<{
    [AssignmentCheckErrorType.USER_REQ_SLOTS_DEFICIT]: {
      [id_user: UserId]: UserReqSlotsDeficitErrorContext;
    };
    [AssignmentCheckErrorType.USER_REQ_SLOTS_SURPLUS]: {
      [id_user: UserId]: UserReqSlotsSurplusErrorContext;
    };
    [AssignmentCheckErrorType.USER_REQ_DURATION_DEFICIT]: {
      [id_user: UserId]: UserReqDurationDeficitErrorContext;
    };
    [AssignmentCheckErrorType.USER_REQ_DURATION_SURPLUS]: {
      [id_user: UserId]: UserReqDurationSurplusErrorContext;
    };
  }>;
};

export type SingleGroupIncompatibilityErrors = {
  [id_incompatibility: IncompatibilityId]: Partial<{
    [AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_DEFICIT]: {
      [id_slot: SlotId]: SingleGroupIncompDeficitErrorContext;
    };
    [AssignmentCheckErrorType.SINGLE_GROUP_INCOMPATIBILITY_SURPLUS]: {
      [id_slot: SlotId]: SingleGroupIncompSurplusErrorContext;
    };
  }>;
};

export type CrossGroupIncompatibilityErrors = {
  [id_incompatibility: IncompatibilityId]: {
    [id_slot: SlotId]: CrossGroupIncompErrorContext;
  };
};

export type UserAssignmentErrors = {
  [id_user: UserId]: {
    [id_section_slot: SectionSlotId]: Partial<{
      [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_EVENT]: AssignmentOnSpecialEventErrorContext;
      [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_JUSTIFIED_BLOCK]: AssignmentOnJustifiedBlockErrorContext;
      [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_PERSONAL_BLOCK]: AssignmentOnPersonalBlockErrorContext;
      [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_REST_PERIOD_VIOLATION]: AssignmentRestPeriodViolationErrorContext;
      [AssignmentCheckErrorType.SHIFT_ASSIGNMENT_ON_OVERLAPPING_ASSIGNMENT]: AssignmentOnOverlappingAssignmentErrorContext;
    }>;
  };
};

export type VirtualSlotErrors = {
  [id_virtual_slot: VirtualSlotId]: Partial<{
    [AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_DEFICIT]: VirtualSlotNeedsDeficitErrorContext;
    [AssignmentCheckErrorType.VIRTUAL_SLOT_NEEDS_SURPLUS]: VirtualSlotNeedsSurplusErrorContext;
  }>;
};

// Main type
export type AssignmentCheckErrors = {
  /**
   * Errors related to user requirement rules.
   * Key: id_req_rule
   * Value: Error types mapped to users and their contexts.
   */
  user_req_rules: UserRequirementRuleErrors;

  /**
   * Errors from single group incompatibilities.
   * Key: id_incompatibility
   * Value: Error types mapped to slots and their contexts.
   */
  single_group_incompatibilities: SingleGroupIncompatibilityErrors;

  /**
   * Errors from cross-group incompatibilities.
   * Key: id_incompatibility
   * Value: Slots mapped to their error contexts.
   */
  cross_group_incompatibilities: CrossGroupIncompatibilityErrors;

  /**
   * Errors related to user assignments.
   * Key: id_user
   * Value: Section slot assignments mapped to error types and their contexts.
   */
  user_assignments: UserAssignmentErrors;

  /**
   * Errors related to virtual slots.
   * Key: id_virtual_slot
   * Value: Error types mapped to their contexts.
   */
  virtual_slots: VirtualSlotErrors;
};
