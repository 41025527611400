import {
  CheckIcon,
  NoSymbolIcon,
  UserIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { UserRequirementInclusionType } from '@youshift/shared/types';
import { useTranslation } from 'react-i18next';

import InfoIcon from '../../../../components/InfoIcon';

// A simple badge component for inclusion/exclusion.
export default function InclusionTypeBadge({
  label,
}: {
  label: UserRequirementInclusionType;
}) {
  const { t } = useTranslation();
  const isInclusion = label === UserRequirementInclusionType.INCLUSION;
  const color = isInclusion
    ? 'bg-green-100 text-green-800'
    : 'bg-orange-100 text-orange-800';

  return (
    <span
      className={`flex items-center justify-center rounded-full w-6 h-6 ${color}`}
    >
      {isInclusion ? (
        <div className="flex flex-row">
          <UserIcon className="w-3 h-3" />
          <CheckIcon className="w-3 h-3" />
        </div>
      ) : (
        <div className="flex flex-row">
          <UserIcon className="w-3 h-3" />
          <NoSymbolIcon className="w-3 h-3" />
        </div>
      )}
    </span>
  );
}

export function RequirementTypeLegend(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="my-2 flex flex-wrap gap-2">
      {Object.values(UserRequirementInclusionType).map(type => (
        <div
          key={type}
          className="inline-flex items-center gap-x-2 rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-200"
        >
          <span
            className={`flex items-center justify-center w-6 h-6 rounded-full ${
              type === UserRequirementInclusionType.INCLUSION
                ? 'bg-green-100 text-green-800'
                : 'bg-orange-100 text-orange-800'
            }`}
          >
            {type === UserRequirementInclusionType.INCLUSION ? (
              <div className="flex flex-row">
                <UserIcon className="w-3 h-3" />
                <CheckIcon className="w-3 h-3" />
              </div>
            ) : (
              <div className="flex flex-row">
                <UserIcon className="w-3 h-3" />
                <NoSymbolIcon className="w-3 h-3" />
              </div>
            )}
          </span>
          {type === UserRequirementInclusionType.INCLUSION
            ? t('manager.rulesConfig.INCLUSION')
            : t('manager.rulesConfig.EXCLUSION')}
          <InfoIcon
            title={t(
              type === UserRequirementInclusionType.INCLUSION
                ? 'manager.rulesConfig.inclusionRules'
                : 'manager.rulesConfig.exclusionRules',
            )}
            content={t(
              type === UserRequirementInclusionType.INCLUSION
                ? 'manager.rulesConfig.inclusionRulesDefinition'
                : 'manager.rulesConfig.exclusionRulesDefinition',
            )}
          />
        </div>
      ))}
    </div>
  );
}
