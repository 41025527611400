import { useQueryClient } from '@tanstack/react-query';
import {
  useDeleteSectionMutation,
  useDuplicateSectionMutation,
  useEditSectionMutation,
} from '@youshift/shared/hooks/mutations';
import { Section } from '@youshift/shared/types';
import { ColorName } from '@youshift/shared/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { Switch } from '@headlessui/react';

import ColorPicker from '../ColorPicker';
import DeleteConfirmationModal from '../DeleteConfirmationModal';
import Modal from '../Modal';
import { YSButton } from '../Buttons';

interface EditSectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  details: Section;
}

export default function EditSectionModal({
  isOpen,
  onClose,
  details,
}: EditSectionModalProps) {
  const {
    name: sectionName,
    acronym: sectionAcronym,
    color: sectionColor,
    assigned_by_smartass: sectionAssignedBySmartass,
    user_can_selfassign: sectionUserCanSelfassign,
    id_itr,
    id_section,
  } = details;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [name, setName] = useState(sectionName);
  const [acronym, setAcronym] = useState(sectionAcronym);
  const [color, setColor] = useState<ColorName>(sectionColor);
  const [assignedBySmartass, setAssignedBySmartass] = useState(
    sectionAssignedBySmartass,
  );
  const [userCanSelfassign, setUserCanSelfassign] = useState(
    sectionUserCanSelfassign,
  );

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');

  const editSectionMutation = useEditSectionMutation(queryClient, {
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({
        queryKey: ['section', id_section.toString()],
      });
      queryClient.invalidateQueries({
        queryKey: ['sectionSlots', String(id_itr)],
      });
      toast.success(t('manager.sectionsConfig.editSuccess'));
    },
  });

  const deleteSectionMutation = useDeleteSectionMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sections', String(id_itr)],
      });
      onClose();
      queryClient.invalidateQueries({
        queryKey: ['sectionSlots', String(id_itr)],
      });
      navigate('../sections');
      toast.success(t('manager.sectionsConfig.deleteSuccess'));
    },
  });

  const handleSave = () => {
    editSectionMutation.mutate({
      id_itr,
      id_section,
      name,
      acronym,
      color,
      assigned_by_smartass: assignedBySmartass,
      user_can_selfassign: userCanSelfassign,
    });
  };

  const handleDeleteClick = () => {
    if (!isDeleteMode) {
      setIsDeleteMode(true);
    } else if (deleteConfirmationText === sectionName) {
      deleteSectionMutation.mutate({
        id_itr,
        id_section,
      });
    }
  };

  const duplicateSectionMutation = useDuplicateSectionMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sections', String(id_itr)],
      });
      queryClient.invalidateQueries({
        queryKey: ['sectionSlots', String(id_itr)],
      });
      onClose();
      navigate('../sections');
      toast.success(t('manager.sectionsConfig.duplicateSuccess'));
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="md"
      editButtons
      handleDelete={handleDeleteClick}
      handleSave={handleSave}
      disableDelete={isDeleteMode && deleteConfirmationText !== sectionName}
    >
      <div className="space-y-4 mb-10">
        <h2 className="text-xl font-semibold">
          {t('manager.sectionsConfig.editSection')}
        </h2>
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            {t('manager.sectionsConfig.name')}
          </label>
          <input
            id="name"
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="acronym"
            className="block text-sm font-medium text-gray-700"
          >
            {t('manager.sectionsConfig.acronym')}
          </label>
          <input
            id="acronym"
            type="text"
            value={acronym}
            maxLength={5}
            onChange={e => setAcronym(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="color"
            className="block text-sm font-medium text-gray-700"
          >
            {t('generic.color')}
          </label>
          <ColorPicker value={color} onChange={setColor} />
        </div>

        <div>
          <label
            htmlFor="assignedBySmartass"
            className="block text-sm font-medium text-gray-700"
          >
            {t('manager.sectionsConfig.sectionAssignedBySmartass')}
          </label>
          <Switch
            checked={assignedBySmartass}
            onChange={() => {
              setAssignedBySmartass(!assignedBySmartass);
            }}
            className={`${
              assignedBySmartass ? 'bg-teal-500' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2`}
          >
            <span
              aria-hidden="true"
              className={`${
                assignedBySmartass ? 'translate-x-5' : 'translate-x-0'
              } inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>

        <div>
          <label
            htmlFor="userCanSelfassign"
            className="block text-sm font-medium text-gray-700"
          >
            {t('manager.sectionsConfig.userCanSelfAssign')}
          </label>
          <Switch
            checked={userCanSelfassign}
            onChange={() => {
              setUserCanSelfassign(!userCanSelfassign);
            }}
            className={`${
              userCanSelfassign ? 'bg-teal-500' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2`}
          >
            <span
              aria-hidden="true"
              className={`${
                userCanSelfassign ? 'translate-x-5' : 'translate-x-0'
              } inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>

        <YSButton
          variant="secondary"
          onClick={() => {
            duplicateSectionMutation.mutate({ id_itr, id_section });
          }}
          classNames="flex gap-2"
          loading={duplicateSectionMutation.isPending}
        >
          <DocumentDuplicateIcon className="w-4 h-4" />
          {duplicateSectionMutation.isPending
            ? t('generic.duplicating')
            : t('generic.duplicate')}
        </YSButton>
      </div>
      {isDeleteMode && (
        <div className="-mt-6 mb-3">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            {t('manager.sectionsConfig.typeNameOfSectionToConfirmDelete', {
              name: sectionName,
            })}
          </label>
          <input
            id="name"
            type="text"
            placeholder={sectionName}
            value={deleteConfirmationText}
            onChange={e => setDeleteConfirmationText(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>
      )}
    </Modal>
  );
}
