import { CursorArrowRippleIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { useCreateUserReqRule } from '@youshift/shared/hooks/mutations';
import {
  RuleTypes,
  UserRequirementInclusionType,
  UserRequirementNumUsersType,
  UserRequirementType,
} from '@youshift/shared/types';
import { getShadeMap } from '@youshift/shared/utils';
import { useMemo, useReducer, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';

import { YSButton } from '../../../../components/Buttons';
import ShiftLabelLegend from '../../../../components/Calendars/ShiftLabelLegend';
import { VirgueriaVersion } from '../../../../components/ManualAssignment/types';
import { Virgueria } from '../../../../components/ManualAssignment/Virgueria';
import VerticalProgressBar from '../../../../components/VerticalProgressBar';
import { useItrContext } from '../../../../layouts/IterationRootLayout/IterationRootLayout';
import DefineReqs from './DefineReqs';
import { useSlotSelection } from './hooks/useSlotSelection';
import { useUserReqReducer } from './hooks/useUserReqReducer';
import DefineExclusionReqs from './DefineExclusionReqs';
import DefineCustomRuleDetails from './DefineCustomRuleDetails';
import SmartSelector from '../../../../components/ItrConfig/SmartSelector';
import DefineSectionRuleDetails from './DefineSectionRuleDetails';
import { hasInvalidReqs } from './helpers';

export default function NewReqRule() {
  const {
    iteration,
    sectionsWithSlots,
    shiftLabels,
    itrUsers,
    roles,
    users,
    sectionSlotsDict,
  } = useItrContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // if this exists, it means we clicked create a rule for a specific user/type
  const selectedUserFromUrl = searchParams.get('user');
  const inclusionTypeFromUrl = searchParams.get('inclusionType');
  const typeFromUrl = searchParams.get('type') as RuleTypes | null;

  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);

  // ------------------------------------------------------------------------------
  // 1. User Requirements Reducer
  // (This manages the per-user staffing requirement values.)
  // ------------------------------------------------------------------------------
  const { initialState, state, dispatch } = useUserReqReducer({
    itrUsers: Object.keys(itrUsers).map(Number),
    roles,
    users,
    userReqs: undefined,
    initialReqType: UserRequirementType.SLOTS,
  });

  // ------------------------------------------------------------------------------
  // 2. Local state for rule details
  // ------------------------------------------------------------------------------
  // These fields are always available.
  const [ruleName, setRuleName] = useState('');
  const [ruleDescription, setRuleDescription] = useState('');
  // For SECTION rules, only one option is applicable.
  const [selectedSection, setSelectedSection] = useState<number | null>(null);
  // For custom rules, if the rule applies to a single user we need to know which one.
  const [selectedUser, selectedUserDispatch] = useReducer(
    (current: number | null, newSelectedUser: number | null) => {
      dispatch({ type: 'UNINCLUDE_ALL' });
      if (newSelectedUser !== null) {
        dispatch({
          type: 'INCLUDE_USER',
          id_user: newSelectedUser,
        });
      }
      return newSelectedUser;
    },
    selectedUserFromUrl ? Number(selectedUserFromUrl) : null,
  );
  // For custom rules the user can choose between single and multiple.
  const [numberOfUsers, setNumberOfUsers] =
    useState<UserRequirementNumUsersType | null>(
      selectedUserFromUrl
        ? UserRequirementNumUsersType.SINGLE
        : inclusionTypeFromUrl || typeFromUrl
          ? UserRequirementNumUsersType.MULTIPLE
          : null,
    );
  // For custom rules the user can choose inclusion or exclusion.
  const [inclusionType, setInclusionType] =
    useState<UserRequirementInclusionType | null>(
      inclusionTypeFromUrl
        ? UserRequirementInclusionType[
            inclusionTypeFromUrl as UserRequirementInclusionType
          ]
        : null,
    );
  const ruleType = typeFromUrl
    ? RuleTypes[typeFromUrl]
    : RuleTypes.CUSTOM_USER_REQS;

  // Initialize rule settings
  useEffect(() => {
    if (typeFromUrl === RuleTypes.SECTION_USER_REQS) {
      dispatch({ type: 'INCLUDE_ALL' });
      // For section rules, only multiple user is allowed.
      setNumberOfUsers(UserRequirementNumUsersType.MULTIPLE);
      setInclusionType(UserRequirementInclusionType.INCLUSION);
    }
    if (selectedUserFromUrl) {
      dispatch({
        type: 'INCLUDE_USER',
        id_user: Number(selectedUserFromUrl),
      });
    }
    // by default, all users are unincluded which works for custom rules
  }, [dispatch, typeFromUrl, selectedUserFromUrl]);

  const [childIndex, setChildIndex] = useState(0);

  // ------------------------------------------------------------------------------
  // 3. Slot selection hook (only used for custom rules)
  // ------------------------------------------------------------------------------
  const {
    selectedSlots,
    toggleSlot,
    onDayClick,
    onSectionClick,
    filteredShiftLabels,
    setSelectedSlotsWithCallback,
  } = useSlotSelection({
    sectionsWithSlots,
    initialSlots: new Set<number>(),
    shiftLabels,
  });

  // ------------------------------------------------------------------------------
  // 4. Create rule mutation.
  // On success, we navigate back and invalidate queries.
  // ------------------------------------------------------------------------------
  const createRuleMutation = useCreateUserReqRule(queryClient, {
    onSuccess: () => {
      navigate('..', { relative: 'path' });
      queryClient.invalidateQueries({
        queryKey: ['extendedUserReqRules', String(iteration.id_itr)],
      });
      queryClient.invalidateQueries({
        queryKey: ['userReqRules', String(iteration.id_itr)],
      });
    },
  });

  // ------------------------------------------------------------------------------
  // 5. Step definitions
  //
  // We now define two different flows:
  //
  // A. For SECTION or SLOT_LABEL rules (scope-only):
  //    Step 0: Rule Scope – choose the section (or label).
  //    Step 1: Define Requirements – using DefineReqs.
  //
  // B. For CUSTOM rules:
  //    Step 0: Rule Scope – enter rule name/description and answer:
  //             "Who does this rule apply to?" (single vs. multiple) and
  //             "What type is this rule?" (inclusion vs. exclusion).
  //    Step 1: Select Slots – choose affected slots manually.
  //    Step 2: Define Requirements – using DefineReqs (for inclusion) or
  //             DefineExclusionReqs (for exclusion with multiple users).
  // ------------------------------------------------------------------------------
  const steps = useMemo(() => {
    // Define our three basic steps:
    const baseStep = {
      name: t('manager.rulesConfig.ruleDetails'),
      key: 'scope',
    };
    const slotsStep = {
      name: t('manager.rulesConfig.selectSlots'),
      key: 'slots',
    };
    const reqsStep = { name: t('manager.rulesConfig.defineReqs'), key: 'reqs' };

    // For section or slot label rules, we don't allow slot selection, so we have two steps.
    if (
      ruleType === RuleTypes.SECTION_USER_REQS ||
      ruleType === RuleTypes.SLOT_LABEL_USER_REQS
    ) {
      return [baseStep, reqsStep];
    }

    // For custom rules:
    if (ruleType === RuleTypes.CUSTOM_USER_REQS) {
      // Always show the base (rule details) and slots steps.
      const stepsArray = [baseStep, slotsStep];

      // For requirements:
      // - If multiple users are chosen, show reqs.
      // - If a single user is chosen, show reqs only if inclusion is selected.
      if (
        numberOfUsers === UserRequirementNumUsersType.MULTIPLE ||
        (numberOfUsers === UserRequirementNumUsersType.SINGLE &&
          inclusionType === UserRequirementInclusionType.INCLUSION)
      ) {
        stepsArray.push(reqsStep);
      }
      return stepsArray;
    }

    // Default fallback.
    return [baseStep];
  }, [t, ruleType, numberOfUsers, inclusionType]);

  // ------------------------------------------------------------------------------
  // 6. Current step validation
  //
  // For the "scope" step:
  //   - If SECTION rule: require selectedSection.
  //   - If SLOT_LABEL rule: require selectedLabel.
  //   - If CUSTOM rule: require a non-empty ruleName,
  //         numberOfUsers is chosen (and if single, selectedUser is provided),
  //         and inclusionType is chosen.
  // For the "slots" step (custom rules only): require at least one slot selected.
  // For the "reqs" step (if needed): if the rule is exclusion and multiple users,
  //   at least one user must be toggled on.
  // ------------------------------------------------------------------------------
  const isCurrentStepValid = useMemo(() => {
    const currentStepKey = steps[childIndex]?.key;
    if (currentStepKey === 'scope') {
      if (
        ruleType === RuleTypes.SECTION_USER_REQS &&
        selectedSection !== null
      ) {
        return true;
      }
      if (ruleType === RuleTypes.CUSTOM_USER_REQS) {
        return (
          ruleName.trim() !== '' &&
          numberOfUsers !== null &&
          (numberOfUsers === UserRequirementNumUsersType.MULTIPLE ||
            selectedUser !== null) &&
          inclusionType !== null
        );
      }
      return false;
    }
    if (currentStepKey === 'slots') {
      return selectedSlots.size > 0;
    }
    if (currentStepKey === 'reqs') {
      if (
        inclusionType === UserRequirementInclusionType.EXCLUSION &&
        numberOfUsers === UserRequirementNumUsersType.MULTIPLE
      ) {
        const anyIncluded = Object.values(state.roles).some(role =>
          role.users.some(user => user.included),
        );
        return anyIncluded;
      }
      return true;
    }
    return false;
  }, [
    childIndex,
    steps,
    ruleType,
    selectedSection,
    ruleName,
    numberOfUsers,
    selectedUser,
    inclusionType,
    selectedSlots,
    state.roles,
  ]);

  const shouldNextButtonBeDisabled =
    !isCurrentStepValid || createRuleMutation.isPending;

  // ------------------------------------------------------------------------------
  // 7. Finalize state before submission
  //
  // For exclusion rules, force every included user to have 0 for min/max.
  // For single‑user rules, ensure only the selected user remains included.
  // ------------------------------------------------------------------------------
  const finalizeState = () => {
    const adjustedState = { ...state };
    if (inclusionType === UserRequirementInclusionType.EXCLUSION) {
      adjustedState.roles = Object.fromEntries(
        Object.entries(adjustedState.roles).map(([roleId, role]) => [
          roleId,
          {
            ...role,
            users: role.users.map(user =>
              user.included
                ? {
                    ...user,
                    min_slots: 0,
                    max_slots: 0,
                    min_duration: 0,
                    max_duration: 0,
                  }
                : user,
            ),
          },
        ]),
      );
    }
    if (numberOfUsers === UserRequirementNumUsersType.SINGLE) {
      adjustedState.roles = Object.fromEntries(
        Object.entries(adjustedState.roles).map(([roleId, role]) => [
          roleId,
          {
            ...role,
            users: role.users.map(user => ({
              ...user,
              included: user.id_user === selectedUser,
            })),
          },
        ]),
      );
    }
    return adjustedState;
  };

  // ------------------------------------------------------------------------------
  // 8. Create rule (submission)
  // ------------------------------------------------------------------------------
  const createRule = () => {
    const finalState = finalizeState();

    const validation = hasInvalidReqs(state.roles, state.req_type);
    if (!validation.isValid && validation.error) {
      toast.error(t(validation.error));
      return;
    }

    createRuleMutation.mutate({
      id_itr: iteration.id_itr,
      name: ruleName,
      description: ruleDescription,
      req_type: finalState.req_type,
      // For custom rules we send selected slots; for section/label rules, we send the selected section/label.
      id_section_slots:
        ruleType === RuleTypes.CUSTOM_USER_REQS
          ? Array.from(selectedSlots)
          : undefined,
      id_section:
        ruleType === RuleTypes.SECTION_USER_REQS ? selectedSection : undefined,
      id_slot_label: undefined,
      user_requirements: Object.values(finalState.roles).flatMap(role =>
        role.users
          .filter(user => user.included)
          .map(user => {
            if (finalState.req_type === UserRequirementType.DURATION) {
              return {
                id_user: user.id_user,
                min_duration: user.min_duration,
                max_duration: user.max_duration,
              };
            }
            return {
              id_user: user.id_user,
              min_slots: user.min_slots,
              max_slots: user.max_slots,
            };
          }),
      ),
    });
  };

  // ------------------------------------------------------------------------------
  // 9. Render the current step's component
  // ------------------------------------------------------------------------------
  const returnChildComponent = useMemo(() => {
    const currentStepKey = steps[childIndex]?.key;
    if (currentStepKey === 'scope') {
      return typeFromUrl === RuleTypes.SECTION_USER_REQS ? (
        <DefineSectionRuleDetails
          sectionsWithSlots={sectionsWithSlots}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />
      ) : (
        <DefineCustomRuleDetails
          ruleName={ruleName}
          setRuleName={setRuleName}
          ruleDescription={ruleDescription}
          setRuleDescription={setRuleDescription}
          numberOfUsers={numberOfUsers}
          setNumberOfUsers={setNumberOfUsers}
          selectedUser={selectedUser}
          setSelectedUser={selectedUserDispatch}
          inclusionType={inclusionType}
          setInclusionType={setInclusionType}
        />
      );
    }
    if (currentStepKey === 'slots') {
      return (
        <div className="ml-3">
          <SmartSelector
            sections={Object.fromEntries(
              sectionsWithSlots.map(section => [
                section.section.id_section,
                section.section,
              ]),
            )}
            sectionSlotsConfig={{
              filteredShiftLabels,
              sectionSlots: sectionSlotsDict,
              setSelectedSectionSlots: setSelectedSlotsWithCallback,
              selectedSectionSlots: selectedSlots,
            }}
          />
          <Virgueria
            end={iteration.end_day}
            labels={shiftLabels}
            onDayClick={onDayClick}
            onSectionClick={onSectionClick}
            onSlotClick={toggleSlot}
            sectionsWithSlots={sectionsWithSlots}
            selectedSlots={selectedSlots}
            shadeMap={shadeMap}
            start={iteration.start_day}
            version={VirgueriaVersion.RulesCreating}
          />
        </div>
      );
    }
    if (currentStepKey === 'reqs') {
      return inclusionType === UserRequirementInclusionType.EXCLUSION ? (
        <div className="px-8">
          <DefineExclusionReqs state={state} dispatch={dispatch} />
        </div>
      ) : (
        <div className="px-8">
          <DefineReqs
            state={state}
            dispatch={dispatch}
            numberOfSlotsSelected={
              ruleType === RuleTypes.CUSTOM_USER_REQS
                ? selectedSlots.size
                : undefined
            }
            selectedUser={selectedUser || undefined}
            ruleType={ruleType}
          />
        </div>
      );
    }
    return null;
  }, [
    childIndex,
    dispatch,
    filteredShiftLabels,
    inclusionType,
    iteration.end_day,
    iteration.start_day,
    numberOfUsers,
    onDayClick,
    onSectionClick,
    ruleDescription,
    ruleName,
    ruleType,
    sectionSlotsDict,
    sectionsWithSlots,
    selectedSection,
    selectedSlots,
    selectedUser,
    setSelectedSlotsWithCallback,
    shadeMap,
    shiftLabels,
    state,
    steps,
    toggleSlot,
    typeFromUrl,
  ]);

  // ------------------------------------------------------------------------------
  // 10. Render the main container with a vertical progress bar and navigation buttons.
  // ------------------------------------------------------------------------------
  return (
    <div className="py-6 h-full">
      <div className="flex flex-row h-full">
        {/* Left: Vertical progress bar using our dynamic steps */}
        <div className="self-start mt-12">
          <VerticalProgressBar steps={steps} childIndex={childIndex} />
        </div>
        <div className="w-full">
          <div className="p-8 flex flex-row justify-between items-center">
            <div>
              <h1 className="text-2xl font-semibold mb-2 text-gray-800">
                {steps[childIndex]?.name}
              </h1>
              <p className="text-gray-500">
                {t('generic.stepXofY', {
                  current: childIndex + 1,
                  total: steps.length,
                })}
              </p>
            </div>
            <div className="flex-shrink-0 mt-6">
              <div className="flex flex-row gap-2 justify-end">
                <YSButton
                  variant="ghost-primary"
                  onClick={() => navigate('..', { relative: 'path' })}
                >
                  {t('generic.cancel')}
                </YSButton>
                {childIndex > 0 && (
                  <YSButton
                    onClick={() => setChildIndex(childIndex - 1)}
                    variant="secondary"
                  >
                    {t('generic.back')}
                  </YSButton>
                )}
                {childIndex === steps.length - 1 ? (
                  <YSButton
                    onClick={createRule}
                    variant="primary"
                    disabled={shouldNextButtonBeDisabled}
                  >
                    {t('generic.create')}
                  </YSButton>
                ) : (
                  <YSButton
                    onClick={() => setChildIndex(childIndex + 1)}
                    variant="primary"
                    disabled={shouldNextButtonBeDisabled}
                  >
                    {t('generic.next')}
                  </YSButton>
                )}
              </div>
            </div>
          </div>
          {steps[childIndex]?.key === 'slots' && (
            <div className="flex flex-row gap-1 ml-8 -mt-4 mb-2 justify-center">
              <CursorArrowRippleIcon className="h-5 w-5 text-teal-600" />
              <p className="font-semibold">
                {inclusionType === UserRequirementInclusionType.INCLUSION
                  ? t('manager.rulesConfig.editEnabled')
                  : t('manager.rulesConfig.editEnabledExclusion')}
              </p>
            </div>
          )}
          {returnChildComponent}
        </div>
      </div>
    </div>
  );
}
