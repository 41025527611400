import { useQuery, QueryClient, useQueryClient } from '@tanstack/react-query';
import {
  adminAlgTasksQuery,
  AlgTasksResponse,
} from '@youshift/shared/hooks/queries';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  AlgTaskStatus,
  Group,
  Iteration,
  WorkerTask,
} from '@youshift/shared/types';
import { dateToString } from '@youshift/shared/utils';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImpersonateGroupMutation } from '@youshift/shared/hooks/mutations';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

import { requireAdmin } from '../../utils/checks';

export const adminAlgExecLoader = (queryClient: QueryClient) => async () => {
  await requireAdmin(queryClient);
  const data = await preLoadQuery(queryClient, adminAlgTasksQuery());
  return data;
};

export default function AdminAlgExec() {
  const initialData = useLoaderData() as AlgTasksResponse;
  const { data, isLoading } = useQuery<AlgTasksResponse>({
    ...adminAlgTasksQuery(),
    initialData,
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const impersonateGroupMutation = useImpersonateGroupMutation(queryClient, {
    onSuccess: () => {
      navigate('/manager');
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return null;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Task ID
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Group
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Iteration
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Error Type
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Created At
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Started At (time since creation)
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Completed At (time since creation)
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Launched By
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {Object.entries(data)
                  .sort(([, a], [, b]) => {
                    // First sort by creation date (newest first)
                    const createDiff =
                      new Date(b.task.created).getTime() -
                      new Date(a.task.created).getTime();
                    if (createDiff !== 0) return createDiff;

                    // Then by completion date if creation dates are equal (newest first)
                    if (!a.task.completed && !b.task.completed) return 0;
                    if (!a.task.completed) return 1;
                    if (!b.task.completed) return -1;
                    return (
                      new Date(b.task.completed).getTime() -
                      new Date(a.task.completed).getTime()
                    );
                  })
                  .map(([id_task, { task, itr, group, org, alg_error }]) => (
                    <tr key={id_task}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">
                        {id_task}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="flex flex-row gap-2">
                          {group.name} (id_group: {group.id_group}) - {org.name}
                          <button
                            onClick={() => {
                              impersonateGroupMutation.mutate({
                                id_group: group.id_group,
                              });
                            }}
                            aria-label="Edit Section"
                          >
                            <PencilSquareIcon className="w-6 h-6 text-gray-600" />
                          </button>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {itr.id_itr}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {task.status}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {alg_error ? alg_error.type : '-'}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {new Date(task.created).toLocaleString()}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {task.started
                          ? `${new Date(task.started).toLocaleString()} (${Math.round(
                              (new Date(task.started).getTime() -
                                new Date(task.created).getTime()) /
                                (1000 * 60),
                            )} min)`
                          : '-'}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {task.completed
                          ? `${new Date(task.completed).toLocaleString()} (${Math.round(
                              (new Date(task.completed).getTime() -
                                new Date(task.created).getTime()) /
                                (1000 * 60),
                            )} min)`
                          : '-'}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {task.launched_by_id_user}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
