import { PlusIcon } from '@heroicons/react/20/solid';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  incompatibilitiesQuery,
  IncompatibilitiesResponse,
  personnelQuery,
  sectionsQuery,
} from '@youshift/shared/hooks/queries';
import {
  CrossGroupIncompatibilityRule,
  IncompatibilityRules,
  RuleTypes,
  Section,
  SingleGroupIncompatibilityRule,
  User,
  UserRole,
} from '@youshift/shared/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, useLoaderData, useParams } from 'react-router-dom';

import { requireApproved, requireManager } from '../../../../utils/checks';
import AddIncompGroup from './AddIncompGroup';
import { YSButton } from '../../../../components/Buttons';
import Wrapper from '../../../../components/Wrapper';
import { useItrContext } from '../../../../layouts/IterationRootLayout/IterationRootLayout';
// TODO: Migrate AddIncompGroup to TS and display errors

type IncompatibilitiesLoader = {
  incompatibilities: IncompatibilitiesResponse;
  users: Record<number, User>;
  roles: Record<number, UserRole>;
  sections: Section[];
};

export const incompatibilitiesLoader =
  (queryClient: QueryClient) =>
  async ({
    params,
  }: LoaderFunctionArgs): Promise<IncompatibilitiesLoader | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined) {
      return null;
    }
    const incompatibilities = await preLoadQuery(
      queryClient,
      incompatibilitiesQuery(params.idItr),
    );
    const sections = await preLoadQuery(
      queryClient,
      sectionsQuery(params.idItr),
    );
    const { users, roles } = await preLoadQuery(queryClient, personnelQuery());
    return { incompatibilities, users, roles, sections };
  };

export function IncompatibilitiesConfig() {
  const { idItr: id } = useParams();
  const { incompatibilities: initialIncompGroups, sections } =
    useLoaderData() as IncompatibilitiesLoader;

  const { users, roles } = useItrContext();

  const { data: shifts } = useQuery(sectionsQuery(id || ''));
  const { data: incomp_groups = initialIncompGroups } = useQuery(
    incompatibilitiesQuery(id || ''),
  );

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [incomp_rule, setIncompRule] = useState<
    SingleGroupIncompatibilityRule | CrossGroupIncompatibilityRule | null
  >(null);

  const context = true;
  // const locked = context ? context[0] : false;
  const locked = false;

  const incompsExist =
    Object.values(incomp_groups.single_group_incomp).length > 0 ||
    Object.values(incomp_groups.cross_group_incomp).length > 0;
  // revisar el handling de los dos casos y sus subcasos: no hay incomp y estamos en preview/config, si hay incomp y estamos en preview o en config
  return (
    <Wrapper>
      {open ? (
        <AddIncompGroup
          open={open}
          setOpen={setOpen}
          incomp_rule={incomp_rule}
          users={users}
          roles={roles}
          sections={sections}
        />
      ) : null}
      {incompsExist ? (
        <div>
          <div className="flex justify-end items-center">
            <YSButton
              type="button"
              onClick={() => {
                setOpen(true);
                setIncompRule(null);
              }}
              classNames="flex flex-row items-center"
            >
              <PlusIcon className="w-5 h-5" />
              {t('manager.incomp.createNew')}
            </YSButton>
          </div>
          {[
            RuleTypes.SINGLE_GROUP_INCOMP.toString().toLowerCase(),
            RuleTypes.CROSS_GROUP_INCOMP.toString().toLowerCase(),
          ].map(rule_type_key =>
            Object.entries(
              incomp_groups[rule_type_key as keyof IncompatibilityRules],
            ).map(
              ([id_incomp, rule]: [
                string,
                SingleGroupIncompatibilityRule | CrossGroupIncompatibilityRule,
              ]) => (
                <div className="overflow-hidden rounded-lg bg-white shadow mt-6">
                  <div className="px-4 py-5 sm:px-6">
                    <div className="flex flex-row gap-2 text-blue-600 font-semibold">
                      <p>{rule?.name}</p>
                      <button
                        onClick={() => {
                          setIncompRule(rule);
                          setOpen(true);
                        }}
                        disabled={!context}
                        aria-label="Edit incompatibility rule"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 text-gray-600"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </button>
                      {' | '}
                      {rule.type === RuleTypes.SINGLE_GROUP_INCOMP ? (
                        <p className="text-gray-400 font-normal text-sm">
                          {t('manager.incomp.singleGroup')}
                        </p>
                      ) : (
                        <p className="text-gray-400 font-normal text-sm">
                          {t('manager.incomp.crossGroup')}
                        </p>
                      )}
                    </div>
                    <p className="mt-2 text-gray-500">
                      {t('manager.incomp.participatingSections')}
                      {rule.section_group
                        ?.map(
                          id_section =>
                            shifts?.find(
                              shift => shift.id_section === id_section,
                            )?.name,
                        )
                        .join(', ')}
                    </p>
                    {rule.type === RuleTypes.SINGLE_GROUP_INCOMP &&
                    'max_simult' in rule ? (
                      <p className="mt-2 text-gray-500">
                        {t('manager.incomp.groupInfo', {
                          number: rule.user_group.length,
                          max: rule.max_simult,
                          min: rule.min_simult,
                        })}
                      </p>
                    ) : (
                      <p className="mt-2 text-gray-500">
                        {t('manager.incomp.crossGroupInfo')}
                      </p>
                    )}
                  </div>
                  {rule.type === RuleTypes.SINGLE_GROUP_INCOMP ? (
                    <div className="bg-gray-50 px-4 py-5 sm:p-6">
                      {rule.user_group.length ? (
                        <div className="flex flex-row gap-8 flex-wrap">
                          {rule.user_group.map(personId => (
                            <div className="bg-teal-400/20 text-gray-900 py-4 px-3 whitespace-nowrap rounded-md">
                              {`${users[personId]?.firstname} ${users[personId]?.lastname}`}
                            </div>
                          ))}
                        </div>
                      ) : (
                        t('manager.incomp.noParticipants')
                      )}
                    </div>
                  ) : (
                    <div className="px-4 py-5 sm:p-6">
                      {rule.user_group.length &&
                      'user_group_secondary' in rule &&
                      rule.user_group_secondary?.length ? (
                        <div className="grid grid-cols-2 gap-12">
                          <p className="absolute p-2 w-8 h-8 text-center rounded-2xl bg-teal-600 text-xs font-semibold text-white">
                            A
                          </p>
                          <div className="flex flex-row flex-wrap gap-8 p-3 rounded-md bg-gray-50">
                            {rule.user_group.map(personId => (
                              <div className="bg-teal-400/20 text-gray-900 py-4 px-3 whitespace-nowrap rounded-md">
                                {`${users[personId]?.firstname} ${users[personId]?.lastname}`}
                              </div>
                            ))}
                          </div>
                          <div>
                            <p className="absolute p-2 w-8 h-8 text-center rounded-2xl bg-blue-600 text-xs font-semibold text-white">
                              B
                            </p>
                            <div className="flex flex-row flex-wrap gap-8 p-3 rounded-md bg-gray-50">
                              {rule.user_group_secondary.map(personId => (
                                <div className="bg-teal-400/20 text-gray-900 py-4 px-3 whitespace-nowrap rounded-md">
                                  {`${users[personId]?.firstname} ${users[personId]?.lastname}`}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        t('manager.incomp.noParticipants')
                      )}
                    </div>
                  )}
                </div>
              ),
            ),
          )}
        </div>
      ) : context ? (
        <div className="text-center mt-24">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            {t('manager.incomp.createPrompt')}
          </h3>
          <p className="text-sm text-gray-500 text-whitespace max-w-lg my-4 mx-auto align-center">
            {t('manager.incomp.definition')}
          </p>
          <div className="mt-6">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => {
                setOpen(true);
                setIncompRule(null);
              }}
            >
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
              {t('manager.incomp.newGroup')}
            </button>
          </div>
        </div>
      ) : (
        <p className="text-center">{t('manager.incomp.noGroups')}</p>
      )}
    </Wrapper>
  );
}
