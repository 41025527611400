import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@headlessui/react';
import { MinusIcon, NoSymbolIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { classNames } from '@youshift/shared/utils';

import { Action, State, UserConfig } from './types';
import InfoIcon from '../../../../components/InfoIcon';

interface DefineExclusionReqsProps {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const getRoleInclusionState = (users: UserConfig[]) => {
  if (users.every(user => user.included)) {
    return 'excluded';
  }
  if (users.every(user => !user.included)) {
    return 'unincluded';
  }
  return null;
};

function TwoWayToggleRole({
  roleId,
  users,
  dispatch,
}: {
  roleId: number;
  users: UserConfig[];
  dispatch: React.Dispatch<Action>;
}) {
  const [selected, setSelected] = useState(getRoleInclusionState(users));

  return (
    <RadioGroup
      value={selected}
      onChange={setSelected}
      className="flex items-center gap-x-3"
    >
      <RadioGroup.Option
        value="unincluded"
        aria-label="unincluded"
        className={({ checked, active }) =>
          classNames(
            'text-gray-500',
            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-current focus:outline-none',
            checked ? 'ring-2 opacity-100' : 'opacity-40',
            active && checked ? 'ring ring-offset-1' : '',
          )
        }
        onClick={() => {
          setSelected('unincluded');
          dispatch({
            type: 'UNINCLUDE_ROLE',
            id_role: roleId,
          });
        }}
      >
        <span
          aria-hidden="true"
          className="w-5 h-5 rounded-full border border-black/10 bg-current relative"
        >
          <MinusIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
        </span>
      </RadioGroup.Option>

      <RadioGroup.Option
        value="excluded"
        aria-label="excluded"
        className={({ checked, active }) =>
          classNames(
            'text-red-500',
            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-current focus:outline-none',
            checked ? 'ring-2 opacity-100' : 'opacity-40',
            active && checked ? 'ring ring-offset-1' : '',
          )
        }
        onClick={() => {
          setSelected('excluded');
          dispatch({
            type: 'EXCLUDE_ROLE',
            id_role: roleId,
          });
        }}
      >
        <span
          aria-hidden="true"
          className="w-5 h-5 rounded-full border border-black/10 bg-current relative"
        >
          <NoSymbolIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
        </span>
      </RadioGroup.Option>
    </RadioGroup>
  );
}

function TwoWayToggleUser({
  user,
  dispatch,
}: {
  user: UserConfig;
  dispatch: React.Dispatch<Action>;
}) {
  const [selected, setSelected] = useState(
    user.included ? 'excluded' : 'unincluded',
  );

  return (
    <RadioGroup
      value={selected}
      onChange={setSelected}
      className="flex items-center gap-x-3"
    >
      <RadioGroup.Option
        value="unincluded"
        aria-label="unincluded"
        className={({ checked, active }) =>
          classNames(
            'text-gray-500',
            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-current focus:outline-none',
            checked ? 'ring-2 opacity-100' : 'opacity-40',
            active && checked ? 'ring ring-offset-1' : '',
          )
        }
        onClick={() => {
          setSelected('unincluded');
          dispatch({
            type: 'UNINCLUDE_USER',
            id_user: user.id_user,
          });
        }}
      >
        <span
          aria-hidden="true"
          className="w-5 h-5 rounded-full border border-black/10 bg-current relative"
        >
          <MinusIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
        </span>
      </RadioGroup.Option>

      <RadioGroup.Option
        value="excluded"
        aria-label="excluded"
        className={({ checked, active }) =>
          classNames(
            'text-red-500',
            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 ring-current focus:outline-none',
            checked ? 'ring-2 opacity-100' : 'opacity-40',
            active && checked ? 'ring ring-offset-1' : '',
          )
        }
        onClick={() => {
          setSelected('excluded');
          dispatch({
            type: 'EXCLUDE_USER',
            id_user: user.id_user,
          });
        }}
      >
        <span
          aria-hidden="true"
          className="w-5 h-5 rounded-full border border-black/10 bg-current relative"
        >
          <NoSymbolIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
        </span>
      </RadioGroup.Option>
    </RadioGroup>
  );
}

export default function DefineExclusionReqs({
  state,
  dispatch,
}: DefineExclusionReqsProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center justify-between">
        <p>{t('manager.rulesConfig.defineExclusionReqs')}</p>
        <div className="flex flex-wrap gap-2 text-sm self-end">
          <div className="inline-flex items-center gap-x-2 rounded-full px-2 py-1 text-gray-900 ring-1 ring-inset ring-gray-200">
            <span className="w-5 h-5 rounded-full bg-gray-500 relative border border-black/10">
              <MinusIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
            </span>
            {t('manager.rulesConfig.unincluded')}
            <InfoIcon
              title={t('manager.rulesConfig.unincluded')}
              content={t('manager.rulesConfig.unincludedInfo')}
              className="w-4 h-4"
            />
          </div>
          <div className="inline-flex items-center gap-x-2 rounded-full px-2 py-1 text-gray-900 ring-1 ring-inset ring-gray-200">
            <span className="w-5 h-5 rounded-full bg-red-500 relative border border-black/10">
              <NoSymbolIcon className="absolute inset-0 w-3 h-3 m-auto text-white" />
            </span>
            {t('manager.rulesConfig.excluded')}
          </div>
        </div>
      </div>
      {Object.entries(state.roles).map(([roleId, { users, role_name }]) => (
        <Disclosure key={roleId} defaultOpen={!getRoleInclusionState(users)}>
          {({ open }) => (
            <>
              <div className="grid grid-cols-2">
                <div className="flex flex-row items-center gap-4 border-l-2 border-l-blue-600 pl-3 mt-5 mb-2">
                  <Disclosure.Button
                    as="button"
                    className="flex flex-row items-center"
                  >
                    <ChevronRightIcon
                      className={`${open ? 'rotate-90 transform' : ''} w-5 h-5`}
                    />
                  </Disclosure.Button>
                  <TwoWayToggleRole
                    roleId={Number(roleId)}
                    users={users}
                    dispatch={dispatch}
                    key={`${users.every(user => user.included)}-${role_name}`}
                  />
                  {role_name} ({users.length})
                </div>
              </div>
              <Disclosure.Panel
                as="div"
                className="grid grid-cols-2 ml-16 mt-3 border-l-2 border-l-blue-600 pl-3 items-center gap-y-4"
              >
                {[...users]
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(user => (
                    <div
                      key={user.id_user}
                      className="flex flex-row items-center gap-2"
                    >
                      <TwoWayToggleUser
                        user={user}
                        dispatch={dispatch}
                        key={`${user.included}-${user.name}`}
                      />
                      <p>{user.name}</p>
                    </div>
                  ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
}
