import { Section, SectionWithSlots, SlotLabel } from '@youshift/shared/types';
import { useCallback, useState, useMemo } from 'react';

import { ArrangedSectionSlot } from '../../Sections/types';

interface UseSlotSelectionProps {
  initialSlots?: Set<number>;
  sectionsWithSlots: SectionWithSlots[];
  onSlotsChange?: () => void;
  disabled?: boolean;
  shiftLabels: SlotLabel[];
}

export function useSlotSelection({
  initialSlots,
  sectionsWithSlots,
  onSlotsChange,
  disabled = false,
  shiftLabels,
}: UseSlotSelectionProps) {
  const [selectedSlots, setSelectedSlots] = useState(
    initialSlots || new Set<number>(),
  );

  const filteredShiftLabels = useMemo(() => {
    const usedLabelIds = new Set(
      sectionsWithSlots.flatMap(({ section_slots }) =>
        Object.values(section_slots).map(slot => slot.id_slot_label),
      ),
    );

    return shiftLabels.filter(label => usedLabelIds.has(label.id_slot_label));
  }, [shiftLabels, sectionsWithSlots]);

  // const toggleLabel = useCallback(
  //   (labelId: number) => {
  //     if (disabled) return;

  //     setSelectedSlots(prev => {
  //       const slotsWithLabel = new Set(
  //         sectionsWithSlots.flatMap(({ section_slots }) =>
  //           Object.values(section_slots)
  //             .filter(slot => slot.id_slot_label === labelId)
  //             .map(slot => slot.id_section_slot),
  //         ),
  //       );

  //       const allSelected = Array.from(slotsWithLabel).every(slotId =>
  //         prev.has(slotId),
  //       );

  //       const newSelected = new Set(prev);
  //       slotsWithLabel.forEach(slotId => {
  //         if (allSelected) {
  //           newSelected.delete(slotId);
  //         } else {
  //           newSelected.add(slotId);
  //         }
  //       });

  //       onSlotsChange?.();
  //       return newSelected;
  //     });
  //   },
  //   [disabled, sectionsWithSlots, onSlotsChange],
  // );

  const setSelectedSlotsWithCallback = useCallback(
    (newSlots: Set<number>) => {
      setSelectedSlots(newSlots);
      onSlotsChange?.();
    },
    [onSlotsChange],
  );

  const toggleSlot = useCallback(
    (slot: number | ArrangedSectionSlot) => {
      if (disabled) return;

      const id = typeof slot === 'number' ? slot : slot.id_section_slot!;
      const newSelectedSlots = new Set<number>(selectedSlots);
      if (newSelectedSlots.has(id)) {
        newSelectedSlots.delete(id);
      } else {
        newSelectedSlots.add(id);
      }
      setSelectedSlotsWithCallback(newSelectedSlots);
    },
    [disabled, selectedSlots, setSelectedSlotsWithCallback],
  );

  const onDayClick = useCallback(
    (d: Date) => {
      if (disabled) return;

      const dayStart = new Date(d);
      dayStart.setUTCHours(0, 0, 0, 0);
      const dayEnd = new Date(d);
      dayEnd.setUTCHours(23, 59, 59, 999);

      const slotsInDay = new Set<number>();
      sectionsWithSlots.forEach(({ section_slots }) => {
        Object.values(section_slots).forEach(slot => {
          const slotStart = new Date(slot.start);
          if (slotStart >= dayStart && slotStart <= dayEnd) {
            slotsInDay.add(slot.id_section_slot);
          }
        });
      });

      // Check if all slots in this day are already selected
      const allSelected = Array.from(slotsInDay).every(slotId =>
        selectedSlots.has(slotId),
      );

      const newSelectedSlots = new Set(selectedSlots);
      slotsInDay.forEach(slotId => {
        if (allSelected) {
          newSelectedSlots.delete(slotId);
        } else {
          newSelectedSlots.add(slotId);
        }
      });

      setSelectedSlotsWithCallback(newSelectedSlots);
    },
    [disabled, selectedSlots, sectionsWithSlots, setSelectedSlotsWithCallback],
  );

  const onSectionClick = useCallback(
    (s: Section) => {
      if (disabled) return;

      const slotsInSection = new Set<number>();
      sectionsWithSlots.forEach(({ section, section_slots }) => {
        if (section.id_section === s.id_section) {
          Object.values(section_slots).forEach(slot => {
            slotsInSection.add(slot.id_section_slot);
          });
        }
      });

      // Check if all slots in this section are already selected
      const allSelected = Array.from(slotsInSection).every(slotId =>
        selectedSlots.has(slotId),
      );

      const newSelectedSlots = new Set(selectedSlots);
      slotsInSection.forEach(slotId => {
        if (allSelected) {
          newSelectedSlots.delete(slotId);
        } else {
          newSelectedSlots.add(slotId);
        }
      });

      setSelectedSlotsWithCallback(newSelectedSlots);
    },
    [disabled, selectedSlots, sectionsWithSlots, setSelectedSlotsWithCallback],
  );

  return {
    selectedSlots,
    setSelectedSlotsWithCallback,
    toggleSlot,
    onDayClick,
    onSectionClick,
    filteredShiftLabels,
  };
}
