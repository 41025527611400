import {
  ShiftAssignment,
  SpecialEvent,
  User,
  UserRequirement,
} from '@youshift/shared/types';

import {
  AssignmentCheckErrorType,
  VirtualSlotNeedsDeficitErrorContext,
} from '../iteration_checks/assignment_checks/types';

export enum ShiftExchangeViolationType {
  // User is already assigned to an overlapping shift
  OVERLAPPING_ASSIGNMENT_VIOLATION = 'overlappingAssignmentViolation',

  // New assignment on rest period of a previously existing assignment
  NEW_ASSIGNMENT_ON_REST_PERIOD_VIOLATION = 'newAssignmentOnRestPeriodViolation',

  // Existing assignment on rest period of a new assignment
  EXISTING_ASSIGNMENT_ON_NEW_REST_PERIOD_VIOLATION = 'existingAssignmentOnNewRestPeriodViolation',

  SPECIAL_EVENT_CONFLICT = 'specialEventConflict',

  USER_SHIFT_REQ_MAX_SLOTS_VIOLATION = 'userShiftReqMaxSlotsViolation',

  USER_SHIFT_REQ_MIN_SLOTS_VIOLATION = 'userShiftReqMinSlotsViolation',

  USER_SHIFT_REQ_MAX_DURATION_VIOLATION = 'userShiftReqMaxDurationViolation',

  USER_SHIFT_REQ_MIN_DURATION_VIOLATION = 'userShiftReqMinDurationViolation',

  SINGLE_GROUP_INCOMPATIBILITY_MAX_SIMULT_VIOLATION = 'singleGroupIncompatibilityMaxSimultViolation',

  SINGLE_GROUP_INCOMPATIBILITY_MIN_SIMULT_VIOLATION = 'singleGroupIncompatibilityMinSimultViolation',

  CROSS_GROUP_INCOMPATIBILITY_VIOLATION = 'crossGroupIncompatibilityViolation',
}

export type OverlappingAssignmentViolation = {
  type: ShiftExchangeViolationType.OVERLAPPING_ASSIGNMENT_VIOLATION;
  context: {
    overlappingSectionSlots: number[]; // SectionSlot Ids
  };
};

export type NewAssignmentOnRestPeriodViolation = {
  type: ShiftExchangeViolationType.NEW_ASSIGNMENT_ON_REST_PERIOD_VIOLATION;
  context: {
    violatedRestPeriodShiftAssignments: number[]; // SectionSlot Ids
  };
};

export type ExistingAssignmentOnNewRestPeriodViolation = {
  type: ShiftExchangeViolationType.EXISTING_ASSIGNMENT_ON_NEW_REST_PERIOD_VIOLATION;
  context: {
    existingShiftAssignmentsOnNewRestPeriod: number[];
  };
};

export type SpecialEventConflictViolation = {
  type: ShiftExchangeViolationType.SPECIAL_EVENT_CONFLICT;
  context: {
    conflictingSpecialEvent: number;
  };
};

export type SingleGroupIncompatibilityMaxSimultViolation = {
  type: ShiftExchangeViolationType.SINGLE_GROUP_INCOMPATIBILITY_MAX_SIMULT_VIOLATION;
  context: {
    ruleId: number;
    maxSimult: number;
    usersAlreadyAssigned: number[];
  };
};

export type SingleGroupIncompatibilityMinSimultViolation = {
  type: ShiftExchangeViolationType.SINGLE_GROUP_INCOMPATIBILITY_MIN_SIMULT_VIOLATION;
  context: {
    ruleId: number;
    minSimult: number;
    usersAlreadyAssigned: number[];
  };
};

export type CrossGroupIncompatibilityViolation = {
  type: ShiftExchangeViolationType.CROSS_GROUP_INCOMPATIBILITY_VIOLATION;
  context: {
    ruleId: number;
    oppositeGroupAssignedUsers: number[];
  };
};

export type UserReqMaxSlotsViolation = {
  type: ShiftExchangeViolationType.USER_SHIFT_REQ_MAX_SLOTS_VIOLATION;
  context: {
    ruleId: number;
    maxSlots: number;
    alreadyAssignedSlots: number;
  };
};

export type UserReqMinSlotsViolation = {
  type: ShiftExchangeViolationType.USER_SHIFT_REQ_MIN_SLOTS_VIOLATION;
  context: {
    ruleId: number;
    minSlots: number;
    alreadyAssignedSlots: number;
  };
};

export type UserReqMaxDurationViolation = {
  type: ShiftExchangeViolationType.USER_SHIFT_REQ_MAX_DURATION_VIOLATION;
  context: {
    ruleId: number;
    maxReqDuration: number;
    totalAssignedDuration: number; // in minutes, total assigned duration AFTER the exchange would be completed
  };
};

export type UserReqMinDurationViolation = {
  type: ShiftExchangeViolationType.USER_SHIFT_REQ_MIN_DURATION_VIOLATION;
  context: {
    ruleId: number;
    minReqDuration: number;
    totalAssignedDuration: number; // in minutes, total assigned duration AFTER the exchange would be completed
  };
};

export type ShiftExchangeViolation =
  | OverlappingAssignmentViolation
  | NewAssignmentOnRestPeriodViolation
  | ExistingAssignmentOnNewRestPeriodViolation
  | SpecialEventConflictViolation
  | SingleGroupIncompatibilityMaxSimultViolation
  | SingleGroupIncompatibilityMinSimultViolation
  | CrossGroupIncompatibilityViolation
  | UserReqMaxSlotsViolation
  | UserReqMinSlotsViolation
  | UserReqMaxDurationViolation
  | UserReqMinDurationViolation;
