import {
  CalendarDaysIcon,
  MapPinIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import {
  ExchangeRequest,
  ExchangeResponseType,
  ShiftAssignment,
} from '@youshift/shared/types';
import { dateToString } from '@youshift/shared/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

import {
  checkUserCanGiveUpShift,
  checkUserCanTakeNewShift,
} from '../../../utils/shift_exchange/shift_exchange';
import PostResponse from './PostResponse';
import { useUserContext } from '../../../layouts/UserLayout';
import { useUserShiftExchangeContext } from './ShiftExchangeLayout';
import {
  ShiftExchangeViolation,
  ShiftExchangeViolationType,
} from '../../../utils/shift_exchange/types';

const allowedShiftExchangeViolationsTypes = [
  ShiftExchangeViolationType.OVERLAPPING_ASSIGNMENT_VIOLATION,
  ShiftExchangeViolationType.NEW_ASSIGNMENT_ON_REST_PERIOD_VIOLATION,
  ShiftExchangeViolationType.EXISTING_ASSIGNMENT_ON_NEW_REST_PERIOD_VIOLATION,
  ShiftExchangeViolationType.SPECIAL_EVENT_CONFLICT,
  ShiftExchangeViolationType.USER_SHIFT_REQ_MAX_SLOTS_VIOLATION,
  ShiftExchangeViolationType.USER_SHIFT_REQ_MIN_SLOTS_VIOLATION,
  ShiftExchangeViolationType.USER_SHIFT_REQ_MAX_DURATION_VIOLATION,
  ShiftExchangeViolationType.USER_SHIFT_REQ_MIN_DURATION_VIOLATION,
];

function UserShiftExchangeFeed() {
  const { userLayout, user, events } = useUserContext();

  const {
    exchangeData,
    teamAssignments,
    allUserReqRules,
    incompatibleSectionSlotsMap,
    allSectionSlots,
    allShiftAssignments,
  } = useUserShiftExchangeContext();
  const [open, setOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] =
    useState<ExchangeRequest | null>(null);
  const [exchangeType, setExchangeType] = useState<ExchangeResponseType | null>(
    null,
  );
  const [displayedViolationTypes, setDisplayedViolationTypes] = useState<
    Set<ShiftExchangeViolationType>
  >(new Set(allowedShiftExchangeViolationsTypes));

  const { t } = useTranslation();

  // Get all active shift assignments for the user that could be offered as a one-for-one
  const oneForOnePossibleResponseAssignments: Record<number, ShiftAssignment> =
    Object.entries(userLayout.itrs).reduce((acc, [_, itr]) => {
      const activeShiftAssignments = Object.fromEntries(
        Object.entries(itr.shift_assignments || {}).filter(
          ([_, shift_assignment]) => {
            const sectionSlot =
              itr.section_slots[shift_assignment.id_section_slot];
            if (!sectionSlot) {
              console.log('Undefined section slot found:', {
                id_section_slot: shift_assignment.id_section_slot,
                shift_assignment,
                available_section_slots: Object.keys(itr.section_slots || {}),
                itr_id: itr.itr.id_itr,
              });
            }
            return sectionSlot && new Date(sectionSlot.start) > new Date();
          },
        ),
      );
      return { ...acc, ...activeShiftAssignments };
    }, {});

  // Get all the violations for each request if the respondent were to accept the request as a one-for-zero
  const canTakeOneForZeroShift: Record<string, ShiftExchangeViolation[]> =
    Object.entries(exchangeData).reduce((acc, [id_itr, itr]) => {
      const requestResults = Object.entries(
        itr.other_users_pending_requests,
      ).reduce((requestAcc, [id_request, other_user_request]) => {
        const request_shift_assignment =
          itr.other_users_shift_assignments[
            other_user_request.id_shift_assignment
          ];

        const shiftExchangeViolations = checkUserCanTakeNewShift(
          user,
          request_shift_assignment,
          allUserReqRules,
          allShiftAssignments,
          events.special_events,
          incompatibleSectionSlotsMap,
          allSectionSlots,
        );
        return {
          ...requestAcc,
          [id_request]: shiftExchangeViolations,
        };
      }, {});

      return {
        ...acc,
        ...requestResults,
      };
    }, {});

  // Get all the violations for each request-response pair if the respondent were to accept the request as a one-for-one
  const canTakeOneForOneShift: Record<
    string,
    Record<number, ShiftExchangeViolation[]>
  > = Object.entries(exchangeData).reduce(
    (
      acc: Record<string, Record<number, ShiftExchangeViolation[]>>,
      [_, itr],
    ) => {
      const requests = Object.entries(itr.other_users_pending_requests);

      for (const [id_request, other_user_request] of requests) {
        if (!acc[id_request]) {
          acc[id_request] = {};
        }

        const other_user_request_shift_assignment =
          itr.other_users_shift_assignments[
            other_user_request.id_shift_assignment
          ];

        for (const response_shift_assignment of Object.values(
          oneForOnePossibleResponseAssignments,
        )) {
          const takeShiftViolations = checkUserCanTakeNewShift(
            user,
            other_user_request_shift_assignment,
            allUserReqRules,
            allShiftAssignments,
            events.special_events,
            incompatibleSectionSlotsMap,
            allSectionSlots,
            response_shift_assignment,
          );

          const giveUpShiftViolations = checkUserCanGiveUpShift(
            user,
            response_shift_assignment,
            allUserReqRules,
            allShiftAssignments,
            allSectionSlots,
            other_user_request_shift_assignment,
          );

          acc[id_request][response_shift_assignment.id_shift_assignment] = [
            ...giveUpShiftViolations,
            ...takeShiftViolations,
          ];
        }
      }

      return acc;
    },
    {},
  );
  return (
    <>
      {open && exchangeType && selectedRequest ? (
        <PostResponse
          open={open}
          setOpen={setOpen}
          selectedRequest={selectedRequest}
          exchangeType={exchangeType}
          oneForOnePossibleResponseAssignments={
            oneForOnePossibleResponseAssignments
          }
          oneForZeroViolations={
            canTakeOneForZeroShift[selectedRequest.id_request]
          }
          oneForOneViolations={
            canTakeOneForOneShift[selectedRequest.id_request]
          }
        />
      ) : null}
      {Object.values(exchangeData).every(
        itr => Object.keys(itr.other_users_pending_requests).length === 0,
      ) ? (
        <p className="mt-6 text-center text-gray-600">
          {t('user.shiftExchange.emptyFeed')}
        </p>
      ) : null}
      {Object.entries(exchangeData)
        .flatMap(([id_itr, itr]) =>
          Object.entries(itr.other_users_pending_requests).map(
            ([id_request, other_user_request]) => ({
              id_itr,
              itr,
              id_request,
              other_user_request,
            }),
          ),
        )
        .sort(
          (a, b) =>
            (canTakeOneForZeroShift[a.id_request]?.length || 0) -
            (canTakeOneForZeroShift[b.id_request]?.length || 0),
        )
        .map(({ id_itr, itr, id_request, other_user_request }) => {
          const request_shift_assignment =
            itr.other_users_shift_assignments[
              other_user_request.id_shift_assignment
            ];
          const request_section_slot =
            userLayout.itrs[Number(id_itr)].section_slots[
              request_shift_assignment.id_section_slot
            ];
          const request_section =
            userLayout.itrs[Number(id_itr)].sections[
              request_section_slot.id_section
            ];
          const request_user = itr.other_users[other_user_request.id_requestor];
          return (
            <ul
              key={id_request}
              className="space-y-4 mt-6 overflow-hidden bg-white m-4 shadow-md border border-slate-200 rounded-lg"
            >
              <li
                key={other_user_request.id_request}
                className="relative flex sm:flex-row flex-col justify-between gap-x-6 px-4 py-5  sm:px-6 sm:rounded-lg"
              >
                <div className="flex min-w-0 gap-x-4">
                  <div>
                    <p className="flex flex-row gap-1 items-center">
                      <CalendarDaysIcon className="h-4 text-blue-600" />
                      {dateToString(
                        request_section_slot.start,
                        'weekday-hour',
                      )}{' '}
                      {' - '}{' '}
                      {dateToString(request_section_slot.end, 'weekday-hour')}
                    </p>
                    <p className="flex flex-row gap-1 items-center">
                      <MapPinIcon className="h-4 text-teal-600" />
                      {request_section.name}
                    </p>
                    <p className="flex flex-row gap-1 items-center">
                      <UserIcon className="h-4 text-gray-600" />
                      {`${request_user.firstname} ${request_user.lastname}`}
                    </p>
                  </div>
                </div>
                <div className="flex shrink-0 items-center gap-x-4 mt-3 sm:mt-0">
                  <div className="flex flex-row sm:items-end sm:gap-1 gap-4">
                    <button
                      type="button"
                      className="relative border border-teal-400 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-teal-400/50 sm:ml-3 sm:w-auto disabled:bg-gray-100 disabled:border-gray-100"
                      disabled={other_user_request.allow_one_for_one === false}
                      onClick={() => {
                        setSelectedRequest(other_user_request);
                        setExchangeType(ExchangeResponseType.ONE_FOR_ONE);
                        setOpen(true);
                      }}
                    >
                      {(() => {
                        // At least one of the exchanges is valid.
                        if (
                          Object.values(
                            canTakeOneForOneShift[id_request] || {},
                          ).some(list => list.length == 0)
                        ) {
                          return (
                            <ExclamationCircleIcon className="h-6 w-6 bg-white text-yellow-600 absolute rounded-full -top-3 -right-2" />
                          );
                        }
                        // None of the exchanges is valid
                        return (
                          <ExclamationCircleIcon className="h-6 w-6 bg-white text-red-600 absolute rounded-full -top-3 -right-2" />
                        );
                      })()}
                      {t('user.shiftExchange.trade')}
                    </button>
                    <button
                      type="button"
                      className="relative border border-blue-400 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-blue-400/50 sm:ml-3 sm:w-auto disabled:bg-gray-100 disabled:border-gray-100"
                      disabled={other_user_request.allow_one_for_zero === false}
                      onClick={() => {
                        setSelectedRequest(other_user_request);
                        setExchangeType(ExchangeResponseType.ONE_FOR_ZERO);
                        setOpen(true);
                      }}
                    >
                      {canTakeOneForZeroShift[id_request]?.length > 0 && (
                        <ExclamationCircleIcon className="h-6 w-6 bg-white text-red-600 absolute rounded-full -top-3 -right-2" />
                      )}
                      {t('user.shiftExchange.accept')}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          );
        })}
    </>
  );
}
export default UserShiftExchangeFeed;
