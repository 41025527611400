import { AlgComponentType } from '@youshift/shared/types';
import { TFunction } from 'i18next';

export const getComponentTitle = (
  componentType: AlgComponentType,
  t: TFunction,
): string => {
  const titles: Record<AlgComponentType, string> = {
    [AlgComponentType.VIRTUAL_SLOT_NEEDS_MIN]: t(
      'manager.smartass.algorithmComponentType.VIRTUAL_SLOT_NEEDS_MIN',
    ),
    [AlgComponentType.VIRTUAL_SLOT_NEEDS_MAX]: t(
      'manager.smartass.algorithmComponentType.VIRTUAL_SLOT_NEEDS_MAX',
    ),
    [AlgComponentType.SINGLE_GROUP_INCOMPATIBILITY_MIN]: t(
      'manager.smartass.algorithmComponentType.SINGLE_GROUP_INCOMPATIBILITY_MIN',
    ),
    [AlgComponentType.SINGLE_GROUP_INCOMPATIBILITY_MAX]: t(
      'manager.smartass.algorithmComponentType.SINGLE_GROUP_INCOMPATIBILITY_MAX',
    ),
    [AlgComponentType.CROSS_GROUP_INCOMPATIBILITY]: t(
      'manager.smartass.algorithmComponentType.CROSS_GROUP_INCOMPATIBILITY',
    ),
    [AlgComponentType.C_1]: t('manager.smartass.algorithmComponentType.C_1'),
    [AlgComponentType.C_CUSTOM_REST_PERIOD]: t(
      'manager.smartass.algorithmComponentType.C_CUSTOM_REST_PERIOD',
    ),
    [AlgComponentType.C_PERSONAL_BLOCKING]: t(
      'manager.smartass.algorithmComponentType.C_PERSONAL_BLOCKING',
    ),
    [AlgComponentType.C_JUSTIFIED_BLOCKING]: t(
      'manager.smartass.algorithmComponentType.C_JUSTIFIED_BLOCKING',
    ),
    [AlgComponentType.C_SPECIAL_EVENTS]: t(
      'manager.smartass.algorithmComponentType.C_SPECIAL_EVENTS',
    ),
    [AlgComponentType.C_PREVIOUSLY_ASSIGNED_SHIFTS]: t(
      'manager.smartass.algorithmComponentType.C_PREVIOUSLY_ASSIGNED_SHIFTS',
    ),
    [AlgComponentType.C_PENALTY_WORKER_MAX]: t(
      'manager.smartass.algorithmComponentType.C_PENALTY_WORKER_MAX',
    ),
    [AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MAX_SLOTS]: t(
      'manager.smartass.algorithmComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MAX_SLOTS',
    ),
    [AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MIN_SLOTS]: t(
      'manager.smartass.algorithmComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MIN_SLOTS',
    ),
    [AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MAX_DURATION]: t(
      'manager.smartass.algorithmComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MAX_DURATION',
    ),
    [AlgComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MIN_DURATION]: t(
      'manager.smartass.algorithmComponentType.C_USER_SHIFT_REQUIREMENT_RULE_MIN_DURATION',
    ),
  };
  return titles[componentType];
};
