import { QueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  teamAssignmentsQuery,
  TeamAssignmentsResponse,
} from '@youshift/shared/hooks/queries';
import { LoaderFunctionArgs, redirect, useLoaderData } from 'react-router-dom';
import { ShiftAssignment } from '@youshift/shared/types';
import {
  addMonths,
  dateToString,
  getFirstDayOfWeek,
  localeNormalizer,
  mergeIterationData,
  subtractMonths,
} from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import { Calendar } from 'react-native-big-calendar';
import {
  ArrowLeftIcon,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { requireApproved, requireLoggedIn } from '../../utils/checks';
import { useUserContext } from '../../layouts/UserLayout';
import {
  CalendarEvents,
  customEventRenderer,
  generateGroupedTeamShiftAssignments,
  generateShiftAssignments,
  isCalendarShiftAssignments,
  teamShiftAssignmentRenderer,
} from '../../utils/calendar';
import i18n from '../../utils/i18n';
import UsersFilterDropdown from '../../FilterDropdownWithSubgroups';
import FilterDropdown from '../../components/FilterDropdown';
import Wrapper from '../../components/Wrapper';
import { YSButton } from '../../components/Buttons';

export const teamAssignmentsLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<TeamAssignmentsResponse> => {
    const user = await requireLoggedIn(queryClient);
    await requireApproved(user);
    if (!user.group?.display_team_assignments) {
      throw redirect('/user/dashboard');
    }
    const teamAssignments = preLoadQuery(queryClient, teamAssignmentsQuery());
    return teamAssignments;
  };

export default function TeamAssignments() {
  const { itrs, users, roles } = useLoaderData() as TeamAssignmentsResponse;
  const { userLayout } = useUserContext();
  const { t } = useTranslation();

  const [selectedUsers, setSelectedUsers] = useState<Set<number>>(
    new Set(
      Object.values(users)
        .filter(user => user.id_user_role)
        .map(user => Number(user.id)),
    ),
  );

  const {
    sections: allSections,
    section_slots: allSectionSlots,
    slot_labels: allSlotLabels,
  } = useMemo(
    () =>
      mergeIterationData(userLayout.itrs, [
        'sections',
        'section_slots',
        'slot_labels',
      ]),
    [userLayout],
  );

  const [selectedSections, setSelectedSections] = useState<Set<string>>(
    new Set(Object.keys(allSections)),
  );

  const { shift_assignments: allShiftAssignmentsByUser } = useMemo(
    () => mergeIterationData(itrs, ['shift_assignments']),
    [itrs],
  );

  const allShiftAssignments: { [shiftAssignmentId: string]: ShiftAssignment } =
    Object.values(allShiftAssignmentsByUser).reduce((acc, userAssignments) => {
      return { ...acc, ...userAssignments };
    }, {});

  const [calendarMonthStart, setCalendarMonthStart] = useState(new Date());

  const goToNextMonth = () => {
    setCalendarMonthStart(prev => addMonths(prev, 1));
  };
  const goToPreviousMonth = () => {
    setCalendarMonthStart(prev => subtractMonths(prev, 1));
  };

  const isFirstMonth = useMemo(() => {
    const today = new Date();
    const firstOfCurrentMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      1,
    );
    const firstOfCalendarMonth = new Date(
      calendarMonthStart.getFullYear(),
      calendarMonthStart.getMonth(),
      1,
    );
    return firstOfCalendarMonth <= firstOfCurrentMonth;
  }, [calendarMonthStart]);

  const calendarGroupedTeamShiftAssignments = useMemo(() => {
    const filteredAssignments = Object.entries(allShiftAssignments).reduce(
      (acc, [id, assignment]) => {
        if (selectedUsers.has(assignment.id_user)) {
          if (
            selectedSections.size === 0 ||
            selectedSections.has(assignment.id_section.toString())
          ) {
            acc[id] = assignment;
          }
        }
        return acc;
      },
      {} as typeof allShiftAssignments,
    );

    return generateGroupedTeamShiftAssignments(
      filteredAssignments,
      allSectionSlots,
      allSections,
      allSlotLabels,
    );
  }, [
    allShiftAssignments,
    allSectionSlots,
    allSections,
    allSlotLabels,
    selectedUsers,
    selectedSections,
  ]);

  const sectionFilterItems = useMemo(
    () =>
      Object.values(allSections).map(section => ({
        id: section.id_section.toString(),
        name: section.name,
      })),
    [allSections],
  );

  const locale = localeNormalizer(i18n.language);

  return (
    <Wrapper>
      <div className="flex flex-row justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold text-gray-900 flex flex-row gap-2 items-center">
          <CalendarIcon className="w-6 h-6 text-blue-600" />
          {t('user.dashboard.teamAssignments')}
        </h1>
        <button
          className="mb-2 px-4 py-2 text-sm cursor-pointer font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          onClick={() => window.history.back()}
        >
          ← {t('generic.back')}
        </button>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-row justify-between items-center mb-4">
          <div className="flex flex-row gap-1">
            <button
              onClick={goToPreviousMonth}
              className={`rounded-md border border-gray-300 px-3 text-sm ${
                isFirstMonth
                  ? 'opacity-50 cursor-not-allowed'
                  : 'hover:bg-gray-50'
              }`}
              disabled={isFirstMonth}
              aria-label="previous-week"
            >
              <ChevronLeftIcon className="w-4 h-4" />
            </button>
            <button
              aria-label="next-week"
              onClick={goToNextMonth}
              className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
            >
              <ChevronRightIcon className="w-4 h-4" />
            </button>
            <p className="text-md font-semibold text-blue-600">
              {dateToString(calendarMonthStart, 'month-year')}
            </p>
          </div>
          <div className="flex gap-4">
            <UsersFilterDropdown
              users={users}
              roles={roles}
              selectedUsers={selectedUsers}
              onSelectionChange={setSelectedUsers}
            />
            <FilterDropdown
              label={t('generic.sections')}
              items={sectionFilterItems}
              selectedItems={selectedSections}
              onSelectionChange={setSelectedSections}
            />
          </div>
        </div>
        <Calendar
          locale={locale}
          events={calendarGroupedTeamShiftAssignments}
          height={800}
          mode="month"
          sortedMonthView
          date={calendarMonthStart}
          weekStartsOn={getFirstDayOfWeek(locale) === 7 ? 0 : 1}
          renderEvent={(event, touchableOpacityProps) =>
            teamShiftAssignmentRenderer(event, touchableOpacityProps, users)
          }
          maxVisibleEventCount={30}
        />
      </div>
    </Wrapper>
  );
}
