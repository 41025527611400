import { useTranslation } from 'react-i18next';
import {
  CalendarIcon,
  CheckCircleIcon,
  ClockIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import {
  ExchangeResponseType,
  Section,
  SectionSlot,
  ShiftAssignment,
  UserReqRule,
} from '@youshift/shared/types';
import {
  localeNormalizer,
  minutesToHours,
  parseSectionSlotDateTime,
} from '@youshift/shared/utils';

import {
  ShiftExchangeViolation,
  ShiftExchangeViolationType,
} from '../../utils/shift_exchange/types';
import Modal from '../../components/Modal';
import i18n from '../../utils/i18n';
import ShiftViolationsList from '../../components/ShiftExchange/ShiftViolationsList';

interface ManagerShiftExchangeViolationsSummaryProps {
  isOpen: boolean;
  onClose: () => void;
  violations: {
    requestor: ShiftExchangeViolation[];
    respondent: ShiftExchangeViolation[];
  };
  allUserReqRules: Record<number, UserReqRule>;
  allSectionSlots: Record<number, SectionSlot>;
  allSections: Record<number, Section>;
}

export default function ManagerShiftExchangeViolationsSummary({
  isOpen,
  onClose,
  violations,
  allUserReqRules,
  allSectionSlots,
  allSections,
}: ManagerShiftExchangeViolationsSummaryProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {violations.requestor.length === 0 &&
      violations.respondent.length === 0 ? (
        <div className="mt-2 border border-green-600 bg-green-50 rounded-md p-2">
          <div className="flex items-center gap-1 text-green-600 mb-1">
            <CheckCircleIcon
              className="mt-1 h-5 w-5 flex-none text-green-600"
              aria-hidden="true"
            />
            <h4 className="font-medium">
              {t('generic.shiftExchange.shiftExchangeCompliant')}
            </h4>
          </div>
        </div>
      ) : (
        <div className="mt-2 border border-yellow-600 bg-yellow-50 rounded-md p-2">
          <div className="flex items-center gap-1 text-yellow-600 mb-1">
            <ExclamationTriangleIcon className="h-5 w-5" />
            <h4 className="font-medium">
              {t('generic.shiftExchange.shiftExchangeViolation')}
            </h4>
          </div>

          {/* Requestor Violations */}
          {violations.requestor.length > 0 && (
            <>
              <h5 className="font-medium mt-2 mb-1">
                {t('generic.shiftExchange.requestorViolations')}
              </h5>
              <ShiftViolationsList
                violations={violations.requestor}
                allUserReqRules={allUserReqRules}
                allSectionSlots={allSectionSlots}
                allSections={allSections}
              />
            </>
          )}

          {/* Respondent Violations */}
          {violations.respondent.length > 0 && (
            <>
              <h5 className="font-medium mt-4 mb-1">
                {t('generic.shiftExchange.respondentViolations')}
              </h5>
              <ShiftViolationsList
                violations={violations.respondent}
                allUserReqRules={allUserReqRules}
                allSectionSlots={allSectionSlots}
                allSections={allSections}
              />
            </>
          )}
        </div>
      )}
    </Modal>
  );
}
