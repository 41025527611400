import { ExchangeResponseStatus } from '@youshift/shared/types';
import React from 'react';

function AcceptIcon({
  status,
  arrowDirection,
}: {
  status: ExchangeResponseStatus;
  arrowDirection: 'right' | 'left';
}) {
  // Determine the size based on the small prop
  const strokeColor =
    status === ExchangeResponseStatus.APPROVED
      ? 'green'
      : status === ExchangeResponseStatus.PENDING_MANAGER ||
          status === ExchangeResponseStatus.PENDING_DOCTOR
        ? '#FFAC30'
        : 'red';

  let transform;
  switch (arrowDirection) {
    case 'right':
      transform = 'rotate(180)';
      break;
    default:
      transform = '';
      break;
  }

  return (
    <svg
      width={75}
      height={24}
      viewBox="0 0 50 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-auto"
      transform={transform}
    >
      <path
        d="M50 12.2444L38.8525 12.2444L0.999999 12.2444M0.999999 12.2444L16.19 23M0.999999 12.2444L16.19 1"
        stroke={strokeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AcceptIcon;
