import { Dialog, Transition } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import {
  useEditChainMutation,
  useDuplicateChainMutation,
} from '@youshift/shared/hooks/mutations';
import { Fragment, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Chain } from '@youshift/shared/types';

import { YSButton } from '../../components/Buttons';

interface EditChainProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  chain: Chain;
}

export default function EditChain({ open, setOpen, chain }: EditChainProps) {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const [name, setName] = useState<string>(chain?.chain_name || '');
  const [vdays, setVdays] = useState<string>(
    chain?.min_cons_holidays?.toString() || '',
  );
  const [vpoints, setVpoints] = useState<string>(
    chain?.holiday_point_penalty?.toString() || '',
  );
  const [maxSavedPoints, setMaxSavedPoints] = useState<number>(
    chain?.max_saved_points_allowed || 0,
  );
  const [autoExchange, setAutoExchange] = useState<boolean>(
    chain?.auto_approve_shift_exchange || false,
  );
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const editChainMutation = useEditChainMutation(queryClient, {
    onSuccess: () => {
      toast.success(t('manager.chains.success'));
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: ['chains', 'all'] });
    },
  });

  const duplicateChainMutation = useDuplicateChainMutation(queryClient, {
    onSuccess: () => {
      toast.success(t('manager.chains.successDuplicate'));
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: ['chains', 'all'] });
    },
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title
                      as="h3"
                      className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6"
                    >
                      {t('manager.iterationLayouts.editParameters')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="isolate -space-y-px rounded-md shadow-sm">
                        <div className="relative rounded-md rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label
                            htmlFor="name"
                            className="block text-xs font-medium text-gray-900"
                          >
                            {t('generic.name')}
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={chain?.chain_name}
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                        <div className="relative rounded-md rounded-t-none rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label
                            htmlFor="job-title"
                            className="block text-xs font-medium text-gray-900"
                          >
                            {t('manager.chains.minConsHolidays')}
                          </label>
                          <input
                            id="vdays"
                            name="vdays"
                            type="number"
                            value={vdays}
                            onChange={e => setVdays(e.target.value)}
                            autoComplete="off"
                            min="0"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={String(chain?.min_cons_holidays)}
                          />
                        </div>
                        <div className="relative rounded-md rounded-t-none rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label
                            htmlFor="job-title"
                            className="block text-xs font-medium text-gray-900"
                          >
                            {t('manager.chains.pointsSpentOnHolidays')}
                          </label>
                          <input
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            id="vpoints"
                            name="vpoints"
                            type="number"
                            value={vpoints}
                            onChange={e => setVpoints(e.target.value)}
                            min="0"
                          />
                        </div>
                        <div className="relative rounded-md rounded-t-none rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label
                            htmlFor="job-title"
                            className="block text-xs font-medium text-gray-900"
                          >
                            {t('manager.chains.maxPointsSavedAllowed')}
                          </label>
                          <input
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            id="maxSavedPoints"
                            name="maxSavedPoints"
                            type="number"
                            value={maxSavedPoints}
                            onChange={e =>
                              setMaxSavedPoints(Number(e.target.value))
                            }
                            min="0"
                          />
                        </div>
                        <div className="relative flex items-center rounded-md rounded-t-none px-3 py-4 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label
                            htmlFor="autoExchange"
                            className="block text-xs font-medium text-gray-900 mr-2"
                          >
                            {t('manager.chains.autoApprovedShiftExchange')}
                          </label>
                          <input
                            className="form-checkbox text-blue-600 border-gray-300 rounded focus:ring-blue-500 h-4 w-4"
                            id="autoExchange"
                            name="autoExchange"
                            type="checkbox"
                            checked={autoExchange}
                            onChange={e => setAutoExchange(e.target.checked)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex gap-2">
                    <YSButton
                      onClick={() =>
                        editChainMutation.mutate({
                          id_chain: chain.id_chain,
                          chain_name: name,
                          min_cons_holidays: Number(vdays),
                          holiday_point_penalty: Number(vpoints),
                          max_saved_points_allowed: Number(maxSavedPoints),
                          auto_approve_shift_exchange: autoExchange,
                        })
                      }
                    >
                      {t('generic.save')}
                    </YSButton>
                    <YSButton
                      variant="ghost-primary"
                      onClick={() => setOpen(false)}
                    >
                      {t('generic.cancel')}
                    </YSButton>
                  </div>
                  {/* <YSButton
                    variant="ghost-secondary"
                    onClick={() =>
                      duplicateChainMutation.mutate({
                        id_chain: chain.id_chain,
                      })
                    }
                  >
                    {t('generic.duplicate')}
                  </YSButton> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
