import { Disclosure } from '@headlessui/react';
import {
  ChevronUpIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import type {
  Section,
  SectionSlot,
  ShiftAssignment,
  User,
  UserReqRule,
  UserRequirement,
  VirtualSlot,
} from '@youshift/shared/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  localeNormalizer,
  parseSectionSlotDateTime,
} from '@youshift/shared/utils';

import {
  ConfigurationCheckErrors,
  ConfigurationCheckErrorType,
  VirtualSlotErrors,
} from '../../utils/iteration_checks/configuration_checks/types';
import Table from '../Table';
import UserConfigErrorsTableDisclosure from './config/UserConfigErrorsTableDisclosure';
import i18n from '../../utils/i18n';

type Props = {
  errors: ConfigurationCheckErrors;
  allUserReqs: Record<number, UserReqRule>;
  users: Record<number, User>;
  sections: Record<number, Section>;
  virtualSlots: Record<number, VirtualSlot>;
};

function ConfigChecksDisplay({
  errors,
  users,
  allUserReqs,
  sections,
  virtualSlots,
}: Props) {
  const { t } = useTranslation();
  const locale = localeNormalizer(i18n.language);

  const userFullNameMap: Record<string | number, string> = Object.fromEntries(
    Object.entries(users).map(([userId, user]) => [
      userId,
      `${user.firstname} ${user.lastname.slice(0, 1)}.`,
    ]),
  );

  return (
    <div>
      {Object.keys(errors.virtual_slots).length > 0 && (
        <>
          <h2 className="font-semibold mb-2 text-md">
            {t('manager.configErrors.virtualSlotsErrors')}
          </h2>
          <Disclosure>
            {({ open }) => (
              <>
                {/* Title Bar */}
                <Disclosure.Button className="flex justify-start gap-1 mb-2 items-center w-full px-4 py-2 text-lg font-medium text-left bg-gray-100 hover:bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                  <ChevronUpIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-gray-500`}
                  />
                  <ExclamationCircleIcon className="w-6 h-6 text-red-600 mr-3" />
                  <span>{t('manager.configErrors.virtualSlotsErrors')}</span>
                </Disclosure.Button>

                {/* Collapsible Content */}
                <Disclosure.Panel className="text-sm text-gray-700">
                  <Table
                    headers={[
                      t('generic.virtualSlots'),
                      t('manager.configErrors.minDemand'),
                      t('manager.configErrors.availableWorkers'),
                      t('manager.configErrors.usersPreassignedOsOnVirtualSlot'),
                    ]}
                    rows={Object.entries(errors.virtual_slots).map(
                      ([virtualSlotId, virtualSlotErrors]) => {
                        const virtualSlot = virtualSlots[Number(virtualSlotId)];
                        const sectionAcr =
                          sections[virtualSlot.id_section].acronym;
                        const dateTime = parseSectionSlotDateTime(
                          virtualSlot.start,
                          virtualSlot.end,
                          locale,
                        );
                        return [
                          `${sectionAcr}: ${dateTime}`,
                          virtualSlotErrors[
                            ConfigurationCheckErrorType
                              .VIRTUAL_SLOT_SUPPLY_DEMAND_DEFICIT
                          ]?.min_demand ?? '-',
                          virtualSlotErrors[
                            ConfigurationCheckErrorType
                              .VIRTUAL_SLOT_SUPPLY_DEMAND_DEFICIT
                          ]?.available_workers.length ?? '-',
                          virtualSlotErrors[
                            ConfigurationCheckErrorType
                              .VIRTUAL_SLOT_SUPPLY_DEMAND_DEFICIT
                          ]?.users_preassigned_OS_on_virtual_slot.length ?? '-',
                        ];
                      },
                    )}
                  />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </>
      )}
      {Object.keys(errors.user_req_rules).length > 0 && (
        <>
          <h2 className="font-semibold mb-2 text-md">
            {t('manager.configErrors.rulesErrors')}
          </h2>
          <Disclosure>
            {({ open }) => (
              <>
                {/* Title Bar */}
                <Disclosure.Button className="flex justify-start gap-1 mb-2 items-center w-full px-4 py-2 text-lg font-medium text-left bg-gray-100 hover:bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                  <ChevronUpIcon
                    className={`${
                      open ? 'transform rotate-180' : ''
                    } w-5 h-5 text-gray-500`}
                  />
                  <ExclamationCircleIcon className="w-6 h-6 text-red-600 mr-3" />
                  <span>{t('manager.configErrors.rulesErrors')}</span>
                </Disclosure.Button>

                {/* Collapsible Content */}
                <Disclosure.Panel className="text-sm text-gray-700">
                  {/* Table for full_section_user_req_rule_slots_supply_deficit */}
                  {Object.entries(errors.user_req_rules).some(
                    ([_, reqRuleErrors]) =>
                      reqRuleErrors.full_section_user_req_rule_slots_supply_deficit,
                  ) && (
                    <Table
                      headers={[
                        t('generic.rule'),
                        t('manager.configErrors.minNeeds'),
                        t('manager.configErrors.maxSlotSupply'),
                      ]}
                      rows={Object.entries(errors.user_req_rules)
                        .filter(
                          ([_, reqRuleErrors]) =>
                            reqRuleErrors.full_section_user_req_rule_slots_supply_deficit,
                        )
                        .map(([reqRuleId, reqRuleErrors]) => {
                          const ruleName =
                            allUserReqs[Number(reqRuleId)].rule.name;
                          const deficit =
                            reqRuleErrors.full_section_user_req_rule_slots_supply_deficit!;
                          return [
                            ruleName,
                            deficit.min_needs,
                            deficit.max_supply,
                          ];
                        })}
                    />
                  )}

                  {/* Table for full_section_user_req_rule_slots_supply_surplus */}
                  {Object.entries(errors.user_req_rules).some(
                    ([_, reqRuleErrors]) =>
                      reqRuleErrors.full_section_user_req_rule_slots_supply_surplus,
                  ) && (
                    <Table
                      headers={[
                        t('generic.rule'),
                        t('manager.configErrors.minSlotSupply'),
                        t('manager.configErrors.maxNeeds'),
                      ]}
                      rows={Object.entries(errors.user_req_rules)
                        .filter(
                          ([_, reqRuleErrors]) =>
                            reqRuleErrors.full_section_user_req_rule_slots_supply_surplus,
                        )
                        .map(([reqRuleId, reqRuleErrors]) => {
                          const ruleName =
                            allUserReqs[Number(reqRuleId)].rule.name;
                          const surplus =
                            reqRuleErrors.full_section_user_req_rule_slots_supply_surplus!;
                          return [
                            ruleName,
                            surplus.min_supply,
                            surplus.max_needs,
                          ];
                        })}
                    />
                  )}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </>
      )}
      {Object.keys(errors.users).length > 0 && (
        <>
          <h2 className="font-semibold mb-2 text-md">
            {t('manager.configErrors.rulesErrors')}
          </h2>
          {Object.entries(errors.users).map(([userId, userErrors]) => (
            <UserConfigErrorsTableDisclosure
              name={userFullNameMap[userId]}
              userErrors={userErrors}
              allUserReqs={allUserReqs}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default ConfigChecksDisplay;
