import { useQueryClient } from '@tanstack/react-query';
import { useInterruptAlgMutation } from '@youshift/shared/hooks/mutations';
import { ItrStatuses } from '@youshift/shared/types';
import { classNames } from '@youshift/shared/utils';
import { useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Navigate, NavLink, Outlet, useParams } from 'react-router-dom';

import LoadingOverlay from '../components/RunningAlgOverlay';
import { useItrContext } from './IterationRootLayout/IterationRootLayout';

export default function IterationAssignmentLayout() {
  const { t } = useTranslation();
  const { idItr } = useParams();

  const itrContext = useItrContext();
  const { status } = itrContext;

  const tabs = useMemo(
    () => [
      { name: t('generic.calendar'), href: 'manual' },
      { name: t('manager.iterationLayouts.global'), href: 'table' },
      { name: t('manager.iterationLayouts.person'), href: 'user' },
      { name: t('manager.iterationLayouts.summary'), href: 'stats' },
    ],
    [t],
  );

  const queryClient = useQueryClient();

  const interruptAlgorithm = useInterruptAlgMutation(queryClient, {
    onSuccess: () => {
      toast.success(t('manager.runningAlg.interruptAlgSuccess'));
      queryClient.invalidateQueries({ queryKey: ['iteration', idItr] });
      queryClient.invalidateQueries({ queryKey: ['status', idItr] });
    },
  });

  useEffect(() => {
    if (status === ItrStatuses.ASSIGNMENT) {
      queryClient.invalidateQueries({ queryKey: ['shiftAssignments', idItr] });
      queryClient.invalidateQueries({ queryKey: ['assignmentTool', idItr] });
      queryClient.invalidateQueries({ queryKey: ['iteration', idItr] });
    }
  }, [status, queryClient, idItr]);

  return (
    <>
      {status === ItrStatuses.INITIALIZATION ? (
        <Navigate to="../initialization/roles" replace />
      ) : null}
      {status === ItrStatuses.CONFIGURATION ? (
        <Navigate to="../configuration/roles" replace />
      ) : null}
      <div className="block">
        <nav
          className="isolate flex divide-x rounded-lg shadow"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <NavLink
              key={tab.name}
              to={tab.href}
              id={tab.href}
              className={classNames(
                '[&.active]:text-gray-900 [&.active]:border-b-2 [&.active]:border-solid [&.active]:border-b-blue-500 text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
              )}
            >
              <span>{tab.name}</span>
            </NavLink>
          ))}
        </nav>
      </div>
      {/* TODO RUNNING : revisar */}
      {status === ItrStatuses.RUNNING_SMARTASS ? (
        <LoadingOverlay
          onStopClick={() => interruptAlgorithm.mutate({ id_itr: idItr })}
        />
      ) : (
        <Outlet />
      )}
    </>
  );
}
