/* eslint-disable */

import React, { useMemo, useState } from 'react';
import {
  MinusIcon,
  PlusIcon,
  UserMinusIcon,
  UserPlusIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import type {
  Section,
  SectionSlot,
  ShiftAssignment,
  User,
  UserReqRule,
  UserRequirement,
  UserRequirementType,
  VirtualSlot,
} from '@youshift/shared/types';
import {
  localeNormalizer,
  parseSectionSlotDateTime,
} from '@youshift/shared/utils';

import {
  AssignmentCheckErrors,
  VirtualSlotErrors,
} from '../../utils/iteration_checks/assignment_checks/types';
import i18n from '../../utils/i18n';
import AssignmentErrorsTableDisclosure from './assignment/AssignmentErrorsTableDisclosure';
import UserErrorsTableDisclosure from './assignment/UserErrorsTableDisclosure';
import {
  getSingleUserErrors,
  getSingleUserDisplaySeverity,
  AssignmentCheckErrorStatus,
} from '../../utils/iteration_checks/types';

type Props = {
  errors: AssignmentCheckErrors;
  incompatibilitiesNames: Record<string, string>;
  allUserReqs: Record<number, UserReqRule>;
  users: Record<number, User>;
  sectionSlots: Record<number, SectionSlot>;
  sections: Record<number, Section>;
  virtualSlots: Record<number, VirtualSlot>;
  assignmentsByUser: Record<number, ShiftAssignment[]>;
};

const AssignmentChecksDisplay: React.FC<Props> = ({
  errors,
  users,
  incompatibilitiesNames,
  allUserReqs,
  sectionSlots,
  sections,
  virtualSlots,
  assignmentsByUser,
}) => {
  const { t } = useTranslation();
  const locale = localeNormalizer(i18n.language);

  const [activeTab, setActiveTab] = useState('rulesAndIncompatibilities');

  const rulesNamesAndTypes = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(allUserReqs).map(([id, userReqRule]) => [
          userReqRule.rule.id_rule,
          {
            name: userReqRule.rule.name,
            req_type: Object.values(userReqRule.user_reqs)[0]?.req_type,
          },
        ]),
      ),
    [allUserReqs],
  );

  const userFullNameMap: Record<string | number, string> = Object.fromEntries(
    Object.entries(users).map(([userId, user]) => [
      userId,
      `${user.firstname} ${user.lastname.slice(0, 1)}.`,
    ]),
  );

  const virtualSlotErrorsPerSection: Record<number, VirtualSlotErrors> =
    useMemo(
      () =>
        Object.entries(errors.virtual_slots).reduce<
          Record<number, VirtualSlotErrors>
        >((acc, [virtualSlotIdStr, slotErrors]) => {
          const virtualSlotId = Number(virtualSlotIdStr);
          const sectionId = virtualSlots[virtualSlotId].id_section;
          if (!acc[sectionId]) acc[sectionId] = {};
          acc[sectionId][virtualSlotId] = slotErrors;
          return acc;
        }, {}),
      [],
    );

  type UserReqsByUser = {
    [userId: number]: {
      [ruleId: number]: UserRequirement | null;
    };
  };

  const userReqsByUser: UserReqsByUser = useMemo(() => {
    return Object.entries(allUserReqs).reduce<UserReqsByUser>(
      (acc, [ruleIdStr, userReqRule]) => {
        const ruleId = parseInt(ruleIdStr, 10);

        // Loop through all users to ensure an entry for each one
        Object.keys(users).forEach(userIdStr => {
          const userId = parseInt(userIdStr, 10);

          // Initialize user entry if missing
          acc[userId] = acc[userId] || {};

          // Add rule entry only if there is a requirement for this user
          if (userReqRule.user_reqs[userId]) {
            acc[userId][ruleId] = userReqRule.user_reqs[userId];
          }
        });

        return acc;
      },
      {},
    );
  }, [allUserReqs, users]);

  return (
    <div>
      {/* Tab Navigation */}
      <div className="grid grid-cols-2 mb-4 gap-2">
        <button
          onClick={() => setActiveTab('rulesAndIncompatibilities')}
          className={`tab-button ${
            activeTab === 'rulesAndIncompatibilities' ? 'active' : ''
          } px-2 py-1 border-b-2 font-semibold text-sm ${
            activeTab === 'rulesAndIncompatibilities'
              ? 'border-blue-500'
              : 'border-gray-200'
          }`}
        >
          {t('manager.assignmentErrors.schedulingErrors')}
        </button>
        <button
          onClick={() => setActiveTab('userAssignments')}
          className={`tab-button ${
            activeTab === 'userAssignments' ? 'active' : ''
          } px-2 py-1 border-b-2 font-semibold text-sm ${
            activeTab === 'userAssignments'
              ? 'border-blue-500'
              : 'border-gray-200'
          }`}
        >
          {t('manager.assignmentErrors.userErrors')}
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === 'rulesAndIncompatibilities' && (
        <div>
          {/* Virtual Slot Errors */}
          <h2 className="font-semibold mb-2 text-md">
            {t('generic.sections')}
          </h2>
          {Object.values(sections).map(section => {
            const virtualSlotErrors =
              virtualSlotErrorsPerSection[section.id_section] || {};
            const noErrors = Object.keys(virtualSlotErrors).length === 0;
            return (
              <AssignmentErrorsTableDisclosure
                virtualSlotErrors={virtualSlotErrors}
                name={section.name}
                noErrors={noErrors}
                userFullNameMap={userFullNameMap}
                sections={sections}
                virtualSlots={virtualSlots}
              />
            );
          })}
          {/* Incompatibilities */}
          <h2 className="font-semibold mb-2 text-md">
            {t('generic.incompatibilities')}
          </h2>
          {/* Single Group Incompatibilities */}
          {Object.values(errors.single_group_incompatibilities).length === 0 &&
          Object.values(errors.cross_group_incompatibilities).length === 0 ? (
            <p>{t('manager.incomp.noGroups')}</p>
          ) : null}
          {Object.entries(errors.single_group_incompatibilities).map(
            ([incompId, incompErrors]) => {
              const noErrors = Object.keys(incompErrors).length === 0;
              const incompName = incompatibilitiesNames[incompId];
              return (
                <AssignmentErrorsTableDisclosure
                  singleIncompErrors={incompErrors}
                  name={incompName}
                  noErrors={noErrors}
                  userFullNameMap={userFullNameMap}
                  sections={sections}
                  sectionSlots={sectionSlots}
                />
              );
            },
          )}
          {/* Cross Group Incompatibilities */}
          {Object.entries(errors.cross_group_incompatibilities).map(
            ([incompId, slots]) => {
              const noErrors = Object.keys(slots).length === 0;
              const incompName = incompatibilitiesNames[incompId];
              return (
                <AssignmentErrorsTableDisclosure
                  crossIncompErrors={slots}
                  name={incompName}
                  noErrors={noErrors}
                  userFullNameMap={userFullNameMap}
                  sections={sections}
                  sectionSlots={sectionSlots}
                />
              );
            },
          )}
          {/* User Requirement Rules */}
          <h2 className="font-semibold mb-2 text-md">{t('generic.rules')}</h2>
          {Object.entries(errors.user_req_rules).map(([reqId, ruleErrors]) => {
            const noErrors = Object.keys(ruleErrors).length === 0;
            const ruleName = rulesNamesAndTypes[Number(reqId)].name;
            const req_type = rulesNamesAndTypes[Number(reqId)].req_type;
            return (
              <AssignmentErrorsTableDisclosure
                ruleErrors={ruleErrors}
                name={ruleName}
                reqType={req_type}
                userFullNameMap={userFullNameMap}
                noErrors={noErrors}
              />
            );
          })}
        </div>
      )}

      {activeTab === 'userAssignments' && (
        <div>
          {Object.entries(userFullNameMap)
            .sort(([userIdA, fullNameA], [userIdB, fullNameB]) => {
              const allErrorsA = getSingleUserErrors(errors, Number(userIdA));
              const allErrorsB = getSingleUserErrors(errors, Number(userIdB));
              const errorStatusA = getSingleUserDisplaySeverity(allErrorsA);
              const errorStatusB = getSingleUserDisplaySeverity(allErrorsB);
              // Order by status: VIOLATION -> DEFICIT -> DEFAULT/null
              if (errorStatusA === AssignmentCheckErrorStatus.VIOLATION) {
                if (errorStatusB !== AssignmentCheckErrorStatus.VIOLATION) {
                  return -1;
                }
              } else if (
                errorStatusB === AssignmentCheckErrorStatus.VIOLATION
              ) {
                return 1;
              }

              if (errorStatusA === AssignmentCheckErrorStatus.DEFICIT) {
                if (errorStatusB !== AssignmentCheckErrorStatus.DEFICIT) {
                  return -1;
                }
              } else if (errorStatusB === AssignmentCheckErrorStatus.DEFICIT) {
                return 1;
              }

              // then alphabetically
              return fullNameA.localeCompare(fullNameB);
            })
            .map(([userId, fullName]) => {
              return (
                <UserErrorsTableDisclosure
                  userReqsForUser={userReqsByUser[Number(userId)]}
                  errors={errors}
                  userId={Number(userId)}
                  name={fullName}
                  sections={sections}
                  sectionSlots={sectionSlots}
                  assignmentsForUser={assignmentsByUser[Number(userId)]}
                  allUserReqs={allUserReqs}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default AssignmentChecksDisplay;
