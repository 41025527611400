import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  useCreateSectionMutation,
  useDeleteSectionMutation,
  useEditNeedsMutation,
} from '@youshift/shared/hooks/mutations';
import { slotLabelsQuery } from '@youshift/shared/hooks/queries';
import { SectionSlot, SlotLabel, VirtualSlot } from '@youshift/shared/types';
import {
  ColorName,
  colorNames,
  getFirstDayOfWeek,
  localeNormalizer,
} from '@youshift/shared/utils';
import { useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunctionArgs,
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';

import { YSButton } from '../../../../components/Buttons';
import { AddShiftTypeProps } from '../../../../components/ItrConfig/CreateSlotType';
import VerticalProgressBar from '../../../../components/VerticalProgressBar';
import { requireApproved, requireManager } from '../../../../utils/checks';
import CreateShiftTemplate from './NewSections/CreateShiftTemplate';
import SectionDetails from './NewSections/SectionDetails';
import {
  SectionSlotsReducerActionType,
  VirtualSlotsReducerActionType,
  sectionSlotsReducer,
  virtualSlotsReducer,
} from './reducers';
import { ArrangedSectionSlot, SectionSlotTemplate } from './types';

function calculateEndTime(startTime: string, durationMinutes: number) {
  // Split the start time into hours and minutes
  const [startHours, startMinutes] = startTime.split(':').map(Number);

  // Convert start time to total minutes from midnight
  const totalMinutes = startHours * 60 + startMinutes + durationMinutes;

  // Calculate the end time in hours and minutes, ensuring it wraps around if over 24 hours
  const endHours = Math.floor(totalMinutes / 60) % 24;
  const endMinutes = totalMinutes % 60;

  // Format the end time back to 'HH:MM'
  const formattedEndHours = endHours.toString().padStart(2, '0');
  const formattedEndMinutes = endMinutes.toString().padStart(2, '0');

  return `${formattedEndHours}:${formattedEndMinutes}`;
}

type SectionNeedsStateContextType = {
  parentVirtualSlotsState?: Record<number, VirtualSlot>;
  parentSectionSlotsState?: Record<number, SectionSlot>;
  onParentSectionSlotsDispatch?: React.Dispatch<SectionSlotsReducerActionType>;
  onParentVirtualSlotsDispatch?: React.Dispatch<VirtualSlotsReducerActionType>;
};

export function CreateNewSection() {
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { idItr, idSection } = useParams();
  const navigate = useNavigate();
  const locale = localeNormalizer(i18n.language);

  const children = [
    { name: t('manager.sectionsConfig.sectionDetails'), key: 'new' },
    { name: t('manager.sectionsConfig.shiftCreation'), key: 'shifts' },
    { name: t('manager.sectionsConfig.needs'), key: 'needs' },
  ];
  const [childIndex, setChildIndex] = useState(0);

  // STEP 0
  const [sectionName, setSectionName] = useState('');
  const [acronym, setAcronym] = useState('');
  const [sectionColor, setSectionColor] = useState<ColorName>(
    colorNames[Math.floor(Math.random() * colorNames.length)],
  );
  const [sectionAssignedBySmartass, setSectionAssignedBySmartass] =
    useState(true);
  const [sectionUserCanSelfassign, setSectionUserCanSelfassign] =
    useState(true);

  // STEP 1
  const [sectionSlotsTemplate, setSectionSlotsTemplate] = useState<
    SectionSlotTemplate[]
  >([]);

  // STEP 2
  const [sectionSlotsState, sectionSlotsDispatch] = useReducer(
    sectionSlotsReducer,
    {},
  );

  const [virtualSlotsState, virtualSlotsDispatch] = useReducer(
    virtualSlotsReducer,
    {},
  );

  const shouldNextButtonBeDisabled = useMemo(
    () =>
      (sectionSlotsTemplate.length === 0 && childIndex === 1) ||
      sectionName === '' ||
      acronym === '',
    [sectionSlotsTemplate.length, childIndex, sectionName, acronym],
  );

  // Function to add a shift label to the template
  const addShiftType = ({
    start_time,
    duration_minutes,
    dayIndexes,
    id_slot_label,
    rest_period,
  }: AddShiftTypeProps) => {
    const newSlots = dayIndexes.map(day_idx => ({
      start_time,
      duration_minutes,
      day_idx,
      id_slot_label,
      rest_period,
    }));

    setSectionSlotsTemplate(prevSlots => [...prevSlots, ...newSlots]);
  };

  const deleteSlot = (slotToDelete: ArrangedSectionSlot) => {
    setSectionSlotsTemplate(prevSlots =>
      prevSlots.filter(
        slot =>
          !(
            slot.start_time === slotToDelete.start_time &&
            slot.day_idx === slotToDelete.day_idx &&
            calculateEndTime(slot.start_time, slot.duration_minutes) ===
              slotToDelete.end_time &&
            slot.id_slot_label === slotToDelete.id_slot_label
          ),
      ),
    );
  };

  const newSectionMutation = useCreateSectionMutation(queryClient, {
    onSuccess: data => {
      setChildIndex(childIndex + 1);
      navigate(`${data.id_section}`);
      queryClient.invalidateQueries({
        queryKey: ['section', String(idSection)],
      });
      queryClient.invalidateQueries({
        queryKey: ['sections', String(idItr)],
      });
    },
  });

  const saveNeedsMutation = useEditNeedsMutation(queryClient, {
    onSuccess: () => {
      navigate(`../../sections`, { relative: 'path' });
    },
  });

  const deleteSectionMutation = useDeleteSectionMutation(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['sections', String(idItr)],
      });
      queryClient.invalidateQueries({
        queryKey: ['section', String(idSection)],
      });
      queryClient.invalidateQueries({
        queryKey: ['extendedUserReqRules', String(idItr)],
      });
      queryClient.invalidateQueries({
        queryKey: ['assignmentTool', String(idItr)],
      });
      queryClient.invalidateQueries({
        queryKey: ['shiftAssignments', String(idItr)],
      });
      setChildIndex(childIndex - 1);
    },
  });

  const createSection = () => {
    if (!idItr) {
      return;
    }
    const firstDayOfWeek = getFirstDayOfWeek(locale);

    newSectionMutation.mutate({
      name: sectionName,
      acronym,
      color: sectionColor,
      assigned_by_smartass: sectionAssignedBySmartass,
      user_can_selfassign: sectionUserCanSelfassign,
      section_slots_template:
        firstDayOfWeek === 1
          ? sectionSlotsTemplate
          : // normalize day idx if sunday is the first day of the week
            sectionSlotsTemplate.map(slot => ({
              ...slot,
              day_idx: (slot.day_idx + 6) % 7,
            })),
      id_itr: Number(idItr),
    });
  };

  // after the section has been created in the database, this function saves the updated needs and rest periods
  const finishCreateSection = () => {
    saveNeedsMutation.mutate({
      id_itr: idItr,
      rest_periods: Object.values(sectionSlotsState).map(
        ({ id_section_slot, rest_period }) => ({
          id_section_slot,
          rest_period,
        }),
      ),
      needs: Object.values(virtualSlotsState).map(
        ({ id_virtual_slot, min_need, max_need }) => ({
          id_virtual_slot,
          min_need,
          max_need,
        }),
      ),
    });
  };

  const returnChildComponent = useMemo(() => {
    switch (childIndex) {
      case 0:
        return (
          <SectionDetails
            name={sectionName}
            setName={setSectionName}
            acronym={acronym}
            setAcronym={setAcronym}
            color={sectionColor}
            setColor={setSectionColor}
            assignedBySmartass={sectionAssignedBySmartass}
            setAssignedBySmartass={setSectionAssignedBySmartass}
            userCanSelfassign={sectionUserCanSelfassign}
            setUserCanSelfassign={setSectionUserCanSelfassign}
          />
        );
      case 1:
        return (
          <CreateShiftTemplate
            sectionSlotsTemplate={sectionSlotsTemplate}
            addShiftType={addShiftType}
            deleteSlot={deleteSlot}
            sectionColor={sectionColor}
            calculateEndTime={calculateEndTime}
          />
        );
      case 2:
        return (
          <div className="ml-4">
            <p>{t('manager.sectionsConfig.needsExplanation')}</p>
            <div className="ml-4 -mt-4">
              <Outlet
                context={
                  {
                    parentVirtualSlotsState: virtualSlotsState,
                    parentSectionSlotsState: sectionSlotsState,
                    onParentSectionSlotsDispatch: sectionSlotsDispatch,
                    onParentVirtualSlotsDispatch: virtualSlotsDispatch,
                  } satisfies SectionNeedsStateContextType
                }
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  }, [
    acronym,
    childIndex,
    sectionColor,
    virtualSlotsState,
    sectionSlotsState,
    sectionName,
    sectionSlotsTemplate,
    sectionAssignedBySmartass,
    sectionUserCanSelfassign,
    t,
  ]);

  const handleReturnButton = () => {
    if (childIndex === 2) {
      deleteSectionMutation.mutate({
        id_itr: idItr,
        id_section: idSection,
      });
    } else {
      setChildIndex(childIndex - 1);
    }
  };

  const handleNextButton = () => {
    if (childIndex === 0) {
      setChildIndex(childIndex + 1);
    } else if (childIndex === 1) {
      createSection();
    }
  };

  const handleCancelButton = () => {
    if (childIndex === 2) {
      deleteSectionMutation.mutate({
        id_itr: idItr,
        id_section: idSection,
      });
    }
    navigate('..', { relative: 'path' });
  };

  return (
    <div className="py-6 h-full">
      <div className="flex flex-row h-full">
        <div className="">
          <VerticalProgressBar steps={children} childIndex={childIndex} />
        </div>
        <div className="w-full">
          <div className="p-4 flex flex-row justify-between items-center">
            <div>
              <h1 className="text-2xl font-semibold mb-2 text-gray-800">
                {children[childIndex].name}
              </h1>
              <p className="text-gray-500">
                {t('generic.stepXofY', {
                  current: childIndex + 1,
                  total: children.length,
                })}
              </p>
            </div>
            <div className="flex-shrink-0 mt-6">
              <div className="flex flex-row gap-2 justify-end">
                <YSButton variant="ghost-primary" onClick={handleCancelButton}>
                  {t('generic.cancel')}
                </YSButton>
                {
                  // if first step, don't show previous button
                  childIndex === 0 ? null : (
                    <YSButton
                      onClick={handleReturnButton}
                      variant="secondary"
                      loading={
                        newSectionMutation.isPending ||
                        deleteSectionMutation.isPending
                      }
                    >
                      {t('generic.back')}
                    </YSButton>
                  )
                }
                {childIndex === children.length - 1 ? (
                  <YSButton
                    onClick={finishCreateSection}
                    variant="primary"
                    loading={deleteSectionMutation.isPending}
                  >
                    {t('generic.finish')}
                  </YSButton>
                ) : (
                  <YSButton
                    onClick={handleNextButton}
                    variant="primary"
                    disabled={shouldNextButtonBeDisabled}
                    loading={newSectionMutation.isPending}
                  >
                    {t('generic.next')}
                  </YSButton>
                )}
              </div>
            </div>
          </div>
          {returnChildComponent}
        </div>
      </div>
    </div>
  );
}

export function useSectionNeedsStateContext() {
  return useOutletContext<SectionNeedsStateContextType>();
}
