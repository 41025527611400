import { Link, useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Rule, UserRequirementInclusionType } from '@youshift/shared/types';
import {
  Bars4Icon,
  PlusCircleIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';

import RuleTypeBadge from '../../../../components/ItrConfig/RuleTypeBadge';
import InclusionTypeBadge from './InclusionTypeBadge';
import { useItrContext } from '../../../../layouts/IterationRootLayout/IterationRootLayout';
import { SingleUserRuleType, MultipleUsersRuleType } from './types';
import UsersFilterDropdown from '../../../../FilterDropdownWithSubgroups';

interface RuleTableRowProps {
  rule: SingleUserRuleType[number][0];
}

function RuleTableRow({ rule }: RuleTableRowProps) {
  const hasMinMax = 'min_slots' in rule || 'min_duration' in rule;

  const navigate = useNavigate();
  const handleRuleClick = (ruleId: number) => {
    // Navigate programmatically and scroll to top
    navigate(`${ruleId}`, { replace: false });
    window.scrollTo(0, 0);
  };

  return (
    <tr key={rule.id_rule}>
      <td className="p-1">
        <button
          onClick={() => handleRuleClick(rule.id_rule)}
          className="border border-gray-200 rounded-md p-1 bg-white flex items-center justify-between gap-1"
        >
          {rule.name}
          <div className="p-1 rounded-full border border-gray-300">
            <Bars4Icon className="w-3 h-3 text-gray-400" />
          </div>
        </button>
      </td>
      {rule.inclusionType === UserRequirementInclusionType.INCLUSION && (
        <>
          <td className="text-right">
            {rule.min_slots || rule.min_duration || 0}
          </td>
          <td className="text-right">
            {rule.max_slots || rule.max_duration || 0}
          </td>
        </>
      )}
    </tr>
  );
}

interface UserRulesTableProps {
  userRules: {
    personal: SingleUserRuleType;
    group: SingleUserRuleType;
  };
}

export default function UserRulesTable({ userRules }: UserRulesTableProps) {
  const { t } = useTranslation();
  const { users, roles } = useItrContext();

  const [selectedUsers, setSelectedUsers] = useState<Set<number>>(
    () => new Set(Object.keys(users).map(Number)),
  );

  return (
    <div className="mt-8">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-semibold mb-4">
          {t('manager.rulesConfig.perUserRules')}
        </h2>
        <UsersFilterDropdown
          users={users}
          roles={roles}
          selectedUsers={selectedUsers}
          onSelectionChange={setSelectedUsers}
        />
      </div>
      <div className="overflow-x-auto">
        <table className="bg-gray-50 ml-6 min-w-[55%]">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-2 py-2 text-left max-w-[200px] w-[200px] whitespace-normal">
                {t('generic.name')}
              </th>
              <th className="px-2 py-2 text-left whitespace-normal">
                {t('manager.rulesConfig.groupRules')}
              </th>
              <th className="px-4 py-2 text-left whitespace-normal">
                {t('manager.rulesConfig.personalRules')}
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(users)
              .filter(([userIdStr]) => selectedUsers.has(Number(userIdStr)))
              .map(([userIdStr, user]) => {
                const userId = Number(userIdStr);
                const groupRules = userRules.group[userId] || [];
                const personalRules = userRules.personal[userId] || [];
                return (
                  <tr key={userId} className="border-b border-gray-200">
                    {/* Column 1: User Name */}
                    <td className="px-2 py-2 border-r max-w-[200px] w-[200px] whitespace-normal">
                      {user?.firstname} {user?.lastname}
                    </td>
                    {/* Column 2: Group Rules as mini tables */}
                    <td className="px-2 py-2 whitespace-normal flex items-start">
                      {groupRules.length > 0 && (
                        <div className="flex flex-col gap-3">
                          {/* Inclusion Rules Section */}
                          {groupRules.some(
                            rule =>
                              rule.inclusionType ===
                              UserRequirementInclusionType.INCLUSION,
                          ) && (
                            <div>
                              <table className="w-full text-sm">
                                <thead>
                                  <tr className="text-xs text-gray-500">
                                    <th className="text-left font-normal">
                                      <InclusionTypeBadge
                                        label={
                                          UserRequirementInclusionType.INCLUSION
                                        }
                                      />
                                    </th>
                                    <th className="text-right w-12 font-normal">
                                      {t('generic.min')}
                                    </th>
                                    <th className="text-right w-12 font-normal">
                                      {t('generic.max')}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {groupRules
                                    .filter(
                                      rule =>
                                        rule.inclusionType ===
                                        UserRequirementInclusionType.INCLUSION,
                                    )
                                    .sort((a, b) =>
                                      a.name.localeCompare(b.name),
                                    )
                                    .map(rule => (
                                      <RuleTableRow
                                        key={rule.id_rule}
                                        rule={rule}
                                      />
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          )}

                          {/* Exclusion Rules Section */}
                          {groupRules.some(
                            rule =>
                              rule.inclusionType ===
                              UserRequirementInclusionType.EXCLUSION,
                          ) && (
                            <div>
                              <table className="w-full text-sm">
                                <thead>
                                  <tr className="text-xs text-gray-500">
                                    <th className="text-left font-normal">
                                      <InclusionTypeBadge
                                        label={
                                          UserRequirementInclusionType.EXCLUSION
                                        }
                                      />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {groupRules
                                    .filter(
                                      rule =>
                                        rule.inclusionType ===
                                        UserRequirementInclusionType.EXCLUSION,
                                    )
                                    .sort((a, b) =>
                                      a.name.localeCompare(b.name),
                                    )
                                    .map(rule => (
                                      <RuleTableRow
                                        key={rule.id_rule}
                                        rule={rule}
                                      />
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      )}
                    </td>
                    {/* Column 3: Personal Rules as table format */}
                    <td className="px-2 py-2 whitespace-normal border-l border-r border-gray-200">
                      {personalRules.length > 0 && (
                        <div className="flex flex-col gap-3">
                          {/* Inclusion Rules Section */}
                          {personalRules.some(
                            rule =>
                              rule.inclusionType ===
                              UserRequirementInclusionType.INCLUSION,
                          ) && (
                            <div>
                              <table className="w-full text-sm">
                                <thead>
                                  <tr className="text-xs text-gray-500">
                                    <th className="text-left font-normal">
                                      <InclusionTypeBadge
                                        label={
                                          UserRequirementInclusionType.INCLUSION
                                        }
                                      />
                                    </th>
                                    <th className="text-right w-12 font-normal">
                                      {t('generic.min')}
                                    </th>
                                    <th className="text-right w-12 font-normal">
                                      {t('generic.max')}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {personalRules
                                    .filter(
                                      rule =>
                                        rule.inclusionType ===
                                        UserRequirementInclusionType.INCLUSION,
                                    )
                                    .sort((a, b) =>
                                      a.name.localeCompare(b.name),
                                    )
                                    .map(rule => (
                                      <RuleTableRow
                                        key={rule.id_rule}
                                        rule={rule}
                                      />
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          )}

                          {/* Exclusion Rules Section */}
                          {personalRules.some(
                            rule =>
                              rule.inclusionType ===
                              UserRequirementInclusionType.EXCLUSION,
                          ) && (
                            <div>
                              <table className="w-full text-sm">
                                <thead>
                                  <tr className="text-xs text-gray-500">
                                    <th className="text-left font-normal">
                                      <InclusionTypeBadge
                                        label={
                                          UserRequirementInclusionType.EXCLUSION
                                        }
                                      />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {personalRules
                                    .filter(
                                      rule =>
                                        rule.inclusionType ===
                                        UserRequirementInclusionType.EXCLUSION,
                                    )
                                    .sort((a, b) =>
                                      a.name.localeCompare(b.name),
                                    )
                                    .map(rule => (
                                      <RuleTableRow
                                        key={rule.id_rule}
                                        rule={rule}
                                      />
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      )}
                      <Link to={`./new?user=${userId}`}>
                        <div
                          className="p-1 rounded-full border border-gray-300 text-gray-400 inline-block mt-2"
                          aria-label={t('manager.rulesConfig.createRule')}
                        >
                          <PlusIcon className="w-5 h-5" />
                        </div>
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
