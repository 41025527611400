import { Disclosure } from '@headlessui/react';
import {
  ChevronUpIcon,
  ExclamationCircleIcon,
  UserMinusIcon,
} from '@heroicons/react/24/outline';
import { UserReqRule } from '@youshift/shared/types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ConfigurationCheckErrorType,
  UserErrors,
} from '../../../utils/iteration_checks/configuration_checks/types';
import Table from '../../Table';

type Props = {
  name?: string;
  userErrors: UserErrors;
  allUserReqs: Record<number, UserReqRule>;
};

function UserConfigErrorsTableDisclosure({
  name,
  userErrors,
  allUserReqs,
}: Props) {
  const { user_supply_demand: userSupplyDemand, user_req_rules: userReqRules } =
    userErrors;

  const { t } = useTranslation();

  const rulesNamesAndTypes = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(allUserReqs).map(([id, userReqRule]) => [
          userReqRule.rule.id_rule,
          {
            name: userReqRule.rule.name,
            req_type: Object.values(userReqRule.user_reqs)[0]?.req_type,
          },
        ]),
      ),
    [allUserReqs],
  );

  return (
    <Disclosure>
      {({ open }) => (
        <>
          {/* Title Bar */}
          <Disclosure.Button className="flex justify-start gap-1 mb-2 items-center w-full px-4 py-2 text-lg font-medium text-left bg-gray-100 hover:bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
            <ChevronUpIcon
              className={`${
                open ? 'transform rotate-180' : ''
              } w-5 h-5 text-gray-500`}
            />
            <ExclamationCircleIcon className="w-6 h-6 text-red-600 mr-3" />
            <span>{name}</span>
          </Disclosure.Button>

          {/* Collapsible Content */}
          <Disclosure.Panel className="text-sm text-gray-700 mb-2">
            {userSupplyDemand ? (
              <div className="bg-white shadow-lg rounded-lg mb-2">
                <div className="flex flex-row items-center pl-2">
                  <UserMinusIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-rose-500" />
                  <h3 className="text-base font-semibold p-2">
                    {t('manager.configErrors.userSupplyDemand')}
                  </h3>
                </div>
                {/* TOOD: In the future, we can tell which slots are assignable */}
                <Table
                  headers={[
                    t('manager.configErrors.minConfiguredSupply'),
                    t('manager.configErrors.maxAssignableSlots'),
                  ]}
                  rows={[
                    [
                      userSupplyDemand.min_configured_supply,
                      userSupplyDemand.max_assignable_slots,
                    ],
                  ]}
                />
              </div>
            ) : null}

            {userReqRules ? (
              <div className="bg-white shadow-lg rounded-lg">
                <div className="flex flex-row items-center pl-2">
                  <UserMinusIcon className="text-white font-bold w-5 h-5 rounded-full p-0.5 bg-rose-500" />
                  <h3 className="text-base font-semibold p-2">
                    {t('manager.configErrors.userReqRulesDeficits')}
                  </h3>
                </div>
                <Table
                  headers={[
                    t('generic.rule'),
                    t('manager.configErrors.min'),
                    t('manager.configErrors.available'),
                    t('manager.configErrors.alreadyAssigned'),
                  ]}
                  rows={Object.entries(userReqRules).map(
                    ([idReqRule, errors]) => {
                      const isSlotDeficit =
                        ConfigurationCheckErrorType.SINGLE_USER_REQ_RULE_SLOTS_DEFICIT in
                        errors;
                      if (isSlotDeficit) {
                        return [
                          rulesNamesAndTypes[idReqRule].name,
                          errors[
                            ConfigurationCheckErrorType
                              .SINGLE_USER_REQ_RULE_SLOTS_DEFICIT
                          ]?.min_slots ?? '-',
                          errors[
                            ConfigurationCheckErrorType
                              .SINGLE_USER_REQ_RULE_SLOTS_DEFICIT
                          ]?.available_slots.length ?? '-',
                          errors[
                            ConfigurationCheckErrorType
                              .SINGLE_USER_REQ_RULE_SLOTS_DEFICIT
                          ]?.already_assigned_slots.length ?? '-',
                        ];
                      }
                      return [
                        rulesNamesAndTypes[idReqRule].name,
                        `${
                          errors[
                            ConfigurationCheckErrorType
                              .SINGLE_USER_REQ_RULE_DURATION_DEFICIT
                          ]?.min_duration
                        } h` ?? '-',
                        `${
                          errors[
                            ConfigurationCheckErrorType
                              .SINGLE_USER_REQ_RULE_DURATION_DEFICIT
                          ]?.available_duration
                        } h` ?? '-',
                        `${
                          errors[
                            ConfigurationCheckErrorType
                              .SINGLE_USER_REQ_RULE_DURATION_DEFICIT
                          ]?.already_assigned_duration
                        } h` ?? '-',
                      ];
                    },
                  )}
                />
              </div>
            ) : null}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default UserConfigErrorsTableDisclosure;
