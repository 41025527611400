import {
  GroupStatsReturn,
  UserStatsReturn,
} from '@youshift/shared/hooks/queries';
import {
  Rule,
  Section,
  SectionSlot,
  ShiftAssignment,
  SlotLabel,
  User,
  UserReqRule,
  UserRole,
} from '@youshift/shared/types';
import { getDifferenceInHours } from '@youshift/shared/utils';

import {
  BaseIdObject,
  IdKeys,
  IdKeyMap,
  MergedGroupIterationData,
  MergedUserIterationData,
} from './types';

// Adds or removes an iteration ID from the selection
export const toggleItrSelection = (
  itrId: number,
  selectedItrs: Set<number>,
  setSelectedItrs: (selectedItrs: Set<number>) => void,
) => {
  const updatedSelectedItrs = new Set(selectedItrs);
  if (updatedSelectedItrs.has(itrId)) {
    updatedSelectedItrs.delete(itrId);
  } else {
    updatedSelectedItrs.add(itrId);
  }
  setSelectedItrs(updatedSelectedItrs);
};

// Adds or removes a section ID from the selection
export const toggleSectionSelection = (
  sectionId: number,
  selectedSections: Set<number>,
  setSelectedSections: (selectedSections: Set<number>) => void,
) => {
  const updatedSelectedSections = new Set(selectedSections);
  if (updatedSelectedSections.has(sectionId)) {
    updatedSelectedSections.delete(sectionId);
  } else {
    updatedSelectedSections.add(sectionId);
  }
  setSelectedSections(updatedSelectedSections);
};

// Merges data from multiple iterations into a single flat structure
export const mergeIterationsData = (
  itrs: GroupStatsReturn[keyof GroupStatsReturn]['itrs'],
  keys: (keyof MergedGroupIterationData)[],
  selectedItrs: Set<number>,
): MergedGroupIterationData => {
  const result: MergedGroupIterationData = {
    section_slots: {},
    user_req_rules: {},
    sections: {},
    slot_labels: {},
  };

  // Merge data from each iteration
  Object.entries(itrs)
    .filter(([itrId]) => selectedItrs.has(Number(itrId)))
    .forEach(([_, itrData]) => {
      keys.forEach(key => {
        Object.assign(result[key] as object, itrData[key]);
      });
    });

  return result satisfies MergedGroupIterationData;
};

// SingleUserVersion. Different types; same logic.
export const mergeUserIterationsData = (
  itrs: UserStatsReturn['chains'][0]['itrs'],
  keys: (keyof MergedUserIterationData)[],
  selectedItrs: Set<number>,
): MergedUserIterationData => {
  const result: MergedUserIterationData = {
    section_slots: {},
    user_req_rules: {},
    sections: {},
    slot_labels: {},
    shift_assignments: {},
  };

  // Merge data from each iteration
  Object.entries(itrs)
    .filter(([itrId]) => selectedItrs.has(Number(itrId)))
    .forEach(([_, itrData]) => {
      keys.forEach(key => {
        Object.assign(result[key] as object, itrData[key]);
      });
    });

  return result satisfies MergedUserIterationData;
};

export type AllShiftAssignmentsGroupMap = {
  [id_user: number]: { [id_shift_assignment: number]: ShiftAssignment };
};
export const createShiftAssignmentsMapping = (
  itrs: GroupStatsReturn[keyof GroupStatsReturn]['itrs'],
  selectedItrs: Set<number>,
): AllShiftAssignmentsGroupMap => {
  const assignments: AllShiftAssignmentsGroupMap = {};

  // Only process selected iterations
  Object.entries(itrs)
    .filter(([itrId]) => selectedItrs.has(Number(itrId)))
    .forEach(([_, itrData]) => {
      Object.entries(itrData.user_stats).forEach(([userId, userStats]) => {
        const numUserId = Number(userId);
        assignments[numUserId] = {
          ...(assignments[numUserId] || {}),
          ...userStats.shift_assignments,
        };
      });
    });

  return assignments;
};

type AllShiftAssignmentsUserMap = {
  [id_shift_assignment: number]: ShiftAssignment;
};
export const createUserShiftAssignmentsMapping = (
  itrs: UserStatsReturn['chains'][0]['itrs'],
  selectedItrs: Set<number>,
): AllShiftAssignmentsUserMap => {
  const assignments: AllShiftAssignmentsUserMap = {};

  Object.entries(itrs)
    .filter(([itrId]) => selectedItrs.has(Number(itrId)))
    .forEach(([_, itrData]) => {
      Object.entries(itrData.shift_assignments).forEach(
        ([assignmentId, assignment]) => {
          assignments[Number(assignmentId)] = assignment;
        },
      );
    });
  return assignments;
};

export const createBaseIdMapping = <
  K extends IdKeys,
  T extends BaseIdObject &
    Record<K, number> &
    Record<IdKeyMap[K], number | null>,
>(
  objects: Record<number, T>,
  idKey: K,
  baseIdKey: IdKeyMap[K],
): Record<number, number> => {
  const mapping: Record<number, number> = {};

  Object.values(objects).forEach(obj => {
    const id = obj[idKey];
    const baseId = obj[baseIdKey];
    mapping[id] = baseId ?? id;
  });

  return mapping;
};

export const createLatestBaseObjectMapping = <
  K extends IdKeys,
  T extends BaseIdObject &
    Record<K, number> &
    Record<IdKeyMap[K], number | null>,
>(
  objects: T[] | Record<number, T>,
  idKey: K,
  baseIdKey: IdKeyMap[K],
): Record<number, T> => {
  const mapping: Record<number, T> = {};

  Object.values(objects).forEach(obj => {
    const id = obj[idKey];
    const baseId = obj[baseIdKey] ?? id;

    if (!mapping[baseId] || obj[idKey] > mapping[baseId][idKey]) {
      mapping[baseId] = obj;
    }
  });

  return mapping;
};

export const createSectionSlotToRulesMapping = (
  userReqRules: Record<
    number,
    { rule: { id_rule: number }; section_slots: number[] }
  >,
): Record<number, number[]> => {
  const mapping: Record<number, number[]> = {};

  Object.values(userReqRules).forEach(({ rule, section_slots }) => {
    section_slots.forEach(slotId => {
      if (!mapping[slotId]) {
        mapping[slotId] = [];
      }
      mapping[slotId].push(rule.id_rule);
    });
  });

  return mapping;
};

export function participatesInWhichBaseRules(
  rulesBaseIds: Record<number, number>,
  rulesWithSameBase: Record<number, number[]>,
  allUserRequirements: Record<number, UserReqRule>,
  userId: number,
): Record<number, boolean> {
  // Get all unique base rules
  const allBaseRules = new Set(Object.values(rulesBaseIds));
  const result: Record<number, boolean> = {};

  // Iterate through each base rule
  for (const baseRule of allBaseRules) {
    // Get all rule IDs in the family for the current base rule
    const ruleIdsInFamily = rulesWithSameBase[baseRule] || [];

    // Check if any rule in the family has requirements for this user
    result[baseRule] = ruleIdsInFamily.some(ruleId => {
      const userRequirements = allUserRequirements[ruleId]?.user_reqs || {};
      return !!userRequirements[userId];
    });
  }

  return result;
}

// Define the structure for user shift statistics
export interface UserGroupStats {
  userId: number;
  userName: string;
  roleName: string;
  shiftsPerSection: Record<number, { count: number; hours: number }>;
  shiftsPerRule: Record<number, { count: number; hours: number }>;
  shiftsPerLabel: Record<number, { count: number; hours: number }>;
  shiftsPerDayOfWeek: Record<number, { count: number; hours: number }>;
  customCounters: Record<number, number>; // id_custom_counter -> total value
}

interface BuildGroupStatsParams {
  allSectionSlots: Record<number, SectionSlot>;
  allShiftAssignments: AllShiftAssignmentsGroupMap;
  userBaseRuleParticipation: Record<number, Record<number, boolean>>;
  labelsBaseIds?: Record<number, number>;
  labels: Record<number, SlotLabel>;
  rules: Record<number, Rule>;
  sections: Record<number, Section>;
  roles: Record<number, UserRole>;
  rulesBaseIds?: Record<number, number>;
  sectionsBaseIds?: Record<number, number>;
  sectionSlotToRules: Record<number, number[]>;
  users: Record<number, User>;
}

export const buildGroupStats = ({
  allSectionSlots,
  allShiftAssignments,
  userBaseRuleParticipation,
  labelsBaseIds = {},
  labels,
  rules,
  sections,
  roles,
  rulesBaseIds = {},
  sectionsBaseIds = {},
  sectionSlotToRules,
  users,
}: BuildGroupStatsParams): UserGroupStats[] => {
  const groupStatsMap: Record<number, UserGroupStats> = {};

  Object.entries(allShiftAssignments).forEach(([userIdStr, assignments]) => {
    const userId = Number(userIdStr);
    const user = users[userId];
    const userRole = user.id_user_role;
    const role = userRole ? roles[userRole] : null;

    if (!user) return;

    if (!groupStatsMap[userId]) {
      groupStatsMap[userId] = {
        userId,
        userName: `${user.firstname} ${user.lastname}`,
        roleName: role ? role.name : '-',
        shiftsPerSection: {},
        shiftsPerRule: {},
        shiftsPerLabel: {},
        shiftsPerDayOfWeek: {
          0: { count: 0, hours: 0 }, // Sunday
          1: { count: 0, hours: 0 }, // Monday
          2: { count: 0, hours: 0 }, // Tuesday
          3: { count: 0, hours: 0 }, // Wednesday
          4: { count: 0, hours: 0 }, // Thursday
          5: { count: 0, hours: 0 }, // Friday
          6: { count: 0, hours: 0 }, // Saturday
        },
        customCounters: {},
      };
    }

    Object.values(assignments).forEach(assignment => {
      const { id_section, id_section_slot } = assignment;
      const sectionSlot = allSectionSlots[id_section_slot];

      if (!sectionSlot) return;

      const hours = getDifferenceInHours(sectionSlot.start, sectionSlot.end);
      const baseIdSection = sectionsBaseIds[id_section] ?? id_section;

      // Increment Shifts Per Section
      const section = sections[baseIdSection];
      if (section) {
        const stats = groupStatsMap[userId].shiftsPerSection[
          section.id_section
        ] || { count: 0, hours: 0 };
        stats.count += 1;
        stats.hours += hours;
        groupStatsMap[userId].shiftsPerSection[section.id_section] = stats;
      }

      // Increment Shifts Per Label
      const baseIdLabel =
        labelsBaseIds[sectionSlot.id_slot_label] ?? sectionSlot.id_slot_label;
      const label = Object.values(labels).find(
        label => label.id_slot_label === baseIdLabel,
      );
      if (label) {
        const stats = groupStatsMap[userId].shiftsPerLabel[
          label.id_slot_label
        ] || { count: 0, hours: 0 };
        stats.count += 1;
        stats.hours += hours;
        groupStatsMap[userId].shiftsPerLabel[label.id_slot_label] = stats;
      }

      // Increment Shifts Per Rule
      const ruleIds = sectionSlotToRules[id_section_slot];
      if (ruleIds) {
        ruleIds.forEach(ruleId => {
          const baseIdRule = rulesBaseIds[ruleId] ?? ruleId;
          const userParticipatesInRule =
            userBaseRuleParticipation[userId][baseIdRule];
          const rule = rules[baseIdRule];
          if (rule && userParticipatesInRule) {
            const stats = groupStatsMap[userId].shiftsPerRule[rule.id_rule] || {
              count: 0,
              hours: 0,
            };
            stats.count += 1;
            stats.hours += hours;
            groupStatsMap[userId].shiftsPerRule[rule.id_rule] = stats;
          }
        });
      }

      // Increment Shifts Per Day of Week
      const startDate = new Date(sectionSlot.start);
      const dayOfWeek = startDate.getDay(); // 0 = Sunday, 1 = Monday, etc.
      groupStatsMap[userId].shiftsPerDayOfWeek[dayOfWeek].count += 1;
      groupStatsMap[userId].shiftsPerDayOfWeek[dayOfWeek].hours += hours;

      // Track custom counter increments
      if (sectionSlot.custom_counter_slot_increments) {
        Object.entries(sectionSlot.custom_counter_slot_increments).forEach(
          ([counterIdStr, increment]) => {
            const counterId = Number(counterIdStr);
            groupStatsMap[userId].customCounters[counterId] =
              (groupStatsMap[userId].customCounters[counterId] || 0) +
              increment;
          },
        );
      }
    });
  });

  return Object.values(groupStatsMap);
};

export const calculatePositivePercentage = (
  positiveRespected: number,
  totalPositive: number,
) =>
  totalPositive > 0
    ? Math.round((positiveRespected / totalPositive) * 100)
    : null;

export const calculateNegativePercentage = (
  negativeNotRespected: number,
  totalNegative: number,
) =>
  totalNegative > 0
    ? Math.round(((totalNegative - negativeNotRespected) / totalNegative) * 100)
    : null;

type StatsPerObject = {
  count: number;
  hours: number;
  perItr: Record<number, { count: number; hours: number }>;
};

export interface UserStats {
  shiftsPerSection: Record<number, StatsPerObject>;
  shiftsPerRule: Record<number, StatsPerObject>;
  shiftsPerLabel: Record<number, StatsPerObject>;
  customCounters: Record<number, number>;
  shiftsPerDay: Record<string, { count: number; hours: number }>;
}

export const buildUserStats = (
  allSectionSlots: Record<number, SectionSlot>,
  allShiftAssignments: AllShiftAssignmentsUserMap,
  labelBaseIds: Record<number, number>,
  labels: Record<number, SlotLabel>,
  rules: Record<number, Rule>,
  sections: Record<number, Section>,
  ruleBaseIds: Record<number, number>,
  sectionBaseIds: Record<number, number>,
  sectionSlotToRules: Record<number, number[]>,
): UserStats => {
  const userStats: UserStats = {
    shiftsPerSection: {},
    shiftsPerRule: {},
    shiftsPerLabel: {},
    customCounters: {},
    shiftsPerDay: {},
  };

  // Process each assignment
  Object.values(allShiftAssignments).forEach(assignment => {
    const { id_section, id_section_slot } = assignment;
    const sectionSlot = allSectionSlots[id_section_slot];

    if (!sectionSlot) return;

    const hours = getDifferenceInHours(sectionSlot.start, sectionSlot.end);
    const baseIdSection = sectionBaseIds[id_section];

    // Increment Shifts Per Section
    const section = sections[baseIdSection];
    if (section) {
      const stats = userStats.shiftsPerSection[baseIdSection] || {
        count: 0,
        hours: 0,
        perItr: {},
      };
      stats.count += 1;
      stats.hours += hours;
      stats.perItr[assignment.id_itr] = {
        count: (stats.perItr[assignment.id_itr]?.count || 0) + 1,
        hours: (stats.perItr[assignment.id_itr]?.hours || 0) + hours,
      };
      userStats.shiftsPerSection[baseIdSection] = stats;
    }

    // Increment Shifts Per Label
    const baseIdLabel = labelBaseIds[sectionSlot.id_slot_label];
    const label = labels[baseIdLabel];
    if (label) {
      const stats = userStats.shiftsPerLabel[baseIdLabel] || {
        count: 0,
        hours: 0,
        perItr: {},
      };
      stats.count += 1;
      stats.hours += hours;
      stats.perItr[assignment.id_itr] = {
        count: (stats.perItr[assignment.id_itr]?.count || 0) + 1,
        hours: (stats.perItr[assignment.id_itr]?.hours || 0) + hours,
      };
      userStats.shiftsPerLabel[baseIdLabel] = stats;
    }

    // Increment Shifts per Day of Week
    const dayOfWeek = new Date(sectionSlot.start).getUTCDay();
    const dayStats = userStats.shiftsPerDay[dayOfWeek] || {
      count: 0,
      hours: 0,
    };
    dayStats.count += 1;
    dayStats.hours += hours;
    userStats.shiftsPerDay[dayOfWeek] = dayStats;

    // Increment Shifts Per Rule
    const ruleIds = sectionSlotToRules[id_section_slot];
    if (ruleIds) {
      ruleIds.forEach(ruleId => {
        const baseIdRule = ruleBaseIds[ruleId];
        const rule = rules[baseIdRule];
        if (rule) {
          const stats = userStats.shiftsPerRule[baseIdRule] || {
            count: 0,
            hours: 0,
            perItr: {},
          };
          stats.count += 1;
          stats.hours += hours;
          stats.perItr[assignment.id_itr] = {
            count: (stats.perItr[assignment.id_itr]?.count || 0) + 1,
            hours: (stats.perItr[assignment.id_itr]?.hours || 0) + hours,
          };
          userStats.shiftsPerRule[baseIdRule] = stats;
        }
      });
    }

    // Track custom counter increments
    if (sectionSlot.custom_counter_slot_increments) {
      Object.entries(sectionSlot.custom_counter_slot_increments).forEach(
        ([counterIdStr, increment]) => {
          const counterId = Number(counterIdStr);
          userStats.customCounters[counterId] =
            (userStats.customCounters[counterId] || 0) + increment;
        },
      );
    }
  });

  return userStats;
};

// Add these utility functions at the top of the file
export const loadFromStorage = (
  key: string,
  defaultValue: Set<number>,
): Set<number> => {
  const stored = localStorage.getItem(key);
  if (!stored) return defaultValue;
  try {
    return new Set(JSON.parse(stored));
  } catch {
    return defaultValue;
  }
};

export const saveToStorage = (key: string, value: Set<number>) => {
  localStorage.setItem(key, JSON.stringify(Array.from(value)));
};

export const calculateGroupPercentages = (
  selectedItrs: Set<number>,
  itrs: GroupStatsReturn[keyof GroupStatsReturn]['itrs'],
) => {
  // Track totals per user
  const userTotals: Record<
    number,
    {
      totalPositive: number;
      totalNegative: number;
      positiveRespected: number;
      negativeNotRespected: number;
    }
  > = {};

  // Only process selected iterations
  Object.entries(itrs)
    .filter(([idItr]) => selectedItrs.has(Number(idItr)))
    .forEach(([_, itrData]) => {
      Object.entries(itrData.users_points_breakdown).forEach(
        ([userIdStr, pointsBreakdown]) => {
          if (!pointsBreakdown) return;
          const userId = Number(userIdStr);

          if (!userTotals[userId]) {
            userTotals[userId] = {
              totalPositive: 0,
              totalNegative: 0,
              positiveRespected: 0,
              negativeNotRespected: 0,
            };
          }

          userTotals[userId].totalPositive += pointsBreakdown.total_pos || 0;
          userTotals[userId].totalNegative += pointsBreakdown.total_neg || 0;
          userTotals[userId].positiveRespected +=
            pointsBreakdown.pos_respected || 0;
          userTotals[userId].negativeNotRespected +=
            pointsBreakdown.neg_not_respected || 0;
        },
      );
    });

  return userTotals;
};

export const calculateUserPercentages = (
  selectedItrs: Set<number>,
  itrs: UserStatsReturn['chains'][0]['itrs'],
) => {
  let total_pos = 0;
  let total_neg = 0;
  let total_pos_respected = 0;
  let total_neg_not_respected = 0;

  // Only process selected iterations
  Object.entries(itrs)
    .filter(([idItr]) => selectedItrs.has(Number(idItr)))
    .forEach(([_, itrData]) => {
      const pointsBreakdown = itrData.points_breakdown;
      if (pointsBreakdown) {
        total_pos += pointsBreakdown.total_pos || 0;
        total_neg += pointsBreakdown.total_neg || 0;
        total_pos_respected += pointsBreakdown.pos_respected || 0;
        total_neg_not_respected += pointsBreakdown.neg_not_respected || 0;
      }
    });

  return {
    positivePercentage: calculatePositivePercentage(
      total_pos_respected,
      total_pos,
    ),
    negativePercentage: calculateNegativePercentage(
      total_neg_not_respected,
      total_neg,
    ),
  };
};
