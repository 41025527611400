import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import {
  returnColor,
  colorNames,
  ColorName,
  dateToString,
} from '@youshift/shared/utils';
import {
  useCreateEventType,
  useEditEventType,
  useDeleteEventType,
  useUpdateUserEventTypeLimits,
} from '@youshift/shared/hooks/mutations';
import { SpecialEventType, User } from '@youshift/shared/types';
import { useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import Modal from '../../../components/Modal';
import Wrapper from '../../../components/Wrapper';
import { YSButton } from '../../../components/Buttons';
import { useManagerContext } from '../../../layouts/ManagerLayout';
import ColorPicker from '../../../components/ColorPicker';

interface UserSpecialEventPeriod {
  id_user_special_event_period: number;
  start: Date;
  end: Date;
  initial_allocated_limit: number;
}

export function EventTypes() {
  const { eventTypes, users, events } = useManagerContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Track "new" vs "edit" states
  const [isNewEventTypeModalOpen, setIsNewEventTypeModalOpen] = useState(false);
  const [isEditEventTypeModalOpen, setIsEditEventTypeModalOpen] =
    useState(false);

  // Which event is selected for editing/deleting
  const [selectedEventType, setSelectedEventType] =
    useState<SpecialEventType | null>(null);

  // Our form data
  const [eventTypeForm, setEventTypeForm] = useState({
    name: '',
    description: '',
    acronym: '',
    color: colorNames[0] as ColorName,
  });

  // Add new state for managing limits (keys: id_user -> id_special_event_type)
  const [userLimits, setUserLimits] = useState<
    Record<number, Record<number, UserSpecialEventPeriod>>
  >({});
  const [hasChanges, setHasChanges] = useState(false);

  // --- Create Mutation ---
  const createEventType = useCreateEventType(queryClient, {
    onSuccess: () => {
      toast.success(t('eventCenter.createEventTypeSuccess'));
      queryClient.invalidateQueries({ queryKey: ['eventTypes'] });
      queryClient.invalidateQueries({ queryKey: ['groupEvents'] });
      setIsNewEventTypeModalOpen(false);
      setSelectedEventType(null);
      setEventTypeForm({
        name: '',
        description: '',
        acronym: '',
        color: colorNames[0] as ColorName,
      });
    },
  });

  // --- Edit Mutation ---
  const editEventType = useEditEventType(queryClient, {
    onSuccess: () => {
      toast.success(t('eventCenter.editEventTypeSuccess'));
      queryClient.invalidateQueries({ queryKey: ['eventTypes'] });
      queryClient.invalidateQueries({ queryKey: ['groupEvents'] });
      setIsEditEventTypeModalOpen(false);
      setSelectedEventType(null);
      setEventTypeForm({
        name: '',
        description: '',
        acronym: '',
        color: colorNames[0] as ColorName,
      });
    },
  });

  // --- Delete Mutation ---
  const deleteEventType = useDeleteEventType(queryClient, {
    onSuccess: () => {
      toast.success(t('eventCenter.deleteEventTypeSuccess'));
      queryClient.invalidateQueries({ queryKey: ['eventTypes'] });
      queryClient.invalidateQueries({ queryKey: ['groupEvents'] });
      setUserLimits({});
      setIsEditEventTypeModalOpen(false);
      setSelectedEventType(null);
    },
  });

  // New mutation for updating limits
  const updateLimits = useUpdateUserEventTypeLimits(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['eventTypes'] });
      queryClient.invalidateQueries({ queryKey: ['groupEvents'] });
      toast.success(t('eventCenter.updateLimitsSuccess'));
      setHasChanges(false);
    },
  });

  // Handlers
  const handleCreateEventType = () => {
    createEventType.mutate({
      name: eventTypeForm.name,
      description: eventTypeForm.description,
      acronym: eventTypeForm.acronym,
      color: eventTypeForm.color,
    });
  };

  const handleEditEventType = () => {
    if (!selectedEventType) return;
    editEventType.mutate({
      id_special_event_type: selectedEventType.id_special_event_type,
      name: eventTypeForm.name,
      description: eventTypeForm.description,
      acronym: eventTypeForm.acronym,
      color: eventTypeForm.color,
    });
  };

  const handleDeleteEventType = () => {
    if (!selectedEventType) return;
    deleteEventType.mutate({
      id_special_event_type: selectedEventType.id_special_event_type,
    });
  };

  // Handler for saving changes
  const handleSaveLimits = () => {
    updateLimits.mutate({
      user_event_periods: Object.values(userLimits).flatMap(
        userSpecialEventPeriod => Object.values(userSpecialEventPeriod),
      ),
    });
  };

  const allUserSpecialEventPeriods = Object.fromEntries(
    Object.entries(events).map(([id_user, userEvents]) => [
      id_user,
      Object.fromEntries(Object.entries(userEvents.user_special_event_periods)),
    ]),
  );

  // Handler for limit changes
  const handleLimitChange = (
    userId: number,
    eventTypeId: number,
    value: string,
  ) => {
    const numValue = parseInt(value, 10) || 0;
    setUserLimits(prev => ({
      ...prev,
      [userId]: {
        ...(prev[userId] || {}),
        [eventTypeId]: {
          initial_allocated_limit: numValue,
          start: new Date(
            allUserSpecialEventPeriods[userId]?.[eventTypeId]?.start,
          ),
          end: new Date(allUserSpecialEventPeriods[userId]?.[eventTypeId]?.end),
          id_user_special_event_period:
            allUserSpecialEventPeriods[userId]?.[eventTypeId]
              ?.id_user_special_event_period,
        },
      },
    }));
    setHasChanges(true);
  };

  // Add these new handlers
  const handleBulkEditing = (eventTypeId: number, increase: boolean) => {
    setUserLimits(prev => {
      const newLimits = { ...prev };
      Object.values(users).forEach(user => {
        const initialEventPeriod =
          allUserSpecialEventPeriods[user.id]?.[eventTypeId];
        const currentLimit =
          prev[user.id]?.[eventTypeId]?.initial_allocated_limit ??
          initialEventPeriod?.initial_allocated_limit ??
          0;
        newLimits[user.id] = {
          ...(newLimits[user.id] || {}),
          [eventTypeId]: {
            start: new Date(initialEventPeriod?.start),
            end: new Date(initialEventPeriod?.end),
            id_user_special_event_period:
              initialEventPeriod?.id_user_special_event_period,
            initial_allocated_limit: increase
              ? currentLimit + 1
              : Math.max(0, currentLimit - 1),
          },
        };
      });
      return newLimits;
    });
    setHasChanges(true);
  };

  return (
    <Wrapper mt="mt-8">
      <div className="flex flex-row items-center justify-between">
        <button
          className="mb-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          onClick={() => window.history.back()}
        >
          ← {t('generic.back')}
        </button>
        {hasChanges && (
          <YSButton onClick={handleSaveLimits} loading={updateLimits.isLoading}>
            {t('generic.saveChanges')}
          </YSButton>
        )}
      </div>
      {/* Add this new section before the Modal */}
      <div className="mt-8">
        <div className="flex flex-row items-center justify-between my-2">
          <div>
            <h3 className="text-xl font-semibold leading-6 text-gray-900">
              {t('eventCenter.manageEventTypes')}
            </h3>
            <div className="mt-2">
              <p className="text-gray-500">
                {t('eventCenter.period', {
                  start: dateToString(
                    Object.values(
                      Object.values(allUserSpecialEventPeriods)[0],
                    )[0].start,
                    'longWithYear',
                  ),
                  end: dateToString(
                    Object.values(
                      Object.values(allUserSpecialEventPeriods)[0],
                    )[0].end,
                    'longWithYear',
                  ),
                })}
              </p>
            </div>
          </div>
          <YSButton
            onClick={() => setIsNewEventTypeModalOpen(true)}
            type="button"
            classNames="flex flex-row items-center gap-2"
          >
            <PlusIcon className="h-4 w-4 text-white" />
            {t('eventCenter.createEventType')}
          </YSButton>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r border-r-gray-300">
                  {t('generic.user')}
                </th>
                {Object.values(eventTypes).map(eventType => (
                  <th
                    key={eventType.id_special_event_type}
                    className="px-3 py-1.5 bg-gray-50 text-left text-xs font-medium border-r border-r-gray-300 text-gray-500 tracking-wider my-auto"
                  >
                    <div className="flex flex-row w-full items-center gap-2 uppercase">
                      <div className="flex items-center gap-2">
                        {/* Display a color swatch */}
                        <span
                          className="w-5 h-5 rounded-full"
                          style={{
                            backgroundColor: returnColor(eventType.color),
                          }}
                        />
                        <div className="text-md text-gray-500">
                          {eventType.acronym}
                        </div>
                        <h4 className="text-md font-medium text-gray-900">
                          {eventType.name}
                        </h4>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-center gap-3">
                      <div className="flex flex-row items-center justify-center gap-2">
                        <p className="text-xs text-blue-600">
                          {t('generic.max')}
                        </p>
                        <div className="flex flex-col">
                          <button
                            onClick={() =>
                              handleBulkEditing(
                                eventType.id_special_event_type,
                                true,
                              )
                            }
                          >
                            <ChevronUpIcon className="h-4 w-4 text-blue-600" />
                          </button>
                          <button
                            onClick={() =>
                              handleBulkEditing(
                                eventType.id_special_event_type,
                                false,
                              )
                            }
                          >
                            <ChevronDownIcon className="h-4 w-4 text-blue-600" />
                          </button>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          setSelectedEventType(eventType);
                          setEventTypeForm({
                            name: eventType.name,
                            description: eventType.description,
                            acronym: eventType.acronym,
                            color: eventType.color as ColorName,
                          });
                          setIsEditEventTypeModalOpen(true);
                        }}
                      >
                        <PencilSquareIcon className="h-5 w-5 text-gray-500" />
                      </button>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Object.values(users).map(user => (
                <tr key={user.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {user.firstname} {user.lastname}
                  </td>
                  {Object.values(eventTypes).map(eventType => {
                    const idSpecialEventType = eventType.id_special_event_type;
                    const eventPeriodValue =
                      userLimits[user.id]?.[idSpecialEventType]
                        ?.initial_allocated_limit ??
                      allUserSpecialEventPeriods[user.id][idSpecialEventType]
                        ?.initial_allocated_limit ??
                      0;
                    return (
                      <td
                        key={eventType.id_special_event_type}
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        <div className="flex justify-center gap-2">
                          <input
                            type="number"
                            min="0"
                            className="w-16 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            value={eventPeriodValue}
                            onChange={e =>
                              handleLimitChange(
                                user.id,
                                eventType.id_special_event_type,
                                e.target.value,
                              )
                            }
                          />
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Create/Edit EventType Modal */}
      <Modal
        isOpen={isNewEventTypeModalOpen || isEditEventTypeModalOpen}
        onClose={() => {
          setIsNewEventTypeModalOpen(false);
          setIsEditEventTypeModalOpen(false);
          setSelectedEventType(null);
          setEventTypeForm({
            name: '',
            description: '',
            acronym: '',
            color: colorNames[0] as ColorName,
          });
        }}
        size="md"
        handleSave={
          isNewEventTypeModalOpen ? handleCreateEventType : handleEditEventType
        }
        editButtons
        handleDelete={
          isEditEventTypeModalOpen ? handleDeleteEventType : undefined
        }
      >
        <div className="space-y-4">
          <h2 className="text-xl font-semibold">
            {isNewEventTypeModalOpen
              ? t('eventCenter.createEventType')
              : t('eventCenter.editEventType')}
          </h2>
          {/* Name Field */}
          <div>
            <label htmlFor="name" className="block text-sm text-gray-700">
              {t('generic.name')}
            </label>
            <input
              type="text"
              id="name"
              value={eventTypeForm.name}
              onChange={e =>
                setEventTypeForm(prev => ({ ...prev, name: e.target.value }))
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>

          {/* Description Field */}
          <div>
            <label
              htmlFor="description"
              className="block text-sm text-gray-700"
            >
              {t('generic.description')}{' '}
              <span className="text-xs text-gray-500">
                {t('generic.optional')}
              </span>
            </label>
            <textarea
              id="description"
              value={eventTypeForm.description}
              onChange={e =>
                setEventTypeForm(prev => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>

          {/* Acronym Field */}
          <div>
            <label htmlFor="acronym" className="block text-sm text-gray-700">
              {t('eventCenter.acronym')}{' '}
              <span className="text-xs text-gray-500">
                {t('eventCenter.max2Characters')}
              </span>
            </label>
            <input
              type="text"
              id="acronym"
              placeholder={t('eventCenter.acronymDescription')}
              value={eventTypeForm.acronym}
              onChange={e =>
                setEventTypeForm(prev => ({ ...prev, acronym: e.target.value }))
              }
              maxLength={2}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>

          {/* Color Field Using the ColorPicker */}
          <div>
            <div className="mb-24">
              <label htmlFor="color" className="block text-sm text-gray-700">
                {t('generic.color')}
              </label>
              <ColorPicker
                value={eventTypeForm.color}
                onChange={(newColor: ColorName) =>
                  setEventTypeForm(prev => ({ ...prev, color: newColor }))
                }
              />
            </div>
          </div>
        </div>
      </Modal>
    </Wrapper>
  );
}
