/* eslint-disable max-len */
/* eslint-disable guard-for-in */
import {
  ArrowPathRoundedSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  teamAssignmentsQuery,
  TeamAssignmentsResponse,
  userExchangeDashboardQuery,
  UserShiftExchangeResponse,
} from '@youshift/shared/hooks/queries';
import {
  addMonths,
  classNames,
  dateToString,
  getFirstDayOfWeek,
  localeNormalizer,
  mergeIterationData,
  subtractMonths,
} from '@youshift/shared/utils';
import { createContext, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-native-big-calendar';
import { NavLink, Outlet, useLoaderData } from 'react-router-dom';
import {
  EventStatus,
  Section,
  SectionSlot,
  ShiftAssignment,
  UserReqRule,
} from '@youshift/shared/types';

import SectionLegend from '../../../components/Calendars/SectionLegend';
import { EmptyState } from '../../../components/EmptyState';
import { useUserContext } from '../../../layouts/UserLayout';
import {
  CalendarEvents,
  customEventRenderer,
  generateCalendarEvents,
  generateShiftAssignments,
  isCalendarShiftAssignments,
} from '../../../utils/calendar';
import { requireLoggedIn } from '../../../utils/checks';
import i18n from '../../../utils/i18n';
import PostAnnouncement from './PostAnnouncement';
import {
  createBaseIdMapping,
  createLatestBaseObjectMapping,
} from '../../Stats/utils';
import {
  buildIncompatibleSectionSlotsMap,
  IncompatibleSectionSlotsMap,
  SectionSlotInfo,
} from '../../../utils/section_slot_utils/utils';

export const userShiftExchangeLayoutLoader =
  (queryClient: QueryClient) =>
  async (): Promise<{
    exchangeData: UserShiftExchangeResponse;
    teamAssignments: TeamAssignmentsResponse;
  }> => {
    await requireLoggedIn(queryClient);
    const exchangeData = await preLoadQuery(
      queryClient,
      userExchangeDashboardQuery(),
    );
    const teamAssignments = await preLoadQuery(
      queryClient,
      teamAssignmentsQuery(),
    );
    return {
      exchangeData,
      teamAssignments,
    };
  };

export const UserShiftExchangeContext = createContext<
  | {
      exchangeData: UserShiftExchangeResponse;
      teamAssignments: TeamAssignmentsResponse;
      allSections: Record<number, Section>;
      allSectionSlots: Record<number, SectionSlot>;
      allShiftAssignments: Record<number, ShiftAssignment>;
      allUserReqRules: Record<number, UserReqRule>;
      incompatibleSectionSlotsMap: IncompatibleSectionSlotsMap;
    }
  | undefined
>(undefined);

export function useUserShiftExchangeContext() {
  const context = useContext(UserShiftExchangeContext);
  if (!context) {
    throw new Error(
      'useUserShiftExchangeContext must be used within an UserShiftExchangeProvider',
    );
  }
  return context;
}

export function useUserShiftExchangeLoader(): {
  exchangeData: UserShiftExchangeResponse;
  teamAssignments: TeamAssignmentsResponse;
} {
  const initialData = useLoaderData() as {
    exchangeData: UserShiftExchangeResponse;
    teamAssignments: TeamAssignmentsResponse;
  };
  const { data: exchangeData } = useQuery({
    ...userExchangeDashboardQuery(),
    initialData: initialData.exchangeData,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const { data: teamAssignments } = useQuery({
    ...teamAssignmentsQuery(),
    initialData: initialData.teamAssignments,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  return {
    exchangeData,
    teamAssignments,
  };
}

function UserShiftExchange() {
  // both userLayout and exchangeData contain shift_assignments and my_shift_assignments respectively
  // the difference is my_shift_assignments contains inactive shifts for display purposes in the exchange dashboard
  const { user, userLayout, events, eventTypes } = useUserContext();
  const { exchangeData, teamAssignments } = useUserShiftExchangeLoader();

  const { t } = useTranslation();

  const tabs = [
    { name: t('generic.shiftExchange.feed'), href: 'feed' },
    { name: t('generic.shiftExchange.myExchanges'), href: 'requests' },
  ];

  const {
    sections: allSections,
    section_slots: allSectionSlots,
    shift_assignments: allShiftAssignments,
    slot_labels: allSlotLabels,
    user_req_rules: allUserReqRules,
  } = useMemo(
    () =>
      mergeIterationData(userLayout.itrs, [
        'sections',
        'section_slots',
        'shift_assignments',
        'slot_labels',
        'user_req_rules',
      ]),
    [userLayout],
  );

  const incompatibleSectionSlotsMap = useMemo(
    () =>
      buildIncompatibleSectionSlotsMap(
        Object.entries(allSectionSlots).reduce(
          (acc, [id, slot]) => {
            acc[Number(id)] = {
              idSectionSlot: slot.id_section_slot,
              start: new Date(slot.start),
              end: new Date(slot.end),
              restPeriod: slot.rest_period,
            };
            return acc;
          },
          {} as Record<number, SectionSlotInfo>,
        ),
      ),
    [allSectionSlots],
  );

  const sectionsBaseIds = createBaseIdMapping(
    allSections,
    'id_section',
    'base_id_section',
  );
  const latestSections = createLatestBaseObjectMapping(
    allSections,
    'id_section',
    'base_id_section',
  );

  const [open, setOpen] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState<number | null>(
    null,
  );
  const [calendarMonthStart, setCalendarMonthStart] = useState(new Date());

  const goToNextMonth = () => {
    setCalendarMonthStart(prev => addMonths(prev, 1));
  };
  const goToPreviousMonth = () => {
    setCalendarMonthStart(prev => subtractMonths(prev, 1));
  };

  const locale = localeNormalizer(i18n.language);

  const calendarShiftAssignments = useMemo(
    () =>
      generateShiftAssignments(
        allShiftAssignments,
        allSectionSlots,
        latestSections,
        allSlotLabels,
        sectionsBaseIds,
      ),
    [
      allShiftAssignments,
      allSectionSlots,
      latestSections,
      allSlotLabels,
      sectionsBaseIds,
    ],
  );
  const calendarEvents = useMemo(
    () =>
      generateCalendarEvents(
        events.special_events.filter(e => e.status === EventStatus.APPROVED),
        eventTypes,
      ),
    [events, eventTypes],
  );

  const contextValue = useMemo(
    () => ({
      exchangeData,
      teamAssignments,
      allSections,
      allSectionSlots,
      allShiftAssignments,
      allUserReqRules,
      incompatibleSectionSlotsMap,
    }),
    [
      exchangeData,
      teamAssignments,
      allSections,
      allSectionSlots,
      allShiftAssignments,
      allUserReqRules,
      incompatibleSectionSlotsMap,
    ],
  );

  if (Object.keys(userLayout).length < 1) {
    return (
      <EmptyState
        title={t('user.shiftExchange.noItrsTitle')}
        subtitle={t('user.shiftExchange.noItrsSubtitle')}
        Icon={ArrowPathRoundedSquareIcon}
      />
    );
  }

  return (
    <div className="md:mt-16">
      <div className="xl:grid xl:grid-cols-2">
        <div className="sm:mr-6">
          <div className="block">
            <nav
              className="isolate flex divide-x rounded-lg shadow"
              aria-label="Tabs"
            >
              {tabs.map((tab, tabIdx) => (
                <NavLink
                  key={tab.name}
                  to={tab.href}
                  id={tab.href}
                  className={classNames(
                    '[&.active]:text-gray-900 [&.active]:border-b-2 [&.active]:border-solid [&.active]:border-b-blue-500 text-gray-500 hover:text-gray-700',
                    tabIdx === 0 ? 'rounded-l-lg' : '',
                    tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                    'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
                  )}
                >
                  <span>{tab.name}</span>
                </NavLink>
              ))}
            </nav>
          </div>
          {open && selectedAssignment ? (
            <PostAnnouncement
              open={open}
              setOpen={setOpen}
              selectedAssignment={selectedAssignment}
              sectionSlot={
                allSectionSlots[
                  allShiftAssignments[selectedAssignment].id_section_slot
                ]
              }
              sectionName={
                allSections[
                  allSectionSlots[
                    allShiftAssignments[selectedAssignment].id_section_slot
                  ].id_section
                ].name
              }
            />
          ) : null}
          <UserShiftExchangeContext.Provider value={contextValue}>
            <Outlet />
          </UserShiftExchangeContext.Provider>
        </div>
        <div>
          <p className="text-xl font-bold mb-0.5">
            {t('user.shiftExchange.mySchedule')}
          </p>
          <p className="text-sm text-gray-500 mb-2">
            {t('user.shiftExchange.myScheduleSubtitle')}
          </p>
          <div className="flex flex-row gap-1">
            <button
              onClick={goToPreviousMonth}
              className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
              aria-label="previous-week"
            >
              <ChevronLeftIcon className="w-4 h-4" />
            </button>
            <button
              aria-label="next-week"
              onClick={goToNextMonth}
              className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
            >
              <ChevronRightIcon className="w-4 h-4" />
            </button>
            <p className="text-md font-semibold text-blue-600">
              {dateToString(calendarMonthStart, 'month-year')}
            </p>
          </div>
          <div className="mb-2">
            <SectionLegend sections={Object.values(latestSections)} />
          </div>
          <Calendar
            locale={locale}
            events={[...calendarShiftAssignments, ...calendarEvents]}
            height={600}
            mode="month"
            onPressEvent={(event: CalendarEvents) => {
              if (isCalendarShiftAssignments(event)) {
                if (event.start.getTime() > new Date().getTime()) {
                  setSelectedAssignment(event.id_shift_assignment);
                  setOpen(true);
                }
              }
            }}
            date={calendarMonthStart}
            weekStartsOn={getFirstDayOfWeek(locale) === 7 ? 0 : 1}
            renderEvent={customEventRenderer}
          />
        </div>
      </div>
    </div>
  );
}

export default UserShiftExchange;
