/* eslint-disable max-len */
import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import {
  CreateIncompatibilityParams,
  CreateIncompatibilityResponse,
  DeleteIncompatibilityParams,
  DeleteIncompatibilityResponse,
  EditIncompatibilityParams,
  EditIncompatibilityResponse,
  useCreateIncompatibilityMutation,
  useDeleteIncompatibilityMutation,
  useEditIncompatibilityMutation,
} from '@youshift/shared/hooks/mutations';
import {
  CrossGroupIncompatibilityRule,
  IncompatibilityType,
  RuleType,
  RuleTypes,
  Section,
  SingleGroupIncompatibilityRule,
  User,
  UserRole,
} from '@youshift/shared/types';
import { classNames } from '@youshift/shared/utils';
import { Fragment, useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { YSButton } from '../../../../components/Buttons';

function RuleTypeToIncompatibilityType(
  ruleType: RuleType,
): IncompatibilityType {
  if (ruleType === RuleTypes.SINGLE_GROUP_INCOMP) {
    return IncompatibilityType.SINGLE_GROUP_INCOMP;
  }
  if (ruleType === RuleTypes.CROSS_GROUP_INCOMP) {
    return IncompatibilityType.CROSS_GROUP_INCOMP;
  }
  throw new Error(`Invalid rule type ${ruleType}`);
}

function CrossGroupIncompParamsSelector({
  idItr,
  crossGroupIncomp,
  users,
  roles,
  name,
  type,
  checkedSections,
  addIncompGroup,
  editIncompGroup,
  deleteIncompGroup,
  handleCheckboxChange,
  setOpen,
  cancelButtonRef,
}: {
  idItr: string;
  crossGroupIncomp: CrossGroupIncompatibilityRule;
  users: Record<number, User>;
  roles: Record<number, UserRole>;
  name: string;
  type: IncompatibilityType | null;
  checkedSections: number[];
  addIncompGroup: (
    data: CreateIncompatibilityParams,
  ) => CreateIncompatibilityResponse;
  editIncompGroup: (
    data: EditIncompatibilityParams,
  ) => EditIncompatibilityResponse;
  deleteIncompGroup: (
    data: DeleteIncompatibilityParams,
  ) => DeleteIncompatibilityResponse;
  handleCheckboxChange: (
    usersIds: number[],
    all: boolean,
    group: number[],
    setGroup: React.Dispatch<React.SetStateAction<number[]>>,
  ) => void;
  setOpen: (open: boolean) => void;
  cancelButtonRef: React.RefObject<HTMLButtonElement>;
}) {
  const { t } = useTranslation();

  // For cross group incomp
  const [checkedGroupA, setCheckedGroupA] = useState<number[]>(
    crossGroupIncomp?.user_group || [],
  );
  const [checkedGroupB, setCheckedGroupB] = useState<number[]>(
    crossGroupIncomp?.user_group_secondary || [],
  );

  const saveCrossGroupIncompChanges = () => {
    if (name.trim() === '') {
      toast.error(t('manager.incomp.emptyName'));
      return;
    }

    if (!type) {
      toast.error(t('manager.incomp.emptyType'));
      return;
    }

    if (checkedSections.length < 1) {
      toast.error(t('manager.incomp.emptySections'));
      return;
    }
    if (checkedGroupA.length < 1 || checkedGroupB.length < 1) {
      toast.error(t('manager.incomp.emptyGroups'));
      return;
    }
    if (crossGroupIncomp) {
      const data: EditIncompatibilityParams = {
        id_itr: crossGroupIncomp.id_itr,
        id_rule: crossGroupIncomp.id_rule,
        user_group: checkedGroupA,
        user_group_secondary: checkedGroupB,
        id_sections: checkedSections,
      };
      editIncompGroup(data);
    } else {
      const data: CreateIncompatibilityParams = {
        id_itr: Number(idItr),
        name,
        user_group: checkedGroupA,
        user_group_secondary: checkedGroupB,
        id_sections: checkedSections,
        type: IncompatibilityType.CROSS_GROUP_INCOMP,
      };
      addIncompGroup(data);
    }
  };

  return (
    <>
      <legend className="text-base font-semibold text-gray-900 my-2">
        {t('manager.incomp.chooseGroups')}
      </legend>
      <div className="my-2 pb-3 px-3 overflow-scroll flex flex-row justify-around">
        {/* LHS */}
        <div className="shadow-md bg-gray-50 rounded-md p-4">
          <p className="text-center font-semibold text-teal-600">
            {t('manager.incomp.groupA')}
          </p>
          {Object.values(roles)
            .sort((a: UserRole, b: UserRole) => a.name.localeCompare(b.name))
            .map(role => {
              const usersInRole: User[] = Object.values(users).filter(
                (user: User) => user.id_user_role === role.id,
              );
              const entireRoleDisabled: boolean = usersInRole.every(
                (user: User) => checkedGroupB.includes(user.id),
              );
              if (!usersInRole.length) {
                return null;
              }
              return (
                <div key={role.id}>
                  <div className="flex flex-row items-center">
                    <input
                      id={role.id.toString()}
                      name={role.id.toString()}
                      type="checkbox"
                      disabled={entireRoleDisabled}
                      checked={usersInRole.every(user =>
                        checkedGroupA.includes(user.id),
                      )}
                      onChange={() =>
                        handleCheckboxChange(
                          usersInRole.map(user => user.id),
                          true,
                          checkedGroupA,
                          setCheckedGroupA,
                        )
                      }
                      className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600 disabled:bg-gray-200"
                    />
                    <h3
                      className={`text-base text-blue-600 font-semibold my-2 ${entireRoleDisabled ? 'line-through' : ''}`}
                    >
                      {role.name}
                    </h3>
                  </div>
                  {usersInRole
                    .sort(
                      (a: User, b: User) =>
                        a.firstname.localeCompare(b.firstname) &&
                        a.lastname.localeCompare(b.lastname),
                    )
                    .map((user: User) => {
                      const disabled = checkedGroupB.includes(user.id);
                      return (
                        <div
                          key={user.id}
                          className="relative flex items-start"
                        >
                          <div className="ml-3 flex h-6 items-center">
                            <input
                              id={`person-${user.id}`}
                              name={`person-${user.id}`}
                              type="checkbox"
                              disabled={disabled}
                              checked={checkedGroupA.includes(user.id)}
                              onChange={() =>
                                handleCheckboxChange(
                                  [user.id],
                                  false,
                                  checkedGroupA,
                                  setCheckedGroupA,
                                )
                              }
                              className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600 disabled:bg-gray-200"
                            />
                          </div>
                          <div className="min-w-0 flex-1 text-sm leading-6">
                            <label
                              htmlFor={`person-${user.id}`}
                              className={`select-none font-medium text-gray-900 ${disabled ? 'line-through' : ''}`}
                            >
                              {user.firstname} {user.lastname}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })}
        </div>
        <div className="border-r-2 border-blue-500 w-1" />
        {/* RHS */}
        <div className="shadow-md bg-gray-50 rounded-md p-4">
          <p className="text-center font-semibold text-teal-600">
            {t('manager.incomp.groupB')}
          </p>
          {Object.values(roles)
            .sort((a: UserRole, b: UserRole) => a.name.localeCompare(b.name))
            .map(role => {
              const usersInRole: User[] = Object.values(users).filter(
                (user: User) => user.id_user_role === role.id,
              );
              const entireRoleDisabled: boolean = usersInRole.every(
                (user: User) => checkedGroupA.includes(user.id),
              );
              if (!usersInRole.length) {
                return null;
              }
              return (
                <div key={role.id}>
                  <div className="flex flex-row items-center">
                    <input
                      id={role.id.toString()}
                      name={role.id.toString()}
                      type="checkbox"
                      disabled={entireRoleDisabled}
                      checked={usersInRole.every(user =>
                        checkedGroupB.includes(user.id),
                      )}
                      onChange={() =>
                        handleCheckboxChange(
                          usersInRole.map(user => user.id),
                          true,
                          checkedGroupB,
                          setCheckedGroupB,
                        )
                      }
                      className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600 disabled:bg-gray-200"
                    />
                    <h3
                      className={`text-base text-blue-600 font-semibold my-2 ${entireRoleDisabled ? 'line-through' : ''}`}
                    >
                      {role.name}
                    </h3>
                  </div>
                  {usersInRole
                    .sort(
                      (a: User, b: User) =>
                        a.firstname.localeCompare(b.firstname) &&
                        a.lastname.localeCompare(b.lastname),
                    )
                    .map((user: User) => {
                      const disabled = checkedGroupA.includes(user.id);
                      return (
                        <div
                          key={user.id}
                          className="relative flex items-start"
                        >
                          <div className="ml-3 flex h-6 items-center">
                            <input
                              id={`person-${user.id}`}
                              name={`person-${user.id}`}
                              type="checkbox"
                              disabled={disabled}
                              checked={checkedGroupB.includes(user.id)}
                              onChange={() =>
                                handleCheckboxChange(
                                  [user.id],
                                  false,
                                  checkedGroupB,
                                  setCheckedGroupB,
                                )
                              }
                              className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600 disabled:bg-gray-200"
                            />
                          </div>
                          <div className="min-w-0 flex-1 text-sm leading-6">
                            <label
                              htmlFor={`person-${user.id}`}
                              className={`select-none font-medium text-gray-900 ${disabled ? 'line-through' : ''}`}
                            >
                              {user.firstname} {user.lastname}
                            </label>
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })}
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex">
        <YSButton
          variant="primary"
          onClick={() => {
            saveCrossGroupIncompChanges();
          }}
        >
          {t('generic.save')}
        </YSButton>
        <YSButton
          type="button"
          variant="ghost-primary"
          onClick={() => setOpen(false)}
        >
          {t('generic.cancel')}
        </YSButton>
        {crossGroupIncomp ? (
          <YSButton
            variant="delete"
            onClick={() => {
              deleteIncompGroup({
                id_itr: crossGroupIncomp.id_itr,
                id_rule: crossGroupIncomp.id_rule,
              });
            }}
          >
            {t('generic.eliminate')}
          </YSButton>
        ) : null}
      </div>
    </>
  );
}

function SingleGroupIncompParamsSelector({
  idItr,
  singleGroupIncomp,
  users,
  roles,
  name,
  type,
  checkedSections,
  addIncompGroup,
  editIncompGroup,
  deleteIncompGroup,
  handleCheckboxChange,
  setOpen,
  cancelButtonRef,
}: {
  idItr: string;
  singleGroupIncomp: SingleGroupIncompatibilityRule;
  users: Record<number, User>;
  roles: Record<number, UserRole>;
  name: string;
  type: IncompatibilityType | null;
  checkedSections: number[];
  addIncompGroup: (
    data: CreateIncompatibilityParams,
  ) => CreateIncompatibilityResponse;
  editIncompGroup: (
    data: EditIncompatibilityParams,
  ) => EditIncompatibilityResponse;
  deleteIncompGroup: (
    data: DeleteIncompatibilityParams,
  ) => DeleteIncompatibilityResponse;
  handleCheckboxChange: (
    usersIds: number[],
    all: boolean,
    group: number[],
    setGroup: React.Dispatch<React.SetStateAction<number[]>>,
  ) => void;
  setOpen: (open: boolean) => void;
  cancelButtonRef: React.RefObject<HTMLButtonElement>;
}) {
  const { t } = useTranslation();
  const [checkedParticipants, setCheckedParticipants] = useState<number[]>(
    singleGroupIncomp?.user_group || [],
  );
  const [maxSimult, setMaxSimult] = useState<number>(
    singleGroupIncomp?.max_simult || 0,
  );
  const [minSimult, setMinSimult] = useState<number>(
    singleGroupIncomp?.min_simult || 0,
  );

  const saveSingleGroupIncompChanges = () => {
    if (name.trim() === '') {
      toast.error(t('manager.incomp.emptyName'));
      return;
    }

    if (!type) {
      toast.error(t('manager.incomp.emptyType'));
      return;
    }

    if (checkedSections.length < 1) {
      toast.error(t('manager.incomp.emptySections'));
      return;
    }
    if (
      Number.isNaN(maxSimult) ||
      !Number.isInteger(Number(maxSimult)) ||
      maxSimult < 1 ||
      maxSimult > Object.keys(users).length ||
      maxSimult < minSimult ||
      checkedParticipants.length < minSimult
    ) {
      toast.error(t('manager.incomp.invalid#Participants'));
      return;
    }

    if (singleGroupIncomp) {
      const data: EditIncompatibilityParams = {
        id_itr: singleGroupIncomp.id_itr,
        id_rule: singleGroupIncomp.id_rule,
        name,
        user_group: checkedParticipants,
        max_simult: maxSimult,
        min_simult: minSimult,
        id_sections: checkedSections,
      };
      editIncompGroup(data);
    } else {
      // Create new single group incomp
      const data: CreateIncompatibilityParams = {
        id_itr: Number(idItr),
        type: IncompatibilityType.SINGLE_GROUP_INCOMP,
        name,
        user_group: checkedParticipants,
        max_simult: maxSimult,
        min_simult: minSimult,
        id_sections: checkedSections,
      };
      addIncompGroup(data);
    }
  };
  return (
    <>
      <div className="relative rounded-md mb-3 px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
        <label
          htmlFor="job-title"
          className="block text-xs font-medium text-gray-900"
        >
          {t('manager.incomp.minNumber')}
        </label>
        <input
          type="number"
          name="minSimult"
          id="minSimult"
          className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="#"
          min="0"
          value={minSimult}
          onChange={e => setMinSimult(Number(e.target.value))}
        />
      </div>
      <div className="relative rounded-md mb-3 px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
        <label
          htmlFor="job-title"
          className="block text-xs font-medium text-gray-900"
        >
          {t('manager.incomp.maxNumber')}
        </label>
        <input
          type="number"
          name="description"
          id="description"
          className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="#"
          min="0"
          max={Object.keys(users).length}
          value={maxSimult}
          onChange={e => setMaxSimult(Number(e.target.value))}
        />
      </div>
      <legend className="text-base font-semibold text-gray-900 my-2">
        {t('manager.incomp.choosePeople')}
      </legend>
      <div className="my-2 pb-3 px-3 overflow-scroll">
        {Object.values(roles)
          .sort((a: UserRole, b: UserRole) => a.name.localeCompare(b.name))
          .map(role => {
            const usersInRole: User[] = Object.values(users).filter(
              (user: User) => user.id_user_role === role.id,
            );
            if (!usersInRole.length) {
              return null;
            }
            return (
              <div key={role.id}>
                <div className="flex flex-row items-center">
                  <input
                    id={role.id.toString()}
                    name={role.id.toString()}
                    type="checkbox"
                    checked={usersInRole.every(user =>
                      checkedParticipants.includes(user.id),
                    )}
                    onChange={() =>
                      handleCheckboxChange(
                        usersInRole.map(user => user.id),
                        true,
                        checkedParticipants,
                        setCheckedParticipants,
                      )
                    }
                    className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                  />
                  <h3 className="text-base text-blue-600 font-semibold my-2">
                    {role.name}
                  </h3>
                </div>
                {usersInRole
                  .sort(
                    (a: User, b: User) =>
                      a.firstname.localeCompare(b.firstname) &&
                      a.lastname.localeCompare(b.lastname),
                  )
                  .map((user: User) => (
                    // <p key={person.id}>{person.fullName}</p>
                    <div key={user.id} className="relative flex items-start">
                      <div className="ml-3 flex h-6 items-center">
                        <input
                          id={`person-${user.id}`}
                          name={`person-${user.id}`}
                          type="checkbox"
                          checked={checkedParticipants.includes(user.id)}
                          onChange={() =>
                            handleCheckboxChange(
                              [user.id],
                              false,
                              checkedParticipants,
                              setCheckedParticipants,
                            )
                          }
                          className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                        />
                      </div>
                      <div className="min-w-0 flex-1 text-sm leading-6">
                        <label
                          htmlFor={`person-${user.id}`}
                          className="select-none font-medium text-gray-900"
                        >
                          {user.firstname} {user.lastname}
                        </label>
                      </div>
                    </div>
                  ))}
              </div>
            );
          })}
      </div>
      <div className="mt-5 sm:mt-4 sm:flex">
        <YSButton
          variant="primary"
          onClick={() => {
            saveSingleGroupIncompChanges();
          }}
        >
          {t('generic.save')}
        </YSButton>
        <YSButton
          type="button"
          variant="ghost-primary"
          onClick={() => setOpen(false)}
        >
          {t('generic.cancel')}
        </YSButton>
        {singleGroupIncomp ? (
          <YSButton
            variant="delete"
            onClick={() => {
              deleteIncompGroup({
                id_itr: singleGroupIncomp.id_itr,
                id_rule: singleGroupIncomp.id_rule,
              });
            }}
          >
            {t('generic.eliminate')}
          </YSButton>
        ) : null}
      </div>
    </>
  );
}

export default function AddIncompGroup({
  open,
  setOpen,
  incomp_rule,
  users,
  roles,
  sections,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  incomp_rule:
    | SingleGroupIncompatibilityRule
    | CrossGroupIncompatibilityRule
    | null;
  users: Record<number, User>;
  roles: Record<number, UserRole>;
  sections: Section[];
}) {
  const { idItr = '' } = useParams<{ idItr: string }>();

  const { t } = useTranslation();

  const cancelButtonRef = useRef(null);
  const [type, setType] = useState<IncompatibilityType | null>(
    incomp_rule ? RuleTypeToIncompatibilityType(incomp_rule.type) : null,
  );
  const [name, setName] = useState(incomp_rule?.name || '');
  const allSectionIds: number[] = sections.map(
    (section: Section) => section.id_section,
  );
  const [checkedSections, setCheckedSections] = useState<number[]>(
    incomp_rule?.section_group
      .map(Number)
      .filter((id: number) => allSectionIds.includes(id)) || allSectionIds,
  );

  const queryClient = useQueryClient();

  const createIncompMutation = useCreateIncompatibilityMutation(queryClient, {
    onSuccess: () => {
      toast.success(t('manager.incomp.successCreate'));
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: ['incompatibilities', idItr] });
    },
  });

  const editIncompMutation = useEditIncompatibilityMutation(queryClient, {
    onSuccess: () => {
      toast.success(t('manager.incomp.successEdit'));
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: ['incompatibilities', idItr] });
    },
  });

  const deleteIncompMutation = useDeleteIncompatibilityMutation(queryClient, {
    onSuccess: () => {
      toast.success(t('manager.incomp.successEliminate'));
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: ['incompatibilities', idItr] });
    },
  });

  const memoizedCreateIncompWrapper = useCallback(
    (data: CreateIncompatibilityParams): CreateIncompatibilityResponse =>
      createIncompMutation.mutate(data),
    [createIncompMutation],
  );
  const memoizedEditIncompWrapper = useCallback(
    (data: EditIncompatibilityParams): EditIncompatibilityResponse =>
      editIncompMutation.mutate(data),
    [editIncompMutation],
  );
  const memoizedDeleteIncompWrapper = useCallback(
    (data: DeleteIncompatibilityParams): DeleteIncompatibilityResponse =>
      deleteIncompMutation.mutate(data),
    [deleteIncompMutation],
  );

  const handleCheckboxChange = (
    usersIds: number[],
    all: boolean,
    group: number[],
    setGroup: React.Dispatch<React.SetStateAction<number[]>>,
  ) => {
    // Helper function to update the state by adding/removing a personId
    const updateState = (prevState: number[], personId: number) =>
      prevState.includes(personId)
        ? prevState.filter(id => id !== personId)
        : [...prevState, Number(personId)];

    if (all) {
      // If all flag is set, check if all personIds are included in the group
      if (usersIds.every(id_user => group.includes(id_user))) {
        // If all are included, remove them from the group
        setGroup(prevState =>
          prevState.filter(id_user => !usersIds.includes(id_user)),
        );
      } else {
        // If not all are included, add the missing personIds to the group
        setGroup(prevState => {
          const newState = [...prevState];
          usersIds.forEach(id_user => {
            if (!newState.includes(id_user)) {
              newState.push(Number(id_user));
            }
          });
          return newState;
        });
      }
    } else {
      // If all flag is not set, toggle each personId individually
      usersIds.forEach(id_user => {
        setGroup(prevState => updateState(prevState, id_user));
      });
    }
  };

  const handleCheckboxChangeSections = (sectionId: number) => {
    if (checkedSections.includes(sectionId)) {
      setCheckedSections(prevState => prevState.filter(id => id !== sectionId));
    } else {
      setCheckedSections(prevState => [...prevState, sectionId]);
    }
  };

  const radioButtonSettings: {
    id: IncompatibilityType;
    name: string;
    description: string;
  }[] = [
    {
      id: IncompatibilityType.SINGLE_GROUP_INCOMP,
      name: t('manager.incomp.singleGroup'),
      description: t('manager.incomp.singleGroupDescription'),
    },
    {
      id: IncompatibilityType.CROSS_GROUP_INCOMP,
      name: t('manager.incomp.crossGroup'),
      description: t('manager.incomp.crossGroupDescription'),
    },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title
                      as="h3"
                      className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6"
                    >
                      {incomp_rule
                        ? t('manager.incomp.edit')
                        : t('manager.incomp.create')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="relative rounded-md px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                        <label
                          htmlFor="name"
                          className="block text-xs font-medium text-gray-900"
                        >
                          {t('generic.name')}
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder={t('manager.incomp.incompNamePrompt')}
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                      </div>
                      <div className="my-4 overflow-scroll">
                        <p className="text-base font-semibold text-gray-900 mb-2">
                          {t('manager.incomp.chooseType')}
                        </p>
                        <RadioGroup value={type} onChange={setType}>
                          <div className="-space-y-px rounded-md bg-white">
                            {radioButtonSettings.map((setting, settingIdx) => (
                              <RadioGroup.Option
                                key={setting.id}
                                value={setting.id}
                                className={({ checked, disabled }) =>
                                  classNames(
                                    settingIdx === 0
                                      ? 'rounded-tl-md rounded-tr-md'
                                      : '',
                                    settingIdx ===
                                      radioButtonSettings.length - 1
                                      ? 'rounded-bl-md rounded-br-md'
                                      : '',
                                    checked
                                      ? 'z-10 border-blue-200 bg-blue-50'
                                      : 'border-gray-200',
                                    disabled
                                      ? 'cursor-not-allowed'
                                      : 'cursor-pointer',
                                    'relative flex border p-4 focus:outline-none',
                                  )
                                }
                                disabled={Boolean(incomp_rule)}
                              >
                                {({ active, checked, disabled }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        checked
                                          ? 'bg-blue-600 border-transparent'
                                          : 'bg-white border-gray-300',
                                        active
                                          ? 'ring-2 ring-offset-2 ring-blue-600'
                                          : '',
                                        disabled
                                          ? 'cursor-not-allowed'
                                          : 'cursor-pointer',
                                        'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center',
                                      )}
                                      aria-hidden="true"
                                    >
                                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                                    </span>
                                    <span className="ml-3 flex flex-col">
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? 'text-blue-900'
                                            : 'text-gray-900',
                                          'block text-sm font-medium',
                                        )}
                                      >
                                        {setting.name}
                                      </RadioGroup.Label>
                                      <RadioGroup.Description
                                        as="span"
                                        className={classNames(
                                          checked
                                            ? 'text-blue-700'
                                            : 'text-gray-500',
                                          'block text-sm',
                                        )}
                                      >
                                        {setting.description}
                                      </RadioGroup.Description>
                                    </span>
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>
                      <fieldset className="mt-4 max-h-[50vh] overflow-scroll">
                        <legend className="text-base font-semibold text-gray-900 my-2">
                          {t('manager.incomp.chooseSections')}
                        </legend>
                        <div className="my-2 pb-3 px-3 overflow-scroll">
                          {sections
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(section => (
                              <div key={section.id_section}>
                                <div className="flex flex-row items-center">
                                  <input
                                    id={section.id_section.toString()}
                                    name={section.id_section.toString()}
                                    type="checkbox"
                                    checked={checkedSections.includes(
                                      section.id_section,
                                    )}
                                    onChange={() =>
                                      handleCheckboxChangeSections(
                                        section.id_section,
                                      )
                                    }
                                    className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                  />
                                  <h3 className=" font-medium text-gray-900">
                                    {section.name}
                                  </h3>
                                </div>
                              </div>
                            ))}
                        </div>
                      </fieldset>
                      <fieldset className="mt-1 overflow-scroll">
                        {type === IncompatibilityType.SINGLE_GROUP_INCOMP ? (
                          <SingleGroupIncompParamsSelector
                            idItr={idItr}
                            singleGroupIncomp={
                              incomp_rule as SingleGroupIncompatibilityRule
                            }
                            users={users}
                            roles={roles}
                            name={name}
                            type={type}
                            checkedSections={checkedSections}
                            addIncompGroup={memoizedCreateIncompWrapper}
                            editIncompGroup={memoizedEditIncompWrapper}
                            deleteIncompGroup={memoizedDeleteIncompWrapper}
                            handleCheckboxChange={handleCheckboxChange}
                            cancelButtonRef={cancelButtonRef}
                            setOpen={setOpen}
                          />
                        ) : type === IncompatibilityType.CROSS_GROUP_INCOMP ? (
                          <CrossGroupIncompParamsSelector
                            idItr={idItr}
                            crossGroupIncomp={
                              incomp_rule as CrossGroupIncompatibilityRule
                            }
                            name={name}
                            type={type}
                            users={users}
                            roles={roles}
                            checkedSections={checkedSections}
                            addIncompGroup={memoizedCreateIncompWrapper}
                            editIncompGroup={memoizedEditIncompWrapper}
                            deleteIncompGroup={memoizedDeleteIncompWrapper}
                            handleCheckboxChange={handleCheckboxChange}
                            cancelButtonRef={cancelButtonRef}
                            setOpen={setOpen}
                          />
                        ) : null}
                      </fieldset>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
