/**
 * Checks if two datetime ranges overlap
 * @param start1 Start of first range
 * @param end1 End of first range
 * @param start2 Start of second range
 * @param end2 End of second range
 * @returns True if the ranges overlap, false otherwise
 */
export const datetimeRangeOverlap = (
  start1: Date,
  end1: Date,
  start2: Date,
  end2: Date,
) => start1 < end2 && end1 > start2;
