import {
  ItrUser,
  PreferenceSlot,
  ShiftAssignment,
  UserPreference,
  UserPreferenceType,
  UserRole,
} from '@youshift/shared/types';
import { useTranslation } from 'react-i18next';
import { AwardedShiftAssignments } from '@youshift/shared/hooks/queries';

import {
  calculateNegativePercentage,
  calculatePositivePercentage,
} from '../pages/Stats/utils';
import SectionSlotChip from './Calendars/SectionSlotChip';

interface PointsBreakdownProps {
  itrUser: ItrUser;
  awardedShiftAssignments: AwardedShiftAssignments;
  userPrefs: Record<number, UserPreference>;
  shiftAssignments: Record<number, ShiftAssignment>;
  prefSlots: Record<number, PreferenceSlot>;
  maxSavedPointsAllowed: number;
  manager?: boolean;
  userRole?: UserRole;
}

function PointsBreakdown({
  itrUser,
  userRole,
  awardedShiftAssignments,
  userPrefs,
  shiftAssignments,
  prefSlots,
  maxSavedPointsAllowed,
  manager = false,
}: PointsBreakdownProps) {
  const { t } = useTranslation();

  const basePoints = itrUser?.base_points ?? 0;
  const pointsSavedFromPrev = itrUser?.points_saved_from_prev ?? 0;
  const pointsRewarded = Object.values(awardedShiftAssignments).reduce(
    (acc, curr) => acc + curr.shift_assignment.point_award,
    0,
  );

  const initialPoints = basePoints + pointsSavedFromPrev + pointsRewarded;

  const { pos_respected, neg_not_respected, total_pos, total_neg } =
    Object.values(userPrefs).reduce(
      (acc, userPref: UserPreference) => {
        if (userPref.preference === UserPreferenceType.POINTS) {
          const { id_pref_slot } = userPref;
          const { section_slots } = prefSlots[id_pref_slot];
          const shiftAssignment = Object.values(shiftAssignments).find(
            (assignment: ShiftAssignment) =>
              section_slots.includes(assignment.id_section_slot),
          );
          if (userPref.points > 0) {
            return {
              ...acc,
              pos_respected: shiftAssignment
                ? acc.pos_respected + userPref.points
                : acc.pos_respected,
              total_pos: acc.total_pos + userPref.points,
            };
          }
          return {
            ...acc,
            neg_not_respected: shiftAssignment
              ? acc.neg_not_respected + Math.abs(userPref.points)
              : acc.neg_not_respected,
            total_neg: acc.total_neg + Math.abs(userPref.points),
          };
        }
        return acc;
      },
      { pos_respected: 0, neg_not_respected: 0, total_pos: 0, total_neg: 0 },
    );

  const positivePercentage = calculatePositivePercentage(
    pos_respected,
    total_pos,
  );
  const negativePercentage = calculateNegativePercentage(
    neg_not_respected,
    total_neg,
  );

  const pointsNotUsed = Math.max(0, initialPoints - (total_pos + total_neg));
  const pointsNotRespected = neg_not_respected;
  const pointsToBeSaved = Math.min(
    pointsNotUsed + pointsNotRespected,
    maxSavedPointsAllowed,
  );

  return (
    <div className="mb-2">
      {!manager && (
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          {t('user.results.inThisItr')}
        </h3>
      )}
      <dl
        className={`mt-5 grid grid-cols-1 gap-5 ${manager ? 'md:grid-cols-3' : 'md:grid-cols-5'}`}
      >
        {/* First two simple stat cards */}
        {!manager && (
          <>
            <div className="overflow-hidden rounded-lg bg-white shadow py-3 px-6 h-min">
              <dt className="text-sm font-medium text-gray-500">
                {t('user.results.yourRole')}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-blue-600">
                {userRole?.name}
              </dd>
            </div>

            <div className="overflow-hidden rounded-lg bg-white shadow py-3 px-6 h-min">
              <dt className="text-sm font-medium text-gray-500">
                {t('user.results.numberOfShifts')}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-blue-600">
                {Object.values(shiftAssignments).length}
              </dd>
            </div>
          </>
        )}

        {/* Last three expandable stat cards in a group */}
        <div className="md:col-span-3 overflow-hidden rounded-lg bg-white shadow py-3 px-6">
          <details className="group">
            <summary className="flex cursor-pointer list-none items-center justify-between font-medium">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-5 w-full">
                <div>
                  <dt className="truncate text-sm font-medium text-gray-500">
                    {t('user.results.usedPoints')}
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-blue-600">
                    {total_pos + total_neg}
                    {' / '}
                    {initialPoints}
                  </dd>
                </div>

                <div>
                  <dt className="truncate text-sm font-medium text-gray-500">
                    {t('user.results.pointsRespected')}
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold flex flex-row justify-around">
                    <span className="text-green-600">
                      {positivePercentage === null ? '-' : positivePercentage}
                      {' %'}
                    </span>
                    <span className="text-red-600">
                      {negativePercentage === null ? '-' : negativePercentage}
                      {' %'}
                    </span>
                  </dd>
                </div>

                <div>
                  <dt className="truncate text-sm font-medium text-gray-500">
                    {t('user.results.pointsToSave')}
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold tracking-tight text-blue-600">
                    {pointsToBeSaved}
                  </dd>
                </div>
              </div>
              <span className="transition group-open:rotate-180 ml-2 flex-shrink-0">
                <svg
                  fill="none"
                  height="24"
                  shapeRendering="geometricPrecision"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <div className="mt-3 text-sm text-gray-600 border-t pt-3">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                {/* Initial Points Details */}
                <div>
                  <div className="flex justify-between py-1 pr-2">
                    <span>{t('user.results.basePoints')}</span>
                    <span className="font-medium">{basePoints}</span>
                  </div>
                  <div className="flex justify-between py-1 pr-2">
                    <span>{t('user.results.savedFromPrevious')}</span>
                    <span className="font-medium">{pointsSavedFromPrev}</span>
                  </div>
                  <div className="flex justify-between py-1 pr-2">
                    <span>
                      {t('user.results.rewards')}
                      {Object.values(awardedShiftAssignments).length > 0
                        ? ':'
                        : ''}
                    </span>
                    <span className="font-medium">{pointsRewarded}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    {Object.values(awardedShiftAssignments).map(
                      ({ shift_assignment, section_slot, section_name }) => (
                        <div className="flex flex-row items-center justify-between gap-1 ml-6">
                          <SectionSlotChip
                            key={shift_assignment.id_shift_assignment}
                            sectionName={section_name}
                            start={section_slot.start}
                            end={section_slot.end}
                            small
                          />
                          <span className="font-medium text-green-600">{`+ ${shift_assignment.point_award}`}</span>
                        </div>
                      ),
                    )}
                  </div>
                </div>

                {/* Points Usage Details */}
                <div>
                  <div className="flex justify-between py-1 pr-2">
                    <span>{t('user.results.positivePoints')}</span>
                    <span className="font-medium text-green-600">
                      {pos_respected} / {total_pos} (
                      {positivePercentage !== null ? positivePercentage : '-'}
                      %)
                    </span>
                  </div>
                  <div className="flex justify-between py-1 pr-2">
                    <span>{t('user.results.negativePoints')}</span>
                    <span className="font-medium text-red-600">
                      {total_neg - neg_not_respected} / {total_neg} (
                      {negativePercentage !== null ? negativePercentage : '-'}
                      %)
                    </span>
                  </div>
                </div>

                {/* Points to Save Details */}
                <div>
                  <div className="flex justify-between py-1 pr-2">
                    <span>{t('user.results.pointsNotUsed')}</span>
                    <span className="font-medium">{pointsNotUsed}</span>
                  </div>
                  <div className="flex justify-between py-1 pr-2">
                    <span>{t('user.results.negPointsNotRespected')}</span>
                    <span className="font-medium">{pointsNotRespected}</span>
                  </div>
                  <div className="flex justify-between py-1 pr-2">
                    <span>{t('user.results.maxSavingsAllowed')}</span>
                    <span className="font-medium">{maxSavedPointsAllowed}</span>
                  </div>
                </div>
              </div>
            </div>
          </details>
        </div>
      </dl>
    </div>
  );
}

export default PointsBreakdown;
