import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import {
  EventStatus,
  ItrStatuses,
  UserPreferenceType,
} from '@youshift/shared/types';
import {
  addDays,
  getFirstDayOfWeek,
  localeNormalizer,
  parseIterationDates,
  subtractDays,
} from '@youshift/shared/utils';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-native-big-calendar';
import { Navigate, useNavigation, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import SectionLegend from '../../components/Calendars/SectionLegend';
import ShiftOutputLegend from '../../components/Calendars/ShiftOutputLegend';
import Wrapper from '../../components/Wrapper';
import { useUserContext } from '../../layouts/UserLayout';
import {
  customEventRenderer,
  customWeeklyEventRenderer,
  generateCalendarEvents,
  generateShiftAssignments,
} from '../../utils/calendar';
import { backendToLocalDate } from '../../utils/helpers';
import i18n from '../../utils/i18n';
import {
  calculateNegativePercentage,
  calculatePositivePercentage,
} from '../Stats/utils';
import PointsBreakdown from '../../components/PointsBreakdown';

function Results() {
  const { idItr } = useParams();
  const { user, userLayout, events, eventTypes } = useUserContext();
  const {
    itr_user,
    user_role,
    awarded_shift_assignments,
    shift_assignments,
    itr,
    sections,
    section_slots,
    slot_labels,
    user_prefs,
    pref_slots,
  } = userLayout.itrs[Number(idItr)];

  const { t } = useTranslation();
  const locale = localeNormalizer(i18n.language);

  const firstDayOfWeek = getFirstDayOfWeek(locale);

  const canSeeOriginOfShift = Boolean(user.group?.show_assignment_origin);

  // const [showPreferences, setShowPreferences] = useState(false);

  const globalShiftsViewAllowed = false;

  const calendarShiftAssignments = useMemo(
    () =>
      generateShiftAssignments(
        shift_assignments,
        section_slots,
        sections,
        slot_labels,
      ),
    [shift_assignments, section_slots, sections, slot_labels],
  );

  // Add state for current date
  const [calendarWeekStart, setCalendarWeekStart] = useState(
    new Date(...backendToLocalDate(itr.start_day)),
  );

  // Add navigation functions
  const goToNextWeek = () => {
    setCalendarWeekStart(prev => addDays(prev, 7));
  };
  const goToPreviousWeek = () => {
    setCalendarWeekStart(prev => subtractDays(prev, 7));
  };

  const calendarEvents = useMemo(
    () =>
      generateCalendarEvents(
        events.special_events.filter(e => e.status === EventStatus.APPROVED),
        eventTypes,
      ),
    [events, eventTypes],
  );

  // Add view state
  const [calendarView, setCalendarView] = useState<'month' | 'week'>('month');

  return (
    <>
      {itr.status !== ItrStatuses.PUBLISHED ? (
        <Navigate to="/user/iterations" replace />
      ) : null}
      <Wrapper>
        <div className="px-4 sm:px-6 lg:px-8">
          <h1 className="text-2xl font-semibold leading-6 text-gray-900">
            <time>{`${t('user.results.title')}: ${parseIterationDates(itr.start_day, itr.end_day, itr.itr_type)}`}</time>
          </h1>
          <div className="flex flex-row justify-between mb-6 align-middle">
            {/* <Switch.Group as="div" className="flex items-center gap-1">
            <Switch
              checked={showPreferences}
              onChange={() => setShowPreferences(!showPreferences)}
              className={classNames(
                showPreferences ? 'bg-blue-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  showPreferences ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
            <Switch.Label as="span" className="text-sm flex ml-3 flex-row">
              <span className="font-medium text-gray-900">
                {t('user.results.showMyPreferences')}
              </span>
            </Switch.Label>
          </Switch.Group> */}
            {/* {globalShiftsViewAllowed && (
            <div className="flex items-center">
              <NavLink
                to="all"
                className="ml-6 rounded-md  bg-blue-600 flex flex-row py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                state={state}
              >
                {navigation.state === 'loading' ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                ) : null}
                {t('user.results.globalShifts')}
              </NavLink>
            </div>
          )} */}
          </div>
          <PointsBreakdown
            itrUser={itr_user}
            awardedShiftAssignments={awarded_shift_assignments}
            userPrefs={user_prefs}
            shiftAssignments={shift_assignments}
            prefSlots={pref_slots}
            maxSavedPointsAllowed={
              userLayout.chains[itr.id_chain].max_saved_points_allowed
            }
            userRole={user_role}
          />
          <div className="flex flex-row justify-between mb-4 flex-wrap">
            <SectionLegend sections={Object.values(sections)} />
            {canSeeOriginOfShift && (
              <div className="flex items-center gap-4">
                <ShiftOutputLegend />
              </div>
            )}
          </div>
          <div className="flex flex-row justify-end mb-2 gap-1">
            <select
              value={calendarView}
              onChange={e =>
                setCalendarView(e.target.value as 'month' | 'week')
              }
              className="rounded-md border-gray-300 py-1 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            >
              <option value="month">{t('calendars.monthView')}</option>
              <option value="week">{t('calendars.weekView')}</option>
            </select>
            {calendarView === 'week' && (
              <>
                <button
                  onClick={goToPreviousWeek}
                  className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
                  aria-label="previous-week"
                >
                  <ChevronLeftIcon className="w-4 h-4" />
                </button>
                <button
                  aria-label="next-week"
                  onClick={goToNextWeek}
                  className="rounded-md border border-gray-300 px-3 text-sm hover:bg-gray-50"
                >
                  <ChevronRightIcon className="w-4 h-4" />
                </button>
              </>
            )}
          </div>

          {calendarView === 'month' ? (
            <Calendar
              locale={locale}
              events={[...calendarShiftAssignments, ...calendarEvents]}
              height={700}
              mode="month"
              date={new Date(...backendToLocalDate(itr.start_day))}
              weekStartsOn={firstDayOfWeek === 7 ? 0 : 1}
              renderEvent={(event, touchableOpacityProps) =>
                customEventRenderer(
                  event,
                  touchableOpacityProps,
                  canSeeOriginOfShift,
                )
              }
            />
          ) : (
            <Calendar
              locale={locale}
              events={[...calendarShiftAssignments, ...calendarEvents]}
              mode="week"
              height={700}
              date={calendarWeekStart}
              showTime
              ampm={false}
              weekStartsOn={firstDayOfWeek === 7 ? 0 : 1}
              renderEvent={(event, touchableOpacityProps) =>
                customWeeklyEventRenderer(
                  event,
                  touchableOpacityProps,
                  canSeeOriginOfShift,
                )
              }
              hourRowHeight={25}
            />
          )}

          <Tooltip
            id="my-tooltip"
            style={{
              backgroundColor: 'gray',
              opacity: 0.5,
              padding: 5,
              borderRadius: 4,
            }}
          />
        </div>
      </Wrapper>
    </>
  );
}

export default Results;
