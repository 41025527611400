import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

import Information from './Information';

interface InfoIconProps {
  title?: string;
  content?: React.ReactNode | string;
  className?: string;
}

export default function InfoIcon({
  title,
  content,
  className = 'w-5 h-5',
}: InfoIconProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(true)}
        className="text-gray-400 hover:text-gray-500 ml-1 focus:outline-none"
      >
        <InformationCircleIcon className={className} aria-hidden="true" />
      </button>
      <Information
        open={isOpen}
        setOpen={setIsOpen}
        title={title}
        content={content}
      />
    </>
  );
}
