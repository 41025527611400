"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFetchGroups = exports.getUsersQuery = exports.getOrgsQuery = exports.adminStatsQuery = exports.profileQuery = exports.getNotificationsQuery = exports.getIterationsHistoryQuery = exports.getStatsQuery = exports.userPrefsQuery = exports.allResultsQuery = exports.getStatsManagerQuery = exports.slotSubsetsQuery = exports.shiftsQuery = exports.participantsQuery = exports.incompatibilitiesQuery = exports.crossShiftReqsQuery = exports.loadUserQuery = exports.itrRolesQuery = exports.slotPrefsQuery = exports.managerShiftExchangeQuery = exports.postExecChecksStatusQuery = exports.getOutputsQuery = exports.statusQuery = exports.iterationQuery = void 0;
var react_query_1 = require("@tanstack/react-query");
var api_1 = require("../../api");
/**************** MANAGER QUERIES *************** */
var iterationQuery = function (id) { return ({
    queryKey: ['iteration', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/manager/itrs/".concat(id), method: 'get' })];
    }); }); },
    staleTime: Infinity,
}); };
exports.iterationQuery = iterationQuery;
var statusQuery = function (id) { return ({
    queryKey: ['status', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/manager/itrs/".concat(id, "/fetch_status"), method: 'get' })];
    }); }); },
    refetchInterval: function (data) { return (data.status === 'running-algo' ? 1000 : 0); },
}); };
exports.statusQuery = statusQuery;
var getOutputsQuery = function (id) { return ({
    queryKey: ['outputs', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/manager/itrs/".concat(id, "/fetch_shift_output"), method: 'get' })];
    }); }); },
}); };
exports.getOutputsQuery = getOutputsQuery;
var postExecChecksStatusQuery = function (id) { return ({
    queryKey: ['postExecStatus', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/manager/itrs/".concat(id, "/fetch_post_exec_status"), method: 'get' })];
    }); }); },
    refetchInterval: function (data) { return ((data.task_status === 'running' || data.task_status === 'enqueued') ? 1000 : 0); },
}); };
exports.postExecChecksStatusQuery = postExecChecksStatusQuery;
var managerShiftExchangeQuery = function () { return ({
    queryKey: ['managerExchangeDashboard'],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: 'manager/shift_exchange', method: 'get' })];
    }); }); },
}); };
exports.managerShiftExchangeQuery = managerShiftExchangeQuery;
var slotPrefsQuery = function (id) { return ({
    queryKey: ['slotPrefs', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/manager/itrs/".concat(id, "/fetch_slot_prefs"), method: 'get' })];
    }); }); },
    staleTime: Infinity,
}); };
exports.slotPrefsQuery = slotPrefsQuery;
var itrRolesQuery = function (id) { return ({
    queryKey: ["itrRoles", id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/manager/itrs/".concat(id, "/fetch_itr_roles"), method: "get" })];
    }); }); },
}); };
exports.itrRolesQuery = itrRolesQuery;
var loadUserQuery = function () { return ({
    queryKey: ["user"],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/load_user", method: "get" })];
    }); }); },
    staleTime: Infinity,
}); };
exports.loadUserQuery = loadUserQuery;
var crossShiftReqsQuery = function (id) { return ({
    queryKey: ['shiftClusters', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/manager/itrs/".concat(id, "/fetch_shift_clusters"), method: 'get' })];
    }); }); },
    staleTime: Infinity,
}); };
exports.crossShiftReqsQuery = crossShiftReqsQuery;
var incompatibilitiesQuery = function (id) { return ({
    queryKey: ['incompatibilities', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/manager/itrs/".concat(id, "/fetch_incomp"), method: 'get' })];
    }); }); },
    staleTime: Infinity,
}); };
exports.incompatibilitiesQuery = incompatibilitiesQuery;
var participantsQuery = function (id) { return ({
    queryKey: ['participants', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/manager/itrs/".concat(id, "/fetch_participants"), method: 'get' })];
    }); }); },
}); };
exports.participantsQuery = participantsQuery;
var shiftsQuery = function (id) { return ({
    queryKey: ['shifts', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/manager/itrs/".concat(id, "/fetch_shifts"), method: 'get' })];
    }); }); },
    staleTime: Infinity,
}); };
exports.shiftsQuery = shiftsQuery;
var slotSubsetsQuery = function (id) { return ({
    queryKey: ['slotSubsets', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/manager/itrs/".concat(id, "/fetch_slot_subsets"), method: 'get' })];
    }); }); },
    staleTime: Infinity,
}); };
exports.slotSubsetsQuery = slotSubsetsQuery;
var getStatsManagerQuery = function (id) { return ({
    queryKey: ['stats', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "manager/personnel/user/".concat(id, "/fetch_stats"), method: 'get' })];
    }); }); },
}); };
exports.getStatsManagerQuery = getStatsManagerQuery;
/**************** USER QUERIES ****************/
var allResultsQuery = function (id) { return ({
    queryKey: ['allResults', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "user/".concat(id, "/fetch_all_shift_outputs"), method: 'get' })];
    }); }); },
}); };
exports.allResultsQuery = allResultsQuery;
var userPrefsQuery = function (id) { return ({
    queryKey: ['userPrefs', id],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: "/user/".concat(id, "/fetch_user_prefs"), method: 'get' })];
    }); }); },
}); };
exports.userPrefsQuery = userPrefsQuery;
var getStatsQuery = function () { return ({
    queryKey: ['stats'],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: '/user/fetch_stats', method: 'get' })];
    }); }); },
}); };
exports.getStatsQuery = getStatsQuery;
var getIterationsHistoryQuery = function () { return ({
    queryKey: ['chains', 'all', 'user'],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: '/user/itrs', method: 'get' })];
    }); }); },
}); };
exports.getIterationsHistoryQuery = getIterationsHistoryQuery;
var getNotificationsQuery = function () { return ({
    queryKey: ['notifications'],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: '/fetch_notifications', method: 'get' })];
    }); }); },
}); };
exports.getNotificationsQuery = getNotificationsQuery;
/**************** SHARED QUERIES ****************/
// TODO: merge
var profileQuery = function () { return ({
    queryKey: ['profile'],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: '/load_user', method: 'get' })];
    }); }); },
}); };
exports.profileQuery = profileQuery;
/**************** ADMIN QUERIES ****************/
var adminStatsQuery = function () { return ({
    queryKey: ['adminStats'],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: '/admin/fetch_summary_stats', method: 'get' })];
    }); }); },
}); };
exports.adminStatsQuery = adminStatsQuery;
var getOrgsQuery = function () { return ({
    queryKey: ['orgs'],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: '/orgs', method: 'get' })];
    }); }); },
}); };
exports.getOrgsQuery = getOrgsQuery;
var getUsersQuery = function () { return ({
    queryKey: ['users'],
    queryFn: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, (0, api_1.request)({ url: 'admin/users', method: 'get' })];
    }); }); },
}); };
exports.getUsersQuery = getUsersQuery;
var useFetchGroups = function () { return (0, react_query_1.useQuery)({
    queryKey: ['admin'],
    queryFn: function () { return (0, api_1.request)({ url: '/admin/fetch_manager_main_dashboard', method: 'get' }); },
    select: function (data) { return data.orgs.flatMap(function (org) { return org.groups.map(function (group) { return ({
        id: group.id_group,
        name: "".concat(group.name, " - ").concat(org.name),
    }); }); }); },
}); };
exports.useFetchGroups = useFetchGroups;
