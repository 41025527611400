/* eslint-disable jsx-a11y/control-has-associated-label */
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import {
  CalendarDaysIcon,
  ClockIcon,
  Cog8ToothIcon,
  PencilSquareIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { preLoadQuery } from '@youshift/shared/hooks';
import {
  useCreateSlotsFromTemplateMutation,
  useDeleteSlotsMutation,
  useEditNeedsMutation,
} from '@youshift/shared/hooks/mutations';
import { sectionQuery } from '@youshift/shared/hooks/queries';
import { SectionWithSlots } from '@youshift/shared/types';
import {
  getFirstDayOfWeek,
  getShadeMap,
  localeNormalizer,
  returnColor,
} from '@youshift/shared/utils';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, useLoaderData, useParams } from 'react-router-dom';

import { YSButton } from '../../../../components/Buttons';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal';
import DragCloseDrawer from '../../../../components/DragCloseDrawer';
import CreateSlot from '../../../../components/ItrConfig/CreateSlot';
import CreateSlotType, {
  AddShiftTypeProps,
} from '../../../../components/ItrConfig/CreateSlotType';
import EditSectionModal from '../../../../components/ItrConfig/EditSectionModal';
import EditShiftLabels from '../../../../components/ItrConfig/EditShiftLabels';
import EditSlot from '../../../../components/ItrConfig/EditSlot';
import NavigateSectionsOrRules from '../../../../components/ItrConfig/NavigateSectionsOrRules';
import SmartSelector from '../../../../components/ItrConfig/SmartSelector';
import { VirgueriaVersion } from '../../../../components/ManualAssignment/types';
import { Virgueria } from '../../../../components/ManualAssignment/Virgueria';
import Modal from '../../../../components/Modal';
import Wrapper from '../../../../components/Wrapper';
import { useItrContext } from '../../../../layouts/IterationRootLayout/IterationRootLayout';
import { requireApproved, requireManager } from '../../../../utils/checks';
import i18n from '../../../../utils/i18n';
import { useSectionNeedsStateContext } from './CreateNewSection';
import {
  SectionSlotsReducerActionType,
  VirtualSlotsReducerActionType,
  sectionSlotsReducer,
  virtualSlotsReducer,
} from './reducers';
import { ArrangedSectionSlot } from './types';
import BulkEditSlots from './BulkEditSlots';
import {
  checkAllVirtualSlotsMaxIncompatibility,
  checkVirtualSlotsAreAdjacent,
} from './utils';

export const sectionLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs): Promise<SectionWithSlots | null> => {
    const user = await requireManager(queryClient);
    await requireApproved(user);
    if (params.idItr === undefined || params.idSection === undefined) {
      return null;
    }
    const { idItr, idSection } = params;
    const sectionWithSlots = await preLoadQuery(
      queryClient,
      sectionQuery(idItr, idSection),
    );
    return sectionWithSlots;
  };

// When creating a new section, the parent layout component manages and persists
// the section and slot states. If not creating then state is managed here.
export default function SectionConfig({ creating }: { creating: boolean }) {
  const sectionWithSlotsLoader = useLoaderData() as SectionWithSlots;
  const { iteration, shiftLabels, sectionsWithSlots } = useItrContext();
  const sections = sectionsWithSlots.map(
    sectionWithSlots => sectionWithSlots.section,
  );

  const { idItr, idSection } = useParams();

  const {
    data: { section, section_slots, virtual_slots },
  } = useQuery({
    ...sectionQuery(String(iteration.id_itr), String(idSection)),
    initialData: sectionWithSlotsLoader,
  });
  const [changesToSave, setChangesToSave] = useState<boolean>(false);

  // LOCAL STATE: used when not creating
  const [sectionSlotsState, sectionSlotsDispatch] = useReducer(
    sectionSlotsReducer,
    section_slots,
  );
  const [virtualSlotsState, virtualSlotsDispatch] = useReducer(
    virtualSlotsReducer,
    virtual_slots,
  );
  const [selectedVirtualSlots, setSelectedVirtualSlots] = useState<Set<number>>(
    new Set(),
  );
  const [selectedSectionSlots, setSelectedSectionSlots] = useState<Set<number>>(
    new Set(),
  );

  const [bulkEditedSlots, setBulkEditedSlots] = useState<Set<number>>(
    new Set(),
  );
  const [bulkEditedVirtualSlots, setBulkEditedVirtualSlots] = useState<
    Set<number>
  >(new Set());

  const {
    parentSectionSlotsState,
    onParentSectionSlotsDispatch,
    parentVirtualSlotsState,
    onParentVirtualSlotsDispatch,
  } = useSectionNeedsStateContext() || {};

  useEffect(() => {
    onParentSectionSlotsDispatch &&
      onParentSectionSlotsDispatch({
        type: 'RESET_STATE',
        payload: section_slots,
      });
    onParentVirtualSlotsDispatch &&
      onParentVirtualSlotsDispatch({
        type: 'RESET_STATE',
        payload: virtual_slots,
      });
  }, [
    sectionWithSlotsLoader,
    onParentSectionSlotsDispatch,
    onParentVirtualSlotsDispatch,
    section_slots,
    virtual_slots,
  ]);

  const virtualSlots =
    creating && parentVirtualSlotsState
      ? parentVirtualSlotsState
      : virtualSlotsState; // fallback to local if not creating

  const sectionSlots =
    creating && parentSectionSlotsState
      ? parentSectionSlotsState
      : sectionSlotsState;

  const handleSectionSlotsDispatch = useCallback(
    (action: SectionSlotsReducerActionType) => {
      if (creating && onParentSectionSlotsDispatch) {
        onParentSectionSlotsDispatch(action);
      } else {
        sectionSlotsDispatch(action);
      }
    },
    [creating, onParentSectionSlotsDispatch, sectionSlotsDispatch],
  );

  const handleVirtualSlotsDispatch = useCallback(
    (action: VirtualSlotsReducerActionType) => {
      if (creating && onParentVirtualSlotsDispatch) {
        onParentVirtualSlotsDispatch(action);
      } else {
        virtualSlotsDispatch(action);
      }
    },
    [creating, onParentVirtualSlotsDispatch, virtualSlotsDispatch],
  );

  const queryClient = useQueryClient();

  const [isEditSectionModalOpen, setIsEditSectionModalOpen] = useState(false);
  const [isEditSlotModalOpen, setIsEditSlotModalOpen] =
    useState<boolean>(false);
  // newSlotModal is to create one slot; newSlotsModal is to create multiple slots using the template form
  const [isNewSlotModalOpen, setIsNewSlotModalOpen] = useState<boolean>(false);
  const [isNewSlotsModalOpen, setIsNewSlotsModalOpen] =
    useState<boolean>(false);
  const [isBulkEditSlotsOpen, setIsBulkEditSlotsOpen] =
    useState<boolean>(false);
  const [isBulkDeleteConfirmationOpen, setIsBulkDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [slotToEdit, setSlotToEdit] = useState<number | undefined>(undefined);
  const [newLabelId, setNewLabelId] = useState<number | undefined>(undefined);
  const { t } = useTranslation();
  const shadeMap = useMemo(() => getShadeMap(shiftLabels || []), [shiftLabels]);

  const editSlot = (slot: ArrangedSectionSlot | number) => {
    const id = typeof slot === 'number' ? slot : slot.id_section_slot;
    setIsEditSlotModalOpen(true);
    setSlotToEdit(id);
  };

  const locale = localeNormalizer(i18n.language);

  useEffect(() => {
    handleSectionSlotsDispatch({
      type: 'RESET_STATE',
      payload: section_slots,
    });
    handleVirtualSlotsDispatch({
      type: 'RESET_STATE',
      payload: virtual_slots,
    });
  }, [
    handleSectionSlotsDispatch,
    handleVirtualSlotsDispatch,
    section_slots,
    virtual_slots,
  ]);

  const editNeeds = useEditNeedsMutation(queryClient, {
    onSuccess: () => {
      setChangesToSave(false);
      toast.success(t('generic.saved'));
      queryClient.invalidateQueries({ queryKey: ['sectionSlots', idItr] });
      setSelectedVirtualSlots(new Set());
      setSelectedSectionSlots(new Set());
      setIsBulkEditSlotsOpen(false);
    },
  });

  const createSlotsFromTemplate = useCreateSlotsFromTemplateMutation(
    queryClient,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['section', idSection] });
        queryClient.invalidateQueries({ queryKey: ['sectionSlots', idItr] });
        toast.success(t('manager.sectionsConfig.createSlotsSuccess'));
      },
    },
  );

  const { color, name } = section;
  const usedLabelIds = useMemo(
    () => new Set(Object.values(sectionSlots).map(slot => slot.id_slot_label)),
    [sectionSlots],
  );

  const filteredShiftLabels = useMemo(
    () => shiftLabels.filter(label => usedLabelIds.has(label.id_slot_label)),
    [shiftLabels, usedLabelIds],
  );

  if (!section) {
    return null;
  }

  const onVirtualSlotClick = (virtualSlotId: number) => {
    setSelectedVirtualSlots(prev => {
      const newSet = new Set(prev);
      if (prev.has(virtualSlotId)) {
        newSet.delete(virtualSlotId);
      } else {
        newSet.add(virtualSlotId);
      }
      return newSet;
    });
  };

  const onSectionSlotClick = (slotId: number) => {
    setSelectedSectionSlots(prev => {
      const newSet = new Set(prev);
      if (prev.has(slotId)) {
        newSet.delete(slotId);
      } else {
        newSet.add(slotId);
      }
      return newSet;
    });
  };

  // we only save at this level when we're not creating the section
  const handleSave = () => {
    if (!creating) {
      editNeeds.mutate({
        id_itr: idItr,
        rest_periods: Object.values(sectionSlots)
          .filter(({ id_section_slot }) => bulkEditedSlots.has(id_section_slot))
          .map(({ id_section_slot, rest_period }) => ({
            id_section_slot,
            rest_period,
          })),
        needs: Object.values(virtualSlots)
          .filter(({ id_virtual_slot }) =>
            bulkEditedVirtualSlots.has(id_virtual_slot),
          )
          .map(({ id_virtual_slot, min_need, max_need }) => ({
            id_virtual_slot,
            min_need,
            max_need,
          })),
      });
    }
  };

  const createShiftType = (props: AddShiftTypeProps) => {
    const {
      dayIndexes,
      id_slot_label,
      rest_period,
      duration_minutes,
      start_time: string,
      custom_counter_increments,
    } = props;
    const firstDayOfWeek = getFirstDayOfWeek(locale);
    createSlotsFromTemplate.mutate({
      id_itr: Number(idItr),
      id_section: Number(idSection),
      section_slots_template: dayIndexes.map(day_idx => ({
        day_idx: firstDayOfWeek === 1 ? day_idx : (day_idx + 6) % 7,
        id_slot_label,
        rest_period,
        start_time: string,
        duration_minutes,
        custom_counter_increments,
      })),
    });
  };

  return (
    <Wrapper>
      <div className="flex flex-row justify-between items-center">
        <div className="flex items-center gap-2">
          <div
            className="rounded-full w-6 h-6"
            style={{
              backgroundColor: returnColor(color),
            }}
          />
          <h1 className="text-2xl font-bold">{name}</h1>
          {!creating && (
            <button
              onClick={() => setIsEditSectionModalOpen(true)}
              aria-label="Edit Section"
            >
              <PencilSquareIcon className="w-6 h-6 text-gray-600" />
            </button>
          )}
        </div>
        {sections && !creating ? (
          <NavigateSectionsOrRules
            items={sections.map(section => ({
              id: section.id_section,
              name: section.name,
            }))}
            currentId={section.id_section}
          />
        ) : null}
      </div>
      <div className="flex flex-row justify-between mt-2 items-center flex-wrap">
        {shiftLabels.length && filteredShiftLabels.length ? (
          <EditShiftLabels
            shiftLabels={shiftLabels}
            filteredShiftLabels={filteredShiftLabels}
            sectionColor={color}
          />
        ) : null}
        <div className="lg:flex lg:flex-row flex-col gap-1">
          <YSButton
            classNames="flex flex-row gap-0.5 items-center"
            variant="primary"
            onClick={() => setIsNewSlotModalOpen(true)}
          >
            <PlusIcon className="text-white h-5 w-5 -ml-1.5" />
            <CalendarDaysIcon className="text-white h-5 w-5 -mr-1.5" />
          </YSButton>
          <YSButton
            classNames="flex flex-row gap-0.5 items-center"
            variant="primary"
            onClick={() => setIsNewSlotsModalOpen(true)}
          >
            <PlusIcon className="text-white h-5 w-5 -ml-2" />
            <div className="flex flex-row relative top-1">
              <CalendarDaysIcon className="text-white h-5 w-5" />
              <CalendarDaysIcon className="text-white h-5 w-5 relative -top-2 -left-2 -mr-2" />
            </div>
          </YSButton>
        </div>
      </div>
      {/* Smart selector */}
      <SmartSelector
        virtualSlotsConfig={{
          virtualSlots,
          setSelectedVirtualSlots,
          selectedVirtualSlots,
        }}
        sectionSlotsConfig={{
          filteredShiftLabels,
          sectionSlots,
          setSelectedSectionSlots,
          selectedSectionSlots,
        }}
        sections={{ [section.id_section]: section }}
      />
      <div className="flex flex-row gap-2 relative mr-10">
        <Virgueria
          start={iteration.start_day}
          end={iteration.end_day}
          sectionsWithSlots={[
            {
              section,
              section_slots: sectionSlots,
              virtual_slots: virtualSlots,
            },
          ]}
          shadeMap={shadeMap}
          onIconClick={editSlot}
          version={VirgueriaVersion.Section}
          labels={shiftLabels}
          onVirtualSlotClick={onVirtualSlotClick}
          onSlotClick={onSectionSlotClick}
          selectedSlots={selectedSectionSlots}
          selectedVirtualSlots={selectedVirtualSlots}
        />
        <div className="flex flex-col justify-start gap-4 items-center fixed bottom-0 right-0 pr-3 pb-16">
          {!creating && (
            <YSButton
              classNames="pl-2 pr-2 pt-1 pb-1"
              disabled={!changesToSave}
              onClick={handleSave}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                className="w-6 h-6"
              >
                <path d="M20 7.828a2 2 0 0 0-.586-1.414l-1.828-1.828A2 2 0 0 0 16.172 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.828z" />
                <path d="M14 4v4H8V4m0 16v-8h8v8" />
              </svg>
            </YSButton>
          )}
          <div
            className={
              selectedVirtualSlots.size
                ? 'bg-orange-100 rounded-md border border-orange-600'
                : ''
            }
          >
            <div className="flex flex-col items-center justify-center relative">
              <span className="relative text-sm text-gray-500">
                {t('generic.max')}
              </span>
              <div className="flex flex-row items-center">
                <UserPlusIcon className="w-6 h-6 text-blue-600" />
                <div className="flex flex-col gap-2">
                  <button
                    onClick={() => {
                      if (!selectedVirtualSlots.size) {
                        toast.error(
                          t('manager.sectionsConfig.selectVirtualSlotsFirst'),
                        );
                        return;
                      }
                      setChangesToSave(true);
                      setBulkEditedVirtualSlots(prev => {
                        selectedVirtualSlots.forEach(id => prev.add(id));
                        return prev;
                      });
                      handleVirtualSlotsDispatch({
                        type: 'UPDATE_NEEDS',
                        field: 'max_need',
                        value: 1,
                        idVirtualSlots: Array.from(selectedVirtualSlots),
                      });
                    }}
                    className="relative text-blue-600"
                  >
                    <PlusIcon className="text-blue-600 h-5 w-5" />
                  </button>
                  <button
                    onClick={() => {
                      if (!selectedVirtualSlots.size) {
                        toast.error(
                          t('manager.sectionsConfig.selectVirtualSlotsFirst'),
                        );
                        return;
                      }
                      setChangesToSave(true);
                      setBulkEditedVirtualSlots(prev => {
                        selectedVirtualSlots.forEach(id => prev.add(id));
                        return prev;
                      });
                      handleVirtualSlotsDispatch({
                        type: 'UPDATE_NEEDS',
                        field: 'max_need',
                        value: -1,
                        idVirtualSlots: Array.from(selectedVirtualSlots),
                      });
                    }}
                    className="relative text-blue-600"
                  >
                    <MinusIcon className="text-blue-600 h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center relative">
              <span className="relative text-sm text-gray-500">
                {t('generic.min')}
              </span>
              <div className="flex flex-row items-center">
                <UserMinusIcon className="w-6 h-6 text-blue-600" />
                <div className="flex flex-col gap-2">
                  <button
                    onClick={() => {
                      if (!selectedVirtualSlots.size) {
                        toast.error(
                          t('manager.sectionsConfig.selectVirtualSlotsFirst'),
                        );
                        return;
                      }
                      setChangesToSave(true);
                      setBulkEditedVirtualSlots(prev => {
                        selectedVirtualSlots.forEach(id => prev.add(id));
                        return prev;
                      });
                      handleVirtualSlotsDispatch({
                        type: 'UPDATE_NEEDS',
                        field: 'min_need',
                        value: 1,
                        idVirtualSlots: Array.from(selectedVirtualSlots),
                      });
                    }}
                    className="relative text-blue-600"
                  >
                    <PlusIcon className="text-blue-600 h-5 w-5" />
                  </button>
                  <button
                    onClick={() => {
                      if (!selectedVirtualSlots.size) {
                        toast.error(
                          t('manager.sectionsConfig.selectVirtualSlotsFirst'),
                        );
                        return;
                      }
                      setChangesToSave(true);
                      setBulkEditedVirtualSlots(prev => {
                        selectedVirtualSlots.forEach(id => prev.add(id));
                        return prev;
                      });
                      handleVirtualSlotsDispatch({
                        type: 'UPDATE_NEEDS',
                        field: 'min_need',
                        value: -1,
                        idVirtualSlots: Array.from(selectedVirtualSlots),
                      });
                    }}
                    className="relative text-blue-600"
                  >
                    <MinusIcon className="text-blue-600 h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              selectedSectionSlots.size
                ? 'bg-purple-100 rounded-md border border-purple-600'
                : ''
            }
          >
            <div className="flex flex-col items-center justify-center relative">
              <span className="relative text-sm text-gray-500">
                {t('generic.restPeriod')}
              </span>
              <div className="flex flex-row items-center">
                <ClockIcon className="w-6 h-6 text-blue-600" />
                <div className="flex flex-col gap-2">
                  <button
                    onClick={() => {
                      if (!selectedSectionSlots.size) {
                        toast.error(
                          t('manager.sectionsConfig.selectSlotsFirst'),
                        );
                        return;
                      }
                      setChangesToSave(true);
                      setBulkEditedSlots(prev => {
                        selectedSectionSlots.forEach(id => prev.add(id));
                        return prev;
                      });
                      handleSectionSlotsDispatch({
                        type: 'UPDATE_REST',
                        value: 60,
                        idSectionSlots: Array.from(selectedSectionSlots),
                      });
                    }}
                    className="relative text-blue-600"
                  >
                    <PlusIcon className="text-blue-600 h-5 w-5" />
                  </button>
                  <button
                    onClick={() => {
                      if (!selectedSectionSlots.size) {
                        toast.error(
                          t('manager.sectionsConfig.selectSlotsFirst'),
                        );
                        return;
                      }
                      setChangesToSave(true);
                      setBulkEditedSlots(prev => {
                        selectedSectionSlots.forEach(id => prev.add(id));
                        return prev;
                      });
                      handleSectionSlotsDispatch({
                        type: 'UPDATE_REST',
                        value: -60,
                        idSectionSlots: Array.from(selectedSectionSlots),
                      });
                    }}
                    className="relative text-blue-600"
                  >
                    <MinusIcon className="text-blue-600 h-5 w-5" />
                  </button>
                </div>
              </div>
            </div>
            {!creating && (
              <div className="flex flex-col items-center justify-center relative">
                <span className="relative text-sm text-gray-500">
                  {t('generic.edit')}
                </span>
                <button
                  className="flex flex-row items-center"
                  type="button"
                  onClick={() => {
                    if (!selectedSectionSlots.size) {
                      toast.error(t('manager.sectionsConfig.selectSlotsFirst'));
                      return;
                    }
                    setIsBulkEditSlotsOpen(true);
                  }}
                >
                  <Cog8ToothIcon className="w-6 h-6 text-blue-600" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <EditSectionModal
        isOpen={isEditSectionModalOpen}
        onClose={() => setIsEditSectionModalOpen(false)}
        details={section}
        key={section.id_section}
      />
      {slotToEdit ? (
        <DragCloseDrawer
          open={isEditSlotModalOpen}
          setOpen={setIsEditSlotModalOpen}
          modalHeight={42}
        >
          <EditSlot
            setOpen={setIsEditSlotModalOpen}
            shiftLabels={shiftLabels}
            slot={sectionSlotsState[slotToEdit]}
            iteration={iteration}
            idSection={section.id_section}
          />
        </DragCloseDrawer>
      ) : null}
      {isNewSlotModalOpen ? (
        <DragCloseDrawer
          open={isNewSlotModalOpen}
          setOpen={setIsNewSlotModalOpen}
          modalHeight={42}
        >
          <CreateSlot
            setOpen={setIsNewSlotModalOpen}
            shiftLabels={shiftLabels}
            iteration={iteration}
            idSection={section.id_section}
          />
        </DragCloseDrawer>
      ) : null}
      {isNewSlotsModalOpen ? (
        <DragCloseDrawer
          open={isNewSlotsModalOpen}
          setOpen={setIsNewSlotsModalOpen}
          modalHeight={42}
        >
          <CreateSlotType
            setOpen={setIsNewSlotsModalOpen}
            addShiftType={createShiftType}
            shiftLabels={shiftLabels}
          />
        </DragCloseDrawer>
      ) : null}
      {isBulkEditSlotsOpen ? (
        <BulkEditSlots
          isBulkEditSlotsOpen={isBulkEditSlotsOpen}
          setIsBulkEditSlotsOpen={setIsBulkEditSlotsOpen}
          setIsBulkDeleteConfirmationOpen={setIsBulkDeleteConfirmationOpen}
          selectedSectionSlots={selectedSectionSlots}
          setSelectedSectionSlots={setSelectedSectionSlots}
          sectionSlots={sectionSlots}
          shiftLabels={shiftLabels}
          newLabelId={newLabelId}
          setNewLabelId={setNewLabelId}
          handleSectionSlotsDispatch={handleSectionSlotsDispatch}
          editNeeds={editNeeds}
        />
      ) : null}
    </Wrapper>
  );
}
