import { useQueryClient } from '@tanstack/react-query';
import {
  useDeleteUserReqRule,
  useEditUserReqRule,
  useEditUserReqRuleDetails,
} from '@youshift/shared/hooks/mutations';
import { Rule, RuleTypes } from '@youshift/shared/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import DeleteConfirmationModal from '../DeleteConfirmationModal';
import Modal from '../Modal';

interface EditSectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  details: Rule;
}

export default function EditRuleModal({
  isOpen,
  onClose,
  details,
}: EditSectionModalProps) {
  const {
    name: ruleName,
    description: ruleDescription,
    id_itr,
    id_rule,
    type,
  } = details;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [name, setName] = useState(ruleName);
  const [description, setDescription] = useState(ruleDescription);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const editRuleMutation = useEditUserReqRuleDetails(queryClient, {
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({
        queryKey: ['userReqRule', id_rule.toString()],
      });
      queryClient.invalidateQueries({
        queryKey: ['extendedUserReqRules', String(id_itr)],
      });
      toast.success(t('manager.rulesConfig.ruleUpdated'));
    },
  });

  const deleteRuleMutation = useDeleteUserReqRule(queryClient, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['rules', String(id_itr)],
      });
      queryClient.invalidateQueries({
        queryKey: ['extendedUserReqRules', String(id_itr)],
      });
      onClose();
      navigate('../rules');
    },
  });

  const handleSave = () => {
    if (type !== RuleTypes.CUSTOM_USER_REQS) {
      toast.error(t('manager.rulesConfig.editNonCustomRuleError'));
      return;
    }
    editRuleMutation.mutate({
      id_itr,
      id_rule,
      name,
      description,
    });
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = () => {
    deleteRuleMutation.mutate({
      id_itr,
      id_rule,
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="md"
        editButtons
        handleDelete={handleDeleteClick}
        handleSave={
          type === RuleTypes.CUSTOM_USER_REQS ? handleSave : undefined
        }
      >
        <div className="space-y-4 mb-6">
          <h2 className="text-xl font-semibold">
            {t('manager.rulesConfig.editRule')}
          </h2>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              {t('manager.rulesConfig.name')}
            </label>
            <input
              id="name"
              type="text"
              value={name}
              disabled={type !== RuleTypes.CUSTOM_USER_REQS}
              onChange={e => setName(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm disabled:bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              {t('generic.description')}
            </label>
            <input
              id="description"
              type="text"
              value={description}
              disabled={type !== RuleTypes.CUSTOM_USER_REQS}
              onChange={e => setDescription(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm disabled:bg-gray-100"
            />
          </div>
        </div>
      </Modal>

      <DeleteConfirmationModal
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onConfirm={handleConfirmDelete}
        title={t('manager.rulesConfig.deleteConfirmation')}
        description={t('manager.rulesConfig.deleteConfirmationText', {
          name,
        })}
      />
    </>
  );
}
