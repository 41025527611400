/* eslint-disable react/require-default-props */
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import {
  ColorName,
  dateToString,
  localeNormalizer,
  returnColor,
} from '@youshift/shared/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SlotCapacity from './ManualAssignment/SlotCapacity';
import { ArrangedVirtualSlot } from './ManualAssignment/types';
import { borderOfSpanningSlot } from '../layouts/IterationRootLayout/utils';
import i18n from '../utils/i18n';
import {
  VirtualSlotTripletError,
  VirtualSlotTripletErrorType,
} from '../pages/Manager/IterationConfig/Sections/utils';
import InfoIcon from './InfoIcon';

interface VirtualSlotProps {
  slot: ArrangedVirtualSlot;
  sectionColor: ColorName;
  onVirtualSlotClick?: (virtualSlotId: number) => void;
  nextIsContiguous: boolean;
  selected: boolean;
  virtualSlotError?: VirtualSlotTripletError | null;
}

interface VirtualSlotVirgueriaProps {
  slot: ArrangedVirtualSlot;
  sectionColor: ColorName;
  currentlyAssigned?: number;
  nextIsContiguous: boolean;
}

export function VirtualSlot({
  slot,
  sectionColor,
  onVirtualSlotClick,
  nextIsContiguous,
  selected,
  virtualSlotError,
}: VirtualSlotProps) {
  const borderColor = returnColor(sectionColor, 500);
  const { t } = useTranslation();
  const locale = localeNormalizer(i18n.language);

  return (
    <button
      key={`${slot.start_time}-${slot.id_virtual_slot}`}
      className="bg-white justify-between relative gap-0.5 mt-3 mb-1.5"
      style={{
        borderTop: `1px solid ${borderColor}`,
        borderBottom: `1px solid ${borderColor}`,
        gridColumnStart: slot.start_position,
        gridColumnEnd: slot.end_position,
        borderRight: nextIsContiguous ? `1px solid ${borderColor}` : 'none',
      }}
      onClick={() => onVirtualSlotClick?.(slot.id_virtual_slot)}
    >
      <CheckCircleIcon
        className={`absolute -top-3 -right-1 w-4 h-4 text-orange-500 bg-orange-100 rounded-full ${selected ? '' : 'hidden'}`}
      />
      {/* Main content container to avoid overlap */}
      <div
        className={`flex flex-col items-center ${virtualSlotError ? 'bg-red-100' : ''}`}
      >
        {virtualSlotError && (
          <InfoIcon
            className="absolute -top-3 -left-3 z-10 w-5 h-5 text-red-500 bg-white rounded-full p-0"
            aria-hidden="true"
            title={`${t('manager.sectionsConfig.virtualSlotConfigErrors.title')}: ${dateToString(virtualSlotError.context.currSlot.start, 'hh:mm', locale)} - ${dateToString(virtualSlotError.context.currSlot.end, 'hh:mm', locale)}`}
            content={
              <div className="whitespace-normal max-w-xs">
                {virtualSlotError.type ===
                  VirtualSlotTripletErrorType.VIRTUAL_SLOT_TRIPLET_REACHABLE_MIN_NEED && (
                  <>
                    <div className="py-2 font-bold ">
                      {t(
                        'manager.sectionsConfig.virtualSlotConfigErrors.reachableMinNeedError.curSlotMinNeedUnreachable',
                      )}
                    </div>
                    <div className="py-2">
                      {t(
                        'manager.sectionsConfig.virtualSlotConfigErrors.reachableMinNeedError.curSlotMinNeed',
                      )}
                      {': '}
                      <b>{virtualSlotError.context.currentSlotMinNeed}</b>
                    </div>
                    <div>
                      {t(
                        'manager.sectionsConfig.virtualSlotConfigErrors.reachableMinNeedError.maxRestrictedByNeighbor',
                      )}
                      {': '}
                      <b>{virtualSlotError.context.maxRestrictedByNeighbors}</b>
                    </div>
                    <ul className="list-disc list-inside pl-5">
                      <li>
                        {`${dateToString(virtualSlotError.context.prevSlot.start, 'hh:mm', locale)} - ${dateToString(virtualSlotError.context.prevSlot.end, 'hh:mm', locale)}`}{' '}
                        {t(
                          'manager.sectionsConfig.virtualSlotConfigErrors.limitsTo',
                        )}{' '}
                        {t('generic.max')}
                        {': '}
                        <b>
                          {
                            virtualSlotError.context
                              .maxRestrictedByNeighborsPrev
                          }
                        </b>
                      </li>
                      <li>
                        {`${dateToString(virtualSlotError.context.nextSlot.start, 'hh:mm', locale)} - ${dateToString(virtualSlotError.context.nextSlot.end, 'hh:mm', locale)}`}{' '}
                        {t(
                          'manager.sectionsConfig.virtualSlotConfigErrors.limitsTo',
                        )}{' '}
                        {t('generic.max')}
                        {': '}
                        <b>
                          {
                            virtualSlotError.context
                              .maxRestrictedByNeighborsNext
                          }
                        </b>
                      </li>
                    </ul>
                  </>
                )}
                {virtualSlotError.type ===
                  VirtualSlotTripletErrorType.VIRTUAL_SLOT_TRIPLET_FEASIBLE_MAX_NEED && (
                  <>
                    <div className="py-2 font-bold ">
                      {t(
                        'manager.sectionsConfig.virtualSlotConfigErrors.infeasibleMaxNeedError.curSlotMaxNeedExceeded',
                      )}
                    </div>
                    <div className="py-2">
                      {t(
                        'manager.sectionsConfig.virtualSlotConfigErrors.infeasibleMaxNeedError.curSlotMaxNeed',
                      )}
                      {': '}
                      <b>{virtualSlotError.context.currentSlotMaxNeed}</b>
                    </div>
                    <div className="py-2">
                      {t(
                        'manager.sectionsConfig.virtualSlotConfigErrors.infeasibleMaxNeedError.minRequiredByNeighbors',
                      )}
                      {': '}
                      <b>{virtualSlotError.context.minRequiredByNeighbors}</b>
                    </div>
                    <ul className="list-disc list-inside pl-5">
                      <li>
                        {`${dateToString(virtualSlotError.context.prevSlot.start, 'hh:mm', locale)} - ${dateToString(virtualSlotError.context.prevSlot.end, 'hh:mm', locale)}`}{' '}
                        {t(
                          'manager.sectionsConfig.virtualSlotConfigErrors.increasesTo',
                        )}{' '}
                        {t('generic.min')}
                        {': '}
                        <b>
                          {virtualSlotError.context.minRequiredByNeighborsPrev}
                        </b>
                      </li>
                      <li>
                        {`${dateToString(virtualSlotError.context.nextSlot.start, 'hh:mm', locale)} - ${dateToString(virtualSlotError.context.nextSlot.end, 'hh:mm', locale)}`}{' '}
                        {t(
                          'manager.sectionsConfig.virtualSlotConfigErrors.increasesTo',
                        )}{' '}
                        {t('generic.min')}
                        {': '}
                        <b>
                          {virtualSlotError.context.minRequiredByNeighborsNext}
                        </b>
                      </li>
                    </ul>
                  </>
                )}
              </div>
            }
          />
        )}
        <div className="flex flex-col justify-center items-center gap-0.5 mx-1">
          {/* Display Current Needs */}
          <p className="text-gray-800 md:text-xs text-xxxs">{`${slot.max_need}`}</p>
          <p className="text-gray-800 md:text-xs text-xxxs">{`${slot.min_need}`}</p>
        </div>
      </div>
    </button>
  );
}

export function VirtualSlotVirgueria({
  slot,
  sectionColor,
  currentlyAssigned,
  nextIsContiguous,
}: VirtualSlotVirgueriaProps) {
  if (slot.id_virtual_slot === undefined) {
    return null;
  }

  const color = returnColor(sectionColor, 500);
  const borderColor = returnColor(sectionColor, 500);
  return (
    <div
      key={`${slot.start_time}-${slot.id_virtual_slot}`}
      className="bg-white flex flex-col items-center justify-around relative gap-0.5 mt-3"
      style={{
        borderTop: `1px solid ${color}`,
        borderBottom: `1px solid ${color}`,
        gridColumnStart: slot.start_position,
        gridColumnEnd: slot.end_position,
        borderRight: nextIsContiguous ? `1px solid ${borderColor}` : 'none',
        ...borderOfSpanningSlot(slot.spanning),
      }}
    >
      {/* Main content container to avoid overlap */}
      <div className="flex flex-col items-center pb-0.5 pt-0.5">
        {currentlyAssigned !== undefined && (
          <SlotCapacity
            minNeed={slot.min_need}
            maxNeed={slot.max_need}
            current={currentlyAssigned}
          />
        )}
      </div>
    </div>
  );
}

export function VirtualSlotVerticalVirgueria({
  slot,
  sectionColor,
  currentlyAssigned,
  nextIsContiguous,
}: VirtualSlotVirgueriaProps) {
  if (slot.id_virtual_slot === undefined) {
    return null;
  }

  const color = returnColor(sectionColor, 500);
  const borderColor = returnColor(sectionColor, 500);
  return (
    <div
      key={`${slot.start_time}-${slot.id_virtual_slot}`}
      className="bg-white flex flex-row items-center justify-around relative gap-0.5 mx-2 px-2"
      style={{
        borderRight: `1px solid ${color}`,
        borderLeft: `1px solid ${color}`,
        gridRowStart: slot.start_position,
        gridRowEnd: slot.end_position,
        borderBottom: nextIsContiguous ? `1px solid ${borderColor}` : 'none',
        ...borderOfSpanningSlot(slot.spanning),
      }}
    >
      {/* Main content container to avoid overlap */}
      <div className="flex flex-row items-center pb-0.5 pt-0.5">
        {currentlyAssigned !== undefined && (
          <SlotCapacity
            minNeed={slot.min_need}
            maxNeed={slot.max_need}
            current={currentlyAssigned}
            isVertical
          />
        )}
      </div>
    </div>
  );
}
