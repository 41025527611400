/* eslint-disable max-len */
import { Disclosure, Transition } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const faqs = [0, 1, 2, 3];

const featuresList = [0, 1, 2, 3, 4, 5, 6];

export default function FAQ() {
  const { t } = useTranslation();

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <div className="mx-auto divide-y divide-gray-900/10">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900">
            <span className="text-teal-600">{t('landing.faq.title')}</span>
            {' '}
            {t('landing.faq.subtitle')}
          </h2>
          <div className="flex flex-col items-start mt-6 gap-x-8 gap-y-6 rounded-3xl px-8 py-5 ring-1 ring-gray-900/10 sm:gap-y-10 sm:px-10 sm:py-5 lg:col-span-2 lg:flex-row lg:items-center">
            <div className="lg:min-w-0 lg:flex-1">
              <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-800">
                {t('landing.faq.contactQuestion')}
              </h3>
            </div>
            <Link
              to="/contact"
              className="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-blue-600 ring-1 ring-inset ring-blue-200 hover:ring-blue-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              {t('landing.faq.contactLink')}
              {' '}
              <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map(id => (
              <Disclosure as="div" key={id} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {t(`landing.faq.items.${id}.question`)}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Transition
                      enter="transition duration-500 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-500 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base leading-7 text-gray-600">
                          {t(`landing.faq.items.${id}.answer`)}
                        </p>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
