import {
  CrossGroupIncompatibilityRule,
  SectionSlot,
  ShiftAssignment,
  SingleGroupIncompatibilityRule,
  SpecialEvent,
  UserPreference,
  UserPreferenceType,
  UserRequirement,
  ExchangeRequest,
  ExchangeResponse,
  User,
  ExchangeResponseType,
  UserRequirementType,
  ExchangeRequestStatus,
  ExchangeResponseStatus,
  UserReqRule,
} from '@youshift/shared/types';
import { getDifferenceInMinutes } from '@youshift/shared/utils';
import { TeamAssignmentsResponse } from '@youshift/shared/hooks/queries';

import { UserLayoutContext } from '../../layouts/UserLayout';
import { checkUserReqs } from '../iteration_checks/assignment_checks/assignment_checks';
import {
  CrossGroupIncompatibilityViolation,
  ExistingAssignmentOnNewRestPeriodViolation,
  NewAssignmentOnRestPeriodViolation,
  OverlappingAssignmentViolation,
  ShiftExchangeViolationType,
  SingleGroupIncompatibilityMaxSimultViolation,
  SingleGroupIncompatibilityMinSimultViolation,
  SpecialEventConflictViolation,
  UserReqMaxSlotsViolation,
  UserReqMinSlotsViolation,
  UserReqMaxDurationViolation,
  UserReqMinDurationViolation,
  ShiftExchangeViolation,
} from './types';
import { datetimeRangeOverlap } from '../iteration_checks/utils';
import { IncompatibleSectionSlotsMap } from '../section_slot_utils/utils';

/**
 * Utility functions for validating shift exchange operations.
 *
 * This module provides functions to check if shift exchanges between users are valid
 * according to various rules and constraints:
 *
 * - Single group incompatibility rules (min/max simultaneous shifts)
 * - Cross group incompatibility rules
 * - Rest period requirements
 * - Special event conflicts
 * - User requirements/limits
 * - Existing shift assignment conflicts
 *
 * The main validation functions are:
 * - checkUserCanGiveUpShift: Validates if a user can give up their shift
 * - checkUserCanTakeShift: Validates if a user can take on a new shift
 */

// A user taking a shiftAssignment might lead to the violation of a SingleGroupIncompatibilityRule max_simult on the slot of the new ShiftAssignment.
const breaksSingleGroupIncompatibilityRuleMaxSimult = (
  receivingUser: User,
  newShiftAssignment: ShiftAssignment,
  singleGroupIncompRule: SingleGroupIncompatibilityRule,
  teamAssignments: TeamAssignmentsResponse,
): false | SingleGroupIncompatibilityMaxSimultViolation => {
  // If the new ShiftAssignment is not on a slot that is part of the SingleGroupIncompatibilityRule, it cannot not break the rule.
  if (
    !(newShiftAssignment.id_section_slot in singleGroupIncompRule.section_slots)
  ) {
    return false;
  }

  const usersAssignedOnRule = Object.values(teamAssignments.itrs).flatMap(itr =>
    Object.values(itr.shift_assignments).flatMap(userAssignments =>
      Object.values(userAssignments).filter(
        assignment =>
          assignment.id_section_slot === newShiftAssignment.id_section_slot,
      ),
    ),
  );

  const maxSimult = singleGroupIncompRule.max_simult;
  const alreadyAssigned = usersAssignedOnRule.length;
  if (alreadyAssigned == maxSimult) {
    return {
      type: ShiftExchangeViolationType.SINGLE_GROUP_INCOMPATIBILITY_MAX_SIMULT_VIOLATION,
      context: {
        ruleId: singleGroupIncompRule.id_rule,
        maxSimult,
        usersAlreadyAssigned: usersAssignedOnRule.map(
          assignment => assignment.id_user,
        ),
      },
    };
  }
  return false;
};

// A user giving up a shift might lead to the violation of a SingleGroupIncompatibilityRule min_simult.
const breaksSingleGroupIncompatibilityRuleMinSimult = (
  givingUpUser: User,
  lostShiftAssignment: ShiftAssignment,
  singleGroupIncompRule: SingleGroupIncompatibilityRule,
  teamAssignments: TeamAssignmentsResponse,
): false | SingleGroupIncompatibilityMinSimultViolation => {
  // If the new ShiftAssignment is not on a slot that is part of the SingleGroupIncompatibilityRule, it cannot not break the rule.
  if (
    !(
      lostShiftAssignment.id_section_slot in singleGroupIncompRule.section_slots
    )
  ) {
    return false;
  }

  const usersAssignedOnRule = Object.values(teamAssignments.itrs).flatMap(itr =>
    Object.values(itr.shift_assignments).flatMap(userAssignments =>
      Object.values(userAssignments).filter(
        assignment =>
          assignment.id_section_slot === lostShiftAssignment.id_section_slot,
      ),
    ),
  );

  const minSimult = singleGroupIncompRule.min_simult;
  const alreadyAssigned = usersAssignedOnRule.length;
  if (alreadyAssigned == minSimult) {
    return {
      type: ShiftExchangeViolationType.SINGLE_GROUP_INCOMPATIBILITY_MIN_SIMULT_VIOLATION,
      context: {
        ruleId: singleGroupIncompRule.id_rule,
        minSimult,
        usersAlreadyAssigned: usersAssignedOnRule.map(
          assignment => assignment.id_user,
        ),
      },
    };
  }
  return false;
};

// A user taking a shiftAssignment might lead to the violation of a CrossGroupIncompatibilityRule on the slot of the new ShiftAssignment.
const breaksCrossGroupIncompatibilityRule = (
  crossGroupIncompRule: CrossGroupIncompatibilityRule,
  receivingUser: User,
  newShiftAssignment: ShiftAssignment,
  teamAssignments: TeamAssignmentsResponse,
): false | CrossGroupIncompatibilityViolation => {
  // If the new ShiftAssignment is not on a slot that is part of the CrossGroupIncompatibilityRule, it cannot not break the rule.
  if (
    !(newShiftAssignment.id_section_slot in crossGroupIncompRule.section_slots)
  ) {
    return false;
  }

  return false;
};

const isAssignedOnOverlappingSectionSlot = (
  newShiftAssignment: ShiftAssignment,
  allUserShiftAssignments: ShiftAssignment[],
  incompatibleSectionSlotsMap: IncompatibleSectionSlotsMap,
  lostShiftAssignment?: ShiftAssignment, // if provided, we will not check for overlapping assignments on the same slot
): false | OverlappingAssignmentViolation => {
  console.log(
    newShiftAssignment,
    allUserShiftAssignments,
    incompatibleSectionSlotsMap,
    lostShiftAssignment,
  );
  const overlappingAssignmentsSectionSlots = Object.values(
    allUserShiftAssignments,
  )
    .filter(assignment => {
      if (lostShiftAssignment) {
        return (
          assignment.id_section_slot !== lostShiftAssignment.id_section_slot
        );
      }
      return true;
    })
    .filter(assignment =>
      incompatibleSectionSlotsMap[
        assignment.id_section_slot
      ].overlappingSectionSlots.includes(newShiftAssignment.id_section_slot),
    )

    .map(assignment => assignment.id_section_slot);

  if (overlappingAssignmentsSectionSlots.length > 0) {
    return {
      type: ShiftExchangeViolationType.OVERLAPPING_ASSIGNMENT_VIOLATION,
      context: {
        overlappingSectionSlots: overlappingAssignmentsSectionSlots,
      },
    };
  }
  return false;
};

const exceedsUserReqMaxSlots = (
  userShiftRequirement: UserRequirement,
  newShiftAssignment: ShiftAssignment,
  userShiftAssignmentsOnRule: Record<number, ShiftAssignment>, // includes all shift assignments for the user on the rule
): false | UserReqMaxSlotsViolation => {
  if (userShiftRequirement.req_type !== UserRequirementType.SLOTS) {
    throw new Error(
      'exceedsUserReqMaxSlots called on  a non-slots UserRequirement',
    );
  }

  const maxSlots = userShiftRequirement.max_slots || 0;
  const alreadyAssignedSlots = Object.values(userShiftAssignmentsOnRule).length;
  if (maxSlots == alreadyAssignedSlots) {
    return {
      type: ShiftExchangeViolationType.USER_SHIFT_REQ_MAX_SLOTS_VIOLATION,
      context: {
        ruleId: userShiftRequirement.id_rule,
        maxSlots,
        alreadyAssignedSlots: alreadyAssignedSlots + 1, // +1 for the new shift assignment
      },
    };
  }
  return false;
};

const missesUserReqMinSlots = (
  userShiftRequirement: UserRequirement,
  lostShiftAssignment: ShiftAssignment,
  userShiftAssignmentsOnRule: Record<number, ShiftAssignment>,
): false | UserReqMinSlotsViolation => {
  if (userShiftRequirement.req_type !== UserRequirementType.SLOTS) {
    throw new Error(
      'exceedsUserReqMaxSlots called on  a non-slots UserRequirement',
    );
  }
  const minSlots = userShiftRequirement.min_slots || 0;
  const alreadyAssignedSlots = Object.values(userShiftAssignmentsOnRule).length;
  if (alreadyAssignedSlots == minSlots) {
    return {
      type: ShiftExchangeViolationType.USER_SHIFT_REQ_MIN_SLOTS_VIOLATION,
      context: {
        ruleId: userShiftRequirement.id_rule,
        minSlots,
        alreadyAssignedSlots: alreadyAssignedSlots - 1, // -1 for the lost shift assignment
      },
    };
  }
  return false;
};

const exceedsUserReqMaxDuration = (
  userShiftRequirement: UserRequirement,
  newShiftAssignment: ShiftAssignment,
  userShiftAssignmentsOnRule: Record<number, ShiftAssignment>, // includes all CURRENT shift assignments for the user on the rule
  allSectionSlots: Record<number, SectionSlot>,
  oneForOneLostShiftAssignment?: ShiftAssignment,
): false | UserReqMaxDurationViolation => {
  if (userShiftRequirement.req_type !== UserRequirementType.DURATION) {
    throw new Error(
      'exceedsUserReqMaxDuration called on  a non-duration UserRequirement',
    );
  }
  let totalAssignedDuration = 0; // total assigned duration AFTER the exchange is completed
  const alreadyAssignedDuration = Object.values(
    userShiftAssignmentsOnRule,
  ).reduce((total, assignment) => {
    const slot = allSectionSlots[assignment.id_section_slot];
    const durationInMinutes = getDifferenceInMinutes(slot.start, slot.end);
    return total + durationInMinutes;
  }, 0); // in minutes
  totalAssignedDuration = alreadyAssignedDuration;

  const newAssignmentSectionSlot =
    allSectionSlots[newShiftAssignment.id_section_slot];
  const newAssignmentDuration = getDifferenceInMinutes(
    newAssignmentSectionSlot.start,
    newAssignmentSectionSlot.end,
  );
  totalAssignedDuration += newAssignmentDuration;

  if (oneForOneLostShiftAssignment) {
    const lostAssignmentSectionSlot =
      allSectionSlots[oneForOneLostShiftAssignment.id_section_slot];
    const lostAssignmentDuration = getDifferenceInMinutes(
      lostAssignmentSectionSlot.start,
      lostAssignmentSectionSlot.end,
    );
    totalAssignedDuration -= lostAssignmentDuration;
  }

  const maxReqDuration = (userShiftRequirement.max_duration || 0) * 60; // in minutes
  if (maxReqDuration < totalAssignedDuration) {
    return {
      type: ShiftExchangeViolationType.USER_SHIFT_REQ_MAX_DURATION_VIOLATION,
      context: {
        maxReqDuration,
        ruleId: userShiftRequirement.id_rule,
        totalAssignedDuration, // in minutes, total assigned duration AFTER the exchange would be completed
      },
    };
  }
  return false;
};

const missesUserReqMinDuration = (
  userShiftRequirement: UserRequirement,
  lostShiftAssignment: ShiftAssignment,
  userShiftAssignmentsOnRule: Record<number, ShiftAssignment>,
  allSectionSlots: Record<number, SectionSlot>,
  oneForOneReceivedShiftAssignment?: ShiftAssignment,
): false | UserReqMinDurationViolation => {
  if (userShiftRequirement.req_type !== UserRequirementType.DURATION) {
    throw new Error(
      'exceedsUserReqMaxDuration called on  a non-duration UserRequirement',
    );
  }
  let totalAssignedDuration = 0; // total assigned duration AFTER the exchange is completed

  const alreadyAssignedDuration = Object.values(
    userShiftAssignmentsOnRule,
  ).reduce((total, assignment) => {
    const slot = allSectionSlots[assignment.id_section_slot];
    const durationInMinutes = getDifferenceInMinutes(slot.start, slot.end);
    return total + durationInMinutes;
  }, 0); // in minutes
  totalAssignedDuration = alreadyAssignedDuration;

  const lostAssignmentSectionSlot =
    allSectionSlots[lostShiftAssignment.id_section_slot];
  const lostAssignmentDuration = getDifferenceInMinutes(
    lostAssignmentSectionSlot.start,
    lostAssignmentSectionSlot.end,
  );
  totalAssignedDuration -= lostAssignmentDuration;

  if (oneForOneReceivedShiftAssignment) {
    const receivedAssignmentSectionSlot =
      allSectionSlots[oneForOneReceivedShiftAssignment.id_section_slot];
    const receivedAssignmentDuration = getDifferenceInMinutes(
      receivedAssignmentSectionSlot.start,
      receivedAssignmentSectionSlot.end,
    );
    totalAssignedDuration += receivedAssignmentDuration;
  }

  const minReqDuration = (userShiftRequirement.min_duration || 0) * 60; // in minutes
  if (totalAssignedDuration < minReqDuration) {
    return {
      type: ShiftExchangeViolationType.USER_SHIFT_REQ_MIN_DURATION_VIOLATION,
      context: {
        totalAssignedDuration, // in minutes, total assigned duration AFTER the exchange would be completed
        minReqDuration,
        ruleId: userShiftRequirement.id_rule,
      },
    };
  }
  return false;
};

// If the new ShiftAssignment is assigned on the rest_period of an existing ShiftAssignment, it will violate the rest_period of the existing ShiftAssignment.
const isAssignedOnExistingShiftRestPeriod = (
  shiftAssignment: ShiftAssignment,
  allUserShiftAssignments: ShiftAssignment[],
  incompatibleSectionSlotsMap: IncompatibleSectionSlotsMap,
  lostShiftAssignment?: ShiftAssignment, // if provided, we will not check for overlapping assignments on the same slot
): false | NewAssignmentOnRestPeriodViolation => {
  // Get the list of existing shift assignments whose rest period overlaps with the new ShiftAssignment.
  const overlappingRestPeriodShiftAssignments = Object.values(
    allUserShiftAssignments,
  )
    .filter(assignment => {
      if (lostShiftAssignment) {
        return (
          assignment.id_section_slot !== lostShiftAssignment.id_section_slot
        );
      }
      return true;
    })
    .filter(existingAssignment =>
      incompatibleSectionSlotsMap[
        existingAssignment.id_section_slot
      ].overlapedByRestPeriod.includes(shiftAssignment.id_section_slot),
    )
    .map(assignment => assignment.id_section_slot);
  if (overlappingRestPeriodShiftAssignments.length > 0) {
    return {
      type: ShiftExchangeViolationType.NEW_ASSIGNMENT_ON_REST_PERIOD_VIOLATION,
      context: {
        violatedRestPeriodShiftAssignments:
          overlappingRestPeriodShiftAssignments,
      },
    };
  }
  return false;
};

const hasExistingShiftOnNewRestPeriod = (
  shiftAssignment: ShiftAssignment,
  allUserShiftAssignments: ShiftAssignment[],
  incompatibleSectionSlotsMap: IncompatibleSectionSlotsMap,
  lostShiftAssignment?: ShiftAssignment, // if provided, we will not check for overlapping assignments on the same slot
): false | ExistingAssignmentOnNewRestPeriodViolation => {
  // Get the list of existing shift assignments that are ON the rest period of the new ShiftAssignment.
  const overlappedByNewRestPeriodShiftAssignments = Object.values(
    allUserShiftAssignments,
  )
    .filter(existingAssignment => {
      if (lostShiftAssignment) {
        return (
          existingAssignment.id_section_slot !==
          lostShiftAssignment.id_section_slot
        );
      }
      return true;
    })
    .filter(existingAssignment =>
      incompatibleSectionSlotsMap[
        existingAssignment.id_section_slot
      ].customRestPeriodOverlaps.includes(shiftAssignment.id_section_slot),
    )
    .map(assignment => assignment.id_section_slot);
  if (overlappedByNewRestPeriodShiftAssignments.length > 0) {
    return {
      type: ShiftExchangeViolationType.EXISTING_ASSIGNMENT_ON_NEW_REST_PERIOD_VIOLATION,
      context: {
        existingShiftAssignmentsOnNewRestPeriod:
          overlappedByNewRestPeriodShiftAssignments,
      },
    };
  }
  return false;
};

const isAssignedOnSpecialEvent = (
  shiftAssignment: ShiftAssignment,
  specialEvents: SpecialEvent[],
  allSectionSlots: Record<number, SectionSlot>,
): false | SpecialEventConflictViolation => {
  const sectionSlot = allSectionSlots[shiftAssignment.id_section_slot];
  const conflictingSpecialEvent = specialEvents.find(event =>
    datetimeRangeOverlap(
      new Date(event.start),
      new Date(event.end),
      new Date(sectionSlot.start),
      new Date(sectionSlot.end),
    ),
  );
  if (conflictingSpecialEvent) {
    return {
      type: ShiftExchangeViolationType.SPECIAL_EVENT_CONFLICT,
      context: {
        conflictingSpecialEvent: conflictingSpecialEvent.id_special_event,
      },
    };
  }
  return false;
};

export const checkUserCanGiveUpShift = (
  user: User,
  lostShiftAssignment: ShiftAssignment,
  userReqRules: Record<number, UserReqRule>,
  userShiftAssignments: Record<number, ShiftAssignment>,
  allSectionSlots: Record<number, SectionSlot>,
  oneForOneReceivedShiftAssignment?: ShiftAssignment,
): ShiftExchangeViolation[] => {
  const violations: ShiftExchangeViolation[] = [];

  // Check that the new ShiftAssignment does not conflict with any UserRequirement for the receiving user (puts them above the maximum).
  // Check each user requirement across all iterations
  Object.entries(userReqRules).forEach(([idRule, userReqRule]) => {
    // If the new ShiftAssignment is not in the user_req_rule, then we can skip the check
    if (
      !userReqRule.section_slots.includes(lostShiftAssignment.id_section_slot)
    ) {
      return;
    }

    if (!userReqRule.user_reqs[user.id]) {
      return;
    }

    const userShiftAssignmentsOnRule = Object.values(userShiftAssignments)
      .filter(assignment =>
        userReqRule.section_slots.includes(assignment.id_section_slot),
      )
      .reduce(
        (acc, assignment) => {
          acc[assignment.id_shift_assignment] = assignment;
          return acc;
        },
        {} as Record<number, ShiftAssignment>,
      );
    // Get the user requirement for the receiving user (MUST be present)
    const userRequirement = userReqRule.user_reqs[user.id];

    if (userRequirement.req_type === UserRequirementType.SLOTS) {
      if (oneForOneReceivedShiftAssignment) {
        // If this is a one-for-one exchange, and the received shift is in the same user_req_rule as the lost shift,
        // then we DO NOT need to check if there is a min violation
        if (
          userReqRule.section_slots.includes(
            oneForOneReceivedShiftAssignment.id_section_slot,
          ) &&
          userReqRule.section_slots.includes(
            lostShiftAssignment.id_section_slot,
          )
        ) {
          return;
        }
      }

      const userReqMinSlotsViolation = missesUserReqMinSlots(
        userRequirement,
        lostShiftAssignment,
        userShiftAssignmentsOnRule,
      );
      if (userReqMinSlotsViolation) {
        violations.push(userReqMinSlotsViolation);
      }
    } else if (userRequirement.req_type === UserRequirementType.DURATION) {
      if (oneForOneReceivedShiftAssignment) {
        // If this is a one-for-one exchange, and the received shift is in the same user_req_rule as the lost shift,
        // then we DO NOT need to check if there is a min violation
        if (
          userReqRule.section_slots.includes(
            oneForOneReceivedShiftAssignment.id_section_slot,
          ) &&
          userReqRule.section_slots.includes(
            lostShiftAssignment.id_section_slot,
          ) &&
          getDifferenceInMinutes(
            allSectionSlots[oneForOneReceivedShiftAssignment.id_section_slot]
              .start,
            allSectionSlots[oneForOneReceivedShiftAssignment.id_section_slot]
              .end,
          ) ===
            getDifferenceInMinutes(
              allSectionSlots[lostShiftAssignment.id_section_slot].start,
              allSectionSlots[lostShiftAssignment.id_section_slot].end,
            )
        ) {
          return;
        }
      }
      const userReqMinDurationViolation = missesUserReqMinDuration(
        userRequirement,
        lostShiftAssignment,
        userShiftAssignmentsOnRule,
        allSectionSlots,
        oneForOneReceivedShiftAssignment,
      );
      if (userReqMinDurationViolation) {
        violations.push(userReqMinDurationViolation);
      }
    } else {
      throw new Error(
        `Unsupported UserRequirementType: ${userRequirement.req_type}`,
      );
    }
  });
  return violations;
};

export const checkUserCanTakeNewShift = (
  user: User,
  receivedShiftAssignment: ShiftAssignment,
  userReqRules: Record<number, UserReqRule>,
  userShiftAssignments: Record<number, ShiftAssignment>,
  events: SpecialEvent[],
  incompatibleSectionSlotsMap: IncompatibleSectionSlotsMap,
  allSectionSlots: Record<number, SectionSlot>,
  oneForOneGivenShiftAssignment?: ShiftAssignment,
): ShiftExchangeViolation[] => {
  const violations: ShiftExchangeViolation[] = [];

  // Get all shift assignments for the user
  const allUserShiftAssignments: ShiftAssignment[] = Object.values(
    userShiftAssignments,
  ).filter(
    assignment =>
      !oneForOneGivenShiftAssignment ||
      assignment.id_shift_assignment !==
        oneForOneGivenShiftAssignment.id_shift_assignment,
  );

  // Check that the user is not already assigned to an overlapping shift.
  const overlappingShiftViolation = isAssignedOnOverlappingSectionSlot(
    receivedShiftAssignment,
    allUserShiftAssignments,
    incompatibleSectionSlotsMap,
    oneForOneGivenShiftAssignment,
  );
  if (overlappingShiftViolation) {
    violations.push(overlappingShiftViolation);
  }

  // Check that the user is assigned to a shift that would conflict with the rest_period of the new ShiftAssignment.
  const restPeriodViolation = isAssignedOnExistingShiftRestPeriod(
    receivedShiftAssignment,
    allUserShiftAssignments,
    incompatibleSectionSlotsMap,
    oneForOneGivenShiftAssignment,
  );
  if (restPeriodViolation) {
    violations.push(restPeriodViolation);
  }
  // Check that the new ShiftAssignment does not conflict with any existing shift assignment's rest_period.
  const newShiftRestPeriodViolation = hasExistingShiftOnNewRestPeriod(
    receivedShiftAssignment,
    allUserShiftAssignments,
    incompatibleSectionSlotsMap,
  );
  if (newShiftRestPeriodViolation) {
    violations.push(newShiftRestPeriodViolation);
  }

  // Check that the new ShiftAssignment does not conflict with any SpecialEvent for the receiving user.
  const specialEventConflict = isAssignedOnSpecialEvent(
    receivedShiftAssignment,
    events,
    allSectionSlots,
  );
  if (specialEventConflict) {
    violations.push(specialEventConflict);
  }

  // Check that the new ShiftAssignment does not conflict with any UserRequirement for the receiving user (puts them above the maximum).
  // Check each user requirement across all iterations
  Object.entries(userReqRules).forEach(([idRule, userReqRule]) => {
    // If the new ShiftAssignment is not in the user_req_rule, then we can skip the check
    if (
      !userReqRule.section_slots.includes(
        receivedShiftAssignment.id_section_slot,
      )
    ) {
      return;
    }

    if (!userReqRule.user_reqs[user.id]) {
      return;
    }

    const userShiftAssignmentsOnRule = Object.values(userShiftAssignments)
      .filter(assignment =>
        userReqRule.section_slots.includes(assignment.id_section_slot),
      )
      .reduce(
        (acc, assignment) => {
          acc[assignment.id_shift_assignment] = assignment;
          return acc;
        },
        {} as Record<number, ShiftAssignment>,
      );
    // Get the user requirement for the receiving user (MUST be present)
    const userRequirement = userReqRule.user_reqs[user.id];

    if (userRequirement.req_type === UserRequirementType.SLOTS) {
      if (oneForOneGivenShiftAssignment) {
        if (
          userReqRule.section_slots.includes(
            oneForOneGivenShiftAssignment.id_section_slot,
          )
        ) {
          // If this is a one-for-one exchange, and the given shift is in the same user_req_rule as the new shift,
          // then we DO NOT need to check if there is a max SLOTS violation as it will remain the same
          return;
        }
      }

      const userReqMaxSlotsViolation = exceedsUserReqMaxSlots(
        userRequirement,
        receivedShiftAssignment,
        userShiftAssignmentsOnRule,
      );
      if (userReqMaxSlotsViolation) {
        violations.push(userReqMaxSlotsViolation);
      }
    } else if (userRequirement.req_type === UserRequirementType.DURATION) {
      if (oneForOneGivenShiftAssignment) {
        if (
          userReqRule.section_slots.includes(
            oneForOneGivenShiftAssignment.id_section_slot,
          ) &&
          getDifferenceInMinutes(
            allSectionSlots[oneForOneGivenShiftAssignment.id_section_slot]
              .start,
            allSectionSlots[oneForOneGivenShiftAssignment.id_section_slot].end,
          ) ===
            getDifferenceInMinutes(
              allSectionSlots[receivedShiftAssignment.id_section_slot].start,
              allSectionSlots[receivedShiftAssignment.id_section_slot].end,
            )
        ) {
          // If this is a one-for-one exchange, and the given shift is in the same user_req_rule as the new shift,
          // and the duration of the given shift is the same as the new shift,
          // then we DO NOT need to check if there is a max DURATION violation as it will remain the same
          return;
        }
      }

      const userReqMaxDurationViolation = exceedsUserReqMaxDuration(
        userRequirement,
        receivedShiftAssignment,
        userShiftAssignmentsOnRule,
        allSectionSlots,
      );
      if (userReqMaxDurationViolation) {
        violations.push(userReqMaxDurationViolation);
      }
    } else {
      throw new Error(
        `Unsupported UserRequirementType: ${userRequirement.req_type}`,
      );
    }
  });

  return violations;
};

// Check that the requestor perform the shift exchange.
// [ Only has access to the data from the REQUESTOR's perspective ]
export const requestorCheckShiftExchange = (
  requestor: User,
  exchangeRequest: ExchangeRequest,
  exchangeResponse: ExchangeResponse,
  userLayoutContext: UserLayoutContext,
  allSectionSlots: Record<number, SectionSlot>,
  requestorTeamAssignments: TeamAssignmentsResponse, // includes all shift assignments for everyone in the requestor's team
  allRequestorShiftAssignments: Record<number, ShiftAssignment>, // includes all requestor's shift assignments for all iterations
  incompatibleSectionSlotsMap: IncompatibleSectionSlotsMap,
): ShiftExchangeViolation[] => {
  const lostShiftAssignment =
    allRequestorShiftAssignments[exchangeRequest.id_shift_assignment];

  const userReqRules = Object.values(userLayoutContext.userLayout.itrs)
    .flatMap(itr =>
      Object.entries(itr.user_req_rules).map(([id_rule, user_req_rule]) => ({
        id_rule: Number(id_rule),
        user_req_rule,
      })),
    )
    .reduce(
      (acc, { id_rule, user_req_rule }) => ({
        ...acc,
        [id_rule]: user_req_rule,
      }),
      {} as Record<number, UserReqRule>,
    );

  const userShiftAssignments = Object.values(userLayoutContext.userLayout.itrs)
    .flatMap(itr =>
      Object.entries(itr.shift_assignments).map(
        ([id_assignment, shift_assignment]) => ({
          id_assignment: Number(id_assignment),
          shift_assignment,
        }),
      ),
    )
    .reduce(
      (acc, { id_assignment, shift_assignment }) => ({
        ...acc,
        [id_assignment]: shift_assignment,
      }),
      {} as Record<number, ShiftAssignment>,
    );

  // One-for-zero
  if (exchangeResponse.type === ExchangeResponseType.ONE_FOR_ZERO) {
    // Check requestor can give up the shift
    const one_for_zero_violations = checkUserCanGiveUpShift(
      requestor,
      lostShiftAssignment,
      userReqRules,
      userShiftAssignments,
      allSectionSlots,
    );
    return one_for_zero_violations;
  }

  // One-for-one
  if (exchangeResponse.type === ExchangeResponseType.ONE_FOR_ONE) {
    const receivedShiftAssignment = Object.values(
      requestorTeamAssignments.itrs,
    ).find(
      itr =>
        itr.shift_assignments[exchangeResponse.id_respondent]?.[
          exchangeResponse.id_shift_assignment!
        ],
    )?.shift_assignments[exchangeResponse.id_respondent][
      exchangeResponse.id_shift_assignment!
    ];

    if (!receivedShiftAssignment) {
      console.warn(
        `Could not find shift assignment ${exchangeResponse.id_shift_assignment} for respondent ${exchangeResponse.id_respondent} on on exchange request ${exchangeRequest.id_request}`,
      );
      return [];
    }

    // Check requestor can give up the shift
    const giveUpShiftViolations = checkUserCanGiveUpShift(
      requestor,
      lostShiftAssignment,
      userReqRules,
      userShiftAssignments,
      allSectionSlots,
      receivedShiftAssignment,
    );

    // Check requestor can take the shift
    const takeShiftViolations = checkUserCanTakeNewShift(
      requestor,
      receivedShiftAssignment,
      userReqRules,
      userShiftAssignments,
      userLayoutContext.events.special_events,
      incompatibleSectionSlotsMap,
      allSectionSlots,
      lostShiftAssignment,
    );
    return [...giveUpShiftViolations, ...takeShiftViolations];
  }
  return [];
};

// Check that the requestor perform the shift exchange.
// [ Only has access to the data from the RESPONDENT's perspective ]
export const respondentCheckShiftExchange = (
  respondent: User,
  exchangeRequest: ExchangeRequest,
  exchangeResponse: ExchangeResponse,
  userLayoutContext: UserLayoutContext,
  allSectionSlots: Record<number, SectionSlot>,
  respondentTeamAssignments: TeamAssignmentsResponse, // includes all shift assignments for everyone in the respondent's team
  receivedShiftAssignment: ShiftAssignment,
  incompatibleSectionSlotsMap: IncompatibleSectionSlotsMap,
): ShiftExchangeViolation[] => {
  if (!receivedShiftAssignment) {
    console.warn(
      `Could not find shift assignment ${exchangeRequest.id_shift_assignment} for respondent ${exchangeRequest} on on exchange request ${exchangeRequest.id_request}`,
    );
  }

  const userReqRules = Object.values(userLayoutContext.userLayout.itrs)
    .flatMap(itr =>
      Object.entries(itr.user_req_rules).map(([id_rule, user_req_rule]) => ({
        id_rule: Number(id_rule),
        user_req_rule,
      })),
    )
    .reduce(
      (acc, { id_rule, user_req_rule }) => ({
        ...acc,
        [id_rule]: user_req_rule,
      }),
      {} as Record<number, UserReqRule>,
    );

  const userShiftAssignments = Object.values(userLayoutContext.userLayout.itrs)
    .flatMap(itr =>
      Object.entries(itr.shift_assignments).map(
        ([id_assignment, shift_assignment]) => ({
          id_assignment: Number(id_assignment),
          shift_assignment,
        }),
      ),
    )
    .reduce(
      (acc, { id_assignment, shift_assignment }) => ({
        ...acc,
        [id_assignment]: shift_assignment,
      }),
      {} as Record<number, ShiftAssignment>,
    );

  // One-for-zero
  if (exchangeResponse.type === ExchangeResponseType.ONE_FOR_ZERO) {
    // Check respondent can give up the shift
    const one_for_zero_violations = checkUserCanTakeNewShift(
      respondent,
      receivedShiftAssignment,
      userReqRules,
      userShiftAssignments,
      userLayoutContext.events.special_events,
      incompatibleSectionSlotsMap,
      allSectionSlots,
    );
    return one_for_zero_violations;
  }

  // One-for-one
  if (exchangeResponse.type === ExchangeResponseType.ONE_FOR_ONE) {
    const givenShiftAssignment = Object.values(
      respondentTeamAssignments.itrs,
    ).find(
      itr =>
        itr.shift_assignments[exchangeResponse.id_respondent]?.[
          exchangeResponse.id_shift_assignment!
        ],
    )?.shift_assignments[exchangeResponse.id_respondent][
      exchangeResponse.id_shift_assignment!
    ];

    if (!givenShiftAssignment) {
      console.warn(
        `Could not find shift assignment ${exchangeResponse.id_shift_assignment} for respondent ${exchangeResponse.id_respondent} on on exchange request ${exchangeRequest.id_request}`,
      );
      return [];
    }

    // Check requestor can give up the shift
    const giveUpShiftViolations = checkUserCanGiveUpShift(
      respondent,
      givenShiftAssignment,
      userReqRules,
      userShiftAssignments,
      allSectionSlots,
      receivedShiftAssignment,
    );

    // Check respondent can take the shift
    const takeShiftViolations = checkUserCanTakeNewShift(
      respondent,
      receivedShiftAssignment,
      userReqRules,
      userShiftAssignments,
      userLayoutContext.events.special_events,
      incompatibleSectionSlotsMap,
      allSectionSlots,
      givenShiftAssignment,
    );
    return [...giveUpShiftViolations, ...takeShiftViolations];
  }
  return [];
};

export const managerCheckShiftExchange = (
  requestor: User,
  exchangeRequest: ExchangeRequest,
  respondent: User,
  exchangeResponse: ExchangeResponse,
  allSectionSlots: Record<number, SectionSlot>,
  allShiftAssignments: Record<number, ShiftAssignment>,
  allUserReqRules: Record<number, UserReqRule>,
  allEvents: SpecialEvent[],
  incompatibleSectionSlotsMap: IncompatibleSectionSlotsMap,
): {
  requestor: ShiftExchangeViolation[];
  respondent: ShiftExchangeViolation[];
} => {
  const violations = {
    requestor: [] as ShiftExchangeViolation[],
    respondent: [] as ShiftExchangeViolation[],
  };
  const requestShiftAssignment =
    allShiftAssignments[exchangeRequest.id_shift_assignment];

  const respondentShiftAssignments = Object.values(allShiftAssignments).filter(
    assignment => assignment.id_user === respondent.id,
  );
  const requestorShiftAssignments = Object.values(allShiftAssignments).filter(
    assignment => assignment.id_user === requestor.id,
  );
  const respondentEvents = allEvents.filter(
    event => event.id_user === respondent.id,
  );
  const requestorEvents = allEvents.filter(
    event => event.id_user === requestor.id,
  );

  // One-for-zero
  if (exchangeResponse.type === ExchangeResponseType.ONE_FOR_ZERO) {
    // Check requestor can give up the shift
    const requestorGiveUpShiftViolations = checkUserCanGiveUpShift(
      requestor,
      requestShiftAssignment,
      allUserReqRules,
      requestorShiftAssignments,
      allSectionSlots,
    );
    violations.requestor.push(...requestorGiveUpShiftViolations);

    const respondentTakeShiftViolations = checkUserCanTakeNewShift(
      respondent,
      requestShiftAssignment,
      allUserReqRules,
      respondentShiftAssignments,
      respondentEvents,
      incompatibleSectionSlotsMap,
      allSectionSlots,
    );
    violations.respondent.push(...respondentTakeShiftViolations);
  }

  // One-for-one
  if (exchangeResponse.type === ExchangeResponseType.ONE_FOR_ONE) {
    const responseShiftAssignment =
      allShiftAssignments[exchangeResponse.id_shift_assignment!];

    if (!responseShiftAssignment) {
      console.warn(
        `Could not find shift assignment ${exchangeResponse.id_shift_assignment} for respondent ${exchangeResponse.id_respondent} on on exchange request ${exchangeRequest.id_request}`,
      );
    }

    // Check requestor can give up the shift
    const requestorGiveUpShiftViolations = checkUserCanGiveUpShift(
      requestor,
      requestShiftAssignment,
      allUserReqRules,
      requestorShiftAssignments,
      allSectionSlots,
      responseShiftAssignment,
    );
    violations.requestor.push(...requestorGiveUpShiftViolations);

    // Check requestor can take the shift
    const requestorTakeShiftViolations = checkUserCanTakeNewShift(
      requestor,
      responseShiftAssignment,
      allUserReqRules,
      requestorShiftAssignments,
      requestorEvents,
      incompatibleSectionSlotsMap,
      allSectionSlots,
      requestShiftAssignment,
    );
    violations.requestor.push(...requestorTakeShiftViolations);

    const respondentGiveUpShiftViolations = checkUserCanGiveUpShift(
      respondent,
      responseShiftAssignment,
      allUserReqRules,
      respondentShiftAssignments,
      allSectionSlots,
    );
    violations.respondent.push(...respondentGiveUpShiftViolations);

    const respondentTakeShiftViolations = checkUserCanTakeNewShift(
      respondent,
      requestShiftAssignment,
      allUserReqRules,
      respondentShiftAssignments,
      respondentEvents,
      incompatibleSectionSlotsMap,
      allSectionSlots,
      responseShiftAssignment,
    );
    violations.respondent.push(...respondentTakeShiftViolations);
  }
  return violations;
};
// Check that the new ShiftAssignment does not conflict with any checkSingleGroupIncompatibilityRuleMaxSimult on the slot of the new ShiftAssignment.

//   // Check that the new ShiftAssignment does not conflict with any checkSingleGroupIncompatibilityRuleMaxSimult on the slot of the new ShiftAssignment.
//   Object.entries(itr.incomp_rules.single_group_incomp).forEach(
//     ([idRule, singleGroupIncompRule]) => {
//       const singleGroupIncompRuleMaxSimultConflict =
//         breaksSingleGroupIncompatibilityRuleMaxSimult(
//           receivingUser,
//           newShiftAssignment,
//           singleGroupIncompRule,
//           teamAssignments,
//         );
//       if (singleGroupIncompRuleMaxSimultConflict) {
//         violations.push(singleGroupIncompRuleMaxSimultConflict);
//       }
//     },
//   );

//   Object.entries(itr.incomp_rules.cross_group_incomp).forEach(
//     ([idRule, crossGroupIncompRule]) => {
//       const crossGroupIncompRuleConflict =
//         breaksCrossGroupIncompatibilityRule(
//           crossGroupIncompRule,
//           receivingUser,
//           newShiftAssignment,
//           teamAssignments,
//         );
//       if (crossGroupIncompRuleConflict) {
//         violations.push(crossGroupIncompRuleConflict);
//       }
//     },
//   );
// });
