import { AtSymbolIcon } from '@heroicons/react/20/solid';
import {
  DocumentArrowDownIcon,
  DocumentIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import { localeNormalizer } from '@youshift/shared/utils';
import toast from 'react-hot-toast';
import { useSendHelpRequestMutation } from '@youshift/shared/hooks/mutations';

import Alert from '../components/FormFeedback/Alert';

interface Video {
  name: string;
  url: string;
}

interface Videos {
  manager: Video[];
  user: Video[];
}

interface Guides {
  manager: { title: string; url: string };
  user: { title: string; url: string };
}

interface HelpProps {
  manager: boolean;
}

const videos = {
  manager: [
    { name: 'Tutorial completo', url: '8Dn965vdHVA' },
    { name: 'Tutorial del usuario', url: 'FvB13UpEZPA' },
    {
      name: 'Introducción - Portal del gestor',
      url: 'https://www.youtube.com/watch?v=13D0i8ZCG3M&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP',
    },
    {
      name: 'Gestionar el Equipo - Personal',
      url: 'https://www.youtube.com/watch?v=xUkiDZ8He0s&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP&index=2',
    },
    {
      name: 'Gestionar el Equipo - Roles',
      url: 'https://www.youtube.com/watch?v=llIQVcjsAfE&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP&index=3',
    },
    {
      name: 'Crear Primer Reparto',
      url: 'https://www.youtube.com/watch?v=HtKw0eVHdqY&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP&index=4',
    },
    {
      name: 'Configurar el reparto: Roles y participantes',
      url: 'https://www.youtube.com/watch?v=7aZLE52jAjQ&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP&index=5',
    },
    {
      name: 'Configurar el Reparto: Secciones a Cubrir',
      url: 'https://www.youtube.com/watch?v=rWT8EZmQU_A&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP&index=6',
    },
    {
      name: 'Configurar el Reparto: Días especiales',
      url: 'https://www.youtube.com/watch?v=xFcxRrZDUyY&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP&index=7',
    },
    {
      name: 'Configurar el Reparto: Incompatibilidades',
      url: 'https://www.youtube.com/watch?v=G4LBTy6eiS4&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP&index=8',
    },
    {
      name: 'Configurar el Reparto: Conjuntos de secciones',
      url: 'https://www.youtube.com/watch?v=PjGD4F7mXeI&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP&index=9',
    },
    {
      name: 'Configurar el Reparto: Preferencias de los usuarios',
      url: 'https://www.youtube.com/watch?v=IntN0-K_TBs&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP&index=10',
    },
    {
      name: 'Calcular reparto',
      url: 'https://www.youtube.com/watch?v=-BrInW3NfHs&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP&index=11',
    },
    {
      name: 'Verificar reparto y publicar',
      url: 'https://www.youtube.com/watch?v=C-VtH58MB_s&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP&index=12',
    },
    {
      name: 'Siguientes pasos tras el primer reparto',
      url: 'https://www.youtube.com/watch?v=Y34oCzhV-MY&list=PLhTVaBWuROpVcdTbUdKJRImZc4f3aX7KP&index=13',
    },
    {
      name: 'Intercambio de guardias - Gestor',
      url: 'https://youtu.be/Otw4poVhqaA',
    },
    {
      name: 'Intercambio de guardias - Usuario',
      url: 'https://www.youtube.com/watch?v=gN-Ep8AuOVY',
    },
  ],
  user: [
    { name: 'Preferencias', url: 'BWQoNS45A5U' },
    { name: 'Intercambio de guardias', url: 'gN-Ep8AuOVY' },
  ],
};

export default function Help({ manager }: HelpProps) {
  const [selected, setSelected] = useState<
    'guides' | 'email' | 'whatsapp' | null
  >('guides');

  const { t, i18n } = useTranslation();
  const locale = localeNormalizer(i18n.language);

  // const guides = {
  //   manager: {
  //     title: t('helpCenter.managerManual'),
  //     url: `https://youshift-docs.s3.eu-west-3.amazonaws.com/YouShift_manual_gestor_${locale}.pdf`,
  //   },
  //   user: {
  //     title: t('helpCenter.userManual'),
  //     url: `https://youshift-docs.s3.eu-west-3.amazonaws.com/YouShift_manual_usuarios_${locale}.pdf`,
  //   },
  // };

  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');

  const queryClient = useQueryClient();

  function classNames(...classes: string[]): string {
    return classes.filter(Boolean).join(' ');
  }

  const sendHelpRequestMutation = useSendHelpRequestMutation(queryClient, {
    onSuccess: () => {
      toast.success(t('contact.successMessage'));
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!message || !subject) {
      toast.error(t('generic.formValidation'));
      return;
    }

    // Prepare the form data
    const formData = {
      // firstname: name,
      // lastname,
      // email,
      // phone,
      // hospital,
      message,
      subject,
      // language: localStorage.getItem('i18nextLng')
    };

    // Send the form data to the endpoint
    sendHelpRequestMutation.mutate(formData);
  };

  return (
    <div className="bg-white px-6 py-16 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <p className="text-base font-semibold leading-7 text-blue-600">
          {t('helpCenter.header')}
        </p>
        <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          {t('helpCenter.title')}
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          {t('helpCenter.description')}
        </p>
      </div>

      <div className="flex flex-col gap-4 items-center lg:flex-row mt-12 justify-around">
        <button
          className={classNames(
            selected === 'guides' ? 'border border-blue-600' : 'border-gray-50',
            'w-4/5 lg:w-1/4 rounded-xl shadow-lg  flex flex-row justify-start items-center border hover:border-blue-600',
          )}
          onClick={() => setSelected('guides')}
        >
          <DocumentIcon className="h-16 my-auto p-2 bg-blue-600 text-white rounded-xl" />
          <h3 className="text-xl font-medium text-gray-900 ml-4">
            {t('helpCenter.guidesAndVideos')}
          </h3>
        </button>

        <button
          className={classNames(
            selected === 'email' ? 'border border-amber-300' : 'border-gray-50',
            'w-4/5 lg:w-1/4 rounded-xl shadow-lg  flex flex-row justify-start items-center border hover:border-amber-300 ',
          )}
          onClick={() =>
            selected !== 'email' ? setSelected('email') : setSelected(null)
          }
        >
          <AtSymbolIcon className="h-16 my-auto p-2 bg-amber-300 text-white rounded-xl" />
          <h3 className="text-xl font-medium text-gray-900 ml-4">
            {t('helpCenter.email')}
          </h3>
        </button>

        <button
          className={classNames(
            selected === 'whatsapp'
              ? 'border border-green-600'
              : 'border-gray-50',
            'w-4/5 lg:w-1/4 rounded-xl shadow-lg flex flex-row justify-start items-center border hover:border-green-600 ',
          )}
          onClick={() =>
            selected !== 'whatsapp'
              ? setSelected('whatsapp')
              : setSelected(null)
          }
        >
          <img
            src="https://youshift-docs.s3.eu-west-3.amazonaws.com/whatsapp.png"
            className="w-16 h-16"
            alt="whatsapp"
          />
          <h3 className="text-xl font-medium text-gray-900 ml-4">
            {t('helpCenter.whatsapp')}
          </h3>
        </button>
      </div>

      {selected === 'email' ? (
        <form onSubmit={handleSubmit} className="mx-auto mt-12">
          <div className="mx-auto max-w-xl lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('contact.subject')}
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="subject"
                    id="subject"
                    value={subject}
                    rows={4}
                    onChange={e => setSubject(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {t('contact.message')}
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    rows={4}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="my-6">
              <button
                type="submit"
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-semibold text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600"
              >
                {t('contact.sendMessage')}
              </button>
            </div>
            {/* <p className="mb-2">
                {t('contact.scheduleCall')}
                {' '}
                <a
                  target="_blank"
                  href="https://calendly.com/you-shift/contacto"
                  className="text-blue-600"
                  rel="noreferrer"
                >
                  {t('contact.scheduleCallLink')}
                </a>
                {' '}
                {t('contact.withUs')}
              </p> */}
            <p className="mb-2">
              {t('helpCenter.emailInfo')}{' '}
              <a className="text-blue-600" href="mailto:info@you-shift.com">
                {t('helpCenter.ourEmail')}
              </a>
            </p>
          </div>
        </form>
      ) : null}

      {selected === 'whatsapp' ? (
        <div className="w-1/2 mt-12 mx-auto">
          <p>
            {t('helpCenter.whatsappInfo')}{' '}
            <a
              className="text-blue-600"
              href="https://wa.me/message/7FJ73LR2RA6JE1"
            >
              {t('helpCenter.whatsappInfo2')}
            </a>
            {t('helpCenter.whatsappInfo3')}{' '}
            <a
              className="text-blue-600"
              href="https://wa.me/message/7FJ73LR2RA6JE1"
            >
              {t('helpCenter.whatsappInfo4')}
            </a>{' '}
            {t('helpCenter.whatsappInfo5')}
          </p>
          <img
            src="https://youshift-docs.s3.eu-west-3.amazonaws.com/qrCode.png"
            className="w-64 h-64 mt-12 mx-auto"
            alt="whatsapp"
          />
        </div>
      ) : null}

      {selected === 'guides' && (
        <>
          {/* <a
            href={guides[manager ? 'manager' : 'user'].url}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className={
                manager
                  ? 'bg-teal-500 py-4 px-6 rounded-lg flex flex-row gap-4 font-semibold text-white hover:bg-teal-500/80 mt-12'
                  : 'bg-blue-500 py-4 px-6 rounded-lg flex flex-row gap-4 font-semibold text-white hover:bg-blue-500/80 mt-12'
              }
            >
              <DocumentArrowDownIcon className="h-6" />
              {guides[manager ? 'manager' : 'user'].title}
            </div>
          </a> */}
          {locale === 'es' && (
            <>
              {/* video 1 of the big ones */}
              <div className="relative mb-6 mt-12 mx-auto">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-start">
                  <span className="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">
                    {videos[manager ? 'manager' : 'user'][0].name}
                  </span>
                </div>
              </div>
              {/* mobile */}
              <a
                href={`https://www.youtube.com/watch?v=${videos[manager ? 'manager' : 'user'][0].url}`}
                target="_blank"
                rel="noreferrer"
                className="flex lg:hidden"
              >
                <div
                  className={
                    manager
                      ? 'bg-teal-500 py-4 px-6 rounded-lg flex flex-row gap-4 font-semibold text-white hover:bg-teal-500/80'
                      : 'bg-blue-500 py-4 px-6 rounded-lg flex flex-row gap-4 font-semibold text-white hover:bg-blue-500/80'
                  }
                >
                  <VideoCameraIcon className="h-6" />
                  {videos[manager ? 'manager' : 'user'][0].name}
                </div>
              </a>
              {/* web */}
              <div className="lg:flex-row justify-center items-center hidden lg:flex">
                <YouTube
                  videoId={videos[manager ? 'manager' : 'user'][0].url}
                />
              </div>

              {/* video 2 of the big ones */}
              <div className="relative mb-6 mt-12 mx-auto">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-start">
                  <span className="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">
                    {videos[manager ? 'manager' : 'user'][1].name}
                  </span>
                </div>
              </div>
              {/* web */}
              <div className="lg:flex-row justify-center items-center hidden lg:flex">
                <YouTube
                  videoId={videos[manager ? 'manager' : 'user'][1].url}
                />
              </div>
              {/* mobile */}
              <a
                href={`https://www.youtube.com/watch?v=${videos[manager ? 'manager' : 'user'][1].url}`}
                target="_blank"
                rel="noreferrer"
                className="flex lg:hidden"
              >
                <div
                  className={
                    manager
                      ? 'bg-teal-500 py-4 px-6 rounded-lg flex flex-row gap-4 font-semibold text-white hover:bg-teal-500/80'
                      : 'bg-blue-500 py-4 px-6 rounded-lg flex flex-row gap-4 font-semibold text-white hover:bg-blue-500/80'
                  }
                >
                  <VideoCameraIcon className="h-6" />
                  {videos[manager ? 'manager' : 'user'][1].name}
                </div>
              </a>
              {manager
                ? videos[manager ? 'manager' : 'user'].length > 1 && (
                    <div className="relative mb-6 mt-12 mx-auto">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full border-t border-gray-300" />
                      </div>
                      <div className="relative flex justify-start">
                        <span className="bg-white pr-3 text-base font-semibold leading-6 text-gray-900">
                          Tutoriales por partes
                        </span>
                      </div>
                    </div>
                  )
                : null}
              <div className="md:grid md:grid-cols-2 md:gap-4">
                {selected === 'guides' &&
                  videos[manager ? 'manager' : 'user'].length > 1 &&
                  videos[manager ? 'manager' : 'user']
                    .slice(2)
                    .map((video, index) => (
                      <a href={video.url} target="_blank" rel="noreferrer">
                        <p
                          className={
                            manager
                              ? 'w-5 h-5 top-3 right-1 text-center bg-blue-600 rounded-xl relative text-sm text-white'
                              : 'w-5 h-5 top-3 right-1 text-center bg-teal-600 rounded-xl relative text-sm text-white'
                          }
                        >
                          {index + 1}
                        </p>
                        <div
                          className={
                            manager
                              ? 'bg-teal-500 py-4 px-6 rounded-lg flex flex-row gap-4 font-semibold text-white hover:bg-teal-500/80'
                              : 'bg-blue-500 py-4 px-6 rounded-lg flex flex-row gap-4 font-semibold text-white hover:bg-blue-500/80'
                          }
                        >
                          <VideoCameraIcon className="h-6" />
                          {video.name}
                        </div>
                      </a>
                    ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
